import React, {ChangeEvent} from 'react'
import {useIntl} from 'react-intl'
import {ControllerRenderProps} from 'react-hook-form'
import {Box, Text} from '../../vanilla'
import {
  MAX_BONUS_CARD_FIELD_LENGTH,
  MAX_FIELD_LENGTH,
  PHONE_REGEXP,
  POSTCODE_REGEXP,
} from '../../utils/constants'
import {formatPhoneNumber} from '../../utils/phone-utils'
import {validateEmail} from '../../utils/email-utils'
import {
  ContactAddressFormFields,
  ContactUsBonusCardFormFields,
  ContactUsHomeDeliveryFormFields,
  ContactUsOnlineFormFields,
  ContactUsPersonalDetailsFormFields,
  ContactUsProductFormFields,
  CreateFields,
  MessageSubjectFormFields,
} from '../../types/forms'

export const useContactUsPersonalDetailsFields: CreateFields<
  ContactUsPersonalDetailsFormFields
> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    firstName: {
      name: `firstname`,
      label: formatMessage({defaultMessage: 'First Name*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `First Name cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`firstname`],
      control,
    },
    lastName: {
      name: `lastname`,
      label: formatMessage({defaultMessage: 'Last Name*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Last Name cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`lastname`],
      control,
    },
    email: {
      name: `email`,
      label: formatMessage({defaultMessage: 'Email address*'}),
      defaultValue: '',
      type: 'email' as const,
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Email cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
        validate: (value: string) =>
          validateEmail(value).isValid ||
          formatMessage({defaultMessage: 'Please enter a valid email address.'}),
      },
      inputProps: {
        // For login fields browser still tries to autofill even if autocomplete is off... So setting `new-password` to fix it.
        // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        autoComplete: 'new-password',
        maxLength: MAX_FIELD_LENGTH,
      },
      error: errors[`email`],
      control,
    },
    phone: {
      name: `phone`,
      label: formatMessage({defaultMessage: 'Telephone Number*'}),
      defaultValue: '',
      type: 'tel' as const,
      rules: {
        pattern: {
          value: PHONE_REGEXP,
          message: 'Please enter a valid phone number',
        },
        required: formatMessage({defaultMessage: 'Please enter your phone number'}),
      },
      error: errors[`phone`],
      inputProps: ({onChange}: Pick<ControllerRenderProps, 'onChange'>) => ({
        onChange(evt: ChangeEvent<HTMLInputElement>) {
          onChange(formatPhoneNumber(evt.target.value))
        },
      }),
      control,
    },
  }

  return fields
}

export const useContactUsAddressFields: CreateFields<ContactAddressFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    streetAddress: {
      name: `streetlineone`,
      label: formatMessage({defaultMessage: 'Street Address*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Street Address cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`streetlineone`],
      control,
    },
    streetAddress2: {
      name: `streetlinetwo`,
      label: '',
      type: 'text' as const,
      defaultValue: '',
      rules: {
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Street Address cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`streetlinetwo`],
      control,
    },
    city: {
      name: `townorcity`,
      label: formatMessage({defaultMessage: 'Town or City*'}),
      defaultValue: '',
      type: 'text' as const,
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Town or city cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
      },
      error: errors[`townorcity`],
      control,
    },
    county: {
      name: `county`,
      label: formatMessage({defaultMessage: 'County*'}),
      defaultValue: '',
      type: 'text' as const,
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `County cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
      },
      error: errors[`county`],
      control,
    },
    postcode: {
      name: `postal`,
      label: formatMessage({defaultMessage: 'Postcode*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        pattern: {
          value: POSTCODE_REGEXP,
          message: 'Please specify a valid postal code.',
        },
      },
      inputProps: ({onChange, onBlur}: Pick<ControllerRenderProps, 'onChange' | 'onBlur'>) => ({
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          onBlur()
          onChange(e.target.value.trim().toUpperCase())
        },
      }),
      error: errors[`postal`],
      control,
    },
  }

  return fields
}

export const useMessageSubjectFields: CreateFields<MessageSubjectFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    messageSubject: {
      name: `messagesubject`,
      label: formatMessage({defaultMessage: 'Message Subject*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
      },
      error: errors[`messagesubject`],
      control,
    },
    comment: {
      name: `comment`,
      label: formatMessage({defaultMessage: 'How can we help you today?'}),
      type: 'textarea' as const,
      defaultValue: '',
      inputProps: {
        withCharsCounter: true,
        maxLength: 1000,
        rows: 8,
        cols: 40,
        size: 'md',
      },
      error: errors[`comment`],
      control,
    },
  }

  return fields
}

export const useContactUsInHomeDeliveryFields: CreateFields<ContactUsHomeDeliveryFormFields> = ({
  form: {
    control,
    formState: {errors},
    setValue,
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    storeName: {
      name: `storename`,
      label: formatMessage({defaultMessage: 'Store Name'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Store Name cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
      },
      helpText: (
        <Box marginLeft="8px">
          <Text variant="text2" lineHeight="none" color="textMuted">
            eg. Iceland Bristol
          </Text>
        </Box>
      ),
      error: errors[`storename`],
      control,
    },
  }

  return fields
}

export const useContactUsBonusCardFields: CreateFields<ContactUsBonusCardFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const fields = {
    birthday: {
      name: `dateofbirth`,
      type: 'text' as const,
      error: errors[`dateofbirth`],
      control,
    },
    bonusCardNumber: {
      name: `bonuscard`,
      label: '00000000000',
      type: 'number' as const,
      rules: {
        maxLength: {
          value: MAX_BONUS_CARD_FIELD_LENGTH,
          message: `Bonus Card number cannot be longer than ${MAX_BONUS_CARD_FIELD_LENGTH} characters.`,
        },
      },
      defaultValue: '',
      error: errors[`bonuscard`],
      control,
    },
  }

  return fields
}

export const useContactUsOnlineFields: CreateFields<ContactUsOnlineFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    subjectDropdown: {
      name: `messagesubject`,
      type: 'select' as const,
      options: [
        {label: 'Payment Issue', value: 'Payment Issue'},
        {label: 'Missing Items', value: 'Missing Items'},
        {label: 'Out of Stock', value: 'Out of Stock'},
        {label: 'Late Delivery', value: 'Late Delivery'},
        {label: 'Damaged Items', value: 'Damaged Items'},
        {label: 'Cancelled my Order', value: 'Cancelled my Order'},
        {label: 'Don’t Like Substitute Item', value: 'Don’t Like Substitute Item'},
        {label: 'I Didn’t Order This', value: 'I Didn’t Order This'},
      ],
      error: errors[`subjectDropDown`],
      control,
    },
    orderNumber: {
      name: `ordernumber`,
      label: formatMessage({defaultMessage: 'Order Number*'}),
      type: 'number' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: 9,
          message: `Please enter no more than 9 characters.`,
        },
        minLength: {
          value: 9,
          message: `Please enter at least 9 characters.`,
        },
      },
      error: errors[`ordernumber`],
      control,
    },
  }

  return fields
}

export const useContactUsProductFields: CreateFields<ContactUsProductFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    productName: {
      name: `productname`,
      label: formatMessage({defaultMessage: 'Product Name*'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Product Name cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
        required: formatMessage({defaultMessage: 'This field is required.'}),
      },
      helpText: (
        <Box marginLeft="8px">
          <Text variant="text2" lineHeight="none" color="textMuted">
            eg. Iceland Fish Fingers
          </Text>
        </Box>
      ),
      error: errors[`productname`],
      control,
    },
    pricePaid: {
      name: `pricepaid`,
      label: formatMessage({defaultMessage: 'Price Paid'}),
      type: 'number' as const,
      defaultValue: '',
      rules: {
        maxLength: {
          value: 6,
          message: `Price Paid cannot be longer than 6 characters.`,
        },
      },
      helpText: (
        <Box marginLeft="8px">
          <Text variant="text2" lineHeight="none" color="textMuted">
            eg. 1.50
          </Text>
        </Box>
      ),
      error: errors[`pricepaid`],
      control,
    },
    barcode: {
      name: `barcode`,
      label: formatMessage({defaultMessage: 'Barcode'}),
      type: 'number' as const,
      defaultValue: '',
      rules: {
        maxLength: {
          value: 15,
          message: `Barcode cannot be longer than 15 characters.`,
        },
      },
      error: errors[`barcode`],
      control,
    },
    bestBeforeDate: {
      name: `bestbefore`,
      type: 'text' as const,
      error: errors[`bestbefore`],
      control,
    },
    otherCodes: {
      name: `othercodes`,
      type: 'text' as const,
      label: formatMessage({defaultMessage: 'Any other codes'}),
      error: errors[`othercodes`],
      control,
    },
  }

  return fields
}
