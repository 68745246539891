import React from 'react'
import {ColorPicker, TextAlignment} from '../../types/cms'
import {BaseButton, Box, Flex, Stack, Text} from '../../vanilla'
import {Link} from '../link'
import {Markdown} from './Markdown'

interface BoxProps {
  textAlignment: TextAlignment
  ctaButtonColor: ColorPicker
  ctaTextColor: ColorPicker
  ctaBackgroundColor: ColorPicker
  isButton: boolean
  boxHeading?: string
  boxSubheading?: string
  boxRichText: string
  ctaText: string
  ctaTextLink: string
  backgroundColor?: ColorPicker
  headlineTextColor?: ColorPicker
}

interface ContentBoxProps {
  content: {
    headingAlignment: boolean
    heading?: string
    headlineTextColor?: ColorPicker
    box: Array<BoxProps>
    _meta: {
      deliveryId: string
    }
  }
}

export const ContentBox = ({content}: ContentBoxProps) => {
  if (!content) {
    return null
  }
  const {headingAlignment, headlineTextColor, box, heading, _meta} = content
  return (
    <Stack spacing={['20px', '28px']}>
      {heading && (
        <Text
          variant="heading5"
          textAlign={headingAlignment ? 'center' : 'left'}
          style={{color: headlineTextColor?.color || '#4a4b4d'}}
        >
          {heading}
        </Text>
      )}
      <Flex gap="20px" direction={['column', 'row']}>
        {box?.map(
          (
            {
              backgroundColor,
              boxHeading,
              boxRichText,
              boxSubheading,
              ctaBackgroundColor,
              ctaButtonColor,
              ctaText,
              ctaTextColor,
              ctaTextLink,
              headlineTextColor,
              isButton,
              textAlignment,
            },
            index
          ) => {
            const alignment = (textAlignment || 'center')?.toLowerCase() as
              | 'left'
              | 'right'
              | 'center'
            const headingStyle = {
              color: headlineTextColor?.color || '#4a4b4d',
              textAlign: alignment,
            }
            return (
              <Stack
                flex="1"
                key={`${_meta.deliveryId}-${index}`}
                padding="20px"
                spacing="24px"
                border="1px"
                borderColor="gray400"
                style={{backgroundColor: backgroundColor?.color || 'white'}}
              >
                {boxHeading && (
                  <Box>
                    <Text style={{color: headlineTextColor?.color || '#4a4b4d'}} variant="heading2">
                      {boxHeading}
                    </Text>
                    {boxSubheading && (
                      <Text variant="text6" style={{color: headlineTextColor?.color || '#4a4b4d'}}>
                        {boxSubheading}
                      </Text>
                    )}
                  </Box>
                )}
                <Markdown
                  markdown={boxRichText || ''}
                  componentProps={{
                    p: {
                      textAlign: alignment,
                      variant: 'text3',
                      lineHeight: 'short',
                    },
                    h1: {
                      style: {
                        ...headingStyle,
                      },
                    },
                    h2: {
                      variant: 'text6',
                      style: {
                        ...headingStyle,
                      },
                    },
                    h3: {
                      style: {
                        ...headingStyle,
                      },
                    },
                    h4: {
                      style: {
                        ...headingStyle,
                      },
                    },
                    h5: {
                      style: {
                        ...headingStyle,
                      },
                    },
                    h6: {
                      style: {
                        ...headingStyle,
                      },
                    },
                  }}
                />
                {ctaText && (
                  <BaseButton
                    as={Link}
                    href={ctaTextLink}
                    variant={isButton ? 'primary' : 'link'}
                    style={{
                      backgroundColor: !isButton
                        ? 'transparent'
                        : ctaBackgroundColor?.color
                        ? ctaBackgroundColor.color
                        : '#21a17e',
                      maxWidth: '216px',
                      color: !isButton ? '#CC092F' : ctaTextColor?.color,
                    }}
                    width="full"
                    alignSelf="center"
                  >
                    <Text>{ctaText}</Text>
                  </BaseButton>
                )}
              </Stack>
            )
          }
        )}
      </Flex>
    </Stack>
  )
}
