import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box, Text, Stack, Button, Flex, Container} from '../../vanilla'
import {useCustomerStore} from '../../store/hooks/useStore'
import {TwoFactorCodesFields} from '../forms/two-factor-fields'
import {SubmitHandler, useForm} from 'react-hook-form'
import { LocationState, LogInFormFields } from '../../types/forms/login'
import { Spinner } from '../spinner'
import { LockIcon } from '../icons'
import { ResendCode } from './resend-link'
import { useLocation } from 'react-router-dom'
import useNavigation from '../../hooks/use-navigation'
import { CredentialScreens } from '../../types/ui'
import { CREDENTIAL_SCREENS } from '../../utils/constants'

interface TwoFactorCodeFormValues {
  email: string
  password: string
  code_1: number | string
  code_2: number | string
  code_3: number | string
  code_4: number | string
  code_5: number | string
  code_6: number | string
}

interface TwoFactorContainerProps {
  currentCredentials: LogInFormFields | undefined
  inModal?: boolean
  searchParams?: string
  redirectTo?: string
  maintainLocation?: boolean,
  setCredentialScreen: React.Dispatch<React.SetStateAction<CredentialScreens>>
}

const TwoFactorContainer: React.FC<TwoFactorContainerProps> = (
    { currentCredentials, searchParams, redirectTo, maintainLocation, setCredentialScreen, inModal = false}
  ) => {
  const form = useForm<TwoFactorCodeFormValues>({
    defaultValues: {
      email: currentCredentials?.email,
      password: currentCredentials?.password,
      code_1: '',
      code_2: '',
      code_3: '',
      code_4: '',
      code_5: '',
      code_6: ''
    },
    mode: 'onSubmit',
  });

  const customerStore = useCustomerStore()
  const {loginCustomer, customerInfo} = customerStore
  const [isLoading, setLoading] = useState(false);
  const location = useLocation<LocationState>()
  const navigate = useNavigation()

  const submitForm: SubmitHandler<TwoFactorCodeFormValues> = async (formData) => {
    try {
      setLoading(true);
      await loginCustomer(formData)

      if (customerStore.verificationData?.verificationToken) {
        customerStore.setLastActiveToNow();
        setLoading(false);
      }
      if (customerStore.twoFactorAuthData?.twoFactorFailed) {
        [...Array(6).keys()].forEach((i) => {
          form.setError(`code_${i + 1}` as keyof TwoFactorCodeFormValues, {type: "custom.noExclamation"})
        })
        const message = 'There is something wrong with the code, please check and try again';
        form.setError('root', {type: "manual", message: message})
        customerStore.setLastActiveToNow();
        setLoading(false);
        return;
      }
      // Assume correct code from here on
      setCredentialScreen(CREDENTIAL_SCREENS.LOGIN);
      if (location?.state?.directedFrom) {
        navigate(location.state.directedFrom)
      }
      if (searchParams?.includes('EmailToken')) {
        navigate(`/account/edit-profile${searchParams}`)
      } else if (!maintainLocation) {
        navigate(redirectTo || '/')
      }
    } catch (error) {
      setLoading(false);
      const message = "Something's not right, please check and try again."
      form.setError('root', {type: 'manual', message})
    }
  }

  return !isLoading ? (
    <Container
        data-test-selector="2fa-container"
        width="full"
        justifyContent="center"
        size="xs"
        bg="white"
        borderRadius="base"
        paddingX={!inModal ? '16px' : undefined}
        {...(!inModal ? {style: {maxWidth: '540px'}} : {})}
      >
    <Box padding="32px" paddingTop="32px">
      <Box as="form" onSubmit={form.handleSubmit(submitForm)}>
          <Stack gap="32px">
            <Text textAlign="center" variant="heading3">
              Verify your device
            </Text>
            <Text textAlign="center" variant="text3">
              Protecting your account is our top priority, for security reasons we've sent a code to
              your email <strong>{customerInfo ? customerInfo.hint : "Your Email"}</strong>
            </Text>
              <TwoFactorCodesFields form={form} />
              <Button
                type="submit"
                onClick={() => form.clearErrors()}
                iconLeft={<LockIcon boxSize="12px" />}
              >
                Continue & Sign-in
              </Button>
              <ResendCode form={form}/>
          </Stack>
      </Box>
    </Box>
    </Container>
  ) : <Flex justify="center" align="center" style={{minHeight: '200px'}}>
      <Spinner color="primary" />
    </Flex>
}

export const TwoFactorCode = observer(TwoFactorContainer)
