import React from 'react'
import {FormattedNumber} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {Link} from '../../../link'
import {Button, Flex, Stack, Text, useDisclosure} from '../../../../vanilla'
import {useBasketStore, useOrderStore} from '../../../../store/hooks/useStore'
import {OrderSummaryProgressBars} from '../../../order-summary/progress-bars'
import {CollapsablePanel} from '../../../collapsable-panel'
import {OrderSummary} from '../../../order-summary'
import {ChevronDownIcon, LockIcon} from '../../../icons'
import {BaseButton} from '../../../../vanilla'
import {ExitOrderEditModeModal} from '../../../edit-order-exit-mode-modal'
import { useCarrierBag } from '../../../order-summary/use-carrier-bag'

export const DrawerTotals = observer(() => {
  const exitEditModeModal = useDisclosure()
  const {editMode} = useOrderStore()
  const basketStore = useBasketStore()
  const {basket} = basketStore
  const {includeBag, setIncludeBag} = useCarrierBag()

  return (
    <>
      <Stack spacing="0px" bg="white" borderTop="1px" borderColor="gray200">
        {basket && !editMode && (
          <Stack padding="16px" spacing="12px">
            <OrderSummaryProgressBars />
          </Stack>
        )}
        <CollapsablePanel
          width="full"
          defaultOpen={false}
          panelContent={basket &&
            <OrderSummary
              tabledMode
              showOrderTotal={false}
              showItemsCount={false}
              paddingBottom="12px"
              borderBottom="1px"
              borderColor="gray200"
              includeBag={includeBag}
              setIncludeBag={setIncludeBag}
            />
          }
        >
          {({onToggle, isOpen}: any) => {
            return (
              <Flex
                onClick={() => {
                  onToggle()
                }}
                cursor="pointer"
                align="center"
                justify="space-between"
                padding="16px"
                width="full"
                borderTop="1px"
                borderBottom="1px"
                borderColor="gray200"
              >
                <Text variant="text3">Basket total</Text>
                <Flex align="center" gap="8px">
                  <Text variant="unstyled" fontSize="md" as="span">
                    {basket?.currency ? <FormattedNumber
                        style="currency"
                        currency={basket?.currency}
                        value={basket?.productTotal || 0}
                      /> : '£0.00'}
                  </Text>

                  <ChevronDownIcon
                    boxSize="16px"
                    style={{transform: isOpen ? 'rotate(180deg)' : undefined}}
                  />
                </Flex>
              </Flex>
            )
          }}
        </CollapsablePanel>
        <Stack paddingX="20px" paddingY="12px" spacing="8px">
          {((basket?.productTotal || 0) < (basketStore.minimumBasketValue || 0)) && (
            <Text variant="text1" color="promotionColorDark" align="center">
              Minimum spend of £{basketStore.minimumBasketValue}
            </Text>
          )}
          <BaseButton
            width="full"
            as={Link}
            size="sm"
            variant="primary"
            to="/basket"
            display="inline-flex"
            gap="12px"
            alignItems="center"
            disabled={(basket?.productTotal || 0) < (basketStore.minimumBasketValue || 0)}
          >
            <Text variant="unstyled" fontSize="inherit" fontWeight="inherit" color="white">
              Checkout Securely
            </Text>
            <LockIcon boxSize="12px" />
          </BaseButton>

          {editMode && (
            <Button
              variant="primary"
              width="full"
              size="sm"
              onClick={() => {
                exitEditModeModal.onOpen()
              }}
            >
              Discard Changes and Exit
            </Button>
          )}
        </Stack>
      </Stack>
      <ExitOrderEditModeModal {...exitEditModeModal} />
    </>
  )
})
