import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react'
import AlgoliaAnalytics from 'search-insights'
import {useCustomerStore, useGlobalStore} from '../../store/hooks/useStore'
import {
  AlgoliaAnalyticsClickEventParams,
  AlgoliaAnalyticsClickSearchEventParams,
} from '../../types/algolia-analytics'
import {AlgoliaAnalyticsProvider, useAlgoliaAnalytics} from '../../contexts'
import {observer} from 'mobx-react'

export const EVENT_NAMES_MAP = {
  PDP_ADD_TO_CART: 'PDP: Product Added to Cart',
  PDP_ADD_TO_CART_AFTER_SEARCH: 'PDP: Product Added to Cart after Search',
  PLP_PRODUCT_CLICKED: 'PLP: Product Clicked',
  PLP_PRODUCT_ADD_TO_CART_AFTER_SEARCH: 'PLP: Product Added to Cart',
  AUTOCOMPLETE_PRODUCT_CLICKED: 'Autocomplete: Product Clicked',
  AUTOCOMPLETE_ADD_TO_CART: 'Autocomplete: Product Added to Cart',
  ADD_TO_FAVOURITE_WISHLIST: 'Add to favorite/wishlist',
  ADD_TO_FAVOURITE_WISHLIST_AFTER_SEARCH: 'Add to favorite/wishlist after Search',
}

export const AlgoliaAnalyticsContext = ({children}: PropsWithChildren<object>) => {
  const {algoliaIndexBase, algoliaAnalyticsAPIKey, algoliaAppId} = useGlobalStore()
  const {customerInfo} = useCustomerStore()

  const algoliaIndex = `${algoliaIndexBase}__products__default`

  const [initialized, setInitialized] = useState(false)

  const setupAlgoliaAnalytics = useCallback((userId: string) => {
    try {
      AlgoliaAnalytics('init', {appId: algoliaAppId, apiKey: algoliaAnalyticsAPIKey})
      AlgoliaAnalytics('setUserToken', userId)
      setInitialized(true)
    } catch (error) {
      console.log('Error occured during Algolia Analytics initialization', error)
      setInitialized(false)
    }
  }, [])

  const eventsActionsMap = useMemo(() => {
    return {
      productPage: {
        sendAddedToCartAfterSearchEvent: (
          params: Omit<AlgoliaAnalyticsClickSearchEventParams, 'positions'>,
        ) => {
          AlgoliaAnalytics('convertedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.PDP_ADD_TO_CART_AFTER_SEARCH,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddedToCartEvent: (params: AlgoliaAnalyticsClickEventParams) => {
          AlgoliaAnalytics('convertedObjectIDs', {
            eventName: EVENT_NAMES_MAP.PDP_ADD_TO_CART,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddToFavouriteAfterSearchEvent: (params: AlgoliaAnalyticsClickSearchEventParams) => {
          AlgoliaAnalytics('convertedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.ADD_TO_FAVOURITE_WISHLIST_AFTER_SEARCH,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddToFavouriteEvent: (params: AlgoliaAnalyticsClickEventParams) => {
          AlgoliaAnalytics('convertedObjectIDs', {
            eventName: EVENT_NAMES_MAP.ADD_TO_FAVOURITE_WISHLIST,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
      },
      productListPage: {
        sendClickEvent: (params: AlgoliaAnalyticsClickSearchEventParams) => {
          AlgoliaAnalytics('clickedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.PLP_PRODUCT_CLICKED,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddedToCartEvent: (
          params: Omit<AlgoliaAnalyticsClickSearchEventParams, 'positions'>,
        ) => {
          AlgoliaAnalytics('convertedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.PLP_PRODUCT_ADD_TO_CART_AFTER_SEARCH,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddToFavouriteAfterSearchEvent: (params: AlgoliaAnalyticsClickSearchEventParams) => {
          AlgoliaAnalytics('convertedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.ADD_TO_FAVOURITE_WISHLIST_AFTER_SEARCH,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddToFavouriteEvent: (params: AlgoliaAnalyticsClickEventParams) => {
          AlgoliaAnalytics('convertedObjectIDs', {
            eventName: EVENT_NAMES_MAP.ADD_TO_FAVOURITE_WISHLIST,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
      },
      autocomplete: {
        sendItemClickEvent: (params: AlgoliaAnalyticsClickSearchEventParams) => {
          AlgoliaAnalytics('clickedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.AUTOCOMPLETE_PRODUCT_CLICKED,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
        sendAddedToCartEvent: (
          params: Omit<AlgoliaAnalyticsClickSearchEventParams, 'positions'>,
        ) => {
          AlgoliaAnalytics('convertedObjectIDsAfterSearch', {
            eventName: EVENT_NAMES_MAP.AUTOCOMPLETE_ADD_TO_CART,
            index: algoliaIndex,
            userToken: customerInfo?.customerId,
            ...params,
          })
        },
      },
    }
  }, [])

  return (
    <AlgoliaAnalyticsProvider
      value={{setupAlgoliaAnalytics, events: initialized ? eventsActionsMap : null}}
    >
      {children}
    </AlgoliaAnalyticsProvider>
  )
}

const AlgoliaAnalyticsSetupContainer = () => {
  const {customerInfo} = useCustomerStore()
  const {setupAlgoliaAnalytics} = useAlgoliaAnalytics()

  useEffect(() => {
    if (customerInfo?.customerId) {
      setupAlgoliaAnalytics(customerInfo.customerId)
    }
  }, [customerInfo?.customerId])

  return null
}

export const AlgoliaAnalyticsSetup = observer(AlgoliaAnalyticsSetupContainer)
