import React from 'react'
import {FormattedMessage} from 'react-intl'

import {FieldError, UseFormReturn} from 'react-hook-form'
import {Box, Button, Flex, Stack, Text} from '../../../vanilla'
import {useLoginFields} from '../../../hooks/forms/useLoginFields'
import {Field} from '../../field'
import {ExclamationCircleIcon, LockIcon} from '../../icons'
import {CredentialsFormFields} from '../../../types/forms'
import { Spinner } from '../../spinner'

interface LoginFormProps {
  form: UseFormReturn<CredentialsFormFields>
  clickForgotPassword: () => void
  hideForgotPassword?: boolean
  isLoading: boolean
  global?: string
}

export const LoginFields = ({
  form,
  clickForgotPassword,
  hideForgotPassword,
  isLoading
}: LoginFormProps) => {
  const fields = useLoginFields({form})
  const {errors} = form.formState || {}

  return (
    <Stack spacing="32px">
      <Stack spacing="24px">
        <Stack spacing="8px">
          <Stack spacing="24px" data-test-selector="login-fields">
            <Field {...fields.email} />
            <Field {...fields.password} />
          </Stack>
          {errors.global && (
            <Flex as="span" align="flex-start" gap="4px">
              <ExclamationCircleIcon
                color="error"
                marginRight="2px"
                marginTop="4px"
                boxSize="12px"
              />
              <Text variant="text4" color="error" data-test-selector="validation-error">
                {(errors.global as FieldError).message}
              </Text>
            </Flex>
          )}
        </Stack>

        {!hideForgotPassword && (
          <Box>
            <Text
              variant="text2"
              cursor="pointer"
              as="span"
              onClick={clickForgotPassword}
              textDecoration="underline"
            >
              <FormattedMessage defaultMessage="Forgot password?" />
            </Text>
          </Box>
        )}
      </Stack>
        {isLoading ? (
          <Flex justify="center" align="center" style={{maxHeight: '25px'}}>
            <Spinner color="primary" />
          </Flex>
        ) : (
            <Button
            size="sm"
            type="submit"
            onClick={() => form.clearErrors()}
            iconLeft={<LockIcon boxSize="12px" />}
          >
            <FormattedMessage defaultMessage="Sign in securely" />
          </Button>
        )}
    </Stack>
  )
}
