import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {useBasketStore, useContentStore} from '../../../../store/hooks/useStore'
import {Stack, Text} from '../../../../vanilla'
import {BasketIcon} from '../../../icons'
import {CartItem} from '../../../cart-item'
import {cartItemDrawer} from '../styles.css'
import {Slot} from '../../../cms/Slot'
import {PreviouslyRemovedItem} from '../../../../types/store/basket'
import {useItemsUnavailable} from '../../../../store/hooks/useItemsUnavailable'

const GLOBAL_MINI_CART_CONTENT_SLOT = 'global/mini-cart'

export const DrawerCartItems = observer(() => {
  const basketStore = useBasketStore()
  const cms = useContentStore()
  const someItemsUnavailable = useItemsUnavailable()

  useEffect(() => {
    ;(async function () {
      if (!cms.contentById[GLOBAL_MINI_CART_CONTENT_SLOT]) {
        await cms.fetchItemByKey(GLOBAL_MINI_CART_CONTENT_SLOT)
      }
    })()
  }, [])

  return (
    <Stack flexBasis="full" overflow="auto" spacing="0px" bg="gray25">
      {!basketStore.hasItems && !someItemsUnavailable ? (
        <Stack align="center" justify="center" width="full" height="full" gap="12px">
          <BasketIcon boxSize="36px" style={{color: '#9b9b9b'}} />
          <Text
            variant="unstyled"
            color="textMuted"
            lineHeight="short"
            as="span"
            align="center"
            style={{fontSize: 18, color: '#9b9b9b'}}
          >
            Your basket is <br /> currently empty
          </Text>
        </Stack>
      ) : (
        <>
          {basketStore.basket?.c_previouslyRemovedItems?.map(
            (productItem: PreviouslyRemovedItem, idx: number) =>
              productItem.pid ? (
                <CartItem
                  inDrawer
                  key={productItem.pid}
                  id={productItem.pid}
                  className={cartItemDrawer}
                  borderBottom={
                    idx === (basketStore.basket?.productItems?.length ?? 0) - 1 ? '0px' : '1px'
                  }
                  isUnavailable={true}
                />
              ) : null,
          )}
          {basketStore.basket?.productItems?.map((productItem, idx) =>
            productItem.itemId ? (
              <CartItem
                inDrawer
                key={productItem.itemId}
                id={productItem.productId || ''}
                className={cartItemDrawer}
                borderBottom={
                  idx === (basketStore.basket?.productItems?.length ?? 0) - 1 ? '0px' : '1px'
                }
              />
            ) : null,
          )}
          {cms.contentById[GLOBAL_MINI_CART_CONTENT_SLOT] && (
            <Slot
              data={cms.contentById[GLOBAL_MINI_CART_CONTENT_SLOT]}
              deliveryKey={cms.contentById[GLOBAL_MINI_CART_CONTENT_SLOT]._meta.deliveryKey}
            />
          )}
        </>
      )}
    </Stack>
  )
})
