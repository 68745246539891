import React from 'react'
import {DefaultContentBody} from 'dc-delivery-sdk-js'
import {CTA, Image} from '../../types/cms'
import {Box, Button, Flex, Stack, Text, useDisclosure} from '../../vanilla'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {BonusCalendarIcon} from '../icons'
import {CmsImage} from './cms-image'
import {Markdown} from './Markdown'
import {MarketingForm, MarketingFormCmsContent} from './marketing-form'

// add more icons to this as more are added to the schema
const iconMapping = {
  'bonus-calendar': BonusCalendarIcon,
}

interface AlternatingTextImageProps {
  content: {
    image: {
      imageOnRight: boolean
      image: {
        images: Array<Image>
      }
    }
    text: {
      body: string
      headline: string
      eyebrow?: string
    }
    textControls: {
      textAreaSpacing: 'None' | 'Small' | 'Medium' | 'Large'
      textPosition: 'None' | 'Middle' | 'Top'
    }
    marketingModal?: {
      ctaText: string
      badgeIcon: string
      marketingForm: MarketingFormCmsContent
      } & DefaultContentBody
      cta?: CTA
    }
  }

export const AlternatingTextImage = ({content}: AlternatingTextImageProps) => {
  const marketingFormModal = useDisclosure(false)
  if (!content) {
    return null
  }
  const {image, text, textControls, cta, marketingModal} = content
  const marketingForm = marketingModal?.marketingForm
  let CtaIcon
  if (marketingModal?.badgeIcon) {
    CtaIcon = iconMapping[marketingModal.badgeIcon as 'bonus-calendar']
  }
  const textSpacing = {
    sm:
      textControls?.textAreaSpacing === 'Small' ||
      textControls?.textAreaSpacing === 'None' ||
      !!textControls?.textAreaSpacing,
    md: textControls?.textAreaSpacing === 'Medium',
    lg: textControls?.textAreaSpacing === 'Large',
  }

  return (
    <>
      <Flex
        direction={['column-reverse', image?.imageOnRight ? 'row' : 'row-reverse']}
        gap={['12px', textSpacing.lg ? '80px' : textSpacing.md ? '64px' : '48px']}
        alignItems={textControls?.textPosition === 'Middle' ? 'center' : 'flex-start'}
      >
        <Stack spacing="16px" flex="1">
          <Box>
            <Text variant="heading5" lineHeight="normal">
              {text.headline}
            </Text>
            {text?.eyebrow && <Text variant="heading0">{text.eyebrow}</Text>}
          </Box>
          <Markdown
            markdown={(text.body || '') as string}
            componentProps={{
              p: {
                variant: 'text3',
                lineHeight: 'short',
              },
            }}
          />
          {cta?.ctaText ? (
            <CTAButton cta={cta} style={{width: 'fit-content'}} />
          ) : marketingModal?.ctaText ? (
            <Button
              variant="blueLink"
              style={{width: 'fit-content', height: 'fit-content'}}
              onClick={marketingFormModal.onToggle}
              {...(marketingModal?.badgeIcon &&
                CtaIcon && {
                  iconLeft: <CtaIcon />,
                })}
            >
              {marketingModal.ctaText}
            </Button>
          ) : null}
        </Stack>
        <Box flex="1">
          <CmsImage
            queryParams={[
              {w: 550, fmt: 'auto', qlt: 'default'},
              {w: 1100, fmt: 'auto', qlt: 'default'},
            ]}
            images={image?.image?.images}
            width="full"
            style={{objectFit: 'cover', minHeight: 260}}
          />
        </Box>
      </Flex>
      {marketingForm ? (
        <MarketingForm content={marketingForm} isModal {...marketingFormModal} />
      ) : null}
    </>
  )
}
