import React, {useState, useRef, useEffect, PropsWithChildren, useMemo} from 'react'
import {Box, Button, Flex, Text, Stack, useDisclosure} from '../../../vanilla'
import {Modal} from '../../modal'
import {AmpliencePencilIcon, AmplienceViewIcon, AmpliencePuzzleIcon} from '../../icons'
import {isDev, isLocal, isProduction, isRelease} from '../utils'

interface XRayPreviewWrapperProps {
  title: string
  isComponent: boolean
  content: any
}

export const XRayPreviewWrapper = ({
  content,
  title,
  isComponent,
  children,
}: PropsWithChildren<XRayPreviewWrapperProps>) => {
  const ref = useRef<HTMLDivElement>(null)

  const modal = useDisclosure()
  const [width, setWidth] = useState(0)
  const [verticalVariant, setVerticalVariant] = useState(false)

  const setVariant = () => {
    if (ref.current) {
      const isVertical =
        ref.current.offsetWidth < ref.current.offsetHeight && ref.current.offsetWidth < 400
      setVerticalVariant(isVertical)
    }
  }

  const getAmplienceNamespace = () => {
    if (isDev() || isLocal()) {
      return 'iceland-dev'
    }

    if (isRelease()) {
      return 'iceland-prd'
    }

    if (isProduction()) {
      return 'iceland-prd'
    }
  }

  const amplienceApp = useMemo(() => getAmplienceNamespace(), [window.location.host])

  useEffect(() => {
    ref.current && setWidth(ref.current.offsetWidth)
    setVariant()

    function handleResize() {
      ref.current && setWidth(ref.current.offsetWidth)
      setVariant()
    }

    window.addEventListener('resize', handleResize)

    return function cleanup() {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Box
        position="relative"
        border="1px"
        borderColor={isComponent ? 'black' : 'previewPrimary'}
        style={{borderStyle: 'dashed'}}
        ref={ref}
      >
        <Stack spacing="40px">{children}</Stack>
        <Box
          position="absolute"
          top="0px"
          width="full"
          height="full"
          textAlign={!isComponent ? 'right' : 'left'}
          style={{background: '#EDF2F799', pointerEvents: !isComponent ? 'none' : 'initial'}}
        >
          <Flex
            position="absolute"
            align="center"
            justify="center"
            bg="white"
            border="1px"
            zIndex="docked"
            right={!isComponent ? '0px' : 'unset'}
            borderColor={isComponent ? 'gray800' : 'previewPrimary'}
            {...(verticalVariant
              ? {
                  flexDirection: 'column',
                  style: {
                    margin: '10px',
                    top: '40px',
                    width: 'fit-content',
                    pointerEvents: 'all',
                  },
                }
              : {
                  top: '0px',
                  height: '32px',
                  style: {
                    pointerEvents: 'all',
                  },
                })}
          >
            <Box
              bg={isComponent ? 'black' : 'previewPrimary'}
              paddingX="20px"
              paddingY="8px"
              height="full"
              display="flex"
              alignItems="center"
              {...(verticalVariant ? {width: 'full'} : {})}
            >
              <AmpliencePuzzleIcon color="white" marginRight="8px" />
              <Text
                variant="text1"
                weight="medium"
                color="white"
                {...(verticalVariant ? {paddingY: '8px'} : {})}
              >
                {title}
              </Text>
            </Box>
            <Box
              display="flex"
              {...(verticalVariant
                ? {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                    paddingX: '20px',
                    paddingY: '12px',
                    style: {width: `${width - 20}px`},
                  }
                : {
                    alignItems: 'center',
                    gap: '8px',
                    paddingX: '12px',
                  })}
            >
              <Text
                variant="text3"
                weight="medium"
                color="gray800"
                lineHeight="none"
                {...(verticalVariant ? {paddingY: '12px'} : {})}
              >
                {content?._meta?.name}
              </Text>
              <Flex gap="12px" align="center">
                <Text
                  as="a"
                  variant="unstyled"
                  top="0px"
                  style={{verticalAlign: 'top'}}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://app.amplience.net/content/#!/${amplienceApp}/authoring/content-item/edit/${content?._meta?.deliveryId}`}
                >
                  <AmpliencePencilIcon boxSize={verticalVariant ? '32px' : '12px'} />
                </Text>
                <Button
                  variant="unstyled"
                  height="auto"
                  paddingX="0px"
                  top="0px"
                  style={{verticalAlign: 'top'}}
                  onClick={() => modal.onToggle()}
                >
                  <Flex
                    align="flex-end"
                    justify="center"
                    bg="white"
                    border="1px"
                    padding="4px"
                    style={{borderColor: '#DCDCDC'}}
                  >
                    <AmplienceViewIcon boxSize={verticalVariant ? '32px' : '12px'} />
                  </Flex>
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Modal title="JSON Preview" size="xl" {...modal}>
          <Box
            bg="white"
            border="1px"
            borderColor="gray300"
            overflow="scroll"
            margin="auto"
            marginBottom="40px"
            style={{height: '50vh', width: '90%'}}
          >
            <pre style={{padding: 12, whiteSpace: 'pre-wrap'}}>
              {JSON.stringify(content, null, 2)}
            </pre>
          </Box>
        </Modal>
      </Box>
    </>
  )
}
