import React from 'react'
import {Flex} from '../../vanilla'
import {ReviewStar, ReviewStarFill, ReviewStarHalf} from '../icons'

interface ReviewStarsProps {
  rating: number
}

const calculateStarRating = (rating: number) => {
  const ratingStars = []
  for (let i = 1; i <= rating; i++) {
    ratingStars.push(<ReviewStarFill key={i} boxSize="20px" />)
  }
  if (Math.floor(rating) !== rating) {
    ratingStars.push(<ReviewStarHalf key={Math.floor(rating) + 1} boxSize="20px" />)
  }
  for (let i = ratingStars.length; i < 5; i++) {
    ratingStars.push(<ReviewStar key={i + 1} boxSize="20px" />)
  }
  return ratingStars
}

export const ReviewStars = ({rating}: ReviewStarsProps) => {
  return (
    <Flex alignItems={'center'} justifyContent={'center'} gap="4px">
      {calculateStarRating(rating)}
    </Flex>
  )
}
