import React, {useState, useRef} from 'react'
import {useRect} from '@reach/rect'
import {Box, Flex, Stack, Text} from '../../vanilla'
import {ChevronDownIcon, ChevronUpIcon} from '../icons'

export interface CollapsableMenuProps {
  children?: React.ReactNode
  title: string
  titleColor: string
}

export const CollapsableMenu = ({title, titleColor, children}: CollapsableMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef(null)
  const rect = useRect(ref, {observe: false})

  const targetHeight = rect?.height || 'auto'

  return (
    <div>
      <Box onClick={() => setIsOpen(!isOpen)}>
        <Flex justifyContent="space-between" alignItems={'center'}>
          <Text
            variant="unstyled"
            paddingY="2px"
            fontWeight="bold"
            lineHeight="tall"
            style={{
              color: titleColor || '#000',
            }}
          >
            {title}
          </Text>
          {isOpen ? (
            <ChevronUpIcon height="20px" width="20px" color="gray700" />
          ) : (
            <ChevronDownIcon height="20px" width="20px" color="gray700" />
          )}
        </Flex>
      </Box>

      <Stack>
        <div
          style={{
            overflow: 'hidden',
            maxHeight: isOpen ? (targetHeight !== 'auto' ? targetHeight : targetHeight) : 0,
            marginRight: '1px',
            transition: 'max-height 300ms ease-out',
          }}
        >
          <div
            ref={ref}
            className="customScroll"
            style={{
              overflowY: 'auto',
              maxHeight: targetHeight,
            }}
          >
            {children}
          </div>
        </div>
      </Stack>
    </div>
  )
}
