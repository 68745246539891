import {useIntl} from 'react-intl'
import {MAX_FIELD_LENGTH} from '../../utils/constants'
import {validateEmail} from '../../utils/email-utils'
import {CreateFields, ResetPasswordFormFields} from '../../types/forms'

export const useResetPasswordFields: CreateFields<ResetPasswordFormFields> = ({
    form: {
        control,
        formState: {errors}
    },
}) => {
    const {formatMessage} = useIntl()

    const fields = {
        email: {
            name: 'email',
            label: formatMessage({defaultMessage: 'Email address'}),
            defaultValue: '',
            type: 'email' as const,
            rules: {
                required: formatMessage({defaultMessage: 'This field is required'}),
                maxLength: {
                    value: MAX_FIELD_LENGTH,
                    message: `Email address cannot be longer than ${MAX_FIELD_LENGTH} characters`,
                  }, 
                  validate: {
                    validate: (value?: string) =>
                        validateEmail(value).isValid ||
                        formatMessage({defaultMessage: 'Please enter a valid email address.'}),
                    }
            },
            error: errors['email'],
            control,
        }
    }

    return fields
}