import React from 'react'
import {observer} from 'mobx-react-lite'
import {SlotComponent} from '../../types/ui'
import {CmsImage} from './cms-image'
import {Image} from '../../types/cms'

export interface PromoBannerCmsContent {
  image: {
    images: Image[]
  }
}

export const PromoBanner: SlotComponent<PromoBannerCmsContent> = observer(({content}) => {
  if (!content?.image?.images?.length) {
    return null
  }

  return <CmsImage images={content.image.images} width="full" style={{objectFit: 'cover'}} />
})
