import React from 'react'
import {SlotComponent} from '../../types/ui'
import {Box, BoxProps, Stack, Text} from '../../vanilla'
import {ColorPicker, Image} from '../../types/cms'
import {CmsImage} from './cms-image'
import {Markdown} from './Markdown'

interface RecipeCmsContent {
  anchorId: string
  image: {
    images: Image[]
  }
  title: {
    recipeTitle: string
    titleTextColor: ColorPicker
    titleBackgroundColor: ColorPicker
  }
  ingredients: {
    ingredients: string
  }
  cookingMethod: {
    cookingMethod: string
    text: string
  }
}

export const Recipe: SlotComponent<RecipeCmsContent> = ({content}) => {
  const {image, anchorId, title, ingredients, cookingMethod} = content

  return (
    <Stack flexDirection={['column', 'row']} spacing={['0px', '24px']} id={anchorId ?? null}>
      <Stack width={['full', 'two-thirds']} position="relative" flexShrink="0" spacing="0px">
        <CmsImage
          width="full"
          images={image.images}
          style={{minHeight: 305, objectFit: 'cover'}}
          queryParams={[
            {w: 550, fmt: 'auto', qlt: 'default'},
            {w: 1100, fmt: 'auto', qlt: 'default'},
          ]}
        />
        {title?.recipeTitle && (
          <Box
            position="absolute"
            top="0px"
            left="0px"
            zIndex="docked"
            paddingY="16px"
            paddingX="24px"
            bg="gray200"
            style={{
              backgroundColor: title?.titleBackgroundColor?.color,
            }}
          >
            <Text
              variant="heading7"
              lineHeight="shorter"
              color="white"
              style={{color: title.titleTextColor.color}}
            >
              {title.recipeTitle}
            </Text>
          </Box>
        )}

        <Stack paddingTop="24px" spacing="24px">
          <Box paddingX="24px">
            <Text variant="unstyled" fontSize="md" lineHeight="short">
              {cookingMethod.text}
            </Text>
          </Box>
          <Ingredients ingredients={ingredients} display={['flex', 'none']} />
          {cookingMethod?.cookingMethod && (
            <Box paddingX="24px" paddingBottom="24px">
              <Markdown
                markdown={cookingMethod?.cookingMethod}
                componentProps={{
                  p: {
                    variant: 'unstyled',
                    fontSize: 'md',
                    lineHeight: 'short',
                  },
                  h3: {
                    variant: 'unstyled',
                    fontSize: 'lg',
                    fontWeight: 'bold',
                    marginBottom: '8px',
                  },
                  li: {
                    variant: 'text3',
                    lineHeight: 'short',
                    marginBottom: '12px',
                  },
                  a: {
                    variant: 'text3',
                    color: {default: 'accent3', hover: 'accent3Light'},
                    textDecoration: 'underline',
                  },
                }}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <Ingredients ingredients={ingredients} display={['none', 'flex']} />
    </Stack>
  )
}

interface IngredientsProps extends BoxProps<'div'> {
  ingredients: {
    ingredients: string
  }
}

const Ingredients = (props: IngredientsProps) => {
  const {ingredients, ...rest} = props

  if (!ingredients.ingredients) return null

  return (
    <Stack
      bg="white"
      paddingX="24px"
      paddingTop="24px"
      paddingBottom="48px"
      flexGrow="1"
      alignSelf="flex-start"
      width={['full', 'auto']}
      {...rest}
    >
      <Markdown
        markdown={ingredients.ingredients}
        componentProps={{
          p: {
            variant: 'text3',
          },
          h3: {
            variant: 'unstyled',
            fontSize: 'lg',
            fontWeight: 'bold',
          },
          li: {
            lineHeight: 'short',
            marginBottom: '4px',
          },
          a: {
            variant: 'text3',
            color: {default: 'accent3', hover: 'accent3Light'},
            textDecoration: 'underline',
          },
        }}
      />
    </Stack>
  )
}
