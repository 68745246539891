import React, {useMemo} from 'react'
import {subYears, isBefore, addYears} from 'date-fns'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import {FieldName} from 'react-hook-form/dist/types/fields'
import {Controller} from 'react-hook-form'
import {
  useContactUsAddressFields,
  useContactUsBonusCardFields,
  useContactUsInHomeDeliveryFields,
  useContactUsOnlineFields,
  useContactUsPersonalDetailsFields,
  useContactUsProductFields,
  useMessageSubjectFields,
} from '../../../hooks/forms/useContactUsFields'
import {Field} from '../../field'
import {Box, Flex, Stack, Text} from '../../../vanilla'
import {Datepicker} from '../../datepicker'
import {noop} from '../../../utils/utils'
import {BONUS_CARD_PREFIX, MIN_AGE_OF_CUSTOMER} from '../../../utils/constants'
import {CheckIcon} from '../../icons'
import {UseFields} from '../../../types/ui'
import {onlyFieldSetWarning} from '../styles.css'

interface BaseFormSetProps {
  form: UseFields<any>
}

const CustomerPersonalDetails = ({form}: BaseFormSetProps) => {
  const fields = useContactUsPersonalDetailsFields({form})

  return (
    <>
      <Field {...fields.firstName} />
      <Field {...fields.lastName} />
      <Field {...fields.email} />
      <Field {...fields.phone} />
    </>
  )
}

const CustomerAddress = ({form}: BaseFormSetProps) => {
  const fields = useContactUsAddressFields({form})

  return (
    <Stack marginTop="12px" width="full" spacing="16px">
      <Text variant="text3">Your Address*</Text>
      <Stack width="full" spacing="24px">
        <Field {...fields.streetAddress} />
        <Field {...fields.streetAddress2} />
        <Field {...fields.city} />
        <Field {...fields.county} />
        <Field {...fields.postcode} />
      </Stack>
    </Stack>
  )
}

const MessageSubject = ({form}: BaseFormSetProps) => {
  const fields = useMessageSubjectFields({form})

  return (
    <>
      <Field {...fields.messageSubject} />
      {/* @ts-ignore*/}
      <Field {...fields.comment} />
    </>
  )
}

export const GeneralFieldSet = ({form}: BaseFormSetProps) => {
  return (
    <>
      <CustomerPersonalDetails form={form} />
      <CustomerAddress form={form} />
      <MessageSubject form={form} />
    </>
  )
}

export const OnlineFieldSet = ({form}: BaseFormSetProps) => {
  const {comment} = useMessageSubjectFields({form})
  const fields = useContactUsOnlineFields({form})

  const handleSubjectDropdownChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    form.setValue(e.currentTarget.name as FieldName<any>, e.currentTarget.value)
  }

  return (
    <>
      <CustomerPersonalDetails form={form} />
      <CustomerAddress form={form} />
      <Field
        {...fields.subjectDropdown}
        inputProps={{
          onChange: handleSubjectDropdownChange,
        }}
      />
      <Stack
        className={onlyFieldSetWarning}
        borderRadius="base"
        paddingX="20px"
        paddingY="12px"
        spacing="16px"
        style={{
          color: '#7e5413',
          backgroundColor: '#fdecd3',
          border: '1px solid #fce5c2',
        }}
      >
        <Text variant="text3" color="inherit" lineHeight="shorter">
          Please list ALL the items missing from your order below. Please note that your refund may
          be delayed if you do not list all the items, including the quantity missing and item
          weight.
        </Text>
        <Text variant="text3" color="inherit" lineHeight="shorter">
          For example:
        </Text>
        <Text variant="text3" color="inherit" lineHeight="shorter">
          1x Farmer&apos;s Market Raspberries 150g <br />
          2x Iceland Thin & Crispy Double Pepperoni Pizza 320g
        </Text>
        <Text variant="text3" color="inherit" lineHeight="shorter">
          You can find the full item description in your Order Dispatch email.
        </Text>
      </Stack>
      {/* @ts-ignore*/}
      <Field {...comment} />
      <Field {...fields.orderNumber} />
    </>
  )
}

export const BonusCardFieldSet = ({form}: BaseFormSetProps) => {
  const fields = useContactUsBonusCardFields({form})

  const years = useMemo(() => {
    const start = 1930
    const end = new Date().getFullYear() - MIN_AGE_OF_CUSTOMER

    return Array.from([...new Array(end - start + 1).keys()], (year) => year + start)
  }, [])

  const filterCalendarDates = (date: Date) => {
    return isBefore(date, subYears(new Date(), MIN_AGE_OF_CUSTOMER))
  }

  return (
    <>
      <CustomerPersonalDetails form={form} />
      <Controller
        name="dateofbirth"
        control={form.control}
        render={(renderProps) => {
          const {
            field: {value, onChange},
          } = renderProps
          return (
            <Datepicker
              error={null}
              onFocus={noop}
              onBlur={noop}
              showYearDropdown
              selected={(value as unknown as Date)}
              label="Date of Birth"
              dropdownMode="select"
              onChange={(date) => onChange(date)}
              filterDate={filterCalendarDates}
              headerYears={years}
              headerDefaultSelectedYear={new Date().getFullYear() - MIN_AGE_OF_CUSTOMER}
            />
          )
        }}
      />
      <CustomerAddress form={form} />
      <MessageSubject form={form} />
      <Stack spacing="16px">
        <Text variant="unstyled" lineHeight="none" fontSize="md" fontWeight="normal">
          Your Bonus Card Number
        </Text>
        <Flex align="flex-start" gap="8px">
          <Text variant="text3" lineHeight="none" marginTop="12px">
            {BONUS_CARD_PREFIX}
          </Text>
          <Field {...fields.bonusCardNumber} />
        </Flex>
      </Stack>
    </>
  )
}

export const InStoreHomeDeliveryFieldSet = ({form}: BaseFormSetProps) => {
  const fields = useContactUsInHomeDeliveryFields({form})

  return (
    <>
      <CustomerPersonalDetails form={form} />
      <CustomerAddress form={form} />
      <MessageSubject form={form} />
      <Field {...fields.storeName} />
    </>
  )
}

export const ProductFieldSet = ({form}: BaseFormSetProps) => {
  const fields = useContactUsProductFields({form})

  const packageWatcher = form.watch('packing')

  const showPackageFields = packageWatcher === 'true'

  const years = useMemo(() => {
    const start = subYears(new Date(), 20).getFullYear()
    const end = addYears(new Date(), 20).getFullYear()

    return Array.from([...new Array(end - start + 1).keys()], (year) => year + start)
  }, [])

  return (
    <>
      <CustomerPersonalDetails form={form} />
      <CustomerAddress form={form} />
      <MessageSubject form={form} />
      <Field {...fields.productName} />
      <Flex align="flex-start" gap="8px">
        <Text variant="text3" lineHeight="none" marginTop="12px">
          £
        </Text>
        <Field {...fields.pricePaid} />
      </Flex>
      <Stack>
        <Text variant="text3" lineHeight="shorter">
          Do you still have the product packaging?
        </Text>
        <Controller
          defaultValue={false}
          name={'packing'}
          control={form.control}
          render={(renderProps) => {
            const {
              field: {value, onChange},
            } = renderProps
            return (
              <Flex gap="16px" align="center">
                <Flex
                  align="center"
                  padding="20px"
                  gap="24px"
                  bg="white"
                  as="label"
                  borderRadius="base"
                  border="1px"
                  borderColor={value === 'true' ? 'accent3' : 'gray200'}
                  onClick={() => {
                    onChange('true')
                  }}
                >
                  <Text as="span" variant="text3" lineHeight="shorter">
                    Yes
                  </Text>
                  <Flex
                    align="center"
                    justify="center"
                    borderRadius="full"
                    boxSize="20px"
                    bg={value === 'true' ? 'accent3' : 'white'}
                    border="1px"
                    borderColor={value === 'true' ? 'accent3' : 'gray200'}
                  >
                    {value === 'true' && <CheckIcon boxSize="12px" color="white" />}
                  </Flex>
                  <Box
                    as="input"
                    type={'radio'}
                    name={'packing'}
                    value={'true'}
                    position="absolute"
                    opacity="0"
                    padding="0px"
                  />
                </Flex>
                <Flex
                  align="center"
                  padding="20px"
                  gap="24px"
                  bg="white"
                  as="label"
                  borderRadius="base"
                  border="1px"
                  borderColor={value === 'false' ? 'accent3' : 'gray200'}
                  onClick={() => {
                    onChange('false')
                  }}
                >
                  <Text as="span" variant="text3" lineHeight="shorter">
                    No
                  </Text>
                  <Flex
                    align="center"
                    justify="center"
                    borderRadius="full"
                    boxSize="20px"
                    border="1px"
                    bg={value === 'false' ? 'accent3' : 'white'}
                    borderColor={value === 'false' ? 'accent3' : 'gray200'}
                  >
                    {value === 'false' && <CheckIcon boxSize="12px" color="white" />}
                  </Flex>
                  <Box
                    as="input"
                    type={'radio'}
                    name={'packing'}
                    value={'false'}
                    position="absolute"
                    opacity="0"
                    padding="0px"
                  />
                </Flex>
              </Flex>
            )
          }}
        />
      </Stack>
      {showPackageFields && (
        <>
          <Field {...fields.barcode} />
          <Stack spacing="4px">
            <Text variant="text1">What is a barcode?</Text>
            <Flex gap="8px">
              <Text variant="text1" lineHeight="shorter">
                The barcode is the series of numbers that runs along the bottom of the box with rows
                of thick and thin vertical bars. It can usually be found at the back of the
                packaging. Please provide the entire code.
              </Text>
              <Box as="img" src={getAssetUrl('static/img/barcode.jpg')} maxWidth="full" />
            </Flex>
          </Stack>
          <Controller
            name="bestbefore"
            control={form.control}
            render={(renderProps) => {
              const {
                field: {value, onChange},
              } = renderProps

              return (
                <Datepicker
                  error={null}
                  onFocus={noop}
                  onBlur={noop}
                  showYearDropdown
                  selected={(value as unknown as Date)}
                  label="Best Before"
                  dropdownMode="select"
                  onChange={(date) => onChange(date)}
                  headerYears={years}
                  headerDefaultSelectedYear={new Date().getFullYear()}
                />
              )
            }}
          />
          <Box>
            <Text variant="text2" lineHeight="shorter" color="textMuted">
              Please check packaging or the lid of a tin or bottle.
            </Text>
          </Box>
          <Field {...fields.otherCodes} />
          <Stack spacing="4px">
            <Text variant="text1">Any other codes?</Text>
            <Flex gap="8px">
              <Text variant="text1" lineHeight="shorter">
                These are any other codes that can be found on the packaging near the barcode or by
                the best before dates
              </Text>
              <Box as="img" src={getAssetUrl('static/img/package-code.jpg')} maxWidth="full" />
            </Flex>
          </Stack>
        </>
      )}
    </>
  )
}
