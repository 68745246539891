import React, {ComponentProps} from 'react'
import {CheckCircleIcon, ExclamationCircleIcon, FaqIcon} from '../../components/icons'
import {Box, Flex, Text} from '../../vanilla'
import { MessageLinks, PushMessage, VersionOperatorTypes, TextSizes } from './types'
import {alertRecipe, alertLinkRecipe, textSizeRecipe, alertBodyRecipe} from './styles.css'
import { gte, lte, gt, lt, eq } from 'semver'

export interface PushAlert extends ComponentProps<typeof Flex> {
    message: PushMessage
    appVersion: string
}

interface AlertTextProps {
    message: string
    links: MessageLinks[]
    size: TextSizes
}

const isWithinDateRange = (message: PushMessage) => {
    const hasDateRestrictions = Object.hasOwn(message.conditions, 'fromDate') && Object.hasOwn(message.conditions, 'toDate')
    if (!hasDateRestrictions) {
        return true
    }
    const now = new Date()
    const alertValidFromDate = new Date(message.conditions.fromDate!)
    const alertValidToDate = new Date(message.conditions.toDate!)
    return now >= alertValidFromDate && now <= alertValidToDate
}

const isApplicableAppVersion = (message: PushMessage, appVersion: string) => {
    const hasAppVersionRestrictions = Object.hasOwn(message.conditions, 'version')
    if (!hasAppVersionRestrictions) {
        return true
    }
    const appVersionRegex = RegExp('(>|<|>=|<=|=) (\\d{1,3}\\.\\d{1,3}\\.\\d{1,3})')
    const operatorMapping = {
        '>': gt,
        '<': lt,
        '>=': gte,
        '<=': lte,
        '=': eq,
    }
    const match = appVersionRegex.exec(message.conditions.version!)
    if (!match) {
      console.error('Failed to parse app version restriction')
      return false
    }
    const operator = match[1] as VersionOperatorTypes
    const version = match[2]
    if (!Object.keys(operatorMapping).includes(operator)){
      console.error('Failed to find operator mapping for app version restriction')
      return false
    }
    return operatorMapping[operator](appVersion, version)
}

const alertOnClickText = (linkItem: MessageLinks) => {
  if (linkItem?.url) {
    window.location.href = linkItem.url
  }else if(linkItem.action && linkItem.action === 'refresh'){
    // Refresh the page using the current date to invalidate the cache in the apps
    window.location.href = window.location.href + `?refresh=${Date.now()}`
  }
}

const createTextSegment = (text: string, key: string, textSize: string) => (
  <Text as="span" key={key} className={textSize}>
    {text}
  </Text>
);

const createLinkSegment = (link: MessageLinks, key: string, textSize: string) => (
  <Text
    as="span"
    key={key}
    onClick={() => alertOnClickText(link)}
    className={`${alertLinkRecipe()} ${textSize}`}
  >
    {link.searchTerm}
  </Text>
);

const embedLinksToText = ({ message, links, size }: AlertTextProps) => {
  const textSize = textSizeRecipe({ size });
  if (!links || links.length === 0) {
    return [createTextSegment(message, "text-0", textSize)];
  }

  let segments: JSX.Element[] = [createTextSegment(message, "text-0", textSize)];

  links.forEach((link, linkIndex) => {
    segments = segments.flatMap((segment, segmentIndex) => {
      if (segment.props.children && typeof segment.props.children === "string") {
        const parts: (string | JSX.Element)[] = segment.props.children.split(link.searchTerm);
        parts.splice(1, 0, createLinkSegment(link, `link-${linkIndex}-${segmentIndex}`, textSize));
        return parts.map((part, index) => {
          if (typeof part === "string") {
            return createTextSegment(part, `text-${linkIndex}-${segmentIndex}-${index}`, textSize);
          }
          return part;
        });
      }
      // If there are no children, return the segment as is.
      return [segment];
    });
  });

  return segments;
};

const AlertText = ({ message, links, size }: AlertTextProps) => {
    // Take a string and replace any instances of a search term with a link.
    return (
      <Box className={alertBodyRecipe()}>
        {embedLinksToText({message, links, size}).map((segment, index) => (
          <React.Fragment key={index}>{segment}</React.Fragment>
        ))}
      </Box>
    );
}

export const PushAlert = ({message, appVersion}: PushAlert) => {
    const className = alertRecipe({alert: 'default'})
    
    const iconMapping = {
        info: FaqIcon,
        warning: ExclamationCircleIcon,
        error: ExclamationCircleIcon,
        success: CheckCircleIcon,
    }
    const hasIcon = Object.hasOwn(message.formatting, 'icon')
    const PushMessageIcon = hasIcon ? iconMapping[message.formatting.icon!] : (() => null)

    const iconSizeMapping = {
        small: '20px',
        normal: '30px',
        large: '64px',
    } as const

    const dynamicStyles = {
        backgroundColor: message.formatting.backgroundColour,
        color: message.formatting.textColour,
        borderBottom: `2px solid ${message.formatting.bottomBorderColour}`,
    }

    return isWithinDateRange(message) && isApplicableAppVersion(message, appVersion) ? (
        <Flex className={className} gap="16px" align="center" style={dynamicStyles}>
            <PushMessageIcon color="inherit" boxSize={iconSizeMapping[message.formatting.iconSize!]} />
            <AlertText message={message.message} links={message.formatting.links || []} size={message.formatting.textSize} />
        </Flex>
    ) : <></>
}
