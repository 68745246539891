import {observer} from 'mobx-react'
import React from 'react'
import queryString from 'query-string'
import {Flex, Stack, Text} from '../../vanilla'
import {MapMarkerLightIcon, PhoneIcon} from '../icons'
import {Link} from '../link'
import {useBasketStore} from '../../store/hooks/useStore'

interface NearestStoreDetailsProps {
  viewText?: string
  wrapperStyles?: object
  storeWrapperStyles?: object
}

const NearestStoreDetails = observer(function NearestStoreDetails({
  viewText,
  wrapperStyles,
  storeWrapperStyles,
}: NearestStoreDetailsProps) {
  const buildViewMapUrl = (id: string, name: string) =>
    `/store-finder?${queryString.stringify(
      {StoreID: id, StoreName: name},
      {skipNull: true, skipEmptyString: true}
    )}`

  const {nearestStoreByDeliveryPostCode: store} = useBasketStore()

  if (!store) return null

  const {name, distance, address1, city, postalCode, id, phone} = store

  return (
    <Stack
      paddingBottom="16px"
      paddingLeft="16px"
      paddingRight="16px"
      style={{marginLeft: -16, marginRight: -16, ...wrapperStyles}}
    >
      <Text variant="text4" lineHeight="shorter" as="span">
        Your nearest store is:
      </Text>
      <Stack
        style={{
          ...storeWrapperStyles,
        }}
      >
        <Text variant="text4" lineHeight="shorter" as="span">
          {name}
          {distance && (
            <Text as="span" variant="text4" lineHeight="shorter" color="textMuted">
              {' '}
              ({distance} miles)
            </Text>
          )}
        </Text>
        <Flex gap="20px">
          <Flex width="full" gap="8px">
            <MapMarkerLightIcon color="accent0" style={{width: 22, height: 'auto'}} />
            <Stack spacing={['4px', '0px']}>
              {address1 && (
                <Text variant="text4" lineHeight="shorter" as="span">
                  {address1}
                </Text>
              )}
              <Text variant="text4" lineHeight="shorter" as="span">
                {city}, {postalCode}
              </Text>
              {viewText && (
                <Text
                  as={Link}
                  to={buildViewMapUrl(id, name)}
                  variant="text4"
                  textDecoration="underline"
                  color={{default: 'accent3', hover: 'accent3Light'}}
                >
                  {viewText}
                </Text>
              )}
            </Stack>
          </Flex>

          {phone && (
            <Flex width="full" gap="8px" align="center">
              <PhoneIcon color="accent0" style={{width: 20, height: 'auto'}} />
              <Text variant="text4" lineHeight="shorter" as="span">
                {phone}
              </Text>
            </Flex>
          )}
        </Flex>
      </Stack>
    </Stack>
  )
})

export default NearestStoreDetails
