import {useBasketStore} from '../store/hooks/useStore'
import { Hit } from '../store/ProductStore'
import {ALCOHOL_PRODUCT_CLASS} from '../utils/constants'

export const useAlcoholRestrictions = () => {
  const {basket} = useBasketStore()
  const checkIfProductAlcohol = (product: Hit) => {
    return !!product.productClasses?.includes?.(ALCOHOL_PRODUCT_CLASS)
  }

  const isCurrentSlotAlcoholRestricted =
    !!basket?.c_restrictedProductClass?.includes?.(ALCOHOL_PRODUCT_CLASS)

  const checkIfAnyProductsAreAlcohol = (products: Hit[]) => {
    return products.filter((product) => checkIfProductAlcohol(product)).length > 0
  }

  return {
    isCurrentSlotAlcoholRestricted,
    checkIfProductAlcohol,
    checkIfAnyProductsAreAlcohol
  }
}
