import * as React from 'react'
import {Link as SPALink, NavLink as NavSPALink} from 'react-router-dom'
import {useGlobalStore} from '../../store/hooks/useStore'
import {Box, BoxProps} from '../../vanilla'

// interface IProps extends LinkProps {}

interface IProps extends BoxProps<'a'> {
  _href?: string
  to?: string
  href?: string
  useNavLink?: boolean
}

const GLOBAL_TIMESTAMP = Date.now()

const compareDatesInMinutes = (startTime: Date, now: Date) => {
  const timeDifferenceMillis: number = Math.abs(now.getTime() - startTime.getTime())
  const minutesDifference: number = Math.round(timeDifferenceMillis / 60000)
  return minutesDifference
}

const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  href: string,
  customerCacheClearInterval: number,
) => {
  const minutesOnSite = compareDatesInMinutes(new Date(GLOBAL_TIMESTAMP), new Date())
  if (minutesOnSite > customerCacheClearInterval) {
    // Replace with environment variable
    event.preventDefault()
    window.location.href = href
  }
}

// interface IProps extends BoxProps<'a'> {}

export const Link = React.forwardRef(
  ({href, to, useNavLink = false, ...props}: IProps, ref: React.Ref<any>) => {
    const {customerCacheClearInterval} = useGlobalStore()
    const _href = (to || href) as string
    const linkOrAnchorSelector = _href?.startsWith('http') ? 'a' : useNavLink ? NavSPALink : SPALink
    const newTabOrStayInApp = _href?.startsWith('http')
      ? {href: _href, target: '_blank', rel: 'noreferrer noopener'}
      : {to: _href}

    return (
      <Box
        as={linkOrAnchorSelector}
        {...(useNavLink && {exact: true})}
        {...newTabOrStayInApp}
        {...props}
        ref={ref}
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          handleClick(event, _href, customerCacheClearInterval)
        }}
      />
    )
  },
)

Link.displayName = 'Link'
