import React, {useRef} from 'react'
import {useRect} from '@reach/rect'
import {Box, BoxProps, useDisclosure} from '../../vanilla'

export interface CollapsablePanelStatelessProps
  extends Omit<BoxProps<'div'>, 'children'>,
    ReturnType<typeof useDisclosure> {
  content: string | undefined
  spacing?: number
  children?: (disclosureProps: ReturnType<typeof useDisclosure>) => React.ReactNode
}

export const CollapsablePanelStateless = ({
  children,
  isOpen,
  setIsOpen,
  onOpen,
  onClose,
  onToggle,
  content,
  spacing = 0,
  maxHeight = 'auto',
  ...props
}: CollapsablePanelStatelessProps) => {
  const ref = useRef(null)
  const rect = useRect(ref, {observe: false})

  const targetHeight = typeof maxHeight === 'number' ? maxHeight : rect?.height || 'auto'

  return (
    <Box {...props}>
      {children?.({isOpen, onOpen, onClose, onToggle, setIsOpen})}

      <div
        style={{
          overflow: 'hidden',
          maxHeight: isOpen
            ? maxHeight === 'fit'
              ? 'fit-content'
              : targetHeight !== 'auto'
              ? `${targetHeight + spacing}px`
              : targetHeight
            : 0,
          transition: 'max-height 300ms ease-out',
        }}
      >
        <div
          ref={ref}
          className="customScroll"
          style={{
            overflowY: 'auto',
            marginTop: spacing,
            maxHeight:
              maxHeight === 'fit'
                ? 'fit-content'
                : typeof targetHeight === 'string'
                ? targetHeight
                : targetHeight + spacing,
          }}
        >
          <div
            style={{
              opacity: isOpen ? 1 : 0,
              transition: 'opacity 200ms ease-out',
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </Box>
  )
}
