export enum CARD_TYPES {
  AMERICAN_EXPRESS = 'amex',
  VISA = 'Visa',
  DISCOVER = 'Discover',
  MASTERCARD = 'MasterCard',
  PAYPAL = 'PayPal',
  GENERIC = 'Generic',
}
export type CARD_TYPES_CODE = `${CARD_TYPES}`

export const CARD_TYPES_NAMES = {
  [CARD_TYPES.AMERICAN_EXPRESS]: 'Amex',
  [CARD_TYPES.VISA]: 'Visa',
  [CARD_TYPES.DISCOVER]: 'Discover',
  [CARD_TYPES.MASTERCARD]: 'MasterCard',
} as const

export const CreditCardValidatorTypesMap = {
  mastercard: CARD_TYPES.MASTERCARD,
  'american-express': CARD_TYPES.AMERICAN_EXPRESS,
  visa: CARD_TYPES.VISA,
  discover: CARD_TYPES.DISCOVER,
}
