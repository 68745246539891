import React from 'react'
import {separator, separatorLine} from './styles.css'
import {Flex, Text, Box} from '../../vanilla'

const Separator = () => (
  <Flex className={separator}>
    <Box as="hr" className={separatorLine} />
    <Text variant="text1" color="accent2">
      or
    </Text>
    <Box as="hr" className={separatorLine} />
  </Flex>
)

export default Separator
