import {useGTMDispatch} from '../analytics/react-gtm-hook/index'
import {transformNavMenuPromoItemsForGA4, transformNavMenuPromoBannersForGA4, transformPromoSlotsForGA4, useSlotLocation} from './utils'

export const usePromotionDataTracking = () => {
  const sendDataToGTM = useGTMDispatch()
  const location = useSlotLocation();

  const sendPromotionData = (content) => {
    try {
      const promotions = transformPromoSlotsForGA4(content)
      if (!promotions || promotions.length === 0) return
  
      setTimeout(() => {
        const promotionData = promotions.map((promotion) => ({
          creative_name: promotion.creative_name,
          creative_slot: `${location} + ${promotion.creative_slot}`,
          promotion_id: promotion.promotion_id,
          promotion_name: promotion.promotion_name,
        }))
  
        const eventData = {
          event: 'view_promotion',
          ecommerce: {
            items: promotionData,
          },
        }
  
        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      }, 0)
    } catch (error) {
        console.error('usePromotionDataTracking error: ' + error.message)
    }
  }

  return sendPromotionData
}

export const useSelectPromotionTracking = () => {
  const sendDataToGTM = useGTMDispatch()
  const location = useSlotLocation()

  const sendSelectPromotion = (promotion) => {
    if (!promotion) return
    try {
      setTimeout(() => {
        const promotionData = [
          {
            creative_name: promotion.creative_name,
            creative_slot: `${location} + ${promotion.creative_slot}`,
            promotion_id: promotion.promotion_id,
            promotion_name: promotion.promotion_name,
            link_position: promotion.link_position ?? 1
          },
        ]
        const eventData = {
          event: 'select_promotion',
          ecommerce: {
            items: promotionData,
          },
        }
        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      }, 0)
    } catch (error) {
      console.error('useSelectPromotionTracking error: ' + error.message)
    }
  }
  return sendSelectPromotion
}

export const useNavMenuPromotionDataTracking = () => {
  const sendDataToGTM = useGTMDispatch()

  const sendNavMenuPromotionData = (promotions, navMenuLevel, hasNavFolders) => {
    if (!promotions) return
    setTimeout(() => {
      try {
        const promotionData = transformNavMenuPromoItemsForGA4(promotions, navMenuLevel, hasNavFolders)
        const eventData = {
          event: 'view_promotion',
          ecommerce: {
            items: promotionData,
          },
        }

        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      } catch (error) { 
        console.error('useNavMenuPromotionDataTracking error within the setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendNavMenuPromotionData
}

export const useNavMenuPromoBannersDataTracking = () => {
  const sendDataToGTM = useGTMDispatch()

  const sendNavMenuPromoBannersData = (flyOutContent) => {
    if (!flyOutContent) return
    setTimeout(() => {
      try {
        const promotionData = transformNavMenuPromoBannersForGA4(flyOutContent)
        const eventData = {
          event: 'view_promotion',
          ecommerce: {
            items: promotionData,
          },
        }

        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      } catch (error) { 
        console.error('useNavMenuPromoBannersDataTracking error within the setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendNavMenuPromoBannersData
}

export const useSelectNavMenuPromotionTracking = () => {
  const sendDataToGTM = useGTMDispatch()

  const sendNavMenuSelectPromotion = (name, path, categoryId, navMenuLevel, navFolderId, index) => {
    if (!name) return

    let creativeName = categoryId || navFolderId || 'No category ID';
    if (creativeName === undefined || creativeName === 'undefined') {
      creativeName = 'No category ID';
    }
    
    setTimeout(() => {
      try {
        const promotionData = [
          {
            creative_name: creativeName,
            creative_slot: navMenuLevel,
            promotion_id: path ?? 'Nav folder',
            promotion_name: name,
            link_position: index ?? 1
          }
        ]
        const eventData = {
          event: 'select_promotion',
          ecommerce: {
            items: promotionData,
          },
        }

        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      } catch (error) { 
        console.error('useSelectNavMenuPromotionTracking error within the setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendNavMenuSelectPromotion
}