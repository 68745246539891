import React from 'react'
import { Flex } from '../../vanilla';
import LoadingOverlay from '../loading-overlay';

export const PaypalButton = ({isLoading, paypalContainer}: {isLoading: boolean, paypalContainer:string}) => {
  return (
    <Flex
      id={paypalContainer}
      style={{ width: '100%', height: 44, backgroundColor: '#0070ba' }}
      position="relative"
      align="center"
      borderRadius="base"
    >
      <LoadingOverlay
        isLoading={isLoading}
        spinnerSize="sm"
        containerStyles={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
      />
    </Flex>
  );
};
