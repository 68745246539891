import React, {ChangeEvent, FormEventHandler, useState} from 'react'
import {useLocation} from 'react-router-dom'
import useNavigation from '../../../../../hooks/use-navigation'
import {Button, Flex, Input, Stack} from '../../../../../vanilla'
import {AmpliencePathIcon} from '../../../../icons'
import {PanelOption} from '../../panel-option'

export const PagePath = () => {
  const navigate = useNavigation()
  const location = useLocation()

  const [pagePathValue, setPagePathValue] = useState('')

  const handleSubmitPath: FormEventHandler = (event) => {
    event.preventDefault()
    if (pagePathValue) navigate(pagePathValue + location.search ?? '')
  }

  return (
    <PanelOption
      title="Page Path"
      Icon={<AmpliencePathIcon boxSize="24px" />}
      selectedOption={location.pathname}
    >
      <Stack padding="24px">
        <form onSubmit={handleSubmitPath}>
          <Flex style={{minWidth: 200}} align="center" justify="center" gap="2px">
            <Input
              value={pagePathValue}
              validatedSuccessfully={false}
              name="preview-page-path"
              label='Enter path (e.g "/pagename")'
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPagePathValue(e.target.value)
              }}
            />
            <Button
              size="sm"
              type="submit"
              bg="previewPrimary"
              borderTopLeftRadius="none"
              borderBottomLeftRadius="none"
            >
              Go
            </Button>
          </Flex>
        </form>
      </Stack>
    </PanelOption>
  )
}
