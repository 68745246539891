import React, {useRef} from 'react'
import {unstable_DialogWrapper as DialogWrapper, DialogInner} from '@reach/dialog'
import VisuallyHidden from '@reach/visually-hidden'
import {Box, Stack, Flex, Text, Button, useDisclosure, BoxProps} from '../../vanilla'
import {CloseIcon} from '../icons'
import {
  dialogOverlay,
  dialogContent,
  DialogContentVariants,
  footerCloseButtonContainer,
} from './modal.css'
import useLockBodyScrolling from '../../hooks/use-lock-body-scrolling'

export type ModalProps = {
  error?: boolean
  title?: React.ReactNode
  children?: React.ReactNode
  containerRef?: React.RefObject<Element>
  dialogContentProps?: BoxProps<'div'>
  closeIcon?: boolean
  styleless?: boolean
  allowScroll?: boolean
  footerCloseBtnText?: string
} & ReturnType<typeof useDisclosure> &
  DialogContentVariants

export const Modal = ({
  error=false,
  title,
  isOpen,
  onClose,
  children,
  size,
  closeIcon = true,
  containerRef,
  dialogContentProps,
  footerCloseBtnText,
  allowScroll = false,
  styleless = false,
}: ModalProps) => {
  const contentRef = useRef<HTMLDivElement | null>(null)
  
  // Make sure the styles of dialog are only set when the scroll bar is removed
  useLockBodyScrolling(isOpen && !allowScroll);
  
  return isOpen ? (
    <DialogWrapper containerRef={containerRef}>
      <DialogInner dangerouslyBypassScrollLock={allowScroll}
        className={dialogOverlay({})}
        onDismiss={(event) => {
          if (!contentRef?.current?.contains(event.target as Element)) {
            onClose()
          }
        }}
      >
        <Box
          className={dialogContent({size: size})}
          ref={contentRef}
          aria-label="modal"
          {...dialogContentProps}
        >
          <Stack spacing="0px" bg={styleless ? undefined : 'white'} minHeight="full">
            {title && (
              <Box paddingX="28px" paddingY="16px" borderBottom="1px" borderColor="gray100">
                <Flex align="center" justify="space-between">
                  <Text variant="heading1" style={{color: error === true ? "red": "initial" }}>{title}</Text>
                  {closeIcon && (
                    <Box style={{marginRight: -12, marginTop: -8}}>
                      <Button variant="unstyled" paddingX="0px" width="44px" onClick={onClose}>
                        <VisuallyHidden>Close</VisuallyHidden>

                        <CloseIcon boxSize="24px" />
                      </Button>
                    </Box>
                  )}
                </Flex>
              </Box>
            )}
            {children}
            {footerCloseBtnText && (
              <Box padding="20px" borderTop="1px" borderColor="gray100">
                <Flex align="center" justify="flex-end">
                  <Box className={footerCloseButtonContainer} width="full">
                    <Button size="sm" variant="dark" onClick={onClose} style={{width: '100%'}}>
                      {footerCloseBtnText}
                    </Button>
                  </Box>
                </Flex>
              </Box>
            )}
          </Stack>
        </Box>
      </DialogInner>
    </DialogWrapper>
  ) : null
}
