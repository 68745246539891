import * as React from 'react'
import classNames from 'classnames'
import {Box, PolymorphicComponentProps} from './Box'
import {container, ContainerVariants} from './container.css'

type ContainerOwnProps = ContainerVariants
type ContainerProps<E extends React.ElementType> = PolymorphicComponentProps<E, ContainerOwnProps>

const defaultElement = 'div'

export const Container: <E extends React.ElementType = typeof defaultElement>(
  props: ContainerProps<E>
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {children, size = 'xl', className, ...restProps}: ContainerProps<E>,
    ref: typeof restProps.ref
  ) => {
    return (
      <Box
        as={defaultElement}
        className={classNames(container({size}), className)}
        paddingX="16px"
        ref={ref}
        {...restProps}
      >
        {children}
      </Box>
    )
  }
)
