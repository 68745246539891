import * as React from 'react'
import {Box, PolymorphicComponentProps} from './Box'

type CenterProps<E extends React.ElementType> = PolymorphicComponentProps<E, object>

const defaultElement = 'div'

export const Center: <E extends React.ElementType = typeof defaultElement>(
  props: CenterProps<E>,
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {children, ...restProps}: CenterProps<E>,
    ref: typeof restProps.ref,
  ) => {
    return (
      <Box
        as={defaultElement}
        display="flex"
        alignItems="center"
        justifyContent="center"
        ref={ref}
        {...restProps}
      >
        {children}
      </Box>
    )
  },
)
