import React from 'react'
import {useIntl} from 'react-intl'
import {validateEmail} from '../../utils/email-utils'
import {LockIcon} from '../../components/icons'
import {CreateFields, LogInFormFields} from '../../types/forms'

export const useLoginFields: CreateFields<LogInFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    email: {
      name: `email`,
      label: formatMessage({defaultMessage: 'Email address'}),
      defaultValue: '',
      type: 'email' as const,
      rules: {
        required: formatMessage({defaultMessage: 'Please enter your email address.'}),
        validate: (value?: string) =>
          validateEmail(value).isValid ||
          formatMessage({defaultMessage: 'Please enter a valid email address.'}),
      },
      inputProps: {
        autoComplete: 'email',
        id: 'login-email-field',
      },
      error: errors[`email`],
      control,
      onSubmitValidation: true, // Pass this prop to only show errors after the form has been submitted
    },
    password: {
      name: `password`,
      label: formatMessage({defaultMessage: 'Your password'}),
      defaultValue: '',
      type: 'password' as const,
      rules: {
        required: formatMessage({defaultMessage: 'Please enter your password'}),
      },

      error: errors[`password`],
      control,
      inputProps: {
        icon: <LockIcon boxSize="12px" />,
        autoComplete: 'current-password',
        id: 'login-password-field',
      },
      onSubmitValidation: true, // Pass this prop to only show errors after the form has been submitted
    },
  }

  return fields
}
