import React, {forwardRef, RefObject} from 'react'
import {Box, Text, Flex} from '../'
import {PolymorphicComponentProps} from './Box'
import {checkboxRecipe, CheckBoxVariants} from './checkbox.css'

type StyleProps = object & CheckBoxVariants

type UVariant<T = StyleProps> = T extends CheckBoxVariants ? Omit<T, 'variant'> : never

type CheckBoxOwnProps = UVariant & {
  disabled?: boolean
  variant?: StyleProps['variant']
  label: React.ReactNode
  hasError: boolean
}

export type CheckboxProps = PolymorphicComponentProps<'input', CheckBoxOwnProps>

export const Checkbox = forwardRef(
  ({variant, size, label, hasError, id, name, ...props}: CheckboxProps, ref) => {
    const checkboxClassName = checkboxRecipe({variant, size, error: hasError})

    return (
      <Flex as="label" align="center" gap="8px">
        <Box
          name={name}
          as="input"
          type="checkbox"
          flexShrink="0"
          alignSelf="flex-start"
          className={checkboxClassName}
          id={id}
          {...props}
          ref={ref as RefObject<HTMLInputElement>}
        />
        {typeof label === 'string' ? (
          <Text as="label" variant="text4" lineHeight="short" marginTop="2px" htmlFor={id}>
            {label}
          </Text>
        ) : (
          label
        )}
      </Flex>
    )
  },
)
