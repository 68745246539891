import { PreviouslyRemovedItem } from '../../types/store/basket'
import {useBasketStore, useProductStore} from './useStore'

export const usePreviouslyRemovedItem = (itemId: string) => {
  const {basket} = useBasketStore()
  const {productsById} = useProductStore()

  const item = basket?.c_previouslyRemovedItems?.find((_item: PreviouslyRemovedItem) => _item.pid === itemId)
  const product = item ? productsById[item.pid!] : null

  return {item, product}
}
