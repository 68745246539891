import {observer} from 'mobx-react'
import React, {RefObject} from 'react'
import {ArrowContainer, Popover} from 'react-tiny-popover'
import {Box, BoxProps, Button, Flex, Stack, Text, useDisclosure} from '../../vanilla'
import {useCustomerStore, useOrderStore} from '../../store/hooks/useStore'
import {Link} from '../link'
import ReactDOM from 'react-dom'
import {isServer} from '../../utils/utils'
import {baseThemeContract} from '../../vanilla/vars.css'
import {ChevronDownIcon} from '../icons'
import {linkUtilityMenu} from './styles.css'
import {ExitOrderEditModeSignOutModal} from '../edit-order-exit-mode-modal'
import {useLocation} from 'react-router-dom'
import useNavigation from '../../hooks/use-navigation'

interface OverlayProps extends BoxProps<'div'> {
  isVisible: boolean
  containerRef?: RefObject<HTMLElement>
}

const Overlay = (props: OverlayProps) => {
  const {isVisible, containerRef, ...rest} = props

  if (isServer) return null

  const container = containerRef?.current || document.querySelectorAll('body')[0]

  return isVisible
    ? ReactDOM.createPortal(
        <Box
          position="fixed"
          top="0px"
          left="0px"
          width="full"
          height="full"
          style={{backgroundColor: 'rgba(0,0,0,.2)'}}
          zIndex="overlay"
          {...rest}
        />,
        container
      )
    : null
}

export const MyAccountPopover = observer(() => {
  const accountPopover = useDisclosure(false)
  const signOutExitOrderEditMode = useDisclosure(false)
  const {customerInfo, logout} = useCustomerStore()
  const {editMode} = useOrderStore()
  const location = useLocation()
  const navigate = useNavigation()

  const logoutHandler = () => {
    if (editMode) {
      signOutExitOrderEditMode.onOpen()
      accountPopover.onClose()
      return
    }

    logout()
    // Redirect to home if the user is on the account page
    if (location.pathname.includes('account')) {
      navigate('/')
    }
  }

  return (
    <>
      <ExitOrderEditModeSignOutModal {...signOutExitOrderEditMode} />
      <Overlay isVisible={accountPopover.isOpen} />
      <Popover
        isOpen={accountPopover.isOpen}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            arrowStyle={{zIndex: baseThemeContract.zIndex.popover}}
          >
            <Stack align="center" bg="white" style={{width: 200}} padding="12px" paddingTop="16px">
              <Text variant="text5" lineHeight="shorter" data-cs-mask="">
                Welcome, {customerInfo?.firstName ?? ''}
              </Text>
              <Stack as="ul" align="center" paddingY="4px" spacing="12px">
                <Box as="li" onClick={accountPopover.onClose}>
                  <Text
                    variant="text3"
                    lineHeight="shorter"
                    as={Link}
                    to="/account"
                    color="gray800"
                    className={linkUtilityMenu}
                  >
                    My Account
                  </Text>
                </Box>
                <Box as="li" onClick={accountPopover.onClose}>
                  <Text
                    variant="text3"
                    lineHeight="shorter"
                    as={Link}
                    to="/account/order-history"
                    color="gray800"
                    className={linkUtilityMenu}
                  >
                    Order History
                  </Text>
                </Box>
              </Stack>
              <Button
                variant="outlinePrimary"
                onClick={logoutHandler}
                width="full"
                size="sm"
                marginTop="8px"
              >
                Sign Out
              </Button>
            </Stack>
          </ArrowContainer>
        )}
        positions={['bottom']}
        align="end"
        reposition={true}
        containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
        onClickOutside={accountPopover.onClose}
      >
        <Flex
          onClick={accountPopover.onToggle}
          paddingLeft="12px"
          align="center"
          gap="4px"
          cursor="pointer"
          className={linkUtilityMenu}
        >
          <Text variant="text1" lineHeight="shorter">
            Account
          </Text>
          <ChevronDownIcon boxSize="8px" color="gray800" />
        </Flex>
      </Popover>
    </>
  )
})
