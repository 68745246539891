import {useGTMDispatch} from './react-gtm-hook/index';
import {transformAlgoliaItemsForBasketEventsGA4} from './utils';

export const useAddToWishlistTracking = () => {
  const sendDataToGTM = useGTMDispatch();

  const sendAddToWishlistData = (product, cartLocation, viewingCategoryId, location, hitItemIndex) => {
    setTimeout(() => {
      try {
        const _items = transformAlgoliaItemsForBasketEventsGA4([product], cartLocation, viewingCategoryId, location, hitItemIndex)
        sendDataToGTM({
          event: 'add_to_wishlist',
          ecommerce: {
            currency: 'GBP',
            value: _items[0].price * _items[0].quantity,
            items: _items, 
          }
        });
      } catch (error) {
        console.error('useAddToWishlistTracking error within setTimeout: ' + error.message);
      }
    }, 0);
  };
  return sendAddToWishlistData;
};