export const POSTCODE_REGEXP =
  /^\s*(([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s*[0-9][A-Za-z]{2}))\s*$/
export const MAX_ADDRESS_NAME_LENGTH = 20
export const POSTCODE_MIN_LENGTH = 5
export const POSTCODE_MAX_LENGTH = 10
export const EMAIL_REGEXP =
  /^\s*(([^<>()[\]\\.,;:\s@"']+(\.[^<>()[\]\\.,;:\s@"']+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/
export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 255
export const FIRST_NAME_REGEXP = /^[^@&,().;:'`/<>]+$/
export const LAST_NAME_REGEXP = /^[^@&,().;:'`/<>]+$/
export const PHONE_REGEXP =
  /^0((\d{4}\s?\d{3}\s?\d{3})|(\d{3}\s?\d{3}\s?\d{4})|(\d{2}\s?\d{4}\s?\d{4}))$/
export const ADDRESS_REGEXP = /^[^&<>"'’”“()\\]+$/
