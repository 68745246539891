import {useEffect, useRef} from 'react'
import {useCustomerStore, useGlobalStore} from '../store/hooks/useStore'
import {useHistory} from 'react-router'
import {listenToCrossTabEvent, sendCrossTabEvent} from '../utils/cross-tabs-events-utils'
const IDLE_TIMEOUT_CHECK_SECS = 30
export const useAutoLogout = () => {
  const history = useHistory()
  const customerStore = useCustomerStore()
  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} =
  useGlobalStore()
  const lastActiveIntervalId = useRef<ReturnType<typeof setInterval>>()
  let IDLE_TIMEOUT_MINS = 43200

  useEffect(() => {
    const idleTimeOutPref = async () => {
      if (!isCustomSitePreferenceSet('autoLogout')) {
        await getCustomSitePreferenceById('autoLogout')
      }
      IDLE_TIMEOUT_MINS = customSitePreferences['autoLogout'] as number
    }
    idleTimeOutPref()
  })

  useEffect(() => {
    if (history) {
      return history.listen(() => {
        customerStore.setLastActiveToNow()
        sendCrossTabEvent('lastActive', `${Date.now()}`)
      })
    }
  }, [])
  useEffect(() => {
    const checkTimeout = () => {
      if (
        customerStore.isRegistered &&
        Date.now() - customerStore.lastActive >= IDLE_TIMEOUT_MINS * 60 * 1000
      ) {
        customerStore.logout()
      }
    }
    const resetTimeout = () => {
      clearTimeout(lastActiveIntervalId.current)
      lastActiveIntervalId.current = setInterval(checkTimeout, 1000 * IDLE_TIMEOUT_CHECK_SECS)
    }
    resetTimeout()
    return () => {
      clearTimeout(lastActiveIntervalId.current)
    }
  }, [])
  useEffect(() => {
    return listenToCrossTabEvent('lastActive', (lastActiveString: string) => {
      customerStore.setLastActive(parseInt(lastActiveString))
    })
  }, [])
}