import {EMAIL_REGEXP} from './constants'

export const validateEmail = (value?: string) => {
  return {
    isValid: value && EMAIL_REGEXP.test(value),
  }
}

export const validateEmailsMatch = (confirmNewEmail: string, newEmail: string) => {
  return newEmail === confirmNewEmail ? true : false
}