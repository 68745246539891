import {BeforeYouGoPage} from '../../types/store/basket'

export const shouldShowPage = (productsCount: number, min: number) => productsCount >= min

export const predicate = (id: string, productIds: string[]) => !productIds.includes(id)

export const getValueIfDefined = (value: boolean | undefined) =>
  value !== undefined ? value : true

export const getShowValues = (
  showBadges: boolean | undefined,
  showPromotions: boolean | undefined,
  showRating: boolean | undefined,
) => {
  const showBadge = showBadges !== undefined ? showBadges : true
  const showPromos = showPromotions !== undefined ? showPromotions : true
  const showRatings = showRating !== undefined ? showRating : true

  return {showBadge, showPromos, showRatings}
}

export const isSearchPage = (page: BeforeYouGoPage) => page.pageType === 'search'
