import React from 'react'
import {Box, Stack, Text} from '../../vanilla'
import {DynamicFormContent} from './dynamic-form-content'

export const ContactUsForms = () => {
  return (
    <Stack spacing="24px">
      <Box bg="white">
        <Box paddingY="24px" paddingX="20px" borderBottom="1px" borderColor="gray200">
          <Text variant="unstyled" fontSize="lg" fontWeight="normal" lineHeight="shorter">
            Couldn&apos;t find what you were looking for? Please contact us by completing the form
            below.
          </Text>
        </Box>
        <DynamicFormContent />
      </Box>

      <Box bg="white">
        <Box paddingY="24px" paddingX="20px" borderBottom="1px" borderColor="gray200">
          <Text variant="unstyled" fontSize="lg" fontWeight="normal" lineHeight="shorter">
            Need to call?
          </Text>
        </Box>
        <Stack spacing="8px" paddingY="24px" paddingX="20px">
          <Text variant="text3">
          If you have a question about our website or need help placing an order, you can phone our Customer Care team on 0800 328 0800. Our lines are open 8AM - 6PM.
          </Text>
        </Stack>
      </Box>
    </Stack>
  )
}
