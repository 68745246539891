import React from 'react'
import {SlotComponent} from '../../types/ui'
import {Stack} from '../../vanilla'
import {Slot} from './Slot'
import {DefaultContentBody} from 'dc-delivery-sdk-js'

export const ContentSlot: SlotComponent<DefaultContentBody> = ({content}) => {
  return (
    <Stack paddingY={['16px', '24px']}>
      <Slot data={content} />
    </Stack>
  )
}
