import {useEffect} from 'react'

const useLockBodyScrolling = (isLocked: boolean) => {
  useEffect(() => {
    if (isLocked) {
      document.body.classList.add('no-body-scrolling')
    } else {
      document.body.classList.remove('no-body-scrolling')
    }

    return () => {
      document.body.classList.remove('no-body-scrolling')
    }
  }, [isLocked])
}

export default useLockBodyScrolling
