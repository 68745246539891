import React from 'react'
import {
  AmexIcon,
  CreditCardIcon,
  DiscoverIcon,
  MastercardAltIcon,
  PaypalAltIcon,
  VisaAltIcon,
} from '../icons'
import {CARD_TYPES, CARD_TYPES_CODE} from '../../utils/constants'
import {BoxProps} from '../../vanilla'

interface CardTypeIconProps extends BoxProps<any> {
  cardType?: CARD_TYPES_CODE
}

export const CreditCardTypeIcon = ({cardType, ...boxProps}: CardTypeIconProps) => {
  switch (cardType) {
    case CARD_TYPES.AMERICAN_EXPRESS:
      return <AmexIcon height="44px" width="44px" {...boxProps} />
    case CARD_TYPES.DISCOVER:
      return <DiscoverIcon height="44px" width="44px" {...boxProps} />
    case CARD_TYPES.MASTERCARD:
      return <MastercardAltIcon height="44px" width="44px" {...boxProps} />
    case CARD_TYPES.VISA:
      return <VisaAltIcon height="44px" width="44px" {...boxProps} />
    case CARD_TYPES.PAYPAL:
      return <PaypalAltIcon height="44px" width="44px" {...boxProps} />
    case CARD_TYPES.GENERIC:
      return <CreditCardIcon height="44px" width="44px" {...boxProps} />
    default:
      return null
  }
}
