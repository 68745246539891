import React from 'react'
import {Image, ImageLink} from '../../../types/cms'
import {Box, BoxProps} from '../../../vanilla'
import {Link} from '../../link'
import {getImageUrl, getStaticImageUrl, media} from '../utils'

// NOTE: documentation on these parameters can be found here:
// https://amplience.com/developers/docs/apis/media-delivery/media-delivery-reference
// NOTE: this is not an exhaustive list of parameters, as there are a *lot* of them.
// Feel free to add to the list if needed.
export type CmsImageQueryParameters = {
  w?: number
  h?: number
  qlt?: number | string
  fmt?: string
  template?: string
}[]

export type CmsImageProps = {
  images: Image[]
  imageLink?: ImageLink
  isStaticImage?: boolean // must be true for GIFs and SVGs
  linkProps?: BoxProps<'a'> & {ref?: React.Ref<any>}
  queryParams?: CmsImageQueryParameters
} & BoxProps<'img'>

/**
 * Builds the full source URL of the image. This method essentially applies the Amplience query parameters
 * to the source. In particular, these parameters take precedence over any parameters that were pre-defined.
 *
 * Also, please note that setting a template will **completely override** all other parameters.
 */
const generateImageSource = (src: string, queryParams: CmsImageQueryParameters | undefined) => {
  let paramString = null

  if (!queryParams) {
    return src
  }

  const currentQueryParams = new URLSearchParams(src.split('?')[1])
  if (!currentQueryParams.has('fmt')) {
    currentQueryParams.set('fmt', 'auto')
  }
  if (Array.isArray(queryParams)) {
    let srcSetString = ''
    queryParams.forEach((params, index) => {
      const adjustedParams = { ...params }
      const sortedParams = Object.entries(adjustedParams).sort(([a], [b]) => a.localeCompare(b))
      sortedParams.forEach(([p, v]) => currentQueryParams.set(p, v.toString()))
      paramString = currentQueryParams.toString()
      const resolution = index < queryParams.length / 2 ? '1x' : '2x'
      srcSetString += `${src.split('?')[0]}${paramString ? '?' : ''}${paramString} ${resolution}, `
    })
    return srcSetString
  } else {
    Object.entries(queryParams).forEach(([p, v]) => currentQueryParams.set(p, v.toString()))
    paramString = currentQueryParams.toString()
    return `${src.split('?')[0]}${paramString ? '?' : ''}${paramString}`
  }
}

const renderPicture = (
  images: Image[],
  props: BoxProps<'img'>,
  queryParams: CmsImageQueryParameters | undefined,
  isStaticImage?: boolean
) => (
  <Box as="picture" display="block">
    {images?.map?.(({image, data}) => {
      if (!data || !data.screen) {
        return null
      } else
        return (
          <source
            key={`${image.name}-${data.screen}`}
            media={media[data?.screen]}
            srcSet={generateImageSource(
              isStaticImage ? getStaticImageUrl(image) : getImageUrl(image),
              queryParams
            )}
          />
        )
    })}
    <Box
      as="img"
      // NOTE: by default we are using the first image on the array.
      srcSet={generateImageSource(
        isStaticImage ? getStaticImageUrl(images?.[0].image) : getImageUrl(images?.[0].image),
        queryParams
      )}
      alt={images?.[0]?.data?.altText ?? 'No alternative text available'}
      draggable={false}
      {...props}
    />
  </Box>
)

export const CmsImage = ({
  images,
  imageLink,
  isStaticImage,
  linkProps,
  queryParams,
  ...props
}: CmsImageProps) => {
  if (imageLink) {
    return (
      <Link {...linkProps} to={imageLink} display="block" draggable={false}>
        {renderPicture(images, props, queryParams, isStaticImage)}
      </Link>
    )
  }
  return renderPicture(images, props, queryParams, isStaticImage)
}
