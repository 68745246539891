import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {TObservablePage} from '../../types/commerce'
import {Box, Flex, Text} from '../../vanilla'
import {Link} from 'react-router-dom'
import * as styles from './styles.css'
import {BaseButton} from '../../vanilla/components/BaseButton'
import {usePageDataTracking} from '../../analytics/page-tracking'

const PageNotFound: TObservablePage = observer(() => {
  const sendPageData = usePageDataTracking()
  const pageType = 'error'
  const pageCategory = 'General Error'
  useEffect(() => {
    sendPageData(pageType, pageCategory)
  }, [])
  
  return (
    <Box
      bg="gray50"
      className={styles.container}
      paddingTop={['0px', '64px']}
      minHeight={['auto', 'fullvh']}
      marginX={['0px', 'auto']}
      style={{color: '#4a4b4d'}}
      paddingLeft={'0px'}
    >
      <Box
        className={styles.callUsContainer}
        padding={'0px'}
        position={'relative'}
        bg={'white'}
        width={['full', 'auto']}
      >
        <Text
          variant={'unstyled'}
          as="h4"
          padding={'24px'}
          fontSize={'lg'}
          lineHeight={'shorter'}
          style={{borderBottom: '1px solid #d8d8d8'}}
        >
          Call Us
        </Text>
        <Box padding={'24px'} color="accent2">
          <Text lineHeight={'base'} marginBottom={'16px'}>
            If you have a question about our website or need help placing an order, you can phone
            our Customer Care team on 0800 328 0800. Our lines are open 8AM - 6PM.
          </Text>
        </Box>
      </Box>
      <Box
        className={styles.oopsContainer}
        paddingX={'20px'}
        paddingBottom={'20px'}
        bg={'white'}
        marginLeft={['0px', '20px']}
        position={'relative'}
        textAlign={'center'}
        overflow={'hidden'}
      >
        <Box>
          <Text
            variant={'unstyled'}
            className={styles.oopsHeader}
            fontWeight={'bold'}
            textAlign={'center'}
            marginY={['24px', '48px']}
          >
             Oops,
          </Text>
          <Text
            variant={'unstyled'}
            fontSize={['xl', 'xxl']}
            style={{lineHeight: '1.3125em'}}
            fontWeight={'bold'}
            textAlign={'center'}
            marginBottom={'16px'}
          >
            looks like this aisle is empty
          </Text>
          <Text
            variant={'unstyled'}
            style={{lineHeight: '1.6em'}}
            textAlign={'center'}
            marginBottom={'16px'}
          >
            The link you clicked may be broken or the page may have been removed.
          </Text>
          <Flex direction={['column', 'row']} justifyContent={'center'} alignItems={'center'}>
            <Text variant={'unstyled'} style={{lineHeight: '1.6em'}} marginBottom={['0px', '16px']}>
              Try the search above or go back to the
            </Text>
            <Text
              as={Link}
              to="/"
              variant={'unstyled'}
              style={{lineHeight: '1.6em', textDecoration: 'underline'}}
              marginBottom={'16px'}
              marginLeft={'4px'}
            >
              {' '}
              homepage.
            </Text>
          </Flex>
          <BaseButton as={Link} to="/" marginBottom={'16px'} paddingY={'8px'} paddingX={'20px'}>
            <Text variant={'unstyled'} lineHeight={'shorter'}>
              Homepage
            </Text>
          </BaseButton>
        </Box>
      </Box>
    </Box>
  )
})

export default PageNotFound
