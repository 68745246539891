import React from 'react'
import {useTimer} from '../../hooks/use-timer'
import {ColorPicker} from '../../types/cms'
import {Flex, Text} from '../../vanilla'

interface CountdownTimerProps {
  content: {
    textColor: ColorPicker
    countdownColor: ColorPicker
    countdownDate: string
    text: string
  }
}

const Timer = ({color, date}: {color: ColorPicker; date: string}) => {
  const shouldPrependZeros = true
  const {hours, minutes, seconds} = useTimer(date, shouldPrependZeros)

  return (
    <Text
      as="span"
      style={{color: color?.color}}
      variant="text4"
    >{`${hours}h:${minutes}m:${seconds}s`}</Text>
  )
}

export const CountdownTimer = ({content}: CountdownTimerProps) => {
  return (
    <Flex
      style={{backgroundColor: content?.countdownColor?.color || '#fff'}}
      justify="center"
      align="center"
      paddingY="16px"
      paddingX="12px"
    >
      <Text variant="text4" style={{color: content?.textColor?.color}}>
        {content?.text} <Timer color={content?.textColor} date={content?.countdownDate} />
      </Text>
    </Flex>
  )
}
