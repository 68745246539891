import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Popover} from 'react-tiny-popover'
import {Box, Flex, Stack, Text} from '../../vanilla'
import {Link} from '../link'
import {useGlobalStore} from '../../store/hooks/useStore'
import {megaMenuListItem} from './styles.css'
import {MegaMenu} from './mega-menu'
import {vars} from '../../vanilla/vars.css'
import {ChevronDownIcon} from '../icons'
import {BaseButton} from '../../vanilla'
import {useLocation} from 'react-router-dom'
import {
  useNavMenuPromotionDataTracking,
  useSelectNavMenuPromotionTracking,
} from '../../analytics/promotion-tracking'
import {NavContentItem} from '../../store/ContentStore'
import {MobileNavigationList} from './mobile-navigation-drawer/mobile-navigation-list'
import {noop} from '../../utils/utils'
import {transformNavItem} from '../cms/utils'
import {isMobile} from '../../utils/utils'

type DesktopNavigationProps = {
  content?: NavContentItem
}

const DesktopNavigation = observer(({content}: DesktopNavigationProps) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const {pathname} = useLocation()
  const {storeAndDaySpecificNavigation} = useGlobalStore()
  const [_hovered, setHovered] = useState(false)

  const sendNavMenuPromotionData = useNavMenuPromotionDataTracking()
  const sendNavMenuSelectPromotion = useSelectNavMenuPromotionTracking()
  const isMobileFlag: boolean = isMobile()

  // close menu on selection
  useEffect(() => {
    setSelectedItem(null)
  }, [pathname])

  useEffect(() => {
    if (!isMobileFlag) {
      sendNavMenuPromotionData(storeAndDaySpecificNavigation, 'Desktop Navigation Top Level', false)
    }
  }, [])

  if (!storeAndDaySpecificNavigation) {
    return null
  }

  let navigation = storeAndDaySpecificNavigation

  if (storeAndDaySpecificNavigation.length === 0 && content) {
    navigation = [transformNavItem(content)]
  }

  return (
    <>
      <Flex
        direction="row"
        align="stretch"
        height="full"
        display={{mobile: 'none', tablet: 'flex'}}
      >
        <Flex as="ul" backgroundColor="white" data-test-selector="menu-desktop">
          {navigation?.map((item, index) => {
            const isSelected = selectedItem === item.name
            let textColor = item.color || undefined
            if (isSelected) {
              textColor = '#0097cc'
            }
            if (!item.path && !item.children) return // ignore top-level nav items if they have no link and no children
            const buttonElement = (props = {}) => (
              <BaseButton
                {...props}
                variant="unstyled"
                color="inherit"
                paddingX="16px"
                paddingY="16px"
                style={{height: 50, borderRadius: 0}}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClickCapture={() => {
                  sendNavMenuSelectPromotion(
                    item.name,
                    item.path,
                    item.categoryId,
                    'Desktop Navigation Top Level',
                    index + 1
                  )
                }}
              >
                <Text
                  variant="text6"
                  fontStyle="normal"
                  textDecoration="none"
                  style={{
                    color: textColor,
                    fontWeight: vars.fontWeight.medium,
                  }}
                >
                  {item.name}
                </Text>
                {!!item.children && (
                  <ChevronDownIcon
                    boxSize="12px"
                    marginLeft="8px"
                    color={isSelected ? 'accent3' : 'black'}
                  />
                )}
              </BaseButton>
            )
            return (
              <Box
                key={item.name}
                as="li"
                height="full"
                className={megaMenuListItem}
                border="1px"
                borderColor={'gray200'}
                borderBottom="0px"
                backgroundColor={isSelected ? 'white' : 'gray25'}
                style={{
                  // TODO: link this color to the theme
                  boxShadow: isSelected ? `inset 0 3px #0097cc` : 'none',
                }}
                onMouseEnter={() => {
                  setSelectedItem(item.name)
                }}
                onMouseLeave={() => {
                  setSelectedItem(null)
                }}
              >
                {item.children && (
                  <Popover
                    isOpen={isSelected}
                    positions={['bottom']}
                    align="start"
                    reposition
                    containerStyle={{zIndex: vars.zIndex['popover']}}
                    content={() => <MegaMenu content={item.children!} />}
                  >
                    {buttonElement()}
                  </Popover>
                )}
                {!!item.path && buttonElement({as: Link, to: item.path})}
              </Box>
            )
          })}
        </Flex>
      </Flex>
      <Stack display={{mobile: 'block', tablet: 'none', desktop: 'none'}}>
        <MobileNavigationList nav={navigation} onClose={noop} />
      </Stack>
    </>
  )
})

export default DesktopNavigation
