import {makeObservable, flow, observable} from 'mobx'
import type {RootStore} from './RootStore'
import type {PromiseFlow, StoreModel} from './utils'
import type {CommerceAPI, ShopperProducts} from 'commerce-api'
import {OmitFromKnownKeys} from './utils'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {REFINEMENTS_ID_MAP} from '../utils/constants'
import { Status } from '../types/commerce'

export type Category = ShopperProducts.Category

export type NormalizedCategory = OmitFromKnownKeys<Category, 'categories'> & {
  isTopLevelCategory: boolean
  categories?: Array<string>
}

const normalizeCategories = (tree: Category): Record<string, NormalizedCategory> => {
  let cats: Record<string, NormalizedCategory> = {}
  const fn = (cat: Category) => {
    const {categories, ...rest} = cat
    const category: NormalizedCategory = {
      ...rest,
      isTopLevelCategory: cat.parentCategoryId === 'root',
    }
    cats[category.id] = category
    if (categories) {
      const childCategoryIds = categories.map((childCat) => {
        fn(childCat)
        return childCat.id
      })
      category.categories = childCategoryIds
    }
  }
  fn(tree)
  return cats
}

type RefinementsResponse = {
  refinements: {
    UUID: string
    attributeID: string
    attributeRefinement: boolean
    categoryRefinement: boolean
    cutoffThreshold: number
    displayName: string
    priceRefinemente: number
    promotionRefinement: boolean
    valueTypeCode: number
  }[]
}

export class CategoryStore implements StoreModel {
  api: CommerceAPI
  categories: Record<string, NormalizedCategory>
  categoryRefinements: Record<string, {label: string; attribute: string}[]>
  status: Status

  constructor(rootStore: RootStore, initialState = {categories: {}}) {
    this.categories = initialState.categories
    this.categoryRefinements = {}
    this.status = 'idle'

    makeObservable(this, {
      categories: observable,
      categoryRefinements: observable,
      fetchAllCategories: flow.bound,
      getRefinementsByCategoryId: flow.bound,
    })

    this.api = rootStore.api
  }

  get asJson() {
    return {
      categories: this.categories,
    }
  }

  *fetchAllCategories(): PromiseFlow<Category> {
    if (this.status !== 'pending' && this.status !== 'done') {
      try {
        this.status = 'pending'
  
        const rootCategory = yield this.api.shopperProducts.getCategory({
          parameters: {id: 'root', levels: 4},
        })

        this.categories = normalizeCategories(rootCategory)
        
        this.status = 'done'
      } catch (error) {
        console.error('Failed to fetch categories', error)
        this.status === 'error'
      }
    }
  }

  *getRefinementsByCategoryId(catId: string) {
    if (!catId) return

    try {
      const response: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Search-GetRefinements?cat=${catId}`,
        {
          method: 'GET',
          headers: {'Content-Type': 'application/json'},
        }
      )

      if (!response.ok) throw new Error(`Failed to get refinements for category: ${catId}`)

      const json: RefinementsResponse = yield response.json()

      if (json.refinements && Array.isArray(json.refinements)) {
        const refinements: {label: string; attribute: string}[] = []

        for (const refinement of json.refinements) {
          if (refinement.categoryRefinement) {
            refinements.push({
              label: 'Category',
              attribute: REFINEMENTS_ID_MAP.CATEGORY,
            })
          } else if (refinement.priceRefinemente) {
            refinements.push({
              label: 'Price',
              attribute: REFINEMENTS_ID_MAP.PRICE,
            })
          } else {
            refinements.push({
              label: refinement.displayName,
              attribute: refinement.attributeID,
            })
          }
        }

        this.categoryRefinements[catId] = refinements
      }
    } catch (error) {
      console.error(error)
    }
  }
}
