import {ContentParams, NavContentItem, NavItem} from '../../store/ContentStore'
import {ImageScreen, MediaLink, ReoccurringSlotContentItem, SlotContentItem, SlotItem, VariationContent} from '../../types/cms'
import {isWithinInterval, isBefore} from 'date-fns'
import {DateUtils} from '../../utils/date-utils'
import type {DefaultContentBody} from 'dc-delivery-sdk-js'
import {Nullable} from '../../types/utils'

export function getImageUrl(source: MediaLink): string {
  return `https://${source?.defaultHost}/i/${source?.endpoint}/${source?.name}`
}

export function getStaticImageUrl(source: MediaLink): string {
  return `https://${source.endpoint}.a.bigcontent.io/v1/static/${source.name}`
}

export const media: {[key in ImageScreen]: string} = {
  mobile: '(max-width: 768px)',
  tablet: '(min-width: 769px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
}

export function transformNavItem(item: NavContentItem) {
  const {menuDefinition, menuContent, route, flyOutContent} = item
  const result: NavItem = {
    name: menuDefinition?.navDisplayName,
    categoryId: item.route?.page?.categoryId,
    categoryName: item.route?.page?.name,
  }

  const children = menuContent?.slice().sort((curr, next) => {
    const currRanking = curr?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER
    const nextRanking = next?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER

    if (currRanking < nextRanking) {
      return -1
    } else if (currRanking > nextRanking) {
      return 1
    }
    return 0
  })

  // Note that we want to keep the resulting NavItem objects as small as possible.
  // That means that we only add the properties that have a value. This way we don't
  // end up with `null` or `undefined` taking up space in the object.
  // We also can assume that default `color` is black and only keep the color field
  // if It's something different.

  if (route?.path) {
    result.path = route.path
  }

  if (menuContent && menuContent.length > 0) {
    result.children = children?.map(transformNavItem)
  }

  if (menuDefinition.mobileIcon) {
    result.icon = menuDefinition.mobileIcon
  }

  if (menuDefinition.menuTextColor && menuDefinition.menuTextColor.name !== 'Black') {
    result.color = menuDefinition.menuTextColor.color
  }

  if (flyOutContent && flyOutContent.default?.length) {
    result.flyOutContent = flyOutContent
  }

  return result
}

export const validateSlotActiveTime = (
  slotContent: ReoccurringSlotContentItem,
  params: ContentParams | undefined
) => {
  const {startTime, endTime} = slotContent?.timeActive || {}

  if (!startTime || !endTime) return false

  const currentDate =
    params?.isContentPreview && params?.previewTimestamp
      ? new Date(params.previewTimestamp)
      : new Date()

  const currentDateStr = DateUtils.format(currentDate, 'yyyy-MM-dd')
  const startDateWithTimeStr = currentDateStr + 'T' + startTime
  const endDateWithTimeStr = currentDateStr + 'T' + endTime

  const correctTime = isWithinInterval(currentDate, {
    start: new Date(startDateWithTimeStr),
    end: new Date(endDateWithTimeStr),
  })
  return correctTime
}

export const validateSlotActiveDays = (
  slotContent: ReoccurringSlotContentItem,
  params: ContentParams | undefined
) => {
  if (!slotContent?.daysActive || !Object.keys(slotContent.daysActive).length) return false

  const currentDate =
    params?.isContentPreview && params?.previewTimestamp
      ? new Date(params.previewTimestamp)
      : new Date()

  const currentWeekday = DateUtils.format(currentDate, 'EEEE').toLowerCase()
  return slotContent.daysActive[currentWeekday as keyof ReoccurringSlotContentItem['daysActive']]
}

export const validateLifecycleExpiryTime = (
  content: DefaultContentBody, 
  params: ContentParams | undefined
) => {
  if (!content?._meta?.lifecycle?.expiryTime) return true

  return isBefore(
    params?.isContentPreview && params?.previewTimestamp
      ? new Date(params.previewTimestamp)
      : new Date(),
    new Date(content._meta.lifecycle.expiryTime),
  )
}

export const isSlotWithReoccurringContent = (slotContent: SlotContentItem): slotContent is ReoccurringSlotContentItem => slotContent.timeActive && slotContent.daysActive

export const isSlotWithoutVariations = (slotContent: SlotContentItem) => {
  return !slotContent.default && !slotContent.variations
}

export const getComponentsBasedOnVariation = (
  content: SlotContentItem,
  params: ContentParams | undefined,
  currentCustomerGroups: string[],
  selectedPreviewCustomerGroup: Nullable<string>
): SlotItem[] | SlotItem | null => {
  if (!validateLifecycleExpiryTime(content, params)) {
    return null
  }

  if (isSlotWithoutVariations(content)) {
    if (content?._meta?.schema === 'https://www.iceland.co.uk/page/content-page.json')
      return content

    return (
      content.contentSlot ??
      content.slotContent ??
      content.content ??
      content.contentItems ??
      content
    )
  }

  if (isSlotWithReoccurringContent(content)) {
    const dayActive = validateSlotActiveDays(content, params)
    if (!dayActive) return null

    const timeActive = validateSlotActiveTime(content, params)

    if (!timeActive) return null
  }

  const {variations, default: defaultVariation} = content

  if (params?.isContentPreview || params?.isContentVisualization) {
    return handleContentPreviewModeContentToShow(content, selectedPreviewCustomerGroup)
  }

  if (currentCustomerGroups?.length && variations) {
    const variationsToShow = [...variations]
      .sort((prevVariation, nextVariation) => {
        return (
          (prevVariation?.customerGroups?.rank ?? 0) - (nextVariation?.customerGroups?.rank ?? 0)
        )
      })
      .find((variation) => getShowingContentFromVariations(variation, currentCustomerGroups))

    return variationsToShow?.contentItems ?? defaultVariation ?? null
  }

  return defaultVariation ?? null
}

export const handleContentPreviewModeContentToShow = (
  content: SlotContentItem, 
  selectedPreviewCustomerGroup: Nullable<string>
) => {
  const defaultContent = Array.isArray(content.default)
    ? content.default
    : content.default
    ? [content.default]
    : null

  if (!selectedPreviewCustomerGroup) {
    return defaultContent
  }

  if (selectedPreviewCustomerGroup && content?.variations) {
    const variation = [...content.variations]
      .sort((prevVariation, nextVariation) => {
        return (
          (prevVariation?.customerGroups?.rank ?? 0) - (nextVariation?.customerGroups?.rank ?? 0)
        )
      })
      .find(({contentItems, customerGroups}) => {
        const groups = customerGroups?.customerGroups?.map((group) => group.id) || []

        return contentItems && groups.includes(selectedPreviewCustomerGroup)
      })

    return variation?.contentItems ?? defaultContent
  }

  return defaultContent
}

const getShowingContentFromVariations = (
  variation: VariationContent,
  customerGroups: string[],
): SlotItem[] | null => {
  const {customerGroups: cg, contentItems} = variation || {}

  if (!cg.customerGroups?.length || !contentItems) {
    return null
  }

  const groups = cg?.customerGroups?.map((group) => group.id) || []
  const customerGroupMatched = customerGroups.some((group) => groups.includes(group))

  return contentItems && customerGroupMatched ? contentItems : null
}