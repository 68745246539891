import React, {FC} from 'react'
import {SlotComponent} from '../../types/ui'
import {WelcomeBack} from '../welcome-back'

interface PlaceholderContentCmsContent {
  placeholderComponentName: string
}

const placeholderContentComponentMap: Record<string, FC> = {
  WelcomeBack: WelcomeBack,
} as const

export const PlaceholderContent: SlotComponent<PlaceholderContentCmsContent> = ({content}) => {
  const {placeholderComponentName} = content

  if (!placeholderComponentName || !placeholderContentComponentMap[placeholderComponentName])
    return null

  const PlaceholderComponent = placeholderContentComponentMap[placeholderComponentName]
  return <PlaceholderComponent />
}
