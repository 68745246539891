import {useEffect, useState} from 'react'
import {useCustomerStore, useGlobalStore} from './useStore'
import {BonusCardData, BonusCardTransaction} from '../../types/store/customer'
import { useHistory } from 'react-router-dom' 
import { runInAction } from 'mobx'
import { MAX_BONUS_CARD_TOPUP_AMOUNT } from '../../utils/constants'

export const useBonusCard = (loadTransactions: boolean) => {
  const customerStore = useCustomerStore()
  const {getCustomSitePreferenceById, customSitePreferences, isCustomSitePreferenceSet} = useGlobalStore()
  const [cardData, setCardData] = useState<BonusCardData | undefined>()
  const [transactions, setTransactions] = useState<BonusCardTransaction[]>([])
  const [cardNumber, setCardNumber] = useState<string | undefined>(
    customerStore?.bonusCardDataAndTransactions?.cardData?.cardNumber
  )
  const [lastFour, setLastFour] = useState<string | undefined>()
  const [balance, setBalance] = useState<number | bigint >()
  const [bonusCardActive, setBonusCardActive] = useState(false)
  const [isBonusCardServiceActive, setBonusCardServiceActive] = useState(true);
  const [maxTopUpAmount, setMaxTopUpAmount] = useState(MAX_BONUS_CARD_TOPUP_AMOUNT)
  const[loading, setLoading] = useState<boolean>(true)
  const history = useHistory()

  const resetBonusCardData = () => {
    setCardData(undefined)
    setTransactions([])
    setCardNumber(undefined)
    setLastFour(undefined)
    setBalance(undefined)
    setBonusCardActive(false)
    setBonusCardServiceActive(true);
    runInAction(() => {
      customerStore.bonusCardDataAndTransactions = null;
    });
  }

  useEffect(() => {
    if(cardNumber){
      setLastFour(cardNumber.slice(-4))
    }
  }, [cardNumber])

  useEffect(() => {
    if(cardData?.balance){
      setBalance(cardData.balance)
    }
  }, [cardData])

  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('bonusCardTopUpMaxValue')) {
        await getCustomSitePreferenceById('bonusCardTopUpMaxValue')
      }
      setMaxTopUpAmount(customSitePreferences['bonusCardTopUpMaxValue'] as number || MAX_BONUS_CARD_TOPUP_AMOUNT)
    })()
  })

  useEffect(() => {
    if (customerStore.status === 'done') {
      const pathUpdateLocations = [
        '/account/bonus-card',
        '/account/bonus-card/topup',
        '/checkout/summary'
      ]
      const isBonusCardPath = pathUpdateLocations.includes(history.location.pathname)
      if (isBonusCardPath) {
        getBonusCardData()
      }
    }
  }, [history.location.pathname, customerStore.bonusCardDataAndTransactions, customerStore.status])

  const getBonusCardData = async (refetchCardData= false) => {
    if(!customerStore.bonusCardDataAndTransactions || refetchCardData) {
      setLoading(true)
      await customerStore.getBonusCardDataAndTransactions(loadTransactions)
      setLoading(false);
    } else if(!cardData) {
      await setBonusCardData()
      setLoading(false);
    }
  }

  const isBonusCardActive = (cardData : BonusCardData | undefined) : boolean =>{
    let cardIsActive = true;
    if(!cardData || cardData.onboardingStatus.nominationError){
      cardIsActive = false;
    }
    return cardIsActive
  }

  const setBonusCardData = async () => {
    const cardResponse = customerStore.bonusCardDataAndTransactions;
    if (cardResponse?.error && cardResponse?.code && [503, 403].includes(cardResponse.code)){ 
      setBonusCardServiceActive(false);
    }else{
      setBonusCardServiceActive(true);
    }
    const cardData = cardResponse?.cardData
    setCardNumber(cardData?.cardNumber) 
    setCardData(cardData)
    if (Array.isArray(cardResponse?.transactions) && cardResponse?.transactions.length > 0){
      // Only update transactions if not empty.
      setTransactions(cardResponse.transactions)
    }
    setBonusCardActive(isBonusCardActive(cardData))
  }

  useEffect(() => {
    if (customerStore.customerInfo?.authType === 'guest') {
      resetBonusCardData()
    }
  }, [customerStore.customerInfo?.authType])

  return {
    cardData,
    balance,
    transactions,
    cardNumber,
    lastFour,
    setCardNumber,
    loading,
    bonusCardActive,
    getBonusCardData,
    isBonusCardServiceActive,
    maxTopUpAmount,
    resetBonusCardData
  }
}
