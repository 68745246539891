import React, {PropsWithChildren, useMemo, useRef, useState} from 'react'
import {useDisclosure} from '../../vanilla'
import {
  CredentialModalPlacement,
  CredentialModalPlacementMap,
  CredentialScreens,
} from '../../types/ui'
import {CredentialModal} from '../../components/credential-modal'
import {noop} from '../../utils/utils'
import {CREDENTIAL_SCREENS} from '../../utils/constants'
import {CredentialModalProvider} from '../'

export const CredentialModalContext = ({children}: PropsWithChildren<object>) => {
  const attachmentRef = useRef(null)
  const {isOpen, onToggle, onOpen, onClose, setIsOpen} = useDisclosure(false)

  const [callback, setCallback] = useState<() => void>(noop)
  const [defaultCredentialScreen, setDefaultCredentialScreen] = useState<CredentialScreens>(
    CREDENTIAL_SCREENS.LOGIN,
  )
  const [modalPlacementType, setModalPlacementType] = useState(CredentialModalPlacementMap.ATTACHED)

  const handleSetCallback = (callbackFunction: () => void) => {
    setCallback(() => {
      return () => callbackFunction()
    })
  }

  const showCredentialModal = (
    type: CredentialModalPlacement,
    callback?: () => void,
    defaultCredentialScreen?: CredentialScreens,
  ) => {
    onOpen()
    setModalPlacementType(type)
    if (callback && typeof callback === 'function') handleSetCallback(callback)
    if (defaultCredentialScreen) setDefaultCredentialScreen(defaultCredentialScreen)
  }

  const contextState = useMemo(() => {
    return {
      isOpen,
      onToggle,
      onOpen,
      onClose,
      setIsOpen,
      callback,
      attachmentRef,
      modalPlacementType,
      defaultCredentialScreen,
      showCredentialModal,
      setModalPlacementType,
      setCallback: handleSetCallback,
    }
  }, [isOpen, callback, attachmentRef?.current, defaultCredentialScreen, modalPlacementType])

  return (
    <CredentialModalProvider value={contextState}>
      {children}
      <CredentialModal
        isOpen={isOpen}
        onOpen={onOpen}
        setIsOpen={setIsOpen}
        onToggle={onToggle}
        onClose={onClose}
        defaultScreen={defaultCredentialScreen}
        refToAttach={attachmentRef}
        placement={modalPlacementType}
        afterLoginHandler={() => {
          callback?.()
          setCallback(noop)
        }}
      />
    </CredentialModalProvider>
  )
}
