import { HostedFields, HostedFieldsEvent } from 'braintree-web'
import { useEffect } from 'react'
import { Nullable } from '../../../../types/utils'
import {
  braintreeInputStyleInvalid,
  braintreeInputStyleValid,
} from '../../../../components/braintree/styles.css'

export const useBraintreeFieldsValidityWatcher = (
  hostedFieldsInstance: Nullable<HostedFields>,
  handleHostedFieldsValidationErrors: React.Dispatch<React.SetStateAction<{ cardholderName: boolean; number: boolean; expirationDate: boolean; cvv: boolean; }>>,
  allowInitEvents: boolean = true
) => {
  const toggleValidityClasses = (fieldContainer: HTMLElement, valid: boolean) => {
    if (!valid) {
      fieldContainer.classList.remove(braintreeInputStyleValid)
      fieldContainer.classList.add(braintreeInputStyleInvalid)
    } else {
      fieldContainer.classList.remove(braintreeInputStyleInvalid)
      fieldContainer.classList.add(braintreeInputStyleValid)
    }
  }
  const handleEvent = (event: HostedFieldsEvent) => {
    const fieldName = event.emittedBy
    const { container, isValid, isEmpty } = event.fields[fieldName]

    if (!isValid && !isEmpty) {
      toggleValidityClasses(container, false)
      handleHostedFieldsValidationErrors((prevState) => ({
        ...prevState,
        [fieldName]: true
      }));
    } else if (isValid && !isEmpty) {
      toggleValidityClasses(container, true)
      handleHostedFieldsValidationErrors((prevState) => ({
        ...prevState,
        [fieldName]: false
      }));
    }

    if (fieldName === 'number' && event?.cards[0]?.type === 'maestro') {
      toggleValidityClasses(container, false)
      handleHostedFieldsValidationErrors((prevState) => ({
        ...prevState,
        number: true
      }));
    }
  }

  useEffect(() => {
    if (!hostedFieldsInstance || !allowInitEvents) return

    hostedFieldsInstance.on('blur', handleEvent)
  }, [hostedFieldsInstance, allowInitEvents])
}
