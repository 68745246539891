import {RefObject, useEffect, useState} from 'react'

interface IntersectionObserverOptions extends IntersectionObserverInit {
  useOnce?: boolean
}

export const useIntersectionObserver = (
  ref: RefObject<Element>,
  options: IntersectionObserverOptions = {}
) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const {useOnce, ...ioOptions} = options

  useEffect(() => {
    if (!ref?.current) return

    // Just set `isIntersecting` true if browser doesn't implement IntersectionObserver. If the use-case
    // is critical and you need to support very old browsers, a polyfill will need to be added.
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
    ) {
      if (!isIntersecting) {
        setIntersecting(true)
      }

      // We want to return early, but `useEffect` expects a function as the return value,
      // so we just return a noop function.
      return () => null
    }

    const observer = new IntersectionObserver(([entry]) => {
      const onScreen = entry.isIntersecting
      setIntersecting(onScreen)
      if (useOnce && onScreen) {
        observer.disconnect()
      }
    }, ioOptions)

    observer.observe(ref?.current)

    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect()
    }
  }, [ref?.current])

  return isIntersecting
}
