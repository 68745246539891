import {useEffect, useState} from 'react'
import {useContentStore} from '../../../store/hooks/useStore'

export const useFetchBadges = (keys: string[]) => {
  const cms = useContentStore()
  const [status, setStatus] = useState<'idle' | 'error' | 'success' | 'loading'>('idle')
  const [failedRequests, setFailedRequests] = useState<string[]>([])

  const filterNotFetched = (badgeKey: string) => !cms.contentById[badgeKey] && !failedRequests.includes(badgeKey)

  useEffect(() => {
    ;(async function () {
      if (!keys?.length) {
        return
      }

      const keysToFetch = keys.filter(filterNotFetched)

      if (!keysToFetch.length) {
        return
      }

      try {
        setStatus('loading')
        const response = await cms.fetchItems(keysToFetch.map((key) => ({key})))
        setStatus('success')
        if (Array.isArray(response)) {
          setFailedRequests((prevFailedRequests) => [...prevFailedRequests, ...response])
        }
      } catch (e) {
        console.error(e)
        setStatus('error')
      }
    })()
  }, [keys])

  return status
}
