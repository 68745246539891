import React, { useState } from 'react'
import { paypalCheckout} from 'braintree-web'
import {AuthorizationResponse } from 'paypal-checkout-components'
import { useBraintree } from './use-braintree';
import { useBasketStore, useGlobalStore} from '../store/hooks/useStore';
import { ButtonsRendererOptions, PaypalSetupData, ButtonLabelOption, ButtonShapeOption, ButtonColorOption, FlowType, Intent } from '../types/payments';

export function useBraintreePaypal() {
    const {
        currency,
    } = useGlobalStore()
    const { createBraintreeClientInstance } = useBraintree()
    const { shippingAddress } = useBasketStore()
    const [hasPaypalButtonRendered, setHasPaypalButtonRendered] = useState<boolean>(false)

    const initPaypalButtons = async (data: PaypalSetupData) => {
        if (shippingAddress && data.billingAddress) {
            await createBraintreePaypal(data)
        }
    }
      const createBraintreePaypalInstance = async () => {
        const {btClientInstance} = await createBraintreeClientInstance()
    
        const paypalInstance = await paypalCheckout.create({
          client: btClientInstance,
        })
    
        return paypalInstance
      }
    
      const createBraintreePaypal = async (ppData: PaypalSetupData) => {
        try {
          setHasPaypalButtonRendered(false)
          const paypalInstance = await createBraintreePaypalInstance()
          const {amount, billingAddress, setErrorMessage, submitOrderProcess} = ppData
          if(!paypalInstance) {
            throw Error('Error connecting to braintree')
          }
    
          paypalInstance.loadPayPalSDK({
            currency: 'GBP',
            intent: Intent.Authorize,
            vault: false,
            locale: 'en_GB',
            'enable-funding': 'card,credit,paylater'
          }, (loadPayPalSDKErr) => {
            if (loadPayPalSDKErr) {
              console.error('Error loading PayPal SDK:', loadPayPalSDKErr);
              return;
            }
    
            const paypalButtonConfig: ButtonsRendererOptions = ({
              fundingSource: 'paypal',
              style: {
                label: ButtonLabelOption.Pay,
                height: 44,
                shape: ButtonShapeOption.Rect,
                color: ButtonColorOption.Blue
              },
              createOrder: () => {
                return paypalInstance.createPayment({
                  flow: FlowType.Checkout,
                  amount: amount,
                  currency: currency,
                  locale: 'en_GB',
                  intent: Intent.Authorize,
                  enableShippingAddress: true,
                  shippingAddressEditable: false,
                  shippingAddressOverride: {
                    recipientName: shippingAddress?.fullName,
                    line1: shippingAddress?.address1 || '',
                    line2: shippingAddress?.address2 || '',
                    city: shippingAddress?.city || '',
                    countryCode: shippingAddress?.countryCode?.toUpperCase() || 'GB',
                    postalCode: shippingAddress?.postalCode || '',
                    state: shippingAddress?.stateCode || '',
                    phone: shippingAddress?.phone || '',
                  },
                });
              },
              onApprove: (data, actions) => {
                return new Promise<AuthorizationResponse>((resolve, reject) => {
                    paypalInstance.tokenizePayment(data, async (tokenizeErr, payload) => {
                        try {
                            if (payload.nonce && billingAddress) {
                                await submitOrderProcess(payload.nonce, payload.type, billingAddress, payload.details.email);
                                resolve(payload);
                            }
                        } catch (error) {
                            console.error(error);
                            setErrorMessage(error)
                            reject(error);
                            return;
                        }
                        if (tokenizeErr) {
                            console.error('Error tokenizing payment:', tokenizeErr);
                            setErrorMessage('Error tokenizing payment: ' + tokenizeErr)
                            reject(tokenizeErr);
                            return;
                        }                        
                        resolve(payload);
                    });
                });
            },
              onCancel: (data) => {
                console.log('PayPal payment cancelled', JSON.stringify(data, null, 2));
              },
              onError: (err) => {
                console.error('PayPal error', err);
                setErrorMessage('PayPal error ' + err)
              },
            })
             // @ts-ignore
            window.paypal.Buttons(paypalButtonConfig).render(`#paypal-container-1`).then(() => {
               // @ts-ignore
              window.paypal.Buttons(paypalButtonConfig).render(`#paypal-container-2`)
            }).catch((error) => {
              setErrorMessage('Error creating paypal button ' + error)
              console.error(error)
            })
          });
        } catch (error) {
          console.error(error)
        }
      }

    return {
        initPaypalButtons,
        hasPaypalButtonRendered
    }
}
