import React from 'react'
import {Flex, Text} from '../../vanilla'
import {EditIcon} from '../icons'

export const EditOrderStickyBanner = () => {
  const calculateTopPosition = () => {
    if (window.innerWidth <= 768) {
      return '96px';
    } else {
      return '0px';
    }
  }; 

  return (
    <Flex
      align="center"
      bg="promotionColorDark"
      padding="4px"
      justify="center"
      gap="4px"
      position="sticky"
      top={calculateTopPosition()}
      left="0px"
      zIndex="sticky"
    >
      <EditIcon color="gray800" style={{width: 18}} height="auto" />
      <Text variant="text4" color="gray800" lineHeight="shorter">
        Editing order - Checkout to Apply Changes.
      </Text>
    </Flex>
  )
}
