import {makeObservable, flow, observable, computed, action, flowResult} from 'mobx'
import type {CommerceAPI} from 'commerce-api'
import {
  ContentClient,
  StagingEnvironmentFactory,
  ContentItem,
  DefaultContentBody,
  ContentBody,
} from 'dc-delivery-sdk-js'
import type {ContentClientConfigOptions} from 'dc-delivery-sdk-js/build/main/lib/config'
import type {FetchResponse} from 'dc-delivery-sdk-js/build/main/lib/content/model/Fetch'
import type {RootStore} from './RootStore'
import type {StoreModel} from './utils'
import {
  ContentItemResponse,
  FilterByResponse,
} from 'dc-delivery-sdk-js/build/main/lib/content/model/FilterBy'
import {ContentMeta} from 'dc-delivery-sdk-js/build/main/lib/content/model/ContentMeta'
import {NavFlyoutBannerCmsContent} from '../components/cms/NavFlyoutBanners'
import {NavFlyoutGridContent} from '../components/cms/NavFlyoutGrid'
import {ColorPicker, PDPAwardSlot, StickyBanner} from '../types/cms'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {Nullable} from '../types/utils'
import {useContentToDisplay} from '../components/cms/Slot'
import {SlotContentItem} from '../types/cms'

interface HierarchyNode {
  nodes?: Node[]
  _meta: ContentMeta
  [key: string]: any
}

export interface RouteContentItem extends ContentBody {
  path?: string
  page?: DefaultContentBody
}

export interface NavContentItem extends ContentBody {
  menuDefinition: {
    showInMenu: boolean
    menuTextColor?: ColorPicker
    navDisplayName: string
    ranking?: number
    mobileIcon?: string
  }
  menuContent?: Array<NavContentItem>
  route?: RouteContentItem
  flyOutContent?: {
    default?: (NavFlyoutBannerCmsContent | NavFlyoutGridContent)[]
  } & ContentBody
}

export interface NavFlyOutContent extends ContentBody {
  default?: (NavFlyoutBannerCmsContent | NavFlyoutGridContent)[]
}

export interface NavItem {
  name: string
  path?: string
  color?: string
  icon?: string
  children?: Array<NavItem>
  flyOutContent?: NavFlyOutContent
  categoryId: string
  categoryName: string
}

export interface ContentStoreInitialState {
  contentClientConfig: ContentClientConfigOptions
  contentById?: Record<string, DefaultContentBody>
  params?: ContentParams
  navMenu: Array<NavItem>
  routeMap?: Record<string, string>
  stickyBanners?: StickyBanner[]
}

export interface ContentParams extends Record<string, unknown> {
  isContentPreview?: boolean
  isContentVisualization?: boolean
  contentId?: string
  deliveryKey?: string
  realtime?: boolean
  vseDomain?: string
  previewTimestamp?: number
  previewCustomerGroup?: string
  snapshotId?: string
  contentType?: string
  navigationContentId?: string
}

export class ContentStore implements StoreModel {
  api: CommerceAPI
  client: ContentClient
  contentById: Record<string, DefaultContentBody>
  contentClientConfig: ContentClientConfigOptions
  params: ContentParams | undefined
  navMenu: Array<NavItem>
  routeMap: Record<string, string>
  filterFetchPending?: boolean | undefined | Promise<any>
  selectedPreviewCustomerGroup: Nullable<string>
  pdpAwardSlots: PDPAwardSlot[]
  stickyBanners: StickyBanner[]

  constructor(rootStore: RootStore, initialState: ContentStoreInitialState) {
    this.api = rootStore.api
    this.contentById = initialState.contentById || {}
    this.routeMap = initialState.routeMap || {}
    this.navMenu = initialState.navMenu || []
    this.contentClientConfig = initialState.contentClientConfig
    this.params = initialState.params || {}
    this.selectedPreviewCustomerGroup = initialState?.params?.previewCustomerGroup ?? null
    this.pdpAwardSlots = []
    this.stickyBanners = initialState?.stickyBanners || []

    makeObservable(this, {
      contentById: observable,
      pdpAwardSlots: observable,
      stickyBanners: observable,
      navMenu: observable,
      routeMap: observable,
      selectedPreviewCustomerGroup: observable,
      fetchItemByKey: flow.bound,
      fetchItemById: flow.bound,
      fetchItems: flow.bound,
      setPreviewTimestamp: flow.bound,
      fetchHierarchy: flow.bound,
      buildNavigationMenu: flow.bound,
      buildRoutingMap: flow.bound,
      buildRoutingMapAndNavMenu: flow.bound,
      getAllPdpAwardSlots: flow.bound,
      getAllStickyBanners: flow.bound,
      isVisualisation: computed,
      setSelectedPreviewCustomerGroup: action.bound,
    })

    /**
     * Handle visualization flow by setting the SDK domain to the provided VSE domain
     */
    if (this.params?.vseDomain) {
      this.contentClientConfig.stagingEnvironment = this.params.vseDomain
    }

    /**
     * Create and store an instance of the Amplience client
     */
    this.client = new ContentClient(this.contentClientConfig)

    this.filterFetchPending = undefined
  }

  get isVisualisation() {
    const path =
      typeof window === 'undefined' ? '' : `${window.location.pathname}${window.location.search}`

    return path?.includes('/visualization?') || /\/visualization$/.test(path)
  }

  get validStickyBanners() {
    if (!this.stickyBanners) {
      return null
    }
    // Map over sticky banners and filter out any that aren't valid using contentToDisplay function
    const validStickyBanners: StickyBanner[] = []
      this.stickyBanners.map((stickyBanner) => {
        const bannersToDisplay = useContentToDisplay(stickyBanner as unknown as SlotContentItem)
        if (bannersToDisplay) {
          validStickyBanners.push(stickyBanner)
        }
        return bannersToDisplay
      }).filter(Boolean)

    return validStickyBanners
  }

  /**
   * The preview URL contains a `vseDomain` that pinned to the time it was created.
   * We can allow for changing this time dynamically by recreating the `vseDomain`
   * with a new timestamp. The SDK provides a helper method for this, so we use
   * that, update our config, and recreate the sdk client.
   *
   * @note - This is intended to be used with the x-ray/preview panel when implemented.
   */
  *setPreviewTimestamp(timestamp: number) {
    if (this.params?.isContentPreview && this.params.vseDomain) {
      const factory = new StagingEnvironmentFactory(this.params.vseDomain)
      const updatedDomain: string = yield factory.generateDomain({
        timestamp,
      })
      this.contentClientConfig.stagingEnvironment = updatedDomain
      this.client = new ContentClient(this.contentClientConfig)

      return updatedDomain
    }
  }

  setSelectedPreviewCustomerGroup(customerGroupId: Nullable<string>) {
    this.selectedPreviewCustomerGroup = customerGroupId
  }

  *fetchItemByKey(deliveryKey: string, forceFetch = false) {
    /** Skip fetching if we already have the content. */
    if (deliveryKey in this.contentById && !forceFetch) {
      return
    }

    try {
      const result: ContentItem = yield this.client.getContentItemByKey(deliveryKey)
      /**
       * Note that we are calling `toJSON` on the sdk result. This ensures we are only
       * storing plain objects instead of the enhanced objects returned by the sdk.
       */
      this.contentById[deliveryKey] = result.toJSON()
    } catch (error) {
      console.error('Failed to fetch', error)
    }
  }

  *fetchHierarchy(deliveryKey: string, depth = 10) {
    let hierarchy = null

    const {client} = this

    function* fetchContentByParentId(
      id: string,
      hierarchyResult: HierarchyNode,
      currentDepth: number,
    ): any {
      if (depth && currentDepth > depth) {
        return
      }

      try {
        const filterBy = client.filterByParentId(id)
        const children = yield filterBy?.request({
          format: 'inlined',
          depth: 'all',
        })

        if (children) {
          const {responses} = children
          for (const child of responses) {
            const node = child.content
            hierarchyResult.nodes = [...(hierarchyResult?.nodes || []), node]
            yield* fetchContentByParentId(node?._meta?.deliveryId, node, currentDepth + 1)
          }
        }
      } catch (e) {
        console.error(`Failed to fetch nodes by parentId - ${id}`, e)
        throw e
      }
    }

    try {
      const result: ContentItem = yield this.client.getContentItemByKey(deliveryKey)

      if (result) {
        const response = result.toJSON()

        const {
          _meta: {deliveryId: rootNodeId},
        } = response

        hierarchy = {...response, nodes: []}

        yield* fetchContentByParentId(rootNodeId, hierarchy, 1)

        this.contentById[deliveryKey] = hierarchy
      }
    } catch (error) {
      console.error('Failed to fetch hierarchy', error)
    }
  }

  *fetchItemById(deliveryId: string, forceFetch = false) {
    /** Skip fetching if we already have the content. */
    if (deliveryId in this.contentById && !forceFetch) {
      return
    }

    try {
      const result: ContentItem = yield this.client.getContentItemById(deliveryId)

      /**
       * Note that we are calling `toJSON` on the sdk result. This ensures we are only
       * storing plain objects instead of the enhanced objects returned by the sdk.
       */
      this.contentById[deliveryId] = result.toJSON()
    } catch (error) {
      console.error('Failed to fetch', error)
    }
  }

  /**
   * Fetch multiple items, by key or id
   * https://github.com/amplience/dc-delivery-sdk-js#fetch-content-items
   */
  *fetchItems(
    requests: Array<{id: string} | {key: string}>,
    parameters: Parameters<typeof this.client.fetchContentItems>[0]['parameters'] = {
      format: 'inlined',
      depth: 'all',
    },
  ) {
    /** Filter out requests that have already been made */
    const filteredRequests = requests.filter((req) => {
      if (
        ('key' in req && this.contentById[req.key]) ||
        ('id' in req && this.contentById[req.id])
      ) {
        return false
      }
      return true
    })

    /** Skip fetching if no requests remain */
    if (filteredRequests.length < 1) {
      return
    }

    const failedRequests: Array<string> = []

    // Set the Limit on number of items in each request to match Amplience limit
    const maxChunkSize = 12;
    
    // Break the requests into smaller chunks due to limitations on items per request
    for (let i = 0; i < filteredRequests.length; i += maxChunkSize) {
      const chunk = filteredRequests.slice(i, i + maxChunkSize);
      try {
        // Fetch the items in this Chunk
        const result: FetchResponse<any> = yield this.client.fetchContentItems({
          requests: chunk,
          parameters,
        })
        
        // Store the results and failures
        result.responses.forEach((res, idx) => {
          if ('error' in res && res.error) {
            console.warn(`Failed to fetch [${res.error.data.deliveryKey}] asset from Amplience.`)
            failedRequests.push(res.error.data.deliveryKey)
          }
  
          if ('content' in res) {
            const req = chunk[idx]
            let storageKeyOrId = ''
            if ('key' in req) storageKeyOrId = req.key
            if ('id' in req) storageKeyOrId = req.id
            if (storageKeyOrId) {
              this.contentById[storageKeyOrId] = res.content
            }
          }
        })
      } catch (error) {
        console.error('Failed to fetch', error)
        // To prevent re-calls for items that were in this failed request, Add them to the failedRequests list here
        chunk.forEach((item) => {  
          console.warn(`Failed to fetch [${item?.key}] asset from Amplience.`)
          failedRequests.push(item.key)
        })
      }
    }

    return failedRequests
  }

  *buildNavigationMenu() {
    const root: FilterByResponse<ContentBody> = yield this.client
      .filterByParentId(this.params!.navigationContentId!)
      .request()

    const navItemPromises = root.responses.map(({content}) =>
      this.client.getContentItemById(content._meta.deliveryId).then((r) => {
        return r.toJSON()
      }),
    )

    const results: NavContentItem[] = yield Promise.all(navItemPromises)

    results.sort((curr, next) => {
      const currRanking = curr?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER
      const nextRanking = next?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER

      if (currRanking < nextRanking) {
        return -1
      } else if (currRanking > nextRanking) {
        return 1
      }
      return 0
    })

    function transformNavItem(item: NavContentItem) {
      const {menuDefinition, menuContent, route, flyOutContent} = item
      const result: NavItem = {
        name: menuDefinition?.navDisplayName,
        categoryId: item.route?.page?.categoryId,
        categoryName: item.route?.page?.name,
      }

      const children = menuContent?.slice().sort((curr, next) => {
        const currRanking = curr?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER
        const nextRanking = next?.menuDefinition?.ranking ?? Number.MAX_SAFE_INTEGER

        if (currRanking < nextRanking) {
          return -1
        } else if (currRanking > nextRanking) {
          return 1
        }
        return 0
      })

      // Note that we want to keep the resulting NavItem objects as small as possible.
      // That means that we only add the properties that have a value. This way we don't
      // end up with `null` or `undefined` taking up space in the object.
      // We also can assume that default `color` is black and only keep the color field
      // if It's something different.

      if (route?.path) {
        result.path = route.path
      }

      if (menuContent && menuContent.length > 0) {
        result.children = children?.map((mc) => transformNavItem(mc))
      }

      if (menuDefinition.mobileIcon) {
        result.icon = menuDefinition.mobileIcon
      }

      if (menuDefinition.menuTextColor && menuDefinition.menuTextColor.name !== 'Black') {
        result.color = menuDefinition.menuTextColor.color
      }

      if (flyOutContent && flyOutContent.default?.length) {
        result.flyOutContent = flyOutContent
      }

      return result
    }

    this.navMenu = results.map((item) => transformNavItem(item))
  }

  *buildRoutingMap() {
    if (Object.keys(this.routeMap).length > 0 || typeof window !== 'undefined') {
      return
    }

    if (typeof global !== 'undefined' && global.__routeMap) {
      /** @todo - should this be conditional for local dev only? */
      this.routeMap = global.__routeMap
      return
    }

    let mapping: Response | undefined
    if (this.filterFetchPending) {
      yield this.filterFetchPending
    } else {
      this.filterFetchPending = true
      mapping = yield fetch(`${getAppOrigin()}/api/amplience/cached`)
      const mappingJson: Record<string, string> = yield mapping!.json()
      this.routeMap = mappingJson
      this.filterFetchPending = undefined
    }

    if (typeof global !== 'undefined') {
      /** @todo - should this be conditional for local dev only? */
      global.__routeMap = mapping
    }

    // If we already have the route map or we are in browser, don't build again
    if (Object.keys(this.routeMap).length > 0 || typeof window !== 'undefined') {
      return
    }

    // If fetching/building are in progress and we're called again, we wait.
    if (this.filterFetchPending) {
      yield this.filterFetchPending
    } else {
      // Return the locally cached data if available.
      if (typeof global !== 'undefined' && global.__routeMap) {
        /** @todo - should this be conditional for local dev only? */
        this.routeMap = global.__routeMap
        this.filterFetchPending = undefined
        return
      }

      this.filterFetchPending = build()
      return this.filterFetchPending
    }

    async function build() {
      const templateMapping: Record<string, string> = {
        'https://www.iceland.co.uk/page/category-page.json': 'CATEGORY',
      }

      let results: Array<ContentItemResponse<CmsRoute>> = []
      const mapping: Record<string, string> = {}

      const client: ContentClient = (self as any)!.client

      const result: FilterByResponse<CmsRoute> = await client
        .filterByContentType('https://www.iceland.co.uk/route.json')
        .request({
          format: 'inlined',
          depth: 'all',
        })

      await paginate(result)

      async function paginate(result: FilterByResponse<CmsRoute>) {
        results = [...results, ...result.responses]

        if (result.page?.nextCursor && typeof result.page?.next === 'function') {
          const next: FilterByResponse<CmsRoute> = await result.page.next()
          await paginate(next)
        }
      }

      results.forEach(({content}) => {
        const template = templateMapping[content.page?._meta?.schema || ''] || 'CONTENT'
        mapping[
          `${content.path}`
        ] = `${content?.page?._meta.deliveryId}|${template}|${content.page?.categoryId}`
      })

      self.routeMap = mapping

      // Cache the data in a global server-side variable. This is meant for
      // local development only.
      if (typeof global !== 'undefined') {
        /** @todo - should this be conditional for local dev only? */
        global.__routeMap = mapping
      }

      self.filterFetchPending = undefined
    }
  }

  *buildRoutingMapAndNavMenu() {
    let routeMapJson
    let navMenuJson
    if (Object.keys(this.routeMap).length > 0 || typeof window !== 'undefined') {
      return
    }

    if (
      typeof global !== 'undefined' &&
      global.__routeMap &&
      global.__navMenu &&
      process.env.APP_ORIGIN === 'http://localhost:3000'
    ) {
      /** @todo - should this be conditional for local dev only? */
      this.routeMap = global.__routeMap
      this.navMenu = global.__navMenu
      return
    }
    async function build(self:any) {
      const routeMap = await fetch(`${getAppOrigin()}/amplience/routemap`)
      routeMapJson = await routeMap.json()
      const navMenu = await fetch(`${getAppOrigin()}/amplience/navmenu`)
      navMenuJson = await navMenu.json()
      self.routeMap = routeMapJson
      self.navMenu = navMenuJson
      self.filterFetchPending = undefined

      if (typeof global !== 'undefined' && process.env.APP_ORIGIN === 'http://localhost:3000') {
        /** @todo - should this be conditional for local dev only? */
        global.__routeMap = routeMapJson
        global.__navMenu = navMenuJson
      }
    }

    if (this.filterFetchPending) {
      yield this.filterFetchPending
    } else {
      this.filterFetchPending = build(this)
      return this.filterFetchPending
    }
  }

  *getAllPdpAwardSlots() {
    const client: ContentClient = this.client

    async function getPdpAwardSlots() {
      const filterByPDPAwardSchemaRequest = client.filterByContentType(
        'https://www.iceland.co.uk/slot/pdp-awards-slot.json',
      )

      const filterByResponse = await filterByPDPAwardSchemaRequest.request({
        format: 'inlined',
        depth: 'all',
      })

      return filterByResponse
    }

    const filterByResponse: FilterByResponse<PDPAwardSlot> = yield flowResult(getPdpAwardSlots())

    if (!filterByResponse || !filterByResponse.responses?.length) {
      return null
    }

    const pdpAwardSlotsContents = filterByResponse.responses
      .map(({content}) => content)
      .filter(Boolean)

    this.pdpAwardSlots = pdpAwardSlotsContents
  }

  *getAllStickyBanners() {
    const client: ContentClient = this.client

    async function getStickyBanners() {
      const result = await client.filterByContentType(
       "https://www.iceland.co.uk/slot/sticky-banner-slot.json",
      ).request({
        format: 'inlined',
        depth: 'all',
      })
      return result
    }

   const filterByResponse: FilterByResponse<StickyBanner> = yield flowResult(getStickyBanners())

    if (!filterByResponse || !filterByResponse.responses?.length) {
      return null
    }

    const stickyBanners = filterByResponse.responses
      .map(({content}) => content)
      .filter(Boolean)

    this.stickyBanners = stickyBanners
  }

  get asJson() {
    return {
      params: this.params,
      contentClientConfig: this.contentClientConfig,
      contentById: this.contentById,
      routeMap: this.routeMap,
      navMenu: this.navMenu,
      selectedPreviewCustomerGroup: this.selectedPreviewCustomerGroup,
    }
  }
}

export interface CmsRoutePageContent extends DefaultContentBody {
  categoryId?: string
}

export interface CmsRoute extends DefaultContentBody {
  page?: CmsRoutePageContent
  path?: string
}
