import JSCookies, {CookieAttributes} from 'js-cookie'

interface BaseStorage {
  set(name: string, value: string, options?: any): void
  get(name: string): void
  remove(name: string): void
}

class CookieStorage implements BaseStorage {
  available: boolean

  constructor() {
    this.available = false

    if (typeof document === 'undefined') {
      console.warn('Cookie is not available on current environment')
      return
    }

    this.available = true
  }

  set(name: string, value: any, options: CookieAttributes) {
    if (!this.available) return

    JSCookies.set(name, value, {secure: true, ...options})
  }

  get(name: string) {
    if (!this.available) return
    return JSCookies.get(name)
  }

  remove(name: string) {
    if (!this.available) return
    JSCookies.remove(name)
  }
}

export const Cookies = new CookieStorage()

class WindowLocalStorage implements BaseStorage {
  available: boolean

  constructor() {
    this.available = false

    if (typeof window === 'undefined') {
      console.warn('LocalStorage is not available on the current environment.')
      return
    }
    this.available = true
  }

  set(key: string, value: string) {
    if (!this.available) return

    window.localStorage.setItem(key, value)
  }

  get(key: string) {
    if (!this.available) return

    return window.localStorage.getItem(key)
  }

  remove(key: string) {
    if (!this.available) return

    window.localStorage.removeItem(key)
  }
}

export const LocalStorage = new WindowLocalStorage()
