import React from 'react'
import {Button, Text} from '../../../../vanilla'
import useNavigation from '../../../../hooks/use-navigation'
import {CheckIcon} from '../../../icons'
import useDeliveryBooking from '../../../../hooks/use-delivery-booking'
import {useBasketStore} from '../../../../store/hooks/useStore'
import {CheckPostcode} from '../../../book-delivery/check-postcode'
import {observer} from 'mobx-react'
import { MobileNavigationLoggedLinksProps } from '../mobile-navigation-logged-links'

const DeliveryInitial = observer(({onClose}: MobileNavigationLoggedLinksProps) => {
  const navigate = useNavigation()
  const {basket, deliveryPostalCode, isAppliedPostCodeDeliverable: isDeliverable} = useBasketStore()
  const {clearPostalCode} = useDeliveryBooking()

  return (
    <>
      {!basket?.c_deliveryPostalCode ? (
        <>
          <Text as="h3" color="accent0" variant="unstyled" fontSize="sm" fontWeight="bold">
            Free Next Day Delivery
          </Text>
          <Text variant="text2" marginBottom="12px">
            When you spend £40 online
          </Text>
          <Text variant="text2" lineHeight="none" marginBottom="12px">
            Check if we can deliver. Please enter your postcode below to check if we are able to
            deliver to your address.
          </Text>
          <CheckPostcode hideText fullWidthForm isMobileMenu formDirection="column" />
        </>
      ) : isDeliverable ? (
        <>
          <Text variant="text2" lineHeight="shorter" marginBottom="12px" data-cs-mask="">
            <CheckIcon
              color="accent1"
              width="24px"
              height="12px"
              marginTop="4px"
              style={{float: 'left', marginLeft: '-4px'}}
            />
            {`Great news! We'll deliver your shop to ${deliveryPostalCode} for free when you spend over £40!`}{' '}
            <Text
              variant="text1"
              as="span"
              padding="0px"
              textDecoration="underline"
              color="accent3"
              lineHeight="shorter"
              cursor="pointer"
              onClick={clearPostalCode}
              marginLeft="4px"
            >
              Change postcode
            </Text>
          </Text>
          <Button
            size="sm"
            alignSelf="stretch"
            variant="primary"
            marginBottom="8px"
            onClick={() => {
              navigate('/book-delivery', 'push', {
                redirectTo: '/basket',
              })
            }}
            style={{width: '100%'}}
          >
            <Text variant="unstyled" fontSize="inherit" fontWeight="inherit" color="white" onClick={onClose}>
              Book Delivery
            </Text>
          </Button>
        </>
      ) : (
        <>
          <Text variant="text2" color="accent0" lineHeight="none" data-cs-mask="">
            {`Sorry, we don't deliver to ${deliveryPostalCode} at the moment`}
          </Text>

          <Text variant="text2">
            Postcode not right?{' '}
            <Text
              as="span"
              variant="text2"
              padding="0px"
              textDecoration="underline"
              color="accent3"
              lineHeight="short"
              cursor="pointer"
              onClick={clearPostalCode}
            >
              Change it
            </Text>
          </Text>
        </>
      )}
    </>
  )
})

export default DeliveryInitial
