import React, {useMemo} from 'react'
import {observer} from 'mobx-react-lite'
import {Flex, Stack, Text} from '../../vanilla'
import {useBasketStore, useOrderStore} from '../../store/hooks/useStore'
import {FormattedNumber} from 'react-intl'

const RefundDetailsContainer = () => {
  const {editingOrder, editMode, refundPaymentMethodsDetails} = useOrderStore()
  const {basket} = useBasketStore()
  const refundTotal = Math.abs((basket?.orderEditBalance || 0))

  const refundDetails = useMemo(() => {
    return (
      refundPaymentMethodsDetails
        ?.map((refundDetail) => {
          const {paymentMethodId, refundAmount} = refundDetail

          const orderPaymentInstrument = editingOrder?.paymentInstruments?.find(
            ({paymentMethodId: orderPaymentMethodId}) => orderPaymentMethodId === paymentMethodId
          )
          if (orderPaymentInstrument) {
            const {paymentMethodId, c_bonusCardNo, paymentCard} = orderPaymentInstrument
            const isBonusCard = paymentMethodId === 'BONUS_CARD'

            return {
              name: isBonusCard
                ? `Bonus Card (${c_bonusCardNo?.substring(c_bonusCardNo?.length - 4)})`
                : `Credit / Debit Card (${paymentCard?.cardType} ${paymentCard?.numberLastDigits})`,
              subtitle: 'Refund to Card',
              amount: refundAmount,
            }
          }
        })
        ?.filter(Boolean) || []
    )
  }, [refundPaymentMethodsDetails, editingOrder?.paymentInstruments])

  if (!editMode) return null

  return (
    <Stack spacing="0px" width="full">
      {refundPaymentMethodsDetails && (
        <Stack
          spacing="0px"
          paddingBottom="12px"
          paddingX="16px"
          width="full"
          borderBottom="1px"
          borderColor="gray200"
        >
          {refundDetails.map((refundDetail) => {
            return (
              <Flex justify="space-between" gap="4px" marginBottom="4px">
                <Stack spacing="0px">
                  <Text variant="text4" lineHeight="shorter" data-cs-mask="">
                    {refundDetail?.name}
                  </Text>
                  <Text variant="text2" lineHeight="shorter" color="textMuted">
                    {refundDetail?.subtitle}
                  </Text>
                </Stack>
                <Text variant="text4">
                  {basket?.currency && (
                    <FormattedNumber
                      style="currency"
                      currency={basket.currency}
                      value={refundDetail?.amount || 0}
                    />
                  )}
                </Text>
              </Flex>
            )
          })}
        </Stack>
      )}
      <Flex
        paddingX="16px"
        paddingY="20px"
        justify="space-between"
        align="center"
        borderBottom="1px"
        borderColor="gray200"
      >
        <Text variant="heading1">Total Refund</Text>
        <Text variant="heading1">
          {basket?.currency && (
            <FormattedNumber style="currency" currency={basket.currency} value={refundTotal} />
          )}
        </Text>
      </Flex>
    </Stack>
  )
}

export const RefundDetails = observer(RefundDetailsContainer)
