import {createCtx} from './create-ctx'
import {IRestoredLocals} from '../types/commerce'
import {RootStore} from '../store/RootStore'
import {
  BonusCardContextValue,
  BookDeliveryPopoverContextValue,
  CheckoutStateContextValue,
  CredentialModalContextValue,
} from '../types/ui'
import {AlgoliaAnalyticsContextValue} from '../types/algolia-analytics'

export const [useCommerceAPIContext, CommerceAPIProvider] = createCtx<IRestoredLocals['api']>()

export const [useStoreContext, StoreProvider] = createCtx<RootStore>()

export const [useCredentialModal, CredentialModalProvider] =
  createCtx<CredentialModalContextValue>()

export const [useAlgoliaAnalytics, AlgoliaAnalyticsProvider] =
  createCtx<AlgoliaAnalyticsContextValue>()

export const [useAddBonusCard, AddBonusCardProvider] = createCtx<BonusCardContextValue>()

export const [useCheckoutState, CheckoutStateContext] = createCtx<CheckoutStateContextValue>()

export const [useBookDeliveryPopover, BookDeliveryPopoverProvider] =
  createCtx<BookDeliveryPopoverContextValue>()

export const [useLinkErrorModal, LinkErrorModalProvider] = createCtx<BonusCardContextValue>()