import { useEffect } from 'react';
import {useLocation} from 'react-router-dom'
import * as Sentry from '@sentry/react';
import {sentryConfig} from '../../sentry.config';
import { SeverityLevel } from '@sentry/types';

export const useSentryAnalytics = () => {
    const { pathname, search} = useLocation()

    // Adds support for console error and warnings
    useEffect(() => {
          const consoleWarn = console.warn.bind(console);
          console.warn = (...args) => {
              Sentry.captureMessage(args[0], 'warning');
              consoleWarn(...args);
          };

          const consoleErr = console.error.bind(console);
          console.error = (...args) => {
              Sentry.captureMessage(args[0], 'error');
              consoleErr(...args);
          };
  }, []);

    // Track Navigation Pages / Search
    useEffect(() => {
        // Sentry Pages/Search
        Sentry.configureScope((scope) => {
            scope.setTag("page", pathname);
            if (search) {
              scope.setTag("search", search);
            }
          });

        const searchData = search ? { search: search } : {};
        Sentry.addBreadcrumb({
            category: "navigation",
            message: `Navigated to ${pathname}`,
            data: searchData,
            level: sentryConfig.client.navigationLogLevel as SeverityLevel,
        });
        
    }, [pathname, search])
    
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
          try {
            const target = event.target as HTMLElement;
            if (target.tagName === 'BUTTON') {
              Sentry.addBreadcrumb({
                category: 'ui.click',
                level: sentryConfig.client.clickLogLevel as SeverityLevel,
              });
            }
          } catch (error) {
            Sentry.captureException(error);
          }
        };

        // Add the global event listener
        document.addEventListener('click', handleClick);

        // Clean up the global event listener when the component unmounts
        return () => {
          document.removeEventListener('click', handleClick);
        };
    }, []);
}