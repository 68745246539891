import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import {Box} from '../../vanilla'
import {AddAllToCart} from '../add-all-to-cart'

interface IProps {
  children?: React.ReactNode
}

export const Layout = ({children}: IProps) => {
  const {pathname, search} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname, search])

  return (
    <>
      <Box display="flex" flexDirection="column" flex="auto">
        <Box aria-hidden borderBottom="1px" borderColor="border1"/>
        <Box as="main" display="flex" flexDirection="column" alignItems="stretch" flex="auto">
          {children}
        </Box>
      </Box>
      <AddAllToCart/>
    </>
  )
}
