import React from 'react'
import {observer} from 'mobx-react'
import {Box, Text, Flex, Stack, Container} from '../../../vanilla'
import {getImageUrl} from '../utils'
import {SlotComponent} from '../../../types/ui'
import {ContentBody} from 'dc-delivery-sdk-js'
import {ColorPicker, Image} from '../../../types/cms'
import {Link} from '../../link'
import {categoryTileTitle, categoryTileGrid, categoryTileFont, categoryTile, categoryTileImage} from './styles.css'
import {useSelectPromotionTracking} from '../../../analytics/promotion-tracking'

interface CategoryTileCmsContent {
  backgroundColor: ColorPicker
  title: string
  image: {images: Array<Image>}
  imageLink?: string
}

interface CategoryTilesCmsContent {
  heading?: string
  tileList?: Array<CategoryTileCmsContent & ContentBody>
  slotGroupName?: string
}

export const CategoryTiles: SlotComponent<CategoryTilesCmsContent> = observer(({content}) => {
  return (
    <Container paddingX="0px">
      {content.heading && (
        <Stack align="stretch" marginY="16px">
          <Flex>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
                borderBottom: '1px solid #d8d8d8',
                lineHeight: '0px',
                margin: '10px 0 20px',
              }}
            >
              <span className={categoryTileTitle}>{content.heading}</span>
            </h2>
          </Flex>
        </Stack>
      )}

      <Box className={categoryTileGrid} display="grid" gap="8px" width="full" marginTop="12px">
        {content.tileList?.map((tile, index) => {
          return <CategoryTile key={tile?._meta?.deliveryId} content={tile} slotGroupName={content.slotGroupName} index={index + 1} />
        })}
      </Box>
    </Container>
  )
})

export const CategoryTile: SlotComponent<CategoryTileCmsContent> = observer(({content, slotGroupName, index}) => {
  if (!content) {
    return null
  }

  const {backgroundColor, image, imageLink, title} = content

  const promotion = {
    creative_name: image?.images?.[0]?.data?.altText ?? `Category tile #${index} missing alt text`,
    creative_slot: `${slotGroupName} + ${content?._meta?.name}` ?? `Category tile #${index}`,
    promotion_id: imageLink ?? `Category tile #${index} without link`,
    promotion_name: title ?? `Category tile #${index} without cta`,
    link_position: index ?? 1
  }

  const sendSelectPromotion = useSelectPromotionTracking()

  return (
    <Box
      as={Link}
      href={imageLink}
      style={{backgroundColor: backgroundColor?.theColor}}
      onClickCapture={() => sendSelectPromotion(promotion)}
      className={categoryTile}
    >
      <Box
        position="absolute"
        bottom="0px"
        right="0px"
        style={{left: 'auto', width: '55%', aspectRatio: '203/165'}}
      >
        <img
          alt={image?.images?.[0]?.data?.altText ?? 'No alternative text available'}
          src={getImageUrl(image?.images?.[0]?.image)}
          draggable={false}
          className={categoryTileImage}
        />
      </Box>

      <Box position="relative" style={{width: '53%', zIndex: 1}}>
        <Text className={categoryTileFont}>
          {title}
        </Text>
      </Box>
    </Box>
  )
})
