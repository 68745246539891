import React, {useEffect, useRef} from 'react'
import {SlotComponent} from '../../types/ui'
import {Box} from '../../vanilla'

interface HTMLContentBlockProps {
  html: string
  width?: string
}

export const HTMLContentBlock: SlotComponent<HTMLContentBlockProps> = ({content}) => {
  const htmlNode = useRef<HTMLDivElement>(null)
  useEffect(() => {
    // duplicate all scripts DANGEROUSLY because innerHTML doesn't execute script tags
    // NOTE: script tags are executed in order with respect to each other, but always after any html
    const scripts = htmlNode.current ? htmlNode.current.querySelectorAll('script') : null

    // If server side rendered, we need to remove the currently loaded iframe otherwise it will dupe
    document.querySelectorAll('iframe')?.forEach((elem) => {
      elem?.parentNode?.removeChild?.(elem)
    })
    // If server side rendered, we need to remove the currently loaded iframe otherwise it will dupe

    scripts?.forEach((scriptElement) => {
      const newScriptElement = document.createElement('script')
      scriptElement.getAttributeNames().forEach((attributeName) => {
        if (attributeName.startsWith('data-')) {
          newScriptElement.setAttribute(
            attributeName,
            scriptElement.dataset[attributeName.replace('data-', '')] as string
          )
        } else {
          const attr = scriptElement.getAttribute(attributeName)
          if (attr) {
            newScriptElement.setAttribute(attributeName, attr)
          }
        }
      })

      newScriptElement.innerHTML = scriptElement.innerHTML
      scriptElement.remove()
      htmlNode.current?.appendChild(newScriptElement)
    })
  }, [content.html])

  const maxWidth = content.width?.includes('Inset') ? '600px' : '100%'
  return (
    <Box display="flex" justifyContent="center" width="full">
      <Box
        ref={htmlNode}
        dangerouslySetInnerHTML={{__html: content?.html}}
        style={{maxWidth}}
        width="full"
        display="flex"
        justifyContent="center"
      />
    </Box>
  )
}
