import {useGTMDispatch} from './react-gtm-hook/index'
import { transformAlgoliaItemsForGA4 } from './utils';

export const useSelectItemTracking = () => {
  const sendDataToGTM = useGTMDispatch();

  const sendSelectItemData = (hit, viewingCategoryId, hitItemIndex) => {
    let categoryName = [];

    for (const categoryArray of hit.categories) {
      for (const category of categoryArray) {
        if (category.id === viewingCategoryId) {
          let cleanedName = category.name.replace(/&#038;/g, '&');
          categoryName.push(cleanedName);
        }
      }
    }

    setTimeout(() => {
      try {
        const _items = transformAlgoliaItemsForGA4([hit], hitItemIndex)
        sendDataToGTM({
          event: 'select_item',
          ecommerce: {
            item_list_id : viewingCategoryId,
            item_list_name : categoryName[0],
            currency : _items[0].currency,
            items : _items,
          }
        })
      } catch (error) {
        console.error('useSelectItemTracking error within setTimeout: ' + error.message);
      }
    }, 0);
  };
  return sendSelectItemData;
};