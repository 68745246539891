import React from 'react'
import {BaseButton, Box, Button, Flex, Stack, Text, useDisclosure} from '../../../vanilla'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import {DialogContent, DialogOverlay} from '@reach/dialog'
import {useOverlayTransition} from '../../../vanilla/hooks/useOverlayTransition'
import {ChevronLeftIcon, CloseIcon, LabsIcon} from '../../icons'
import {vars} from '../../../vanilla/vars.css'
import {MrtEnvironment} from './parts/mrt-environment'
import {PagePath} from './parts/page-path'
import {PreviewInTime} from './parts/preview-in-time'
import {XRayView} from './parts/x-ray-view'
import {CustomerGroups} from './parts/customer-groups'

export const DRAWER_WIDTH = 350
export const TRANSITION_DURATION = 200

interface PreviewDrawerProps extends ReturnType<typeof useDisclosure> {}

export const PreviewDrawer = ({isOpen, onClose}: PreviewDrawerProps) => {
  const duration = 300

  const {bypassFocusLock, isVisible, isReady} = useOverlayTransition({isOpen, duration})

  const handleExitPreview = () => {
    window.location.href = window.location.origin
  }

  // Do not render anything unless the component is being used. This tracks
  // the `isOpen` prop and delays it to allow for enter/exit transitions.
  // See the `useOverlayTransition` for more detail.
  if (!isReady) {
    return null
  }

  return (
    <DialogOverlay
      dangerouslyBypassFocusLock={bypassFocusLock}
      isOpen={isReady}
      onDismiss={onClose}
      style={{
        height: `100vh`,
        top: 0,
        left: 0,
        transition: `background-color ${TRANSITION_DURATION}ms ease-out`,
        backgroundColor: isVisible ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0)',
        zIndex: vars.zIndex.overlay,
      }}
    >
      <Box
        as={DialogContent}
        aria-label="Amplience Preview drawer"
        position="absolute"
        top="0px"
        left="0px"
        bottom="0px"
        zIndex="docked"
        style={{
          width: DRAWER_WIDTH,
          transition: `transform ${TRANSITION_DURATION}ms ease-in`,
          transform: `translateX(${isVisible ? 0 : -DRAWER_WIDTH}px)`,
          backgroundColor: 'white',
          boxShadow: '2px 0 10px 0 rgb(0 0 0 / 20%)',
          padding: 0,
          margin: 0,
        }}
      >
        <Stack position="relative" gap="0px" height="full">
          <Flex
            height="56px"
            border="1px"
            borderColor="white"
            paddingX="12px"
            borderRadius="medium"
            boxShadow="base"
            bg="white"
            align="center"
            position="absolute"
            style={{right: -168, top: 16}}
          >
            <Button
              variant="unstyled"
              color="inherit"
              height="auto"
              paddingX="0px"
              onClick={onClose}
              iconLeft={<ChevronLeftIcon color="previewPrimary" boxSize="24px" />}
            />

            <Flex align="center" padding="0px" bg="white" height="full" paddingLeft="4px">
              <Box
                borderLeft="1px"
                height="half"
                alignSelf="center"
                borderColor="gray200"
                marginLeft="8px"
              />
              <BaseButton
                onClick={handleExitPreview}
                variant="unstyled"
                aria-label="Exit Amplience Preview"
                gap="8px"
              >
                <Text
                  display={{desktop: 'block', mobile: 'none'}}
                  variant="heading0"
                  color="gray700"
                >
                  Exit
                </Text>
                <CloseIcon color="previewPrimary" width="20px" height="auto" />
              </BaseButton>
            </Flex>
          </Flex>

          <Flex width="full" paddingY="24px" paddingX="36px">
            <Box
              as="img"
              maxWidth="full"
              src={getAssetUrl('static/img/amplience-full-logo-dark.png')}
            />
          </Flex>
          <MrtEnvironment />
          <PagePath />
          <PreviewInTime />
          <XRayView />
          <CustomerGroups />
          <Flex
            align="center"
            justify="center"
            width="full"
            flexWrap="wrap"
            paddingTop="44px"
            borderTop="1px"
            borderColor="gray200"
            marginBottom="12px"
          >
            <Flex
              direction="column"
              align="center"
              as="a"
              target="_blank"
              rel="noopener noreferrer"
              href={'https://www.64labs.com/'}
            >
              <LabsIcon boxSize="24px" />
            </Flex>
            <Flex direction="column" align="center" marginTop="4px">
              <Text variant="text1" color="gray700" marginLeft="12px" lineHeight="shorter">
                built by 64labs for SFCC
              </Text>
              <Text variant="text1" color="gray700" marginLeft="12px" lineHeight="shorter">
                composable commerce
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </Box>
    </DialogOverlay>
  )
}
