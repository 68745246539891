import React from 'react'
import {observer} from 'mobx-react'
import {Stack} from '../../../../../vanilla'
import {AmplienceTargetIcon} from '../../../../icons'
import {useContentStore} from '../../../../../store/hooks/useStore'
import {useCustomerGroups} from '../../../../../hooks/use-customer-groups'
import {StatusMap} from '../../../../../types/commerce'
import LoadingOverlay from '../../../../loading-overlay'
import {PanelOption} from '../../panel-option'
import {CustomerMenu} from './customer-menu'

export const CustomerGroups = observer(() => {
  const {customerGroups, status} = useCustomerGroups()
  const {selectedPreviewCustomerGroup} = useContentStore()

  return (
    <PanelOption
      title="Customer Groups"
      Icon={<AmplienceTargetIcon boxSize="24px" color="previewPrimary" />}
      selectedOption={selectedPreviewCustomerGroup ?? undefined}
    >
      <Stack alignItems="center" justify="center" padding="24px">
        {status === StatusMap.PENDING && <LoadingOverlay isLoading />}
        <CustomerMenu openDirection="top" options={customerGroups ?? []} />
      </Stack>
    </PanelOption>
  )
})
