import React from 'react'
import Helmet, {HelmetProps} from 'react-helmet'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import StructuredDataType from '../../utils/structured-data'
import {observer} from 'mobx-react-lite'
import {useLocation} from 'react-router-dom'
import { useGlobalStore } from '../../store/hooks/useStore'

interface SeoProps extends HelmetProps {
  title?: string
  description?: string
  noIndex?: boolean
  structuredData?: StructuredDataType
  canonical?: string
}

interface HtmlLang {
  hrefLang: string
  href: string
}

const langs: HtmlLang[] = []

export const Seo = observer(
  ({title, description, noIndex, structuredData, children, canonical, ...props}: SeoProps) => {
    const {appHostname} = useGlobalStore()
    const canonicalUrl = `https://${appHostname}${canonical}`
    const location = useLocation()
    const ogUrlContent = canonical ? canonicalUrl : `https://${appHostname}${location.pathname}`

    return (
      <Helmet {...props} defer={false}>
        <title>{title ?? 'Iceland Foods'}</title>
        {title && <meta property="og:title" content={title} />}
        {description && <meta name="description" content={description} />}
        {noIndex && <meta name="robots" content="noindex" />}
        <meta property="og:type" content="website" />
        {location && <meta property='og:url' content={ogUrlContent} />}
        <meta property="og:image" content={'https://assets.iceland.co.uk/i/iceland/iceland-url-logo-1200x1200'}/>
        <link
          rel="icon"
          type="image/png"
          href={getAssetUrl('static/img/global/favicon-32x32.png')}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={getAssetUrl('static/img/global/favicon-64x64.png')}
          sizes="64x64"
        />
        <link
          rel="icon"
          type="image/png"
          href={getAssetUrl('static/img/global/favicon-128x128.png')}
          sizes="128x128"
        />

        <meta name="theme-color" content="#ffffff" />

        {langs.map((lang) => (
          <link key={lang.hrefLang} rel="alternate" hrefLang={lang.hrefLang} href={lang.href} />
        ))}

        {canonical && <link rel="canonical" href={canonicalUrl} />}

        <link rel="manifest" href={getAssetUrl('static/manifest.json')} />

        {structuredData &&
          structuredData?.map((d) => (
            <script key={d['@type']} type="application/ld+json">
              {JSON.stringify(d)}
            </script>
          ))}

        {children}
      </Helmet>
    )
  }
)
