
import { Hit } from '../../../store/ProductStore'
import {useFetchBadges} from '../../cms/product-badges/useFetchBadges'
import {useMemo} from 'react'

export const useHitsProductBadges = (hits: Array<Hit>) => {
  const isDeliveryKey = (key: string | undefined): key is string => !!key

  const badgesKeys = useMemo(() => {
    return [
      ...new Set(
        hits
          ?.map(({pdpTopRightTileBadge, pdpMiddleRightTileBadge, pdpBottomRightTileBadge, pdpBadgeAsset}) => [pdpTopRightTileBadge, pdpMiddleRightTileBadge, pdpBottomRightTileBadge, pdpBadgeAsset])
          ?.flat()
          ?.filter(isDeliveryKey)
      ),
    ]
  }, [hits])

  return useFetchBadges(badgesKeys)
}
