export const REFINEMENTS_ID_MAP = {
  CATEGORY: 'categoryPageId',
  BRAND: 'brand',
  PRICE: 'priceRange',
  PACK_SIZE: 'filterPackSize',
  FILTER_BRAND: 'filterBrand',
  WEIGHT: 'filterWeight',
  LITRE: 'filterLitre',
  MANUFACTURE_SKU: 'manufacturerSKU',
  SERVING: 'filterServing',
}

/* Those hardcoded refinements are used on Search page.
 * As we can get refinements list for search page. */
export const SEARCH_DEFAULT_REFINEMENTS = [
  {label: 'Category', attribute: REFINEMENTS_ID_MAP.CATEGORY},
  {label: 'Price', attribute: REFINEMENTS_ID_MAP.PRICE},
  {label: 'Brand', attribute: REFINEMENTS_ID_MAP.BRAND},
]
