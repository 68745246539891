import React from 'react'
import {Box, BoxProps, Stack, Text} from '../../vanilla'

interface UnavailableProductsOverlayProps extends BoxProps<React.ElementType> {
reasonForDisabledStatus: string
}

export const UnavailableProductsOverlay = ({
    reasonForDisabledStatus, 
  ...restBoxProps
}: UnavailableProductsOverlayProps) => {

  const createUnavailabilityMessage = () => {
        let unavailabilityMessage
        if (reasonForDisabledStatus === 'out-of-stock') {
            unavailabilityMessage = 'currently out of stock'
        }
        if (reasonForDisabledStatus === 'coming-soon') {
            unavailabilityMessage = 'coming soon'
        }
        if (reasonForDisabledStatus === 'in-store-only') {
            unavailabilityMessage = 'available in-store only'
        }
        return `This product is ${unavailabilityMessage}`
    }
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      width={'full'}
      height={'full'}
      {...restBoxProps}
    >
      <Stack
        spacing="2px"
        alignItems="center"
        borderRadius="base"
        marginX="16px"
        paddingY="8px"
        paddingX="12px"
        style={{backgroundColor: 'rgba(219, 219, 219, 0.93)'}}
      >
        <Text textAlign="center" variant="text4" lineHeight="shorter">
          {createUnavailabilityMessage()}
        </Text>
      </Stack>
    </Box>
  )
}
