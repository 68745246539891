import React, {ReactNode} from 'react'
import {
  AirFryIcon,
  BakeryIcon,
  BbqIcon,
  BiggerPackIcon,
  BlackFridayIcon,
  BonfireIcon,
  BrandsIcon,
  BreakfastIcon,
  BurgerIcon,
  CanIcon,
  CarrotIcon,
  CheeseIcon,
  ChilliIcon,
  ChinesenewyearIcon,
  ChristmasIcon,
  CupcakeIcon,
  DessertsIcon,
  DrinksIcon,
  EggIcon,
  EventIcon,
  EverydayEssentialsIcon,
  ExclusiveBandWIcon,
  FathersdayIcon,
  FestivedrinksIcon,
  FishIcon,
  FizzyIcon,
  FlashSaleIcon,
  FootballIcon,
  GenericIcon,
  HalloweenIcon,
  HealthAndBeautyIcon,
  HomewareIcon,
  HouseIcon,
  IcelollyIcon,
  JointsIcon,
  MilkIcon,
  MumIcon,
  NachosIcon,
  NewyearIcon,
  OffersIcon,
  OnepoundIcon,
  PancakeDayIcon,
  PartyfoodIcon,
  PicnicIcon,
  PizzaIcon,
  StarLightIcon,
  SweetsIcon,
  ValentinesdayIcon,
  VegetarianIcon,
  VegIcon,
  XmaspudIcon,
} from '../../icons'

export const mobileNavIconsMap: Record<string, ReactNode> = Object.freeze({
  generic: <GenericIcon boxSize="20px" />,
  'air-fry': <AirFryIcon boxSize="20px" />,
  bakery: <BakeryIcon boxSize="20px" />,
  bbq_icon: <BbqIcon boxSize="20px" />,
  'bigger-packs': <BiggerPackIcon boxSize="20px" />,
  blackfriday: <BlackFridayIcon boxSize="20px" />,
  bonfire: <BonfireIcon boxSize="20px" />,
  brands: <BrandsIcon boxSize="20px" />,
  breakfast: <BreakfastIcon boxSize={'20px'} />,
  burger: <BurgerIcon boxSize="20px" />,
  can: <CanIcon boxSize="20px" />,
  carrot: <CarrotIcon boxSize="20px" />,
  cheese: <CheeseIcon boxSize="20px" />,
  chilli: <ChilliIcon boxSize="20px" />,
  chinesenewyear: <ChinesenewyearIcon boxSize="20px" />,
  christmas: <ChristmasIcon boxSize="20px" />,
  cupcake: <CupcakeIcon boxSize="20px" />,
  desserts: <DessertsIcon boxSize="20px" />,
  drinks: <DrinksIcon boxSize="20px" />,
  egg: <EggIcon boxSize="20px" />,
  events: <EventIcon boxSize="20px" />,
  'everyday-essentials': <EverydayEssentialsIcon boxSize="20px" />,
  exclusive: <ExclusiveBandWIcon boxSize="20px" />,
  fathersday: <FathersdayIcon boxSize="20px" />,
  festivedrinks: <FestivedrinksIcon boxSize="20px" />,
  fish: <FishIcon boxSize="20px" />,
  fizzy: <FizzyIcon boxSize="20px" />,
  'flash-sale': <FlashSaleIcon boxSize="20px" />,
  football: <FootballIcon boxSize="20px" />,
  halloween: <HalloweenIcon boxSize="20px" />,
  'health-and-beauty': <HealthAndBeautyIcon boxSize="20px" />,
  homeware: <HomewareIcon boxSize="20px" />,
  house: <HouseIcon boxSize="20px" />,
  icelolly: <IcelollyIcon boxSize="20px" />,
  joints: <JointsIcon boxSize="20px" />,
  milk: <MilkIcon boxSize="20px" />,
  mum: <MumIcon boxSize="20px" />,
  nachos: <NachosIcon boxSize="20px" />,
  newyear: <NewyearIcon boxSize="20px" />,
  offers: <OffersIcon boxSize="20px" />,
  onepound: <OnepoundIcon boxSize="20px" />,
  'pancake-day': <PancakeDayIcon boxSize="20px" />,
  partyfood: <PartyfoodIcon boxSize="20px" />,
  picnic: <PicnicIcon boxSize="20px" />,
  pizza: <PizzaIcon boxSize="20px" />,
  star_light: <StarLightIcon boxSize="20px" />,
  sweets: <SweetsIcon boxSize="20px" />,
  valentinesday: <ValentinesdayIcon boxSize="20px" />,
  veg: <VegIcon boxSize="20px" />,
  vegetarian: <VegetarianIcon boxSize="20px" />,
  xmaspud: <XmaspudIcon boxSize="20px" />,
})
