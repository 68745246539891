import {observer} from 'mobx-react'
import React from 'react'
import {Stack, Text} from '../../vanilla'
import {useBasketStore} from '../../store/hooks/useStore'

interface NearestStoreSmallProps {
  storeWrapperStyles?: object
}

export const NearestStoreSmall = observer(function NearestStoreDetails({
  storeWrapperStyles,
}: NearestStoreSmallProps) {

  const {nearestStoreByDeliveryPostCode: store} = useBasketStore()
  if (!store) return null

  const {address1, city, postalCode} = store

  return (
    <Stack
      style={{
        ...storeWrapperStyles,
      }}
    >
      {address1 && (
        <Text variant="text4" lineHeight="short" as="span" textAlign={['left', 'right']}>
          Your nearest store is: <br />
          {address1} {city}, {postalCode}
        </Text>
      )}
    </Stack>
  )
})
