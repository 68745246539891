import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useBasketItem} from '../../store/hooks/useBasketItem'
import {useBasketStore, useContentStore, useCustomerStore, useOrderStore} from '../../store/hooks/useStore'
import {usePromotion} from '../../store/hooks/usePromotion'
import {BaseButton, Box, BoxProps, Button, Flex, Stack, Text} from '../../vanilla'
import {Link} from '../link'
import {MultiBuyPromoOfferMessage, NearPromo} from '../product-detail/product-promo'
import {Price} from '../price'
import {TrashIcon} from '../icons'
import {
  cartItemActions,
  cartItemDetails,
  cartItemDetailsName,
  cartItemImage,
  cartItemPrice,
  cartItemQuantity,
  cartItemBonusQuantity,
  orderEditModeChanged,
} from './styles.css'
import classNames from 'classnames'
import {useItemPrice} from '../algolia/util/useItemPrice'
import {useCategory} from '../../store/hooks/useCategory'
import {usePreviouslyRemovedItem} from '../../store/hooks/usePreviouslyRemovedItem'
import UpdateCartItemQuantity from '../update-cart-quantity'
import {useRemoveFromCartTracking} from '../../analytics/click-event-tracking'
import {CART_LOCATION} from '../../analytics/utils'
import {useSlotExpiration} from '../../store/hooks/useSlotExpiration'
import {Hit} from '../../store/ProductStore'
import {useAlcoholRestrictions} from '../../hooks/use-alcohol-restrictions'
import {formatProductName} from '../../utils/utils'
import {ProductImage} from '../product-detail/product-image'

interface CartItemProps extends BoxProps<'div'> {
  id: string
  className?: string
  inDrawer?: boolean
  isUnavailable?: boolean
}

const useProductCategoryRoute = (id: string, isUnavailable: boolean | undefined) => {
  const {product} = isUnavailable ? usePreviouslyRemovedItem(id) : useBasketItem(id)
  const category = useCategory(product?.categoryPageId?.[0])
  const {routeMap} = useContentStore()

  const [categoryRoute] =
    Object.entries(routeMap).find(([route, dataStr]) => {
      const [_, __, entityId] = dataStr.split('|')

      return entityId === category?.id
    }) || []

  return categoryRoute
}

const CartItemContainer = ({id, className, inDrawer, isUnavailable, ...props}: CartItemProps) => {
  const {editMode, orderEditProductItemsDelta} = useOrderStore()
  const basketStore = useBasketStore()
  const [clickedQuantity, setClickedQuantity] = useState(0)
  const sendRemoveFromCartData = useRemoveFromCartTracking()
  const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()

  const {item: basketItem, product} = isUnavailable
    ? usePreviouslyRemovedItem(id)
    : useBasketItem(id)

  const {checkIfProductAlcohol, isCurrentSlotAlcoholRestricted} = useAlcoholRestrictions()
  const isAlcohol = product ? checkIfProductAlcohol(product as unknown as Hit) : false
  const disabledProductBecauseOfRestriction = isAlcohol && isCurrentSlotAlcoholRestricted
  const isAvailableProduct = !isUnavailable && !disabledProductBecauseOfRestriction
  const {getApplicablePromotions} = useCustomerStore()
  const {multiBuyPromo, nearPromo, allPromos} = getApplicablePromotions(product?.productPromotions)

  useEffect(() => {
    setClickedQuantity(basketItem?.quantity)
  }, [basketStore.count])

  const promotion = usePromotion(multiBuyPromo?.ID)

  const {displayPrice, promotionPrice} = useItemPrice(product)
  const categoryRoute = useProductCategoryRoute(id, isUnavailable)

  const productLink = `/p/${formatProductName(product?.name)}/${product?.id}.html`

  // @todo format the algolia data to be consistent across PLP and PDP
  const img = product?.image_groups?.[0]?.images?.[0] || product?.imageGroups?.[0]?.images?.[0]

  if (!product) return null

  const hasAnyPromo = allPromos !== null && allPromos.length > 0

  const orderEditModeProductChanged =
    editMode && !inDrawer && orderEditProductItemsDelta[product.id]

  const bonusProductLineItem = basketItem?.bonusProductLineItem

  const getCartLocation = () => {
    if (location.pathname === '/basket') {
      return CART_LOCATION.BASKET
    }
    if (location.pathname === '/checkout/summary') {
      return CART_LOCATION.CHECKOUT
    }
    return CART_LOCATION.MINIBASKET
  }
  const locationOfCart = getCartLocation()
  const isMiniBasket = locationOfCart === 'Mini Basket'

  const removeFromBasket = () => {
    if (editMode && isReservationExpired()) {
      // If in edit mode and slot has expired send to order history and don't add to basket
      handleEditReservationExpired()
    } else if (isUnavailable) {
      basketStore.removePreviouslyRemovedItem(id)
    } else {
      basketStore.addProductToUpdate({product: product, quantity: 0})
      sendRemoveFromCartData(product, locationOfCart)
    }
  }

  return (
    <Flex
      padding="16px"
      justify="space-between"
      flexWrap="wrap"
      alignItems="center"
      borderBottom="1px"
      borderColor="gray200"
      paddingBottom={['0px', '16px']}
      className={classNames(className, {[orderEditModeChanged]: orderEditModeProductChanged})}
      style={{
        backgroundColor: isAvailableProduct ? '#fff' : '#fbf7f8',
      }}
      {...props}
    >
      <Box
        as={Link}
        href={productLink}
        ratio="1"
        width="full"
        flexShrink="0"
        position="relative"
        className={cartItemImage}
        marginBottom={!hasAnyPromo ? '16px' : '0px'}
        paddingRight={isMiniBasket ? '16px' : '0px'}
      >
        <ProductImage 
          image={img}
          imageAlt={img?.alt}
          isThumbnail={true}
          inDrawer={inDrawer}
          minHeight={['auto', 'full']}
          style={{objectFit: 'contain'}}      
        />
      </Box>
      <Box className={cartItemDetails} alignSelf="flex-start">
        <Stack spacing="0px" paddingLeft={[!isMiniBasket ? '16px' : '0px', '0px']}>
          <Text
            as={Link}
            color={{default: 'gray800', hover: 'gray700'}}
            to={productLink}
            variant="text3"
            lineHeight="short"
            className={cartItemDetailsName}
            
          >
            {product.name}
          </Text>
          {bonusProductLineItem && !inDrawer && (
            <Text variant="text3" color="accent0" lineHeight="short">
              Free Bonus Product
            </Text>
          )}
          {promotion?.calloutMsg && !inDrawer && (
            <Text variant="text3" color="accent0" lineHeight="short">
              {promotion.calloutMsg}
            </Text>
          )}
        </Stack>
        {!inDrawer && isAvailableProduct ? (
          <>
            <Flex align="center" marginTop="16px" gap="8px" paddingLeft={['16px', '0px']}>
              {nearPromo && (
                <NearPromo
                  position="static"
                  alwaysInline
                  showViewMore={false}
                  promotion={nearPromo}
                />
              )}
              {nearPromo && (
                <MultiBuyPromoOfferMessage
                  promotion={nearPromo}
                  productId={product.id}
                />
              )}
            </Flex>
            {orderEditModeProductChanged && (
              <Box marginTop="4px">
                <Text variant="text3" lineHeight="short" color="promotionColorDark">
                  {orderEditModeProductChanged.deltaQty
                    ? `${Math.abs(orderEditModeProductChanged.deltaQty)} ${
                        orderEditModeProductChanged.deltaQty < 1 ? 'Removed' : 'Added'
                      }`
                    : orderEditModeProductChanged.recentlyAdded
                    ? 'Recently Added'
                    : null}
                </Text>
              </Box>
            )}
          </>
        ) : null}
      </Box>
      {inDrawer && hasAnyPromo && isAvailableProduct ? (
        <Flex flexDirection="column" marginY="16px" gap="8px" width="full">
          { allPromos.map((promo) => {
              return <Flex width="full" gap="8px">
              <NearPromo
              position="static"
              alwaysInline
              showViewMore={false}
              promotion={promo}
              cartDrawer={true}
            />
                        <MultiBuyPromoOfferMessage
              promotion={promo}
              productId={product.id}
            />
              </Flex>
              
          })}
            
        </Flex>
      ) : null}
      {isAvailableProduct ? (
        <>
          {!bonusProductLineItem ? (
            <Box className={cartItemQuantity}>
              <UpdateCartItemQuantity
                product={product}
                quantity={clickedQuantity || 0}
                setQuantity={setClickedQuantity}
                maxQuantity={product.maxOrderQuantity}
                cartLocation={locationOfCart}
                size="sm"
                variant="outlineDark"
                paddingRight={['0px', '16px']}
              />
            </Box>
          ) : (
            <Box className={cartItemBonusQuantity}>
              <Text
                variant="unstyled"
                fontSize="md"
                textAlign={inDrawer ? 'inherit' : ['left', 'center']}
              >
                1
              </Text>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Text lineHeight="none" color="accent0">
            Product <br /> unavailable &nbsp;
          </Text>
        </Box>
      )}
      {isAvailableProduct ? (
        <Box className={cartItemPrice} paddingLeft="0px">
          {!bonusProductLineItem ? (
            <Price
              promotionPrice={promotionPrice ?? null}
              customPriceAmount={!promotionPrice && displayPrice ? displayPrice * basketItem.quantity : null}
              price={displayPrice}
              quantity={basketItem?.quantity}
              priceInfo={product.priceInfo}
              isBasket={true}
            />
          ) : (
            <Text
              variant="unstyled"
              fontSize="md"
              textAlign={inDrawer ? 'inherit' : ['center', 'right']}
            >
              Free
            </Text>
          )}
        </Box>
      ) : (
        <Box>
          <BaseButton variant="outlineLight" fontSize="xs" as={Link} to={categoryRoute}>
            <Text>Find Replacement</Text>
          </BaseButton>
        </Box>
      )}
      {bonusProductLineItem && !inDrawer && <Box className={cartItemActions}></Box>}

      {!bonusProductLineItem && (
        <Box className={cartItemActions}>
          <Button
            onClick={() => {
              removeFromBasket()
            }}
            variant="unstyled"
            paddingX="0px"
            iconLeft={<TrashIcon color="gray800" style={{height: 22, width: 20}} />}
            style={{height: 'auto'}}
          />
        </Box>
      )}
    </Flex>
  )
}

export const CartItem = observer(CartItemContainer)
