import React from 'react'
import ReactDOM from 'react-dom'
import {ButtonGroupProps, CarouselInternalState, DotProps} from 'react-multi-carousel'
import {ControlConfig, Position} from '../index'
import {Box, BaseButton} from '../../../vanilla'
import {
  ChevronLeftIcon,
  ChevronLeftOutlinedIcon,
  ChevronRightIcon,
  ChevronRightOutlinedIcon,
} from '../../icons'
import {controlButtonRecipe, dotRecipe} from './styles.css'

interface ControlsProps extends ButtonGroupProps {
  config?: ControlConfig
  position: Position
  loop: boolean
  dotContainerClassName: string
  prevCustomHandler?: (
    state: Partial<CarouselInternalState> & {leftReached: boolean; previous?: () => void},
  ) => void
  nextCustomHandler?: (
    state: Partial<CarouselInternalState> & {rightReached: boolean; next?: () => void},
  ) => void
}

export const Controls = (props: ControlsProps) => {
  const {
    previous,
    next,
    dotContainerClassName,
    config,
    loop,
    carouselState,
    prevCustomHandler,
    nextCustomHandler,
  } = props

  const {
    arrowsBgColor,
    showArrows,
    arrowsColor,
    arrowsOutline,
    disableNextControlCondition,
    disablePrevControlCondition,
  } = config || {}

  const {currentSlide, slidesToShow = 1, totalItems = 0} = carouselState || {}

  const container = document.getElementsByClassName(`${dotContainerClassName}`)?.[0]

  const controlButtonClassName = controlButtonRecipe({
    variation: arrowsOutline ? 'outlined' : 'default',
  })

  const leftReached = !loop && currentSlide === 0

  const rightReached =
    !loop &&
    (totalItems !== slidesToShow ? (currentSlide ?? 0) + slidesToShow === totalItems : true)

  const displayProperty = showArrows
    ? ([
        showArrows?.mobile ? 'flex' : 'none',
        showArrows?.tablet ? 'flex' : 'none',
        showArrows?.desktop ? 'flex' : 'none',
      ] as const)
    : 'flex'

  const getNextControlDisableValue = () => {
    if (disableNextControlCondition && typeof disableNextControlCondition === 'function') {
      return disableNextControlCondition(carouselState || {})
    }

    return rightReached
  }

  const getPrevControlDisableValue = () => {
    if (disablePrevControlCondition && typeof disablePrevControlCondition === 'function') {
      return disablePrevControlCondition(carouselState || {})
    }

    return leftReached
  }

  if (!container) {
    return null
  }

  // React portal is used here to render arrows inside dot list container, order properties are set for positioning
  return ReactDOM.createPortal(
    <>
      <Box
        as="button"
        aria-label="Move Carousel Left"
        display={displayProperty}
        onClick={() => {
          if (prevCustomHandler && typeof prevCustomHandler === 'function') {
            prevCustomHandler?.({...carouselState, leftReached, previous})
          } else {
            previous?.()
          }
        }}
        className={controlButtonClassName}
        marginRight={arrowsOutline ? '20px' : '8px'}
        bg={arrowsBgColor ?? 'transparent'}
        style={{order: 0}}
        disabled={getPrevControlDisableValue()}
      >
        {arrowsOutline ? (
          <ChevronLeftOutlinedIcon
            width="24px"
            height="24px"
            color={getPrevControlDisableValue() ? 'gray200' : arrowsColor ?? 'accent0'}
          />
        ) : (
          <ChevronLeftIcon
            color={getPrevControlDisableValue() ? 'gray200' : arrowsColor ?? 'white'}
          />
        )}
      </Box>
      <Box
        as="button"
        aria-label="Move Carousel Right"
        display={displayProperty}
        onClick={() => {
          if (nextCustomHandler && typeof nextCustomHandler === 'function') {
            nextCustomHandler?.({...carouselState, rightReached, next})
          } else {
            next?.()
          }
        }}
        marginLeft={arrowsOutline ? '20px' : '8px'}
        className={controlButtonClassName}
        bg={arrowsBgColor ?? 'transparent'}
        style={{order: 999}}
        disabled={getNextControlDisableValue()}
      >
        {arrowsOutline ? (
          <ChevronRightOutlinedIcon
            width="24px"
            height="24px"
            color={getNextControlDisableValue() ? 'gray200' : arrowsColor ?? 'accent0'}
          />
        ) : (
          <ChevronRightIcon
            color={getNextControlDisableValue() ? 'gray200' : arrowsColor ?? 'white'}
          />
        )}
      </Box>
    </>,
    container,
  )
}

interface CarouselDotProps extends DotProps {
  config?: ControlConfig
}

export const CarouselDot = (props: CarouselDotProps) => {
  const {active, onClick, config, index = 0} = props

  const {dotsOutline, activeDotColor, showDots = true} = config || {}

  const dotClassName = dotRecipe({variation: dotsOutline ? 'outlined' : 'default', active: active})

  if (!showDots) return null

  return (
    <Box as="li" style={{order: index + 1}}>
      <BaseButton
        type="button"
        paddingX="0px"
        height="auto"
        onClick={onClick}
        variant="unstyled"
        aria-label="Carousel Pip"
      >
        <Box
          className={dotClassName}
          backgroundColor={
            active ? activeDotColor ?? 'accent0' : dotsOutline ? 'transparent' : 'gray500'
          }
        />
      </BaseButton>
    </Box>
  )
}
