import React from 'react'
import {Box, Button, Flex, Text} from '../../vanilla'
import {Modal, ModalProps} from '../modal'

interface AddAllToCartConfirmationProps extends ModalProps {
  loading?: boolean
  onCancel: () => void
  onConfirm: () => void
}

export const AddAllToCartConfirmation: React.FC<AddAllToCartConfirmationProps> = (
  {
    loading,
    onCancel,
    onConfirm,
    ...modal
  }) => {
  return (
    <Modal
      {...modal}
      closeIcon={false}
      onClose={onCancel}
    >
      <Box>
        <Box
          paddingX={['0px', '28px']}
          paddingY="28px"
          gap="16px"
        >
          <Text variant="text1">Are you sure you want to add all items to the basket?</Text>
        </Box>
        <Box padding="20px" borderTop="1px" borderColor="gray100">
          <Flex align="center">
            <Button
              variant="outlineDark"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              isLoading={loading}
              onClick={onConfirm}
              marginLeft="auto"
            >
              Confirm
            </Button>
          </Flex>
        </Box>
      </Box>
    </Modal>
  )
}
