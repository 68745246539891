import React from 'react'
import {observer} from 'mobx-react'
import {useIntl} from 'react-intl'
import {useGlobalStore} from '../../store/hooks/useStore'
import {Flex, Stack, Text} from '../../vanilla'
import {Nullable} from '../../types/utils'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {useLocation} from 'react-router-dom'

export interface PriceProps {
  price: Nullable<number>
  quantity?: number
  customPriceAmount?: Nullable<number>
  priceInfo?: {perUnit: string; [price: number]: string; wasPrice: string}
  isPLPTile?: boolean
  isPDPPage?: boolean
  isSearch?: boolean
  isBasket?: boolean
  spacing?: Sprinkles['marginTop']
  promotionPrice: Nullable<number>
}

export const PriceContainer = (props: PriceProps) => {
  const {pathname} = useLocation()
  const {formatNumber} = useIntl()
  const {
    priceInfo,
    price,
    quantity,
    customPriceAmount,
    promotionPrice = null,
    isPLPTile,
    isSearch,
    isBasket,
    spacing,
  } = props

  const globalStore = useGlobalStore()
  const currency = globalStore?.currency

  const isMiniBasket = isBasket && pathname !== '/basket' && pathname !== '/checkout/summary'
  const isBasketPage = isBasket && !isMiniBasket
  const priceAmount = customPriceAmount ?? price

  let priceDisplay = priceAmount
  let wasPriceDisplay = null

  // If there is a promotion price overwrite the displayed price
  if (promotionPrice) {
    // Calulate promotion price based on quantity in basket
    const calculatedPromotionPrice = quantity ? promotionPrice * quantity : promotionPrice
    priceDisplay = calculatedPromotionPrice
  }
  // If there is a wasPrice make sure its valid then display it
  if (priceAmount && priceDisplay && (priceInfo?.wasPrice || promotionPrice)) {
    // Extract was price from the product and convert to a number or null 
    const productWasPrice = promotionPrice ? priceAmount : Number(priceInfo?.wasPrice.replace('£', ''))
    // Calulate was price based on quantity and round to nearest pence
    const calculatedWasPrice = Math.round(productWasPrice * (quantity ?? 1) * 100) / 100
    // Set was price as either product was price or the current price if a promotion
    const wasPrice = calculatedWasPrice
    const isValidWasPrice = wasPrice && wasPrice > priceDisplay

    if (isValidWasPrice) {
      wasPriceDisplay = wasPrice
    }
  }

  const priceDirection = isBasketPage || isSearch ? 'column' : 'row'
  const flexJustify = isPLPTile ? 'center' : isSearch ? 'flex-end' : 'flex-start'
  const flexAlign = isBasketPage ? 'flex-start' : 'center'
  const alignment = isPLPTile ? 'center' : isSearch || isBasketPage ? 'right' : 'left'
  const textVariant = isSearch ? 'text4' : isMiniBasket || isBasket ? 'unstyled' : 'heading1'

  return (
    <Stack gap="4px" marginRight={isBasketPage ? '24px' : '0px'} paddingBottom={isBasket && wasPriceDisplay ? ['8px', '0px'] : '0px'}>
      <Text
        as="div"
        display="flex"
        flexGrow={'0'}
        flexDirection={priceDirection}
        justifyContent={['flex-start', flexJustify]}
        alignItems={[flexAlign, 'flex-end']}
        gap="4px"
        flexWrap="wrap"
        maxWidth={isMiniBasket ? '140px' : 'auto'}
        color={wasPriceDisplay ? 'accent0' : 'black'}
        textAlign={{desktop: alignment, tablet: alignment, mobile: 'left'}}
        lineHeight="none"
        marginTop={spacing || '0px'}
      >
        <Text as="span" variant={textVariant}>
          {currency
            ? priceDisplay && priceDisplay >= 1
            ? formatNumber(priceDisplay, {style: 'currency', currency})
            : priceDisplay && Number((priceDisplay * 100).toFixed(0)).toString() + 'p'
          : null}
        </Text>
        <meta itemProp="price" content={priceDisplay?.toString() ?? ''} />
        <meta itemProp="priceCurrency" content={currency ?? ''} />
        {wasPriceDisplay && wasPriceDisplay > 0 && (
          <Flex gap="4px" align={'center'}>
            <Text
              as="span"
              textAlign="inherit"
              color="textMuted"
              alignSelf="center"
              lineHeight="none"
            >
              Was
            </Text>
            <Text as="span" textAlign="inherit" color="textMuted" textDecoration="line-through">
              {wasPriceDisplay >= 1
                ? formatNumber(wasPriceDisplay, {style: 'currency', currency})
                : Number((wasPriceDisplay * 100).toFixed(0)).toString() + 'p'}
            </Text>
          </Flex>
        )}
      </Text>
      {Boolean(priceInfo) && price != null && !isSearch && (
        <Text
          variant="text2"
          color="textMuted"
          lineHeight="none"
          align={{desktop: alignment, tablet: alignment, mobile: 'left'}}
        >
          {priceInfo?.[price]?.replace(/^0+/, '') } {priceInfo?.perUnit}
        </Text>
      )}
    </Stack>
  )
}

export const Price = observer(PriceContainer)
