import * as React from 'react'
import {sprinkles, Sprinkles} from '../sprinkles.css'

export type BoxOwnProps<E extends React.ElementType = React.ElementType> = {
  as?: E
  className?: string
  children?: React.ReactNode | ((...args: object[]) => React.ReactNode)
  style?: React.CSSProperties
} & Sprinkles

export type BoxProps<E extends React.ElementType> = BoxOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof BoxOwnProps>

export type PolymorphicComponentProps<E extends React.ElementType, P> = P & BoxProps<E>

export type PolymorphicComponent<P, D extends React.ElementType = 'div'> = <
  E extends React.ElementType = D,
>(
  props: PolymorphicComponentProps<E, P>,
) => React.ReactElement | null

const defaultElement = 'div'

/**
This is our core design primitive. It accepts any style props defined in
our sprinkles, converts those prop values to the corresponding generated
classnames and applies to them to a DOM element.
*/
export const Box: <E extends React.ElementType = typeof defaultElement>(
  props: BoxProps<E>,
) => React.ReactElement | null = React.forwardRef(function Box(
  {as, className, style, children, ...rest}: BoxOwnProps,
  ref: React.Ref<Element>,
) {
  const Element = as || defaultElement
  const {hasStyleProps, styleProps, elementProps} = extractSprinklesFromProps(rest)

  /**
   * Generate the className for our style props from our sprinkles and
   * combine with any className given in props. If there are no style props,
   * just apply the className given in props (if it exists). This is an
   * optimization that saves running our sprinkles fn when it's not needed.
   */
  const classNames = hasStyleProps ? composeClassNames(sprinkles(styleProps), className) : className

  return (
    <Element ref={ref} className={classNames} style={style} {...elementProps}>
      {children}
    </Element>
  )
})

/**
 * Combines an array of classnames into a single classname string.
 */
function composeClassNames(...classNames: Array<string | undefined>) {
  const classes = classNames
    .filter((className) => {
      return Boolean(className) && className !== ' '
    })
    .map((className) => {
      return className?.toString().trim()
    })
  return classes.length === 0 ? undefined : classes.join(' ')
}

/**
 * Seperates style props from element props and returns an object for each.
 */
function extractSprinklesFromProps(props: BoxOwnProps) {
  let hasStyleProps = false
  const styleProps: {[key: string]: unknown} = {}
  const elementProps: {[key: string]: unknown} = {}

  for (const key in props) {
    if (sprinkles.properties.has(key as any)) {
      hasStyleProps = true

      styleProps[key] = props[key as keyof BoxOwnProps]
    } else {
      elementProps[key] = props[key as keyof BoxOwnProps]
    }
  }

  return {hasStyleProps, styleProps, elementProps}
}
