import React from "react"
import {Text} from "../../../vanilla"
import {isMobile} from "../../../utils/utils"

interface Props {
    buyXReq: number
    getYQuantity: number
    searchOrCart?: boolean | undefined
    isPromoModal?: boolean
}

export const BuyXGetY = (props: Props) => {

    const {buyXReq, getYQuantity, searchOrCart = false, isPromoModal = false} = props
    const isMobileFlag = isMobile()

    return (
        <Text
            display="flex"
            alignItems={['baseline', searchOrCart ? 'baseline' : 'center']}
            justifyContent={isPromoModal ? "flex-start" : "center"}
            variant={isPromoModal ? "text3" : "text2Bold"}
            style={{ lineHeight: searchOrCart || isMobileFlag ? "normal" : "12px" }}
            lineHeight="inherit"
            flexWrap="wrap"
            gap={['2px', searchOrCart ? "2px" : "0px"]}
        >
                Buy {buyXReq} Get {getYQuantity}
                <Text variant="unstyled" fontWeight="inherit" style={{ fontSize: searchOrCart || isMobileFlag ? "inherit" : "24px", lineHeight: "normal" }}>FREE</Text>
        </Text>
    )
}