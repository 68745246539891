import React from 'react'
import {BaseButton, Flex, Stack, Text} from '../../../../vanilla'
import {ExclamationCircleIcon} from '../../../icons'
import {useBasketStore} from '../../../../store/hooks/useStore'
import {Link} from '../../../link'
import {drawerBookingSlotText} from './styles.css'
import useDeliveryBooking from '../../../../hooks/use-delivery-booking'

export const ReservationExpiredSlot = () => {
  const {currentDeliverySlotId, currentDeliveryDay, basket, slotTime} = useBasketStore()
  const {day, month, isAvailable} = currentDeliveryDay || {}
  const deliveryTimeRange = `${slotTime(basket?.c_windowStartTime)} - ${slotTime(
    basket?.c_windowEndTime
  )}`
  const {reserveSlot} = useDeliveryBooking()

  return (
    <Stack spacing="4px" flexShrink="0" marginY="8px">
      <Flex gap="2px" align="center">
        <ExclamationCircleIcon color="accent0" boxSize="12px" marginRight="4px" />
        <Text color="accent0" className={drawerBookingSlotText}>
          Your delivery reservation has expired!
        </Text>
      </Flex>

      <Text color="gray800" className={drawerBookingSlotText} lineHeight="short">
        Your delivery time:
      </Text>
      <Text color="gray800" className={drawerBookingSlotText} lineHeight="short">
        {deliveryTimeRange} {day} {month}
      </Text>
      <BaseButton
        variant="primary"
        size="sm"
        alignSelf="stretch"
        {...(isAvailable
          ? {onClick: () => reserveSlot(currentDeliverySlotId)}
          : {as: Link, to: '/book-delivery'})}
        marginTop="4px"
      >
        <Text
          variant="unstyled"
          fontSize="inherit"
          lineHeight="inherit"
          fontWeight="inherit"
          align="center"
        >
          {isAvailable ? 'Rebook' : 'Book a New Delivery Time'}
        </Text>
      </BaseButton>
    </Stack>
  )
}
