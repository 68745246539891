import React from 'react'
import {Stack, Text, Flex} from '../../../../../vanilla'
import {ChevronRightIcon} from '../../../../icons'

interface AccordionNavigationProps {
  list: {title: string; hrefLink: string}[]
}
export const AccordionNavigation = ({list}: AccordionNavigationProps) => {
  return list.length ? (
    <Stack spacing="0px">
      {list.map((navigationItem) => {
        return (
          <Flex
            key={navigationItem.hrefLink}
            align="center"
            justify="space-between"
            paddingX="28px"
            paddingY="16px"
            borderBottom="1px"
            borderColor="gray200"
            as="a"
            href={navigationItem.hrefLink}
          >
            <Text as="span" variant="text3" lineHeight="shorter">
              {navigationItem.title}
            </Text>
            <ChevronRightIcon boxSize="16px" />
          </Flex>
        )
      })}
    </Stack>
  ) : null
}
