import {useEffect, useState} from 'react'
import {Nullable} from '../types/utils'
import {Status, StatusMap} from '../types/commerce'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'

export interface CustomerGroup {
  id: string
  link: string
}

export type CustomerGroupList = CustomerGroup[]

export const useCustomerGroups = () => {
  const [customerGroups, setCustomerGroups] = useState<Nullable<CustomerGroupList>>()
  const [status, setStatus] = useState<{
    status: Status
    message?: Nullable<string>
  }>({
    status: StatusMap.IDLE,
  })

  useEffect(() => {
    ;(async function () {
      setStatus({status: StatusMap.PENDING, message: null})

      try {
        const res = await fetch(`${getAppOrigin()}/api/allCustomerGroups`, {method: 'GET'})

        const json = await res.json()

        if (!res.ok) {
          throw new Error(json.errMessage)
        }

        if (json) setCustomerGroups(json)
        setStatus({message: null, status: StatusMap.DONE})
      } catch (error) {
        setStatus({status: StatusMap.ERROR, message: (error as Error)?.message})
      }
    })()
  }, [])

  return {
    customerGroups,
    ...status,
  }
}
