import React from 'react'
import {observer} from 'mobx-react-lite'
import {useIntl} from 'react-intl'
import {Text, Flex, Stack} from '../../../../vanilla'
import {useGlobalStore} from '../../../../store/hooks/useStore'

interface TopUpSummaryProps {
  topUpValue: string
  bcBalance: number | bigint
}

export const TopUpSummary = observer(function TopUpSummary({topUpValue, bcBalance}: TopUpSummaryProps) {
  const {currency} = useGlobalStore()
  const {formatNumber} = useIntl()

  const newBonusCardBalance = bcBalance ? (Number(bcBalance) || 0) + (+topUpValue || 0) : 0

  return (
    <Stack spacing="0px">
      <Stack spacing="0px" width="full" borderBottom="1px" borderColor="gray200">
        <Flex justify="space-between" align="center" paddingY="4px" paddingX="16px">
          <Text variant="text3" lineHeight="short">
            Current Balance{' '}
          </Text>
          <Text variant="text3" lineHeight="short" align="right">
            {formatNumber(bcBalance || 0, {
              currency,
              style: 'currency',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        </Flex>
        <Flex justify="space-between" align="center" paddingY="4px" paddingX="16px">
          <Text variant="text3" lineHeight="short">
            Top up{' '}
          </Text>
          {currency && (
            <Text variant="text3" lineHeight="shorter" align="right">
              {formatNumber(+topUpValue || 0, {
                currency,
                style: 'currency',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          )}
        </Flex>
        <Flex justify="space-between" align="center" paddingY="4px" paddingX="16px">
          <Text variant="text3" lineHeight="short">
            New balance{' '}
          </Text>
          {currency && (
            <Text variant="text3" lineHeight="short" align="right">
              {formatNumber(newBonusCardBalance, {
                currency,
                style: 'currency',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Text>
          )}
        </Flex>
      </Stack>
      <Flex
        borderBottom="1px"
        borderColor="gray200"
        paddingX="16px"
        paddingY="20px"
        justify="space-between"
      >
        <Text variant="text6" lineHeight="short">
          Payment total{' '}
        </Text>
        {currency && (
          <Text variant="text6" lineHeight="short" align="right">
            {formatNumber(+topUpValue, {
              currency,
              style: 'currency',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Text>
        )}
      </Flex>
    </Stack>
  )
})
