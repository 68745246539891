import React from 'react'
import {observer} from 'mobx-react-lite'
import {Box} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {CmsImage} from './cms-image'
import {Image} from '../../types/cms'
import {useLocation} from 'react-router-dom'

interface CategoryHeadlineBannerProps {
  image: {
    images: Image[]
  }
}

const CategoryHeadlineBannerContainer: SlotComponent<CategoryHeadlineBannerProps> = ({content}) => {
  const location = useLocation()

  if (!content?.image?.images?.length) {
    return null
  }
  return (
    <Box
      style={{maxWidth: '1600px'}}
      width="fullvw"
      alignSelf="center"
      display={['none', 'block']}
      {...(location.pathname !== '/style-guide/category-headline-banner'
        ? {position: 'absolute'}
        : {})}
      top="0px"
    >
      <CmsImage
        queryParams={[
          {w: 1600, fmt: 'auto', qlt: 'default'}, 
          {w: 3200, fmt: 'auto', qlt: 'default'}
        ]}
        images={content.image.images}
        width="full"
        style={{minHeight: '140px', objectFit: 'cover'}}
      />
    </Box>
  )
}

export const CategoryHeadlineBanner = observer(CategoryHeadlineBannerContainer)
