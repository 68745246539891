import {isToday} from 'date-fns'
import {useBasketStore, useGlobalStore} from './../store/hooks/useStore'

export const useDeliveryReservationTime = () => {
  const {
    customSitePreferences: {
      LiveSoftReservationDuration,
      LiveSoftReservationDurationSameDay,
      LiveSoftReservationDurationSameDayEnabled,
    },
  } = useGlobalStore()

  const {basket} = useBasketStore()

  const isDeliveryDayToday =
    basket?.c_windowStartTime && isToday(new Date(basket.c_windowStartTime))

  const deliveryReservationSameDay =
    isDeliveryDayToday &&
    LiveSoftReservationDurationSameDayEnabled &&
    LiveSoftReservationDurationSameDay

  const deliveryReservationFallback = `${LiveSoftReservationDuration ?? 1} hour(s)`

  const deliveryReservationDuration = deliveryReservationSameDay
    ? `${deliveryReservationSameDay} minute(s)`
    : deliveryReservationFallback

  return `Delivery reservations last for ${deliveryReservationDuration}`
}
