import React from 'react'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {useBasketStore} from '../store/hooks/useStore'
import {OrderAddress} from '../types/store/basket'
import {flowResult} from 'mobx'

const useDeliveryBooking = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [postCodeAvailability, setPostCodeAvailability] = React.useState<
    'available' | 'unavailable' | undefined
  >()
  const {
    addOrUpdateShippingAddress,
    getDeliverySlots: _getDeliverySlots,
    setPostCode,
    clearPostCode,
    reserveDeliverySlot,
    expandDeliveryAddressForm,
    basket
  } = useBasketStore()

  const getDeliverySlots = async () => {
    setLoading(true)
    try {
      await _getDeliverySlots()
      setLoading(false)
    } catch (error) {
      console.error('Error fetching delivery slots', error)
      setLoading(false)
    }
  }


  const setPostalCode = async (postcode: string) => {
    setLoading(true)
    try {
      await setPostCode(postcode)
      setLoading(false)
    } catch (error) {
      console.error('Error checking postal code', error)
      setLoading(false)
    }
  }

  const checkDeliveryValidity = async (postcode: string) => {
    setLoading(true)
    const res = await fetch(
      `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Delivery-GetDeliveryStatus?postcode=${encodeURIComponent(
        postcode,
      )}`,
      {
        credentials: 'include',
      },
    )
    const json = await res.json()
    if (json?.data?.code) {
      setPostCodeAvailability('available')
    } else {
      setPostCodeAvailability('unavailable')
    }
    setLoading(false)
  }

  const clearPostalCode = async () => {
    setLoading(true)
    try {
      await clearPostCode()
      setLoading(false)
    } catch (error) {
      console.error('Error clearing postal code', error)
      setLoading(false)
    }
    expandDeliveryAddressForm(null)
  }

  const setDeliveryAddress = async (address: OrderAddress, saveAddress?: boolean) => {
    setLoading(true)
    try {
      await addOrUpdateShippingAddress(address, saveAddress ?? false)
      if(basket?.c_deliveryPostalCode != address.postalCode){
        await setPostCode(address.postalCode || '')
      }
      setLoading(false)
    } catch (error) {
      console.error('Error setting delivery address', error)
      setLoading(false)
    }
  }

  const reserveSlot = async (slotId: string) => {
    setLoading(true)
    try {
      const response = await flowResult(reserveDeliverySlot(slotId))
      setLoading(false)
      return response
    } catch (error) {
      console.error('Error reserving delivery slot', error)
      setLoading(false)
      throw error
    }
  }

  return {
    clearPostalCode,
    setPostalCode,
    checkDeliveryValidity,
    loading,
    setLoading,
    postCodeAvailability,
    setDeliveryAddress,
    getDeliverySlots,
    setPostCodeAvailability,
    reserveSlot
  }
}

export default useDeliveryBooking
