import * as React from 'react'
import {useState} from 'react'
import {Box, PolymorphicComponentProps} from './Box'
import {Text} from './Text'
import {inputRecipe, iconContainer, InputVariants, inputLabelRecipe} from './input.css'
import classNames from 'classnames'

type StyleProps = object & InputVariants

type UVariant<T = StyleProps> = T extends InputVariants ? Omit<T, 'variant' | 'label'> : never

type InputOwnProps = UVariant & {
  icon?: React.ReactNode | null
  disabled?: boolean
  variant?: 'unstyled' | StyleProps['variant']
  hasError?: boolean
  label?: string
  as?: 'string'
  validatedSuccessfully: boolean
}

export type InputProps<E extends React.ElementType> = PolymorphicComponentProps<E, InputOwnProps>

const defaultElement = 'input'

export const Input: <E extends React.ElementType = typeof defaultElement>(
  props: InputProps<E>,
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      colorScheme,
      width = 'full',
      focusVisible = false,
      hasError,
      children,
      label,
      icon = null,
      value,
      validatedSuccessfully,
      name,
      className,
      onBlur,
      showAsterisk,
      id,
      ...restProps
    }: InputProps<E>,
    ref: typeof restProps.ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)

    const isFilled = !!value || isFocused

    const classNameInput = inputRecipe({
      variant,
      colorScheme,
      size,
      focusVisible,
      error: hasError,
      withLeftIcon: !!icon,
      validatedSuccessfully,
    })

    const classNameLabel = inputLabelRecipe({
      variant,
      colorScheme,
      size,
      isFilled,
      focusVisible,
      error: hasError,
      withLeftIcon: !!icon,
      validatedSuccessfully,
    })

    return (
      <Box position="relative" width={width} display="flex">
        {icon && <Box className={iconContainer}>{icon}</Box>}
        <Box
          id={id}
          as="input"
          display="block"
          name={name}
          fontFamily="body"
          className={classNames(classNameInput, className)}
          onFocus={() => setIsFocused(true)}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            onBlur?.(e)
            setIsFocused(false)
          }}
          value={value}
          {...restProps}
          ref={ref}
          paddingRight={restProps.type === 'password' ? '44px' : undefined}
        />
        {label && (
          <Box as="label" htmlFor={id} aria-label={label} className={classNameLabel}>
            <Text
              variant="unstyled"
              as="span"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              fontWeight="inherit"
              fontSize="inherit"
              lineHeight="inherit"
              style={{height: 'inherit'}}
              paddingX={isFilled ? '4px' : '0px'}
              backgroundColor={isFilled ? 'white' : 'transparent'}
            >
              {label}
              {showAsterisk && (
                <Text
                  as="span"
                  display="inline-block"
                  marginLeft={isFilled ? '4px' : '0px'}
                  fontSize="inherit"
                  lineHeight="inherit"
                  fontWeight="inherit"
                  color="error"
                  marginBottom="2px"
                >
                  *
                </Text>
              )}
            </Text>
          </Box>
        )}
      </Box>
    )
  },
)
