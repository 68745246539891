import {useCategoryStore} from './useStore'
import {NormalizedCategory} from '../CategoryStore'

type CategoryModel = NormalizedCategory

/**
 * Given an ID, returns a category from the global store if it exists.
 */
export const useCategory = (categoryId?: string): CategoryModel | undefined => {
  const {categories} = useCategoryStore()
  if (!categoryId) {
    return undefined
  }
  return categories[categoryId]
}
