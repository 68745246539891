import React from 'react'
import {BaseButton, Box, BoxProps, Stack, Text} from '../../vanilla'

interface AlcoholRestrictionOverlayProps extends BoxProps<React.ElementType> {
  onLearnMoreClick: () => void
}

export const AlcoholRestrictionOverlay = ({
  onLearnMoreClick,
  ...restBoxProps
}: AlcoholRestrictionOverlayProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      width={'full'}
      height={'full'}
      {...restBoxProps}
    >
      <Stack
        spacing="2px"
        alignItems="center"
        borderRadius="base"
        marginX="16px"
        paddingY="8px"
        paddingX="12px"
        style={{backgroundColor: 'rgba(219, 219, 219, 0.93)'}}
      >
        <Text textAlign="center" variant="text4" lineHeight="shorter">
          Alcohol restriction
        </Text>
        <BaseButton
          variant="unstyled"
          paddingX="0px"
          textDecoration="underline"
          height="auto"
          aria-label="Learn more about alcohol restriction"
          style={{pointerEvents: 'initial'}}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            onLearnMoreClick()
          }}
        >
          <Text variant="text4" color="accent3" textDecoration="underline" lineHeight="none">
            Learn more
          </Text>
        </BaseButton>
      </Stack>
    </Box>
  )
}
