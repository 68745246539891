import React, {useCallback, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {BaseButton, Box, Button, Flex, Text} from '../../vanilla'
import {useBasketStore, useCustomerStore} from '../../store/hooks/useStore'
import {ExclamationCircleIcon} from '../icons'
import * as styles from './styles.css'
import {DeliveryDateTime} from './delivery-date-time'
import {Link} from '../link'
import {useTimer} from '../../hooks/use-timer'
import useNavigation from '../../hooks/use-navigation'
import {useDeliveryReservationTime} from '../../hooks/use-delivery-reservation-time'
import { useSlotExpiration } from '../../store/hooks/useSlotExpiration'
import { useAlcoholRestrictions } from '../../hooks/use-alcohol-restrictions'
import { Hit } from '../../store/ProductStore'

interface CountdownProps {
  slotExpiration: string
  textVariant?: 'text2' | 'text3' | 'text4'
  color?: 'accent0' | 'accent2'
  onCountdownEnd?: () => void
}

export const Countdown = ({
  slotExpiration,
  textVariant = 'text4',
  onCountdownEnd,
  color
}: CountdownProps) => {
  const { minutes, seconds, isFinished } = useTimer(slotExpiration)

  useEffect(() => {
    if (typeof onCountdownEnd === 'function' && isFinished) {
      onCountdownEnd()
    }
  }, [onCountdownEnd, isFinished])

  const displayMinutes = isFinished ? '0' : minutes
  const displaySeconds = isFinished ? '00' : seconds

  return (
    <Text
      as="span"
      minWidth="60px"
      display="inline-block"
      color={color}
      variant={textVariant}
    >
      {`${displayMinutes}m:${displaySeconds}s`}
    </Text>
  )
}

interface ReservationStatusProps {
  rebookSlot: () => void
}

export const ReservationStatusContainer = ({rebookSlot}: ReservationStatusProps) => {
  const navigate = useNavigation()
  const {isRegistered} = useCustomerStore()
  const {basket, currentDeliveryDay, setOpenMiniBasketUI} = useBasketStore()
  const {isReservationExpired} = useSlotExpiration()
  const {isAvailable} = currentDeliveryDay
  const {checkIfAnyProductsAreAlcohol, isCurrentSlotAlcoholRestricted} = useAlcoholRestrictions()
  const isRestricted = checkIfAnyProductsAreAlcohol(basket?.productItems as Hit[]) && isCurrentSlotAlcoholRestricted

  const deliveryReservationDuration = useDeliveryReservationTime()

  const renderBookingAction = useCallback(() => {
    if (!isRegistered && isAvailable) {
      return (
        <Button
          size="sm"
          width="full"
          variant="primary"
          onClick={() =>
            navigate('/login', 'push', {
              directedFrom: '/book-delivery',
            })
          }
        >
          Sign In to Rebook
        </Button>
      )
    }

    return (
      <BaseButton
        variant="primary"
        size="sm"
        width="full"
        style={{padding: '0 20px !important'}}
        {...(isAvailable ? isRestricted ? {onClick: () => setOpenMiniBasketUI(true)} : {onClick: rebookSlot} : {as: Link, to: '/book-delivery'})}
      >
        <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
          {isAvailable ? isRestricted ? 'Edit Basket' : 'Rebook' : 'Book a New Delivery Time'}
        </Text>
      </BaseButton>
    )
  }, [isAvailable, isRegistered])

  return (
    <Flex
      align="center"
      gap={isReservationExpired() ? '20px' : '8px'}
      direction={isReservationExpired() ? ['column', 'row'] : 'column'}
      className={styles.reservationStatusContainer}
    >
      {isReservationExpired() ? (
        <>
          <Box
            borderBottom={['1px', '0px']}
            borderColor="gray900"
            paddingBottom={['20px', '0px']}
            flex="1"
          >
            <Flex align="center" justify={['center', 'flex-end']} gap="4px">
              <ExclamationCircleIcon color="accent0" marginBottom="2px" />
              <Text color="accent0" variant="text4">
                Your delivery reservation has expired!
              </Text>
            </Flex>
            <Text variant="text4" textAlign={['center', 'right']}>
              {deliveryReservationDuration}
            </Text>
          </Box>
          <Box display={['block', 'none']}>
            <DeliveryDateTime />
          </Box>
          <Flex
            className={styles.reservationStatusButton}
            justify="center"
            paddingX={['16px', '0px']}
          >
            {renderBookingAction()}
          </Flex>
        </>
      ) : (
        <>
          <Box
            alignSelf="flex-end"
            className={styles.reservationStatusButton}
            paddingX={['16px', '0px']}
          >
            <BaseButton
              as={Link}
              href="/book-delivery"
              variant="primary"
              maxHeight="36px"
              style={{width: '100%', padding: '0 20px !important'}}
            >
              <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
                Change Delivery Date
              </Text>
            </BaseButton>
          </Box>
          <Text variant="text4">
            This delivery reservation expires in{' '}
            <Countdown slotExpiration={basket?.c_slotExpiryTime || ''} color='accent0' />
          </Text>
        </>
      )}
    </Flex>
  )
}

export const ReservationStatus = observer(ReservationStatusContainer)
