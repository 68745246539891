import React, {useEffect, useState} from 'react'
import useNavigation from '../../../hooks/use-navigation'
import {IntlFormatters} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {Box, Flex, Text, Stack} from '../../../vanilla'
import type {BasketStore} from '../../../store/BasketStore'
import type {AutocompleteComponents} from '@algolia/autocomplete-js'
import * as styles from './styles.css'
import UpdateCartItemQuantity from '../../update-cart-quantity'
import {NearPromo} from '../../product-detail/product-promo'
import {vars} from '../../../vanilla/vars.css'
import {useItemPrice} from '../util/useItemPrice'
import {useAlgoliaAnalytics, useBookDeliveryPopover} from '../../../contexts'
import {Hit, ProductModel} from '../../../store/ProductStore'
import useEinstein from '../../../store/hooks/useEinstein'
import {Price} from '../../price'
import {ATBButton} from '../../add-to-basket-button'
import {useProductImage} from '../../../store/hooks/useProductImage'
import {transformHit} from '../util/transformHit'
import {useBasketItem} from '../../../store/hooks/useBasketItem'
import {CART_LOCATION} from '../../../analytics/utils'
import {useAddToCartTracking} from '../../../analytics/click-event-tracking'
import {EVENT_NAMES_MAP} from '../../../analytics/algolia'
import {useCustomerStore, useOrderStore} from '../../../store/hooks/useStore'
import {useSlotExpiration} from '../../../store/hooks/useSlotExpiration'
import {formatProductName} from '../../../utils/utils'
import {isMobile} from '../../../utils/utils'
import { ModalNavigationProps } from '../../../types/ui'

interface AutocompleteHitProps {
  hit: Hit
  components: AutocompleteComponents
  formatNumber: IntlFormatters['formatNumber']
  basketStore: BasketStore
  // `navigate` is required to be passed as Hit Item is rendered out of react-router context
  navigate: ReturnType<typeof useNavigation>
  searchQuery: string
  modalNavigationProps: ModalNavigationProps
}

// Note: we are not allowed to use Link as we don't have Router context in this template,
// instead we are using navigate passed from parent.
export const HitComponent = observer(
  ({hit, components, basketStore, navigate, searchQuery, modalNavigationProps}: AutocompleteHitProps) => {
    const {events} = useAlgoliaAnalytics()
    const {sendClickSearch} = useEinstein()
    const img = hit.image_groups?.[0]?.images?.[0]
    const imgSrc = useProductImage(img)
    const {displayPrice, promotionPrice} = useItemPrice(hit as unknown as ProductModel)
    const {quantity} = useBasketItem(hit.id)
    const [clickedQuantity, setClickedQuantity] = useState(0)
    const {showBookDeliveryPopover} = useBookDeliveryPopover()
    const customerStore = useCustomerStore()
    const {isReservationExpired, handleEditReservationExpired} = useSlotExpiration()
    const {editMode} = useOrderStore()
    const isMobileFlag = isMobile()
    const {getApplicablePromotions} = useCustomerStore()
    const {nearPromo} = getApplicablePromotions(hit.productPromotions)

    useEffect(() => {
      setClickedQuantity(quantity)
    }, [basketStore.count, hit])

    const addToBasket = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      customerStore.setLastActiveToNow()

      if (editMode && isReservationExpired()) {
        // If in edit mode and slot has expired send to order history and don't add to basket
        handleEditReservationExpired()
      } else if (!basketStore.canAddItems) {
        showBookDeliveryPopover({
          placement: window.innerWidth < 768 ? 'bottom' : 'center',
          bookDeliveryProps: {
            search: true,
            product: hit,
            onNavigate: navigate,
          },
        })
      } else {
        basketStore.addProductToUpdate({
          product: hit,
          quantity: 1,
          event: EVENT_NAMES_MAP.AUTOCOMPLETE_ADD_TO_CART,
          isSearch: true,
        })
        setClickedQuantity(1)
      }
    }

    const productLink = `/p/${formatProductName(hit?.name)}/${hit.id}.html`
    const sendAddToCartData = useAddToCartTracking(basketStore.basket)

    const sendItemClickEvents = () => {
      /* Einstein Analytics Events */
      sendClickSearch({
        searchText: searchQuery,
        product: {
          id: hit.id,
        },
      })

      /* Algolia Analytics Events */
      events?.autocomplete?.sendItemClickEvent({
        objectIDs: [hit.objectID],
        queryID: hit.__queryID || hit.__autocomplete_queryID,
        positions: [(hit.__autocomplete_id as number) + 1],
      })
    }

    if (isMobileFlag) {
      return (
        <Flex gap="12px" align="center">
          <Flex direction="column" className="autocomplete-hit-price-offer" gap="4px" width="full">
            <Flex flex="1" align="center" gap="16px" alignSelf="stretch">
              <Box
                flexShrink="0"
                width="48px"
                height="48px"
                onClick={() => {
                  sendItemClickEvents()
                  navigate(productLink)
                }}
              >
                <img
                  src={`${imgSrc}?w=128&qlt=60`}
                  alt={img?.alt}
                  style={{display: 'block', width: '100%', height: 'auto', objectFit: 'contain'}}
                />
              </Box>
              <Text
                height="full"
                variant="text2"
                lineHeight="shorter"
                flex="1"
                color="textMuted"
                className={styles.itemText}
                onClick={() => {
                  sendItemClickEvents()
                  navigate(productLink)
                }}
              >
                <components.Highlight hit={hit} attribute="name" />
              </Text>
            </Flex>
            {nearPromo && isMobileFlag && (
              <NearPromo
                position="static"
                alwaysInline
                showViewMore={false}
                promotion={nearPromo}
                onNavigate={navigate}
                search={true}
              />
            )}
          </Flex>
          <Flex direction="column" gap="8px" alignItems="flex-end" paddingTop="8px">
            <Price price={displayPrice} promotionPrice={promotionPrice} priceInfo={hit.priceInfo} isSearch={true} />
            <Box width="125px">
              <UpdateCartItemQuantity
                product={hit}
                maxQuantity={hit?.maxOrderQuantity}
                quantity={clickedQuantity}
                setQuantity={setClickedQuantity}
                cartLocation={CART_LOCATION.AUTOCOMPLETE}
                searchQuery={searchQuery}
                renderButton={
                  <ATBButton
                    hit={transformHit(hit) as Hit}
                    displayPrice={displayPrice}
                    onClick={(e) => {
                      addToBasket(e)
                      sendAddToCartData(hit, CART_LOCATION.AUTOCOMPLETE)
                    }}
                    width="full"
                  />
                }
                style={{width: 125, height: vars.space['32px']}}
              />
            </Box>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex gap="12px" align="center">
        <Box
          flexShrink="0"
          width="48px"
          height="48px"
          onClick={() => {
            sendItemClickEvents()
            navigate(productLink)
          }}
        >
          <img
            src={`${imgSrc}?w=128&qlt=60`}
            alt={img?.alt}
            style={{display: 'block', width: '100%', height: 'auto', objectFit: 'contain'}}
          />
        </Box>

        <Flex flex="1" align="center" gap="16px" alignSelf="stretch">
          <Text
            height="full"
            variant="text2"
            lineHeight="shorter"
            flex="1"
            color="textMuted"
            className={styles.itemText}
            onClick={() => {
              sendItemClickEvents()
              navigate(productLink)
            }}
          >
            <components.Highlight hit={transformHit(hit) as Hit} attribute="name" />
          </Text>

          <Flex direction="row" align="center" gap="36px" className="autocomplete-hit-price-offer">
            <Stack spacing="4px">
              {nearPromo && !isMobileFlag && (
                <NearPromo
                  position="static"
                  alwaysInline
                  showViewMore={false}
                  promotion={nearPromo}
                  onNavigate={navigate}
                  search={true}
                  modalNavigationProps={modalNavigationProps}
                />
              )}
              <Price price={displayPrice} promotionPrice={promotionPrice} priceInfo={hit.priceInfo} isSearch={true} />
            </Stack>
            <Box width="125px">
              <UpdateCartItemQuantity
                product={hit}
                maxQuantity={hit?.maxOrderQuantity}
                quantity={clickedQuantity}
                setQuantity={setClickedQuantity}
                cartLocation={CART_LOCATION.AUTOCOMPLETE}
                searchQuery={searchQuery}
                renderButton={
                  <ATBButton
                    hit={transformHit(hit) as Hit}
                    displayPrice={displayPrice}
                    onClick={(e) => {
                      addToBasket(e)
                      sendAddToCartData(hit, CART_LOCATION.AUTOCOMPLETE)
                    }}
                    width="full"
                  />
                }
                style={{width: 125, height: vars.space['32px']}}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    )
  }
)
