import React from 'react'
import {observer} from 'mobx-react'
import {Box, Center, Container} from '../../../vanilla'
import {SlotComponent} from '../../../types/ui'
import {Image} from '../../../types/cms'
import {CmsImage} from '../cms-image'
import {brandFilterItem} from './styles.css'

export interface BrandFilterContentProps {
  brandFilter: Array<{
    content: string
    contentId: string
    image: {images: Array<Image>}
    filterID: string
  }>
}

export const BrandFilter: SlotComponent<BrandFilterContentProps> = observer(({content}) => {
  const {brandFilter} = content

  return (
    <Container paddingY={['32px', '48px']} paddingX={['24px', '36px']}>
      <Box
        display="flex"
        as="ul"
        gap="8px"
        width="full"
        flexWrap={['nowrap', 'wrap']}
        overflowX={['scroll', 'auto']}
      >
        {brandFilter?.map((_brandFilter, idx) => {
          const {image, filterID} = _brandFilter

          return (
            <Center
              as="li"
              display="inline-flex"
              backgroundColor="white"
              key={`${content?._meta?.deliveryId}-${idx}`}
              className={brandFilterItem}
              overflow="hidden"
            >
              <CmsImage images={image.images} imageLink={filterID} maxWidth="full" height="full" />
            </Center>
          )
        })}
      </Box>
    </Container>
  )
})
