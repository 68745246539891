import React, {useState} from 'react'
import {flowResult} from 'mobx'
import {observer} from 'mobx-react-lite'
import {useDisclosure} from '../../vanilla'
import {useBasketStore} from '../../store/hooks/useStore'
import {AddAllToCartConfirmation} from './add-all-to-cart-confirmation'
import {AddAllToCartMain} from './add-all-to-cart-main'
import {useAddAllToBasketParams} from './use-add-all-to-basket-params'
import {useListenToAddAllToBasketLinks} from './use-listen-to-add-all-to-basket-links'
import {useRestoreInitialHref} from './use-restore-initial-href'
import {PartialProductItem} from '../../types/store/basket'

const AddAllToCartContainer: React.FC = () => {
  const {products, confirmation, updateParams} = useAddAllToBasketParams()
  const basketStore = useBasketStore()
  const addAllToBasketModal = useDisclosure(true)
  const confirmationModal = useDisclosure(false)
  const [processing, setProcessing] = useState(false)
  const restoreInitialHref = useRestoreInitialHref()

  useListenToAddAllToBasketLinks({
    update: updateParams,
    openModal: addAllToBasketModal.onOpen
  })

  if (products == null) {
    return null
  }

  const addAllToCart = async () => {
    if (!products?.available?.length) {
      return
    }
    const availableProducts = products.available.map((product) => {
      return {
        product: product,
        quantity: product.quantity,
      }
    })
    setProcessing(true)
    await flowResult(basketStore.updateBasketItemQuantity(availableProducts as PartialProductItem[]))
    setProcessing(false)
  }
  const handleAdd = async () => {
    if (confirmation) {
      confirmationModal.onOpen()
    } else {
      await addAllToCart()
      addAllToBasketModal.onClose()
    }
  }
  const handleConfirm = async () => {
    await addAllToCart()
    confirmationModal.onClose()
  }

  return (
    <>
      <AddAllToCartMain
        onCancel={() => {
          restoreInitialHref()
          addAllToBasketModal.onClose()
        }}
        products={products}
        loading={processing}
        onAdd={handleAdd}
        {...addAllToBasketModal}
      />
      <AddAllToCartConfirmation
        loading={processing}
        onConfirm={handleConfirm}
        onCancel={() => {
          confirmationModal.onClose()
          addAllToBasketModal.onOpen()
        }}
        {...confirmationModal}
      />
    </>
  )
}

export const AddAllToCart = observer(AddAllToCartContainer)
