/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import React from 'react'
import {observer} from 'mobx-react-lite'
import {useBasketStore} from '../../../store/hooks/useStore'
import { PromoThresholdProgressBar } from './promo-threshold-progress-bar'

export const OrderSummaryProgressBars = observer(() => {
  const basketStore = useBasketStore()

  const {basket: order} = basketStore
  
  return (
    basketStore.filteredPromotionalThresholds.map((thresholdItem) => {     
      return (<PromoThresholdProgressBar
        promoThreshold={thresholdItem}
        currency={order?.currency!}
      />) 
   })
  )

})
