import React, {PropsWithChildren, ReactNode} from 'react'
import {BaseButton, Box, useDisclosure, Text, Stack} from '../../../../vanilla'
import {ChevronDownIcon} from '../../../icons'

interface PanelOptionProps {
  title: string
  Icon: ReactNode
  selectedOption?: string
}
export const PanelOption = (props: PropsWithChildren<PanelOptionProps>) => {
  const {title, Icon, selectedOption, children} = props
  const {isOpen, onToggle} = useDisclosure()

  return (
    <Stack spacing="0px" width="full">
      <Box bg="gray50" borderTop="1px" borderColor="gray200" height="100px">
        <BaseButton
          height="full"
          variant="unstyled"
          paddingX="12px"
          paddingY="12px"
          style={{borderRadius: '0px'}}
          width="full"
          justifyContent="center"
          onClick={onToggle}
          gap="20px"
        >
          {Icon}
          <Stack as="span" spacing="4px" style={{width: 130}}>
            <Text as="span" variant="text3">
              {title}
            </Text>
            {selectedOption && (
              <Text as="span" variant="text1" lineHeight="none" color="gray400">
                {selectedOption}
              </Text>
            )}
          </Stack>
          <ChevronDownIcon
            boxSize="16px"
            color="gray800"
            style={{transform: isOpen ? 'rotate(180deg)' : 'unset'}}
          />
        </BaseButton>
      </Box>
      {isOpen ? children : null}
    </Stack>
  )
}
