import {Carousel} from '../carousel'
import React from 'react'
import {SlotComponent} from '../../types/ui'
import {HeroBanner, HeroBannerProps} from './HeroBanner'

interface HeroBannerCarousel {
  items: HeroBannerProps[]
}

export const HeroBannerCarousel: SlotComponent<HeroBannerCarousel> = ({content}) => {
  return (
    <Carousel
      loop
      autoPlay
      itemsPerSide={{desktop: 1, mobile: 1, tablet: 1}}
      controlsPosition="innerBottom"
      itemClassName="hero-banner-carousel-item"
      minimumTouchDrag={20}
      controlConfig={{
        activeDotColor: 'accent0',
        arrowsBgColor: 'transparent',
        arrowsColor: 'accent0',
        arrowsOutline: true,
        dotsOutline: true,
        showArrows: {mobile: false, desktop: true, tablet: true},
      }}
    >
      {content?.items?.map((item, idx) => {
        return (
          <HeroBanner
            content={item}
            key={`hero-banner-carousel-item-${item._meta.schema}-${idx}`}
            index={idx + 1}
            heroBannerCarouselSlotGroupName={content?.slotGroupName}
          />
        )
      })}
    </Carousel>
  )
}
