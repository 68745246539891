import * as React from 'react'
import {Sprinkles} from '../sprinkles.css'
import {Box, PolymorphicComponentProps} from './Box'
import {textRecipe, TextVariants} from './text.css'

type TextOwnProps = TextVariants & {
  disableLinkStyle?: boolean
  align?: Sprinkles['textAlign']
  weight?: Sprinkles['fontWeight']
  size?: Sprinkles['fontSize']
}

export type TextProps<E extends React.ElementType> = PolymorphicComponentProps<E, TextOwnProps>

const defaultElement = 'p'

export const Text: <E extends React.ElementType = typeof defaultElement>(
  props: TextProps<E>,
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      align = 'left',
      weight = 'normal',
      size = 'md',
      variant,
      disableLinkStyle,
      className,
      children,
      ...restProps
    }: TextProps<E>,
    ref: typeof restProps.ref,
  ) => {
    // Use `variant='unstyled'` so bypass recipe. This allows for full customization
    // via style props. Note that the `unstyled` variant is not part of the recipe and
    // is only used here as a flag to bypass the recipe.
    const noVariant = variant === 'unstyled'
    const _className = noVariant ? '' : textRecipe({variant})

    const isLink = (restProps.to || restProps.href) && !disableLinkStyle

    if (isLink) {
      // _className = [_className, linkStyles].join(' ')
    }

    return (
      <Box
        ref={ref}
        className={[_className, className].join(' ')}
        as={defaultElement}
        fontSize={size}
        fontFamily="body"
        lineHeight="base"
        fontWeight={weight}
        letterSpacing="normal"
        color="inherit"
        textAlign={align}
        {...restProps}
      >
        {children}
      </Box>
    )
  },
)
