import React from "react";
import {Box, Text} from "../../vanilla";
import {RefreshIcon} from "../icons";

interface ErrorBoundaryProps {
  children: React.ReactNode
  renderErrorMessage?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    renderErrorMessage: boolean;

    constructor(props: ErrorBoundaryProps) {
      super(props);
      this.state = { hasError: false }
      this.renderErrorMessage = props.renderErrorMessage ?? true
    }
  
    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
      return { hasError: true }
    }
  
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      // Recording an error here should invoke Sentry as it listens to console
      console.error(error, errorInfo)
    }
  
    render() {
      if (this.state.hasError && this.renderErrorMessage) {
        const refreshIconStyle = {
            display: 'inline-block',
            marginTop: '2px',
        }
        return <Box>
            <Text as="span">Oops, something went wrong <RefreshIcon boxSize="16px" style={refreshIconStyle} onClick={() => {
                this.setState({ hasError: false })
            }} /></Text>
            
        </Box>
      }

      if (this.state.hasError && !this.renderErrorMessage) {
        // Hide the component which errored if specified.
        return <></>
      }
  
      return this.props.children
    }
  }