import React, {forwardRef} from 'react'
import {Box, Text, Flex} from '../'
import {radioRecipe, RadioVariants} from './radio.css'
import {PolymorphicComponentProps} from './Box'

type StyleProps = object & RadioVariants

type UVariant<T = StyleProps> = T extends RadioVariants
  ? Omit<T, 'variant' | 'focusVisible'>
  : never

type RadioOwnProps = UVariant & {
  disabled?: boolean
  variant?: StyleProps['variant']
  label: React.ReactNode
}

export type RadioProps<E extends React.ElementType> = PolymorphicComponentProps<E, RadioOwnProps>

const defaultElement = 'input'

export const Radio: <E extends React.ElementType = typeof defaultElement>(
  props: RadioProps<E>,
) => React.ReactElement | null = forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {variant, size, focusVisible = false, label, hasError, ...props}: RadioProps<E>,
    ref: typeof props.ref,
  ) => {
    const checkboxClassName = radioRecipe({variant, size, focusVisible, error: hasError})

    return (
      <Flex as="label" align="center" gap="12px">
        <Box as="input" type="radio" display="none" {...props} ref={ref} />
        <Box
          className={`${checkboxClassName}${props.checked ? ' checked' : ''}`}
          {...props}
          checked={props.checked}
          ref={ref}
        />
        <Text as="span" size="sm">
          {label}
        </Text>
      </Flex>
    )
  },
)
