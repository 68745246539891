import React, {useMemo} from 'react'
import {useCategory} from '../../store/hooks/useCategory'
import {Box, BoxProps, Flex, Stack, Text} from '../../vanilla'
import {Link} from '../link'
import {ChevronRightIcon} from '../icons'
import {useContentStore} from '../../store/hooks/useStore'
import {breadcrumbIcon} from './styles.css'

export interface Crumb {
  id: string
  name: string
  url?: string
}

export interface BreadcrumbsProps extends BoxProps<'nav'> {
  breadcrumbs?: Array<Crumb>
  categoryId?: string
  search?: string
}

export const Breadcrumbs = ({breadcrumbs, categoryId, search, ...rest}: BreadcrumbsProps) => {
  const category = useCategory(categoryId)
  const crumbs: Crumb[] = (breadcrumbs || category?.parentCategoryTree || []) as Crumb[]
  const {routeMap} = useContentStore()

  const home: Crumb = {
    id: 'HOME',
    name: 'Home',
    url: '/',
  }
  const searched: Crumb = {
    id: 'SEARCH',
    name: search || '',
  }

  const extractCategoryPathFromRouteMap = (breadcrumbs: Crumb[]) => {
    if (!breadcrumbs?.length) return breadcrumbs

    for (const routePath in routeMap) {
      const dataStr = routeMap[routePath]

      const [_, entityType, entityId] = dataStr.split('|')

      if (entityType === 'CATEGORY') {
        const crumbIndex = breadcrumbs.findIndex((crumb) => crumb.id === entityId)
        if (crumbIndex !== -1 && !Object.prototype.hasOwnProperty.call(breadcrumbs[crumbIndex], 'url'))
          breadcrumbs[crumbIndex] = {...crumbs[crumbIndex], url: routePath}
      }
    }

    return breadcrumbs
  }

  const _crumbs = useMemo(() => extractCategoryPathFromRouteMap(crumbs), [crumbs])

  if (!_crumbs || _crumbs.length === 0) {
    _crumbs.unshift(searched)
  }
  if (_crumbs && _crumbs.length > 0 && _crumbs[0].name != 'Home') {
    _crumbs.unshift(home)
  }

  return (
    <Box as="nav" aria-label="breadcrumb" {...rest}>
      <Stack
        direction="row"
        align="center"
        as="ol"
        display={['inline-block', 'flex']}
        width="full"
        spacing="0px"
      >
        {_crumbs?.map(({name, id, url}, index) => (
          <Flex as="li" key={id} display={['inline-flex', 'flex']} align="center">
            <Text
              variant="text1"
              textDecoration="none"
              color={url ? {default: 'gray800', hover: 'gray700'} : 'gray500'}
              {...(url ? {as: Link, to: url} : {})}
              style={{cursor: url ? 'pointer' : 'unset'}}
            >
              {name}
            </Text>
            {index !== _crumbs.length - 1 && (
              <ChevronRightIcon
                className={breadcrumbIcon}
                boxSize="12px"
                marginX="4px"
                display="inline-block"
              />
            )}
          </Flex>
        ))}
      </Stack>
    </Box>
  )
}
