import React from 'react'
import {Box, Stack, Text} from '../../vanilla'
import {DeliveryIcon} from '../icons'
import { StackProps } from '../../vanilla/components/Stack'
interface FreeNextDayProps extends StackProps<"div">{

}

export const FreeNextDay = React.forwardRef((props:FreeNextDayProps, ref:React.Ref<any>) => {
  const {...rest} = props
  return (

    <Stack flexDirection="row"  {...rest} ref={ref} >
      <DeliveryIcon color="accent0" width="80px" height="40px" />
      <Box>
        <Text as="h3" color="accent0" variant="unstyled" fontSize="lg" fontWeight="bold">
          Free Next Day Delivery
        </Text>
        <Text variant="text6">When you spend £40 online</Text>
      </Box>
    </Stack>
  )
})
