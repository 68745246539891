import {ChangeEvent} from 'react'
import {ControllerRenderProps} from 'react-hook-form'
import {useIntl} from 'react-intl'
import {CreateFields, MarketingFormFields} from '../../types/forms'
import {MAX_FIELD_LENGTH, PHONE_REGEXP} from '../../utils/constants'
import {validateEmail} from '../../utils/email-utils'
import {validatePhoneNumber} from '../../utils/phone-utils'

export const useMarketingFormFields: CreateFields<MarketingFormFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    firstName: {
      name: `firstname`,
      label: formatMessage({defaultMessage: 'First name'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `First Name cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`firstname`],
      control,
    },
    lastName: {
      name: `lastname`,
      label: formatMessage({defaultMessage: 'Surname'}),
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Last Name cannot be longer than ${MAX_FIELD_LENGTH} characters`,
        },
      },
      error: errors[`lastname`],
      control,
    },
    email: {
      name: `email`,
      label: formatMessage({defaultMessage: 'Email address'}),
      defaultValue: '',
      type: 'email' as const,
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        maxLength: {
          value: MAX_FIELD_LENGTH,
          message: `Email cannot be longer than ${MAX_FIELD_LENGTH} characters.`,
        },
        validate: (value: string) =>
          validateEmail(value).isValid ||
          formatMessage({defaultMessage: 'Please enter a valid email address.'}),
      },
      inputProps: {
        // For login fields browser still tries to autofill even if autocomplete is off... So setting `new-password` to fix it.
        // See: https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion
        autoComplete: 'new-password',
        maxLength: MAX_FIELD_LENGTH,
      },
      error: errors[`email`],
      control,
    },
    phone: {
      name: `phone`,
      label: formatMessage({defaultMessage: 'Phone Number'}),
      defaultValue: '',
      type: 'tel' as const,
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        validate: (phone: string) =>
          validatePhoneNumber(phone).isValid ||
          formatMessage({defaultMessage: 'Please specify a valid phone number.'}),
      },
      error: errors[`phone`],
      control,
    },
  }

  return fields
}
