/**
 * Function to get and set dataLayer
 * @param dataLayer - The dataLayer
 * @param dataLayerName - The dataLayer name
 */
export const getDataLayerSnippet = (dataLayer, dataLayerName = 'dataLayer') => {

  const initialPush = (dataLayer != undefined) ? 
    `window.${dataLayerName}.push(${JSON.stringify(dataLayer)})` 
    : '' 

  return `window.${dataLayerName} = window.${dataLayerName} || [];${initialPush}`
}
   
/**
 * Function to get the Iframe snippet
 * @param environment - The parameters to use a custom environment
 * @param id - The id of the container
 */
export const getIframeSnippet = (
  id,
  environment,
  serverName = 'https://www.googletagmanager.com'
) => {
  let params = ``
  if (environment) {
    const {gtm_auth, gtm_preview} = environment
    params = `&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x`
  }
  return `<iframe src="${serverName}/ns.html?id=${id}${params}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`
}

/**
 * Function to get the GTM script
 * @param dataLayerName - The name of the dataLayer
 * @param environment - The parameters to use a custom environment
 * @param id - The id of the container
 */
export const getGTMScript = (
  dataLayerName,
  id,
  environment,
  serverName = 'https://www.googletagmanager.com'
) => {
  let params = ``
  if (environment) {
    const {gtm_auth, gtm_preview} = environment
    params = `+"&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x"`
  }
  return `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      '${serverName}/gtm.js?id='+i+dl${params};f.parentNode.insertBefore(j,f);
    })(window,document,'script','${dataLayerName}','${id}');
  `
}
