import {PHONE_REGEXP} from './constants/validation'

/**
 * Formats the given phone number to add spaces and symbols
 */
export const formatPhoneNumber = (value: string) => {
  if (!value) return value
  const phoneNumber = value.replace(/[^\d]/g, '')
  return `${phoneNumber.slice(0, 5)} ${phoneNumber.slice(5)}`
}

export const validatePhoneNumber = (value: string) => {
  return {isValid: value.replace(/\s/g, '').match(PHONE_REGEXP)}
}

/**
 * Converts the given phone number to E.164 format
 */
export const normalizePhoneNumberToE164 = (phone: string) => {
 if (!phone) return null
 const normalizedPhone = phone.replace(/\D/g, '')
 return '+44' + normalizedPhone.substring(1)
}
