import {useBasketStore} from '../../store/hooks/useStore'
import {useEffect, useRef, useState} from 'react'

export const useCarrierBag = () => {
  const basketStore = useBasketStore()
  const {isCarrierBagEditable, isCarrierBagAdded, updateCarrierBag} = basketStore
  const [includeBag, setIncludeBag] = useState(isCarrierBagAdded == null ? true : isCarrierBagAdded)
  const ref = useRef<boolean>(includeBag)

  useEffect(() => {
    if (ref.current !== includeBag) {
      updateCarrierBag(includeBag)
      ref.current = includeBag
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeBag])

  return {
    includeBag,
    setIncludeBag,
    isCarrierBagEditable,
  }
}
