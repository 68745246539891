import React, {useRef} from 'react'
import {useRect} from '@reach/rect'
import {Box, BoxProps, useDisclosure} from '../../vanilla'
import {observer} from 'mobx-react'

export interface CollapsablePanelProps extends Omit<BoxProps<React.ElementType>, 'maxHeight'> {
  defaultOpen?: boolean
  panelContent?: JSX.Element
  spacing?: number
  mHeight?: string
  children?: (disclosureProps: ReturnType<typeof useDisclosure>) => React.ReactNode
}

export const CollapsablePanel = observer(
  ({
    children,
    defaultOpen = true,
    panelContent,
    spacing = 0,
    mHeight = 'auto',
    ...props
  }: CollapsablePanelProps) => {
    const ref = useRef(null)
    const rect = useRect(ref, {observe: false})
    const disclosure = useDisclosure(defaultOpen)

    const targetHeight = typeof mHeight === 'number' ? mHeight : rect?.height || 'auto'

    return (
      <Box {...props}>
        {children?.(disclosure)}

        <div
          style={{
            overflow: 'hidden',
            maxHeight: disclosure.isOpen
              ? mHeight === 'fit'
                ? 'fit-content'
                : targetHeight !== 'auto'
                ? `${targetHeight + spacing}px`
                : targetHeight
              : 0,
            transition: 'max-height 300ms ease-out',
          }}
        >
          <div
            ref={ref}
            className="customScroll"
            style={{
              overflowY: 'auto',
              marginTop: spacing,
              maxHeight:
                mHeight === 'fit'
                  ? 'fit-content'
                  : typeof targetHeight === 'string'
                  ? targetHeight
                  : targetHeight + spacing,
            }}
          >
            <div
              style={{
                opacity: disclosure.isOpen ? 1 : 0,
                transition: 'opacity 200ms ease-out',
              }}
            >
              {panelContent}
            </div>
          </div>
        </div>
      </Box>
    )
  },
)
