import type {CommerceAPI} from 'commerce-api'
import {ShopperBaskets} from 'commerce-api'
import type {observer} from 'mobx-react-lite'
import type {RootStore} from '../../store/RootStore'
import {OmitFromKnownKeys} from '../../store/utils'
import {CARD_TYPES_CODE} from '../../utils/constants'
import {ObjectValues} from '../utils'
export interface ENVConfig {
  app: {
    configId: string
    clientId: string
    organizationId: string
    shortCode: string
    ocapiHost: string
  }
  algoliaAppId: string
  algoliaAPIKey: string
  algoliaIndexBase: string
  algoliaAnalyticsAPIKey: string
  loqateAPIKey: string
  recaptchaSiteKey: string
  googleMapsAPIkey: string
  einsteinConfiguration: {
    siteId: string
    einsteinId: string
  }
  gtmId: string
  sentryEnabled: boolean
  sentryEnv: string
  sentryDSN: string
  paypalClient: string
  appVersion: string
  alertsUrl: string
}

export interface SSRContext {
  siteId: string,
  siteCurrency: string
  customerCacheClearInterval: number
  deployTarget: string
  appHostname: string
  appOrigin: string
  query?: Record<string, any>
  path: string
  locale: string
  envConfig: ENVConfig
}

export interface IServerLocals {
  originalUrl: string
  requestClass: string
  context: SSRContext
  api?: CommerceAPI
  store?: RootStore
}

export interface IClientLocals {
  api?: CommerceAPI
  store?: RootStore
}

export interface IRestoredLocals {
  api: CommerceAPI
  store: RootStore
}

export interface IAppConfigProps {
  children: React.ReactNode
  locals: IRestoredLocals
}

export interface IGetPropsParameters {
  params: Record<string, any>
  location: Location
  store: RootStore
  api: CommerceAPI

  /** @todo - figure out how to type this, is an Express response object during SSR, but not on client */
  res: any
  req: any
}

export type TAppConfig<P, S> = {
  restore: (locals: IServerLocals | IClientLocals, initialState?: S) => void
  freeze: (locals: IRestoredLocals) => S
  extraGetPropsArgs: (locals: IRestoredLocals) => Partial<IGetPropsParameters>
} & React.FC<P>

export type TPage = {
  getTemplateName?: () => string
  shouldGetProps?: (args: {previousLocation: Location; location: Location}) => boolean
  getProps?: (args: IGetPropsParameters) => Promise<any>
}

export type TObservablePage<K extends object = {}> = TPage & ReturnType<typeof observer<K>>

export interface PaymentCard extends OmitFromKnownKeys<ShopperBaskets.PaymentCard, 'cardType'> {
  cardType?: CARD_TYPES_CODE
}

export interface PaymentInstrument
  extends Omit<ShopperBaskets.OrderPaymentInstrument, 'paymentCard'> {
  paymentCard: PaymentCard
}

export const StatusMap = {
  IDLE: 'idle',
  PENDING: 'pending',
  ERROR: 'error',
  DONE: 'done',
  INIT: 'init',
}

export type Status = ObjectValues<typeof StatusMap>
