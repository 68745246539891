import React from 'react'
import {Box, Text} from '../../vanilla'
import {Spinner} from '../spinner'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {SpinnerVariants} from '../spinner/spinner.css'

type DefinedSpinnerVariants = Exclude<SpinnerVariants, undefined>
type SpinnerSize = DefinedSpinnerVariants['size']

interface LoadingOverlayProps {
  isLoading: boolean
  showSpinner?: boolean
  spinnerSize?: SpinnerSize
  spinnerStyles?: React.CSSProperties
  containerStyles?: React.CSSProperties
  position?: Sprinkles['position']
  text?: string
}

// Puts a full width/height overlay w/ optional spinner on top of content so it cannot be clicked before loading is finished.
// Created primarily for the PLP loading state to prevent clicking of filters/sort/product tiles before loading is complete
const LoadingOverlay = ({
  isLoading = false,
  showSpinner = true,
  spinnerSize = 'md',
  spinnerStyles = {},
  containerStyles = {},
  position = 'absolute',
  text = '',
  ...props
}: LoadingOverlayProps) => {
  return (
    <Box
      display={isLoading ? 'flex' : 'none'}
      flexDirection={"column"}
      width="full"
      height="full"
      position={position}
      style={{backgroundColor: 'rgba(255, 255,255, 0.4)', ...containerStyles}}
      zIndex="docked"
      justifyContent="center"
      alignItems="center"
      top="0px"
      left="0px"
      {...props}
    >
      {showSpinner && <Spinner size={spinnerSize} color="primary" style={spinnerStyles} />}
      {text && <Text>{text}</Text>}
    </Box>
  )
}

export default LoadingOverlay
