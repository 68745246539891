import React from 'react'
import classNames from 'classnames'
import {BoxProps, Stack, Text} from '../../vanilla'
import {PaymentLoaderIcon} from '../icons'
import {loadingIcon} from './style.css'
import useLockBodyScrolling from '../../hooks/use-lock-body-scrolling'

interface PaymentProcessingOverlayProps extends BoxProps<React.ElementType> {
  className?: string
}

export const PaymentProcessingOverlay = ({className, ...props}: PaymentProcessingOverlayProps) => {
  useLockBodyScrolling(true)

  return (
    <Stack
      top="0px"
      left="0px"
      bottom="0px"
      position="absolute"
      width="full"
      height="full"
      align="center"
      justify="center"
      spacing="16px"
      bg="white"
      zIndex="sticky"
      className={classNames(className)}
      {...(props || {})}
    >
      <PaymentLoaderIcon
        style={{opacity: 0.7}}
        width="72px"
        height="72px"
        className={loadingIcon}
      />
      <Stack spacing="12px">
        <Text variant="heading5" lineHeight="short" style={{opacity: 0.7}} align="center">
          Iceland
        </Text>
        <Text variant="text3" align="center" lineHeight="shorter" style={{opacity: 0.7}}>
          Processing Payment...
        </Text>
      </Stack>
    </Stack>
  )
}
