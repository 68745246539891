import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box, Text, Button, Flex} from '../../vanilla'
import {ChevronDownIcon, ChevronUpIcon} from '../icons'
import {CTA} from '../../types/cms'
import {SlotComponent} from '../../types/ui'
import {Markdown} from './Markdown'

interface ReadMoreCmsContent {
  title: string
  bodyText?: string
  bodyTextMore?: string
  cta: CTA
}

export const ReadMore: SlotComponent<ReadMoreCmsContent> = observer(({content}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Markdown
        markdown={(content?.bodyText || '') as string}
        componentProps={{
          p: {textAlign: 'center', variant: 'text3', lineHeight: 'short'},
        }}
      />
      {isOpen && (
        <Markdown
          markdown={(content?.bodyTextMore || '') as string}
          componentProps={{
            p: {textAlign: 'center', variant: 'text3', lineHeight: 'short'},
          }}
        />
      )}
      <Button variant="unstyled" onClick={() => setIsOpen(!isOpen)}>
        <Flex flexDirection="row" alignItems="center" justifyContent="center" gap="8px">
          <Text textDecoration="underline" variant="text5" lineHeight="none">
            Read More
          </Text>
          {isOpen ? <ChevronUpIcon boxSize="12px" /> : <ChevronDownIcon boxSize="12px" />}
        </Flex>
      </Button>
    </Box>
  )
})
