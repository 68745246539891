import {useLocation} from 'react-router-dom'
import {useGTMDispatch} from '../analytics/react-gtm-hook/index'

export const useVirtualPageview = () => {
  const sendDataToGTM = useGTMDispatch()
  const sendVirtualPageviewData = (pagePath, query) => {
    const categoryTitleArray = pagePath.split('/')
    const pageTitle = categoryTitleArray[categoryTitleArray.length - 1]
    const formattedPageTitle = pageTitle
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace(/ And /g, ' & ')
    const formattedQuery = query.toLowerCase()

    setTimeout(() => {
      try {
        sendDataToGTM({
          event: 'virtual_pageview',
          ecommerce: {
            page_path: pagePath,
            page_title: formattedPageTitle,
            search_term: formattedQuery,
          },
        })
      } catch (error) {
        console.error('useVirtualPageview error within setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendVirtualPageviewData
}

export const usePageDataTracking = () => {
  const location = useLocation()
  const sendDataToGTM = useGTMDispatch()

  const sendPageData = (pageType, pageCategory, pageSubCategory) => {
    if (!pageType) return
    setTimeout(() => {
      sendDataToGTM({
        event: 'page_view',
        pagePath: location.pathname + location.search,
        pageType: pageType,
        pageCategory: pageCategory,
        pageSubCategory: pageSubCategory,
        timestamp: new Date().toISOString(),
      })
    }, 0)
  }

  return sendPageData
}
