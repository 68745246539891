import {validateNumber} from '../../utils/two-factor-utils'
import {CreateFields, TwoFactorCodeFields} from '../../types/forms'

export const useTwoFactorFields: CreateFields<TwoFactorCodeFields> = ({
  form: {
    control,
    formState: {errors},
  },
}) => {
  const commonInputProps = {
    style: {
      marginInlineStart: "3px",
      marginInlineEnd: "5px",
      height: "2.5rem",
      textAlign: "center"
    },
    pattern: "[0-9]*",
    autocomplete: "one-time-code"
  }
  const commonCodeFields = {
    type: 'number' as const,
    rules: {
      required: true,
      validate: (value: string) => validateNumber(value),
    },
    control,
  }

  const fields = {
    code_1: {
      name: "code_1",
      inputProps: {
        id: 'code_1',
        ...commonInputProps
      },
      error: errors.code_1,
      ...commonCodeFields
    },
    code_2: {
      name: "code_2",
      inputProps: {
        id: 'code_2',
        ...commonInputProps
      },
      error: errors.code_2,
      ...commonCodeFields
    },
    code_3: {
      name: "code_3",
      inputProps: {
        id: 'code_3',
        ...commonInputProps
      },
      error: errors.code_3,
      ...commonCodeFields
    },
    code_4: {
      name: "code_4",
      inputProps: {
        id: 'code_4',
        ...commonInputProps
      },
      error: errors.code_4,
      ...commonCodeFields
    },
    code_5: {
      name: "code_5",
      inputProps: {
        id: 'code_5',
        ...commonInputProps
      },
      error: errors.code_5,
      ...commonCodeFields
    },
    code_6: {
      name: "code_6",
      inputProps: {
        id: 'code_6',
        ...commonInputProps
      },
      error: errors.code_6,
      ...commonCodeFields
    },
  }

  return fields
}
