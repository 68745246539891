import React from 'react'
import {observer} from 'mobx-react-lite'
import {Stack, Text} from '../../vanilla'
import ReactMarkdown from 'react-markdown'

interface ProductInforVizProps {
  content: {
    copy: string
    productInformation: {
      title: string
    }
  }
}

export const ProductInformationVizualization = observer(({content}: ProductInforVizProps) => {
  if (!content) {
    return null
  }

  return (
    <Stack padding="20px" spacing="16px">
      <Text variant="text6">{content.productInformation?.title}</Text>
      <ReactMarkdown
        components={{
          p: ({...props}) => {
            return (
              <Text variant="text1" lineHeight="short">
                {props?.children}
              </Text>
            )
          },
        }}
      >
        {content?.copy || ''}
      </ReactMarkdown>
    </Stack>
  )
})
