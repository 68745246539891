import * as React from 'react'
import {Box, PolymorphicComponentProps} from './Box'
import {buttonRecipe, buttonInvariant, ButtonVariants} from './button.css'
import {Spinner} from '../../components/spinner'
import classNames from 'classnames'

type StyleProps = object & ButtonVariants

type UVariant<T = StyleProps> = T extends ButtonVariants ? Omit<T, 'variant'> : never

type ButtonOwnProps = UVariant & {
  disabled?: boolean
  variant?: 'unstyled' | StyleProps['variant']
  isLoading?: boolean
  extraClassName?: string
}

export type BaseButtonProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  ButtonOwnProps
>

const defaultElement = 'button'

export const BaseButton: <E extends React.ElementType = typeof defaultElement>(
  props: BaseButtonProps<E>,
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      size,
      variant,
      disabled,
      isLoading,
      children,
      extraClassName,
      ...restProps
    }: BaseButtonProps<E>,
    ref: typeof restProps.ref,
  ) => {
    // Use `variant='unstyled'` to bypass recipe. This allows for full customization
    // via style props. Note that the `unstyled` variant is not part of the recipe and
    // is only used here as a flag to bypass the recipe.
    const noVariant = variant === 'unstyled'
    const className = noVariant
      ? buttonInvariant
      : buttonRecipe({size, variant, disabled: isLoading || disabled})

    return (
      <Box
        className={classNames(className, extraClassName)}
        as={defaultElement}
        type={restProps.as === 'button' ? 'button' : undefined}
        disabled={isLoading || disabled}
        display="inline-flex"
        position="relative"
        alignItems="center"
        justifyContent="center"
        height="44px"
        paddingX="12px"
        bg="transparent"
        border="none"
        opacity={disabled ? '0.5' : '1'}
        ref={ref}
        {...restProps}
      >
        {isLoading ? <Spinner /> : children}
      </Box>
    )
  },
)
