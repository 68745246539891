import {SetStateAction, useEffect} from 'react'
import queryString from 'query-string'

export const ADD_ALL_TO_CART_LINK_HREF_PATTERN = 'basket/addalltobasket'

export const useListenToAddAllToBasketLinks = (
  {
    update,
    openModal
  }: {
    update: React.Dispatch<SetStateAction<queryString.ParsedQuery<string>>>
    openModal: () => void
  }) => {
  useEffect(() => {
    const linksList = document.querySelectorAll<HTMLAnchorElement>(`[href*="${ADD_ALL_TO_CART_LINK_HREF_PATTERN}"]`)

    const listener = (event: MouseEvent) => {
      const newParams = queryString.parse(location.search, {arrayFormat: 'comma'})
      event.preventDefault()
      update(newParams)
      history.pushState({}, '', (event.target as HTMLAnchorElement).href)
      openModal()
    }

    linksList.forEach((link) => {
      link.addEventListener('click', listener)
    })

    return () => {
      linksList.forEach((link) => {
        link.removeEventListener('click', listener)
      })
    }

  }, [update, openModal])
}
