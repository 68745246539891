import React, {useEffect, useState, createContext, useContext} from 'react'
import {Box, Button, Container, Flex, Input, Select, Stack, Text} from '../../vanilla'

import {CheckCircleIcon, ExclamationTriangleIcon} from '../../components/icons'

export const CMSContext = createContext()
export const CMSProvider = CMSContext.Provider
export const useCMS = () => {
  return useContext(CMSContext)
}

const CardHeading = ({children}) => {
  return (
    <>
      <Box padding="16px">
        <Text fontWeight="normal" fontSize="lg">
          {children}
        </Text>
      </Box>
      <hr />
    </>
  )
}

const Card = ({children}) => {
  return (
    <Box h="full" bg="white">
      {children}
    </Box>
  )
}

const CacheInvalidation = () => {
  const cms = useCMS()

  const [chosenTarget, setChosenTarget] = useState()
  const [chosenPath, setChosenPath] = useState('')
  const [status, setStatus] = useState('idle')

  const params = cms?.params?.installation || {}
  const targets = params.mrtTargets

  useEffect(() => {
    setStatus('idle')
  }, [chosenPath, chosenTarget])

  const createInvalidation = async () => {
    setStatus('loading')

    try {

      const result = await fetch(`/api/mrt/target/${chosenTarget}/invalidation`, {
        method: 'post',
        body: JSON.stringify({pattern: chosenPath}),
      }).then((r) => r.json())

      if (result?.result) {
        setStatus('success')
        setTimeout(() => {
          setStatus('idle')
        }, 5000)
      } else {
        setStatus('error')
      }
    } catch (ex) { 
      console.error(ex)
      setStatus('error')
    }
  }

  useEffect(() => {
    setChosenTarget(targets?.[0])
  }, [targets])

  return (
    <Card>
      <CardHeading>SSR Cache Invalidation</CardHeading>

      <Box padding="20px">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            createInvalidation()
          }}
        >
          <Stack gap="8px">
            <Text fontSize="sm">
              Use this to invalidate the server-side-rendered (SSR) cache of a specific URL path or
              pattern. Only trailing * will work. For example, "/iceland-discovery/*" to clear all
              the pages in Iceland discovery.
            </Text>

            <Stack>
              <Select
                placeholder="Select project target"
                required
                onChange={(e) => setChosenTarget(e.target.value)}
              >
                {targets?.map((target) => (
                  <option key={target} value={target}>
                    {target}
                  </option>
                ))}
              </Select>

              <Input
                type="text"
                placeholder="Enter URL path to invalidate (eg. /best-sellers)"
                value={chosenPath}
                onChange={(e) => setChosenPath(e.target.value)}
                pattern="/.*"
                required
              />
            </Stack>
            <Flex align="center" gap="8px">
              <Button type="submit" isLoading={status === 'loading'}>
                Create Invalidation
              </Button>

              <Flex align="center" gap="2px">
                {status === 'success' && (
                  <>
                    <CheckCircleIcon color="green" />
                    <Text fontSize="xs" color="gray600">
                      Invalidation is in progress.
                    </Text>
                  </>
                )}
                {status === 'error' && (
                  <>
                    <ExclamationTriangleIcon color="error" />
                    <Text fontSize="xs" color="error">
                      Something went wrong. Try again or contact support.
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Stack>
        </form>
      </Box>
    </Card>
  )
}

const MenuRoutingInvalidation = () => {
  const cms = useCMS()
  const [chosenTarget, setChosenTarget] = useState()
  const [status, setStatus] = useState('idle')

  const params = cms?.params?.installation || {}
  const targets = params.mrtTargets
  const paths = params.menuRoutePaths

  useEffect(() => {
    setStatus('idle')
  }, [chosenTarget])

  const createInvalidation = async () => {
    setStatus('loading')

    await Promise.all(
      paths.map((path) =>
        fetch(`/api/mrt/target/${chosenTarget}/invalidation`, {
          method: 'post',
          body: JSON.stringify({pattern: path}),
        }).then((r) => r.json()),
      ),
    )

    setStatus('success')
    setTimeout(() => {
      setStatus('idle')
    }, 5000)
  }

  useEffect(() => {
    setChosenTarget(targets?.[0])
  }, [targets])

  return (
    <Card>
      <CardHeading>Menu / Routing Cache Invalidation</CardHeading>

      <Box padding="20px">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            createInvalidation()
          }}
        >
          <Stack gap="8px">
            <Text fontSize="sm">
              For performance reasons we cache the menu and routes as a file in MRT to be used by
              the site in the server-side-rendering of pages. Use this to invalidate the cached menu
              and route file.
            </Text>

            <Select
              placeholder="Select project target"
              required
              onChange={(e) => setChosenTarget(e.target.value)}
            >
              {targets?.map((target) => (
                <option key={target} value={target}>
                  {target}
                </option>
              ))}
            </Select>

            <Flex align="center" gap="4px">
              <Button colorScheme="blue" type="submit" isLoading={status === 'loading'}>
                Create Invalidation
              </Button>

              <Flex align="center" gap="2px">
                {status === 'success' && (
                  <>
                    <CheckCircleIcon color="green" />
                    <Text fontSize="xs" color="gray600">
                      Invalidation is in progress.
                    </Text>
                  </>
                )}
                {status === 'error' && (
                  <>
                    <ExclamationTriangleIcon color="error" />
                    <Text fontSize="xs" color="error">
                      Something went wrong. Try again or contact support.
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Stack>
        </form>
      </Box>
    </Card>
  )
}

const Faq = () => {
  return (
    <Card>
      <CardHeading>Frequently Asked Questions</CardHeading>

      <Box padding="20px">
        <Stack>
          <Text fontSize="sm">What is MRT and why would I use this?</Text>
          <Text fontSize="sm">
            Managed runtime is the front-end hosting provided by Salesforce...
          </Text>
        </Stack>
      </Box>
    </Card>
  )
}

export default function MRTDashboard() {
  const [cms, setCMS] = useState()
  let cmsError = ''

  useEffect(() => {
    ;(async () => {
      try {
        const {init} = await import('dc-extensions-sdk')
        const cms = await init()
        cms.frame?.startAutoResizer()
        setCMS(cms)
      } catch (error) {
        // Handle errors
        cmsError = 'SOMETHING WENT WRONG'
        console.error('Error:', error)
      }
    })()
  }, [])

  if (!cms) {
    return <Box>{cmsError}</Box>
  }

  return (
    <CMSProvider value={cms}>
      <Box bg="gray900" height="full">
        <Container maxWidth="full" paddingY="40px" paddingX="32px">
          <Box templateColumns="repeat(2, 1fr)" gap="8px">
            <Box>
              <CacheInvalidation />
            </Box>

            <Box>
              <MenuRoutingInvalidation />
            </Box>

            <Box colSpan={2}>
              <Faq />
            </Box>
          </Box>
        </Container>
      </Box>
    </CMSProvider>
  )
}
