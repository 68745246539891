import React from "react";
import QuantityInput from "../quantity-input";
import { ProductItem } from "../../store/BasketStore";
import { BoxProps } from "../../vanilla";
import { Nullable } from "../../types/utils";
import { useAlcoholRestrictions } from "../../hooks/use-alcohol-restrictions";
import { Hit } from "../../store/ProductStore";

export interface RestrictionModal {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }
export interface cartQuantityProps extends Omit<BoxProps<'div'>, 'onChange' | 'value'> {
    product?: ProductItem
    quantity: number
    setQuantity: React.Dispatch<React.SetStateAction<number>>
    size?: 'md' | 'sm'
    maxQuantity?: number
    variant?: 'primary' | 'outlineDark'
    disabled?: boolean
    renderButton?: JSX.Element
    cartLocation?: string
    viewingCategoryId?: Nullable<string> 
    searchQuery?: Nullable<string>
    hitItemIndex?: number
    restrictionModal?: RestrictionModal
  }

const UpdateCartItemQuantity = (props: cartQuantityProps) => {
    const {quantity, setQuantity, product, disabled, maxQuantity, renderButton, cartLocation, viewingCategoryId, searchQuery, hitItemIndex, restrictionModal, ...rest} = props
    const {checkIfProductAlcohol, isCurrentSlotAlcoholRestricted} = useAlcoholRestrictions()
    const isRestricted = checkIfProductAlcohol(product as Hit) && isCurrentSlotAlcoholRestricted
    return (
        <>
        {quantity || 0 > 0 ?
            <QuantityInput
                product={product}
                disabled={disabled}
                quantity={quantity}
                setQuantity={setQuantity}
                maxQuantity={maxQuantity}
                cartLocation = {cartLocation}
                viewingCategoryId={viewingCategoryId}
                searchQuery={searchQuery}
                hitItemIndex={hitItemIndex}
                restrictionModal={restrictionModal}
                {...rest}
            />   
            : renderButton && !isRestricted && renderButton
        }
        </>
    )
}

export default UpdateCartItemQuantity