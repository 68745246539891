import {useBasketStore} from '../../store/hooks/useStore'
import {useEffect, useState} from 'react'

export const useMealDealsSelectionCount = (productsIds: string[]) => {
  const basketStore = useBasketStore()
  const [selectionCount, setSelectionCount] = useState(0)

  useEffect(() => {
    let count = 0
    if (!basketStore?.basket?.productItems) return
    for (const basketItem of basketStore.basket.productItems) {
      for (const id of productsIds) {
        if (basketItem.productId === id) {
          count += basketItem.quantity ?? 0
        }
      }
    }
    setSelectionCount(count)
  }, [basketStore?.basket?.productItems, productsIds])

  return selectionCount
}
