import { makeObservable, observable, action, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import { PushMessage } from '../components/push-alerts/types';

const twoMinutes = 120000; //ms
const fiveMinutes = 300000; //ms

const isMoreThanFiveMinutesAgo = (date: Date): boolean => {
    const now = new Date();
    const fiveMinutesAgo = new Date(now.getTime() - fiveMinutes);
    return date < fiveMinutesAgo;
};

export class AlertsStore {
    alerts: Record<string,PushMessage> = {}
    private ws: WebSocket | undefined
    private keepAliveIntervalId: number | null | undefined
    private lastActive: Date | undefined
    private rootStore: RootStore;

    constructor(rootStore: RootStore) {
        makeObservable(this, {
            alerts: observable,
            ackAlert: action,
        })
        this.rootStore = rootStore
        if (rootStore.globalStore.alertsUrl) {
            this.ws = new WebSocket(rootStore.globalStore.alertsUrl)
            this.keepAliveIntervalId = null
            this.lastActive = new Date();
            this.bindEvents()
        }
    }
    /**
     * Add event listeners to the WebSocket connection.
     * @param {boolean} afterDisconnect Change the onOpen event handler if the connection
     *  needs to be re-established after a disconnect.
     */
    bindEvents(afterDisconnect = false) {
        if (this.ws) {
            if (afterDisconnect) {
                this.ws.onopen = this.onOpenAfterDisconnect
            } else {
                this.ws.onopen = this.onOpen
            }

            this.ws.onmessage = this.onMessage

            this.ws.onerror = this.onError

            this.ws.onclose = this.onClose
        }
    }
    /**
     * Create a poll to check for new messages every 2 minutes.
     * If there was no activity within the last 5 minutes, close the connection.
     */
    pollForMessages = () => {
        this.keepAliveIntervalId = window.setInterval(() => {
            if (this.lastActive && !isMoreThanFiveMinutesAgo(this.lastActive)) {
                if (this.ws?.readyState === WebSocket.OPEN) {
                    this.getNewAlerts()
                } else if (this.ws?.readyState === WebSocket.CLOSED) {
                    // reopen
                    this.reopenConnection()
                }
            } else {
                // 3001 is a custom code which will mean inactivity.
                // Custom codes must be in the 3000-3999 range by IANA.
                this.ws?.close(3001, 'No activity for 5 minutes')
            }

        }, twoMinutes)
    }
    /**
     * Send the API a list of all the messages that have been displayed.
     * to prevent them being resent on reconnect.
     * @param {Event} event The event object from the WebSocket connection.
     */
    onOpenAfterDisconnect = (event: Event) => {
        if (this.keepAliveIntervalId) {
            window.clearInterval(this.keepAliveIntervalId)
        }
        const previousMessages = Object.values(this.alerts).map(alert => alert.messageId).join(',')
        this.ws?.send(`👋 ${previousMessages}`)
        this.getNewAlerts()
    }
    /**
     * Get messages then start polling for new messages.
     * @param {Event} event - The event object from the WebSocket connection.
     */
    onOpen = (event: Event) => {
        this.getNewAlerts()
        this.pollForMessages()
    }
    /**
     * Parse the message and add it to the alerts array.
     * @param {MessageEvent} event - The event object from the WebSocket connection.
     */
    onMessage = (event: MessageEvent) => {
        if (event.data.startsWith('📢')) {
            try {
                const alert = JSON.parse(event.data.slice(2)) as PushMessage
                runInAction(() => {
                    this.alerts[alert.messageId] = alert
                })
                this.ackAlert(alert.messageId)
            } catch (error) {
                console.error('Error parsing alert message', error)
            }
        }
    }
    /**
     * Close the connection and clear the poll interval.
     */
    onClose = () => {
        if (this.keepAliveIntervalId !== null) {
            window.clearInterval(this.keepAliveIntervalId)
            this.keepAliveIntervalId = null;
        }
        console.log('WebSocket connection closed')
    }
    /**
     * Log any errors that occur on the WebSocket connection.
     * @param {Event} error - The event object from the WebSocket connection.
     */
    onError = (error: Event) => {
        console.error('WebSocket error:', error)
    }
    /**
     * Send the API the ID of a message which was understood.
     * @param {string} messageId - The id of the message to acknowledge.
     */
    ackAlert(messageId: string) {
        if (this.ws?.readyState === WebSocket.OPEN) {
            this.ws.send(`🤝 ${messageId}`)
        } else {
            console.error('WebSocket is not open. Cannot acknowledge alert.')
        }
    }
    /**
     * Reopen the WebSocket connection if it was closed.
     */
    reopenConnection() {
        if (this.ws?.readyState === WebSocket.CLOSED && this.rootStore.globalStore.alertsUrl) {
            this.ws = new WebSocket(this.rootStore.globalStore.alertsUrl)
            this.bindEvents(true)
        }
    }
    /**
     * Send the API a request for new messages. Keeps the connection alive
     */
    getNewAlerts() {
        this.ws?.send('👉')
    }
    /**
     * Set the last active date to now and reopen the connection.
     * This is done within the component using a mousedown / touch event.
     */
    set lastActiveDate(date: Date) {
        this.lastActive = date;
        this.reopenConnection()
    }

}