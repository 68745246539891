import React, {FocusEventHandler, forwardRef, useState} from 'react'
import {ChevronDownIcon} from '../../components/icons'
import {Box, PolymorphicComponentProps} from './Box'
import {Flex} from './Flex'
import {selectRecipe, SelectVariants, selectLabelRecipe} from './select.css'
import {Text} from './Text'

type StyleProps = object & SelectVariants

type UVariant<T = StyleProps> = T extends SelectVariants ? Omit<T, 'variant' | 'onChange'> : never

type SelectOwnProps = UVariant & {
  size?: string
  disabled?: boolean
  variant?: 'unstyled' | StyleProps['variant']
  hasError?: boolean
  children?: React.ReactNode
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>
  label?: string
  validatedSuccessfully: boolean
}

export type SelectProps<E extends React.ElementType> = PolymorphicComponentProps<E, SelectOwnProps>

const defaultElement = 'select'

export const Select: <E extends React.ElementType = typeof defaultElement>(
  props: SelectProps<E>,
) => React.ReactElement | null = forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      value,
      focusVisible = false,
      hasError,
      validatedSuccessfully,
      colorScheme,
      label,
      children,
      onChange,
      onBlur,
      showAsterisk,
      id,
      ...props
    }: SelectProps<E>,
    ref: typeof props.ref,
  ) => {
    const className = selectRecipe({
      variant,
      size,
      error: hasError,
      colorScheme,
      focusVisible,
      validatedSuccessfully,
    })
    const [isFocused, setIsFocused] = useState(false)

    const emptyValueSelected = (value?: string) => {
      if (value !== '') return false

      return (
        Array.isArray(children) && Array.from(children).some((child) => child?.props?.value === '')
      )
    }

    const handleBlur = (
      event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>,
    ) => {
      setIsFocused(false)
      if (onBlur) {
        onBlur(event)
      }
    }

    const isFilled = value != null || emptyValueSelected() || isFocused

    const classNameLabel = selectLabelRecipe({
      variant,
      colorScheme,
      size,
      isFilled,
      focusVisible,
      error: hasError,
      validatedSuccessfully,
    })

    return (
      <Flex
        align="center"
        position="relative"
        {...(props.backgroundColor && {backgroundColor: props.backgroundColor})}
      >
        <Box
          as="select"
          id={id}
          className={className}
          value={value}
          fontFamily="body"
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={onChange}
          {...props}
          ref={ref}
        >
          {children}
        </Box>
        <Box position="absolute" style={{zIndex: 0, right: 6, pointerEvents: 'none'}}>
          <ChevronDownIcon boxSize="12px" />
        </Box>
        {label && (
          <Box as="label" htmlFor={id} aria-label={label} className={classNameLabel}>
            <Text
              variant="unstyled"
              as="span"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              fontWeight="inherit"
              fontSize="inherit"
              lineHeight="inherit"
              style={{height: 'inherit'}}
              paddingX={isFilled ? '4px' : '0px'}
              backgroundColor={isFilled ? 'white' : 'transparent'}
            >
              {label}
              {showAsterisk && (
                <Text
                  as="span"
                  display="inline-block"
                  marginLeft={isFilled ? '4px' : '0px'}
                  fontSize="inherit"
                  lineHeight="inherit"
                  fontWeight="inherit"
                  color="error"
                  marginBottom="2px"
                >
                  *
                </Text>
              )}
            </Text>
          </Box>
        )}
      </Flex>
    )
  },
)
