import React, {useState} from 'react'
import {textareaRecipe, textareaLabelRecipe, TextareaVariants} from './textarea.css'
import {InputProps} from './Input'
import {Box, PolymorphicComponentProps} from './Box'
import {Text} from './Text'

type StyleProps = object & TextareaVariants

type UVariant<T = StyleProps> = T extends TextareaVariants
  ? Omit<T, 'variant' | 'label' | 'onBlur'>
  : never

const defaultElement = 'textarea'

type TextareaOwnProps = UVariant & {
  disabled?: boolean
  variant?: 'unstyled' | StyleProps['variant']
  hasError?: boolean
  children?: React.ReactNode
  label?: string
  validatedSuccessfully: boolean
  maxLength?: number
  withCharsCounter?: boolean
}

export type TextareaProps<E extends React.ElementType> = PolymorphicComponentProps<
  E,
  TextareaOwnProps
>
export const Textarea: <E extends React.ElementType = typeof defaultElement>(
  props: TextareaProps<E>,
) => React.ReactElement | null = React.forwardRef(
  <E extends React.ElementType = typeof defaultElement>(
    {
      variant,
      size,
      colorScheme,
      width = 'full',
      focusVisible = false,
      hasError,
      children,
      label,
      icon = null,
      value,
      validatedSuccessfully,
      name,
      className,
      maxLength,
      withCharsCounter,
      onChange,
      onBlur,
      showAsterisk,
      id,
      ...restProps
    }: InputProps<E>,
    ref: typeof restProps.ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false)
    const isFilled = !!value || isFocused

    const classNameTextarea = textareaRecipe({
      variant,
      colorScheme,
      size,
      focusVisible,
      error: hasError,
    })

    const classNameLabel = textareaLabelRecipe({
      variant,
      colorScheme,
      size,
      isFilled,
      focusVisible,
      error: hasError,
      validatedSuccessfully,
    })

    return (
      <Box position="relative" width={width} display="flex" flexDirection="column">
        <Box
          as="textarea"
          id={id}
          display="block"
          name={name}
          className={classNameTextarea}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            onBlur()
          }}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            if (!maxLength) {
              onChange(e)
            } else {
              const charsLength = e.currentTarget.value.length
              if (charsLength <= maxLength) {
                onChange(e)
              }
            }
          }}
          {...restProps}
          ref={ref}
        />
        {label && (
          <Box as="label" htmlFor={id} aria-label={label} className={classNameLabel}>
            <Text
              variant="unstyled"
              as="span"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              fontWeight="inherit"
              fontSize="inherit"
              lineHeight="inherit"
              style={{height: 'inherit'}}
              paddingX={isFilled ? '4px' : '0px'}
              backgroundColor={isFilled ? 'white' : 'transparent'}
            >
              {label}
              {showAsterisk && (
                <Text
                  as="span"
                  display="inline-block"
                  marginLeft={isFilled ? '4px' : '0px'}
                  fontSize="inherit"
                  lineHeight="inherit"
                  fontWeight="inherit"
                  color="error"
                  marginBottom="2px"
                >
                  *
                </Text>
              )}
            </Text>
          </Box>
        )}
        {withCharsCounter && maxLength && (
          <Text variant="text3" lineHeight="shorter" color="gray800" marginTop="4px">
            You have {maxLength - (value.length || 0)} characters left out of {maxLength}
          </Text>
        )}
      </Box>
    )
  },
)
