import {useState} from 'react'
import {useCommerceAPI} from './useCommerceAPI'
import {
  CommerceAPI,
  Recommendations,
  UUIDResponse,
  ViewProductParams,
  ViewRecommendationsParams,
  ClickRecommendationsParams,
  AddToCartParams,
  ViewSearchParams,
  ClickSearchParams,
  RecommendationsParams,
  RecommendersResponse,
  BeginCheckoutParams,
  ClickCategoryProductParams,
  ViewCategoryParams,
} from 'commerce-api'
import {useProductStore} from './useStore'
import {Nullable} from '../../types/utils'

export interface EinsteinState {
  loading: boolean
  recommendations: Recommendations['recs']
  recoUUID: Nullable<string>
}

export interface EinsteinService extends EinsteinState {
  api: CommerceAPI['einstein']
  sendViewProduct: (params: ViewProductParams) => Promise<UUIDResponse>
  sendViewSearch: (params: ViewSearchParams) => Promise<UUIDResponse>
  sendViewRecommendations: (params: ViewRecommendationsParams) => Promise<UUIDResponse>
  sendClickRecommendations: (params: ClickRecommendationsParams) => Promise<UUIDResponse>
  sendClickSearch: (params: ClickSearchParams) => Promise<UUIDResponse>
  sendAddToCart: (params: AddToCartParams) => Promise<UUIDResponse>
  sendBeginCheckout: (params: BeginCheckoutParams) => Promise<UUIDResponse>
  sendClickCategoryProduct: (params: ClickCategoryProductParams) => Promise<UUIDResponse>
  sendViewCategory: (params: ViewCategoryParams) => Promise<UUIDResponse>
  getRecommenders: () => Promise<RecommendersResponse>
  getRecommendations: (name: string, params: RecommendationsParams) => Promise<void>
  getZoneRecommendations?: (name: string, params: RecommendationsParams) => Promise<void>
}

const useEinstein = (): EinsteinService => {
  const api = useCommerceAPI()
  const {fetchProducts} = useProductStore()
  const [state, setState] = useState<EinsteinState>({
    loading: false,
    recommendations: [],
    recoUUID: null,
  })

  return {
    ...state,
    api: api.einstein,

    async sendViewProduct(params) {
      return api?.einstein?.sendViewProduct(params)
    },
    async sendViewSearch(params) {
      return api?.einstein?.sendViewSearch(params)
    },
    async sendClickSearch(params) {
      return api?.einstein?.sendClickSearch(params)
    },
    async sendViewCategory(params) {
      return api?.einstein?.sendViewCategory(params)
    },
    async sendClickCategoryProduct(params) {
      return api?.einstein?.sendClickCategoryProduct(params)
    },
    async sendViewRecommendations(params) {
      return api.einstein?.sendViewRecommendations(params)
    },
    async sendClickRecommendations(params) {
      return api.einstein?.sendClickRecommendations(params)
    },
    async sendAddToCart(params) {
      return api.einstein?.sendAddToCart(params)
    },
    async sendBeginCheckout(params) {
      return api.einstein.sendBeginCheckout(params)
    },
    async getRecommenders() {
      return api.einstein?.getRecommenders()
    },
    async getRecommendations(name, params) {
      setState((s) => ({...s, loading: true}))

      const recommendations = await api.einstein.getRecommendations(name, params)

      const ids = recommendations.recs?.map((rec) => rec.id)
      if (ids?.length > 0) {
        await fetchProducts(ids, false)
      }
      setState({
        loading: false,
        recommendations: recommendations.recs,
        recoUUID: recommendations.recoUUID,
      })
    },
    async getZoneRecommendations(name, params) {
      setState((s) => ({...s, loading: true}))
      const recommendations = await api.einstein.getZoneRecommendations(name, params)
      const ids = recommendations.recs?.map((rec) => rec.id)
      if (ids?.length > 0) {
        fetchProducts(ids, false)
      }
      setState({
        loading: false,
        recommendations: recommendations.recs,
        recoUUID: recommendations.recoUUID,
      })
    },
  }
}

export default useEinstein
