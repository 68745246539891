import React, {CSSProperties, ReactNode} from 'react'
import {Box, BoxProps} from '../../vanilla'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {progressBar} from './style.css'
import {Icon} from '../icons'

interface ProgressBarProps extends BoxProps<'div'> {
  color: Sprinkles['color']
  reachedColor: Sprinkles['color']
  progressValue: number
  reachedContent?: ReactNode
  icon?: Icon
  onClick?: () => void
  style?: CSSProperties
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    color,
    reachedColor,
    progressValue,
    reachedContent,
    icon: IconComponent,
    onClick = () => null,
    style,
    ...restBoxProps
  } = props

  const value = progressValue > 100 ? 100 : progressValue
  const reachedMax = value === 100

  return (
    <Box
      width="full"
      borderRadius="medium"
      bg="gray50"
      borderBottom="1px"
      borderColor="gray200"
      onClick={onClick}
      style={{borderRadius: 10, ...(style || {})}}
      {...(restBoxProps || {})}
    >
      <Box
        position="relative"
        alignItems="center"
        display="flex"
        borderRadius="medium"
        height="20px"
        className={progressBar}
        bg={reachedMax ? reachedColor : color}
        style={{
          width: value + '%',
          borderRadius: 'inherit',
        }}
      >
        {reachedMax && reachedContent ? reachedContent : null}
        {IconComponent ? (
          <IconComponent
            position="absolute"
            style={{right: 5, top: 2, height: '1em'}}
            color="white"
          />
        ) : null}
      </Box>
    </Box>
  )
}
