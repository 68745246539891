import React from 'react'
import {observer} from 'mobx-react'
import {Flex, Text} from '../../../../../vanilla'
import {AmplienceHideIcon, AmpliencePuzzleIcon, AmplienceViewIcon} from '../../../../icons'
import {PanelOption} from '../../panel-option'
import {usePreviewDebugStore} from '../../../../../store/hooks/useStore'

export const XRayView = observer(() => {
  const {setShowContent, showContent, showSlots, setShowSlots} = usePreviewDebugStore()

  const getSelectedOptionText = () => {
    if (showContent && showSlots) return 'Slots & Content View'
    if (showSlots) return 'Slots View'
    if (showContent) return 'Content View'
  }

  return (
    <PanelOption
      title="X-Ray View"
      Icon={<AmpliencePuzzleIcon boxSize="24px" color="previewPrimary" />}
      selectedOption={getSelectedOptionText()}
    >
      <Flex
        direction="row"
        align="center"
        flexWrap="wrap"
        color="white"
        borderTop="1px"
        borderColor="gray200"
        height="60px"
        cursor="pointer"
        onClick={() => {
          setShowSlots(!showSlots)
        }}
      >
        <Text
          as="span"
          color={showSlots ? 'previewPrimary' : 'gray400'}
          marginLeft="76px"
          variant="text3"
          style={{width: 180}}
        >
          View Slots
        </Text>
        {showSlots ? (
          <AmplienceViewIcon color="previewPrimary" boxSize="24px" />
        ) : (
          <AmplienceHideIcon boxSize="24px" color="gray400" />
        )}
      </Flex>
      <Flex
        direction="row"
        align="center"
        flexWrap="wrap"
        color="white"
        borderTop="1px"
        borderColor="gray200"
        height="60px"
        cursor="pointer"
        onClick={() => {
          setShowContent(!showContent)
        }}
      >
        <Text
          as="span"
          color={showContent ? 'previewPrimary' : 'gray400'}
          marginLeft="76px"
          variant="text3"
          style={{width: 180}}
        >
          View Content
        </Text>
        {showContent ? (
          <AmplienceViewIcon color="previewPrimary" boxSize="24px" />
        ) : (
          <AmplienceHideIcon boxSize="24px" color="gray400" />
        )}
      </Flex>
    </PanelOption>
  )
})
