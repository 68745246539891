import {useGTMDispatch} from './react-gtm-hook/index'
import {
  transformAlgoliaItemsForGA4,
  transformSalesforceBasketItemsForGA4,
  transformAlgoliaItemsForBasketEventsGA4
} from './utils'

export const useSelectItemTracking = () => {
  const sendDataToGTM = useGTMDispatch()
  const sendSelectItem = (item, category) => {
    const _items = transformAlgoliaItemsForGA4([item], category)
    sendDataToGTM({
      event: 'select_item',
      ecommerce: {
        items: _items,
        item_list_name: category?.name, // Work this out
        item_list_id: category?.id, // Work this out
        currency: 'GBP',
      },
    })
  }

  return sendSelectItem
}

export const useAddToCartTracking = (basket) => {
  const sendDataToGTM = useGTMDispatch()
  const sendAddToCartData = (product, cartLocation, viewingCategoryId, searchQuery, hitItemIndex) => {

    setTimeout(() => {
      try {
        const _items = transformAlgoliaItemsForBasketEventsGA4([product], cartLocation, viewingCategoryId, searchQuery, hitItemIndex)
        const eventData = {
          event: 'add_to_cart',
          ecommerce: {
            currency: 'GBP',
            value: _items[0].price * _items[0].quantity,
            cart_location: cartLocation,
            coupon: basket?.couponItems?.[0]?.code ?? '',
            items: _items
          },
        }
        sendDataToGTM(eventData)
      } catch (error) {
        console.error('useAddToCartTracking error within setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendAddToCartData
}

export const useRemoveFromCartTracking = () => {
  const sendDataToGTM = useGTMDispatch()
  const sendRemoveFromCartData = (product, cartLocation, viewingCategoryId, searchQuery, hitItemIndex) => {
    setTimeout(() => {
      try {
        const _items = transformAlgoliaItemsForBasketEventsGA4([product], cartLocation, viewingCategoryId, searchQuery, hitItemIndex)
        const eventData = {
          event: 'remove_from_cart',
          ecommerce: {
            currency: 'GBP',
            value: _items[0].price * _items[0].quantity,
            cart_location: cartLocation,
            items: _items
          },
        }
        sendDataToGTM(eventData)
      } catch (error) {
        console.error('useRemoveFromCartTracking error within setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendRemoveFromCartData
}

export const useBeginCheckoutTracking = (productsById) => {
  const sendDataToGTM = useGTMDispatch()
  const sendBeginCheckout = (basket, items) => {
    if (!basket || !items) return
    setTimeout(() => {
      try {
        const itemsData = transformSalesforceBasketItemsForGA4(items, productsById)
        const eventData = {
          event: 'begin_checkout',
          ecommerce: {
            transaction_id: basket.basketId,
            currency: 'GBP',
            value: basket.orderTotal,
            coupon: basket.couponItems?.[0]?.code ?? '',
            items: itemsData,
          },
        }
        if (Object.keys(eventData.ecommerce.items).length > 0) {
          sendDataToGTM(eventData)
        }
      } catch (error) {
        console.error('useBeginCheckoutTracking error within the setTimeout: ' + error.message)
      }
    }, 0)
  }
  return sendBeginCheckout
}
