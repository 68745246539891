import React from 'react'
import {ButtonProps} from './Button'
import {CTA} from '../../types/cms'
import {Link} from '../../components/link'
import {BaseButton} from './BaseButton'
import {Text} from './Text'
import {vars} from '../vars.css'
import {Sprinkles} from '../sprinkles.css'

interface CTAButtonProps extends ButtonProps {
  cta: CTA
}

export const CTAButton = ({cta, style, ...rest}: CTAButtonProps) => {
  const buttonColor = {
    backgroundColor: cta?.ctaButtonColor?.color || vars.color.accent1,
    color: cta?.ctaTextColor?.color || '#ffffff',
  }

  return (
    <BaseButton
      size="sm"
      paddingX="20px"
      style={style ? {...style, ...buttonColor} : buttonColor}
      {...(cta.ctaLink ? {to: cta.ctaLink, as: Link} : {})}
      {...rest}
    >
      <Text
        as="span"
        variant="unstyled"
        fontSize="inherit"
        fontWeight="inherit"
        lineHeight="inherit"
        textAlign={style?.textAlign as Sprinkles['textAlign'] || "inherit"}
      >
        {cta?.ctaText}
      </Text>
    </BaseButton>
  )
}
