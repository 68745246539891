import {useCustomerStore, useProductStore } from './useStore';
import {CredentialModalPlacementMap} from '../../types/ui'
import {useCredentialModal} from '../../contexts'
import { useAddToWishlistTracking } from '../../analytics/add-to-wishlist-tracking'
import { Nullable } from '../../types/utils';

const useWishlistItem = (productId: string) => {
  const customerStore = useCustomerStore()
  const credentialModal = useCredentialModal()
  
  const handleRemoveWishlistItem = () => {
    customerStore?.removeItemFromWishlist(productId)
    
    customerStore.setLastActiveToNow()
  }
  const {productsById} = useProductStore();
  const sendAddToWishlistData = useAddToWishlistTracking();
  const handleAddWishlistItem = async (cartLocation?: string, viewingCategoryId?: Nullable<string> | undefined, location?: string, hitItemIndex?: number) => {
    if (!customerStore.isRegistered) {
      const type =
        window.innerWidth < 768
          ? CredentialModalPlacementMap.BOTTOM
          : CredentialModalPlacementMap.ATTACHED

      if (type === CredentialModalPlacementMap.ATTACHED) {
        window.scrollTo({top: 0})
      }

      credentialModal.showCredentialModal(type, () => customerStore.addItemToWishlist({productId}))
    } else {
      customerStore.addItemToWishlist({productId})
      sendAddToWishlistData(productsById[productId], cartLocation, viewingCategoryId, location, hitItemIndex);
    }
    customerStore.setLastActiveToNow()
  }

  const wishlistItem = customerStore.wishlist?.items?.find(
    (item: any) => item.productId === productId
  )

  return {
    isInWishlist: !!wishlistItem,
    handleRemoveWishlistItem,
    handleAddWishlistItem,
  }
}

export default useWishlistItem
