import {ISearchParams} from './store/ProductStore'

// Constants used in the used for product searching.

export const DEFAULT_SEARCH_PARAMS: ISearchParams = {
  limit: 25,
  offset: 0,
  sort: 'best-matches',
  refine: [],
}

export const DEFAULT_LIMIT_VALUES = [25, 50, 100] // Page sizes

// Constants for Search Component

export const RECENT_SEARCH_LIMIT = 5
export const RECENT_SEARCH_KEY = 'recent-search-key'
export const RECENT_SEARCH_MIN_LENGTH = 3
export const DEFAULT_SEARCH_LIMIT = 24
export const HITS_PER_PAGE = 25

export const SKELETON_APP_URLS = ['/mrt-dashboard']

export const HOME_HREF = '/'

export const urlPartPositions = {
  PATH: 'path',
  QUERY_PARAM: 'query_param',
  NONE: 'none',
}

// Replace with a custom preference
export const MAX_ORDER_QUANTITY = 12

const AMPLIENCE_PREVIEW_DEFAULT_API = '1p1z353x103zb1nw4ko1wth8uq.staging.bigcontent.io'
export const DEFAULT_CMS_BASE_URL = 'https://rsa.cdn.content.amplience.net'
export const VIRTUAL_STAGING_MICROSERVICE_URL = `https://virtual-staging.amplience.net/domain/${AMPLIENCE_PREVIEW_DEFAULT_API}`

export const priceBooks = {
  list: 'GBP-LIST-PRICES',
  promo: 'GBP-PROMO-PRICES',
  sale: 'GBP-SALE-PRICES',
  vip: 'GBP-VIP-SALE-PRICES',
  // list: 'USD-LIST-PRICES',
  // promo: 'USD-PROMO-PRICES',
  // sale: 'USD-SALE-PRICES',
  // vip: 'USD-VIP-SALE-PRICES'
}

export const daysOfTheWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
export const STAR_RATING_THRESHOLD = 3

export const CHECKOUT_SUMMARY_PATH = '/checkout/summary'
