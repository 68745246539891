import {useMemo, useState} from 'react'

export const useDisclosure = (initialState = false) => {
  const [isOpen, setIsOpen] = useState(initialState)

  return useMemo(
    () => ({
      isOpen,
      onOpen: () => setIsOpen(true),
      onClose: () => setIsOpen(false),
      onToggle: () => setIsOpen(!isOpen),
      setIsOpen,
    }),
    [isOpen, setIsOpen],
  )
}
