import React, {useState} from 'react'
import {BaseButton, Box, BoxProps, Flex, Text} from '../../../vanilla'
import {CloseIcon} from '../../icons'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

interface PreviewButtonProps extends BoxProps<React.ElementType> {
  onPreviewButtonClick: () => void
}

export const PreviewButton = ({onPreviewButtonClick, ...restBoxProps}: PreviewButtonProps) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleExitPreview = () => {
    window.location.href = window.location.origin
  }

  return (
    <Box
      display="flex"
      border="1px"
      borderRadius="medium"
      borderColor="transparent"
      boxShadow="base"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      {...restBoxProps}
    >
      <BaseButton
        variant="unstyled"
        bg="white"
        height="auto"
        paddingX="12px"
        paddingY="12px"
        onClick={onPreviewButtonClick}
      >
        <Box
          as="img"
          boxSize={['24px', '32px']}
          alt="Amplience Logo"
          src={getAssetUrl('static/img/amplience.png')}
        />
      </BaseButton>
      {isExpanded && (
        <Flex align="center" padding="0px" bg="white" position="relative" style={{left: -2}}>
          <Box
            borderLeft="1px"
            height="half"
            alignSelf="center"
            borderColor="gray200"
            marginLeft="8px"
          />
          <BaseButton
            onClick={handleExitPreview}
            variant="unstyled"
            aria-label="Close Amplience Preview"
            gap="16px"
          >
            <Text display={{desktop: 'block', mobile: 'none'}} variant="heading0" color="gray700">
              Exit
            </Text>
            <CloseIcon color="gray800" width="20px" height="auto" />
          </BaseButton>
        </Flex>
      )}
    </Box>
  )
}
