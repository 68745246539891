import React from 'react'
import {observer} from 'mobx-react-lite'
import {Link} from '../../../link'
import {useBasketStore, useCustomerStore, useOrderStore} from '../../../../store/hooks/useStore'
import {Box, Flex, Stack, Text, BaseButton} from '../../../../vanilla'
import {CheckPostcode} from '../../../book-delivery/check-postcode'
import useDeliveryBooking from '../../../../hooks/use-delivery-booking'
import LoadingOverlay from '../../../loading-overlay'
import {ExclamationCircleIcon} from '../../../icons'
import {DeliveryOrderInfo} from '../../../book-delivery/delivery-order-info'
import {CredentialModalPlacementMap} from '../../../../types/ui'
import {useCredentialModal} from '../../../../contexts'
import { useSlotExpiration } from '../../../../store/hooks/useSlotExpiration'

const DeliverySectionContainer = () => {
  const basketStore = useBasketStore()

  return (
    <Stack paddingX="8px" paddingY="12px" bg="white" borderBottom="1px" borderColor="gray200">
      {!basketStore.deliveryPostalCode ? (
        <>
          <Text variant="text4" lineHeight="short" color="gray800">
            Check if we can deliver to you
          </Text>
          <CheckPostcode
            hideText
            shortButton
            fullWidthForm
            buttonVariant="secondary"
            flexDirection="column"
          />
        </>
      ) : (
        <EnteredPostCodeStates postCode={basketStore.deliveryPostalCode} />
      )}
    </Stack>
  )
}

export const DeliverySection = observer(DeliverySectionContainer)

const EnteredPostCodeStates = observer(({postCode}: {postCode: string}) => {
  const basketStore = useBasketStore()

  return basketStore.currentDeliverySlotId ? (
    <BookedDelivery />
  ) : (
    <CartDrawerChangeOrBookDelivery
      postcode={postCode}
      isDeliverable={basketStore.isAppliedPostCodeDeliverable || false}
    />
  )
})

interface CartDrawerChangeOrBookDeliveryProps {
  postcode: string
  isDeliverable: boolean
}

const CartDrawerChangeOrBookDelivery = ({
  postcode = '',
  isDeliverable = false,
}: CartDrawerChangeOrBookDeliveryProps) => {
  const {clearPostalCode, loading} = useDeliveryBooking()
  const {recentOrder} = useOrderStore()

  const {nearestStoreByDeliveryPostCode: store} = useBasketStore()
  const {isRegistered} = useCustomerStore()

  return (
    <Stack
      flexDirection="row"
      spacing="24px"
      position="relative"
      data-test-selector="book-delivery"
      justify={['space-between', 'flex-start']}
    >
      <LoadingOverlay isLoading={loading} />
      {recentOrder ? 
        <Flex direction="column" gap="12px">
          <DeliveryOrderInfo order={recentOrder} isMiniBasket={true} /> 
        </Flex>
      : isDeliverable ? (
        <>
          <Stack spacing="4px">
            <Text
              variant="text4"
              color="gray800"
              lineHeight="none"
              data-cs-mask=""
            >{`Great! We'll deliver to ${postcode}`}</Text>

            <Text
              as="span"
              variant="text4"
              padding="0px"
              textDecoration="underline"
              color={{default: 'accent3', hover: 'accent3Light'}}
              lineHeight="short"
              cursor="pointer"
              onClick={clearPostalCode}
            >
              Change postcode
            </Text>
          </Stack>
          <BaseButton
            as={Link}
            href="/book-delivery"
            variant="secondary"
            size="sm"
            alignSelf="flex-end"
            flexBasis={['half', 'auto']}
          >
            <Text variant="unstyled" fontSize="inherit" lineHeight="inherit" fontWeight="inherit">
              Book Delivery
            </Text>
          </BaseButton>
        </>
      ) : (
        <Stack width="full">
          <Box>
            <Text variant="text4" lineHeight="none" color="accent0" data-cs-mask="">
              Sorry, we&apos;re unable to deliver to {postcode}
            </Text>
            <Text variant="text4" lineHeight="none" as="span">
              Postcode is not right?{' '}
              <Text
                variant="text4"
                color={{default: 'accent3', hover: 'accent3Light'}}
                textDecoration="underline"
                as="span"
                lineHeight="none"
                onClick={clearPostalCode}
              >
                Change it
              </Text>
            </Text>
          </Box>
          {store && (
            <Box>
                <Text variant="text4" as="span">
                Your nearest store is {store.name}:
                </Text>
                {!isRegistered && (
                <BaseButton
                    as={Link}
                    href="/account/register"
                    variant="secondary"
                    style={{minWidth: '100%'}}
                    width={['full', 'auto']}
                    maxHeight="36px"
                >
                    <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
                    Register
                    </Text>
                </BaseButton>
                )}
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  )
}

const BookedDeliveryContainer = () => {
  const basketStore = useBasketStore()
  const {reserveSlot} = useDeliveryBooking()
  const {isRegistered} = useCustomerStore()
  const {day, month, isAvailable} = basketStore.currentDeliveryDay || {}
  const {showCredentialModal} = useCredentialModal()
  const {isReservationExpired} = useSlotExpiration()

  const deliveryTimeRange = `${basketStore.slotTime(
    basketStore.basket?.c_windowStartTime
  )} - ${basketStore.slotTime(basketStore.basket?.c_windowEndTime)}`

  const bookingDeliveryUrl = '/book-delivery'

  // Checks if user is logged in before rebooking slot
  const handleSlotReservation = async () => {
    const type =
      window.innerWidth < 768
        ? CredentialModalPlacementMap.BOTTOM
        : CredentialModalPlacementMap.ATTACHED

    if (type === CredentialModalPlacementMap.ATTACHED) {
      window.scrollTo({top: 0})
    }
    if (!isRegistered) {
    showCredentialModal(type, async () => {
      await reserveSlot(basketStore.currentDeliverySlotId)
      })
    } else {
    reserveSlot(basketStore.currentDeliverySlotId)
    }
  }

  return (
    <Stack
      spacing="16px"
      flexDirection={['row', 'column']}
      flexWrap={['wrap', 'nowrap']}
      justifyContent={['space-between', 'flex-start']}
    >
      {isReservationExpired() ? (
        <>
          <Stack spacing="12px" flexShrink="0">
            <Flex gap="2px" align="center">
              <Text color="accent0" variant="text4">
                Your delivery reservation has expired!
              </Text>
              <ExclamationCircleIcon color="accent0" boxSize="16px" />
            </Flex>
            <Stack spacing="0px">
              <Text color="gray800" variant="text4" lineHeight="short">
                {isAvailable ? 'Your delivery time:' : 'Your chosen time is no longer available:'}
              </Text>
              <Text color="gray800" variant="text4" lineHeight="short">
                {deliveryTimeRange} {day} {month}
              </Text>
            </Stack>
          </Stack>
          <BaseButton
            variant="primary"
            size="sm"
            alignSelf={['flex-end', 'stretch']}
            to={bookingDeliveryUrl}
            {...(isAvailable
              ? {onClick: () => handleSlotReservation()}
              : {as: Link, to: '/book-delivery'})}
          >
            <Text
              variant="unstyled"
              fontSize="inherit"
              lineHeight="inherit"
              fontWeight="inherit"
              align="center"
            >
              {isAvailable ? 'Rebook' : 'Book a New Delivery Time'}
            </Text>
          </BaseButton>
        </>
      ) : (
        <Flex gap="16px" width="full" justify={['space-between', 'flex-start']}>
          <Stack spacing="2px" flexShrink="0">
            <Text color="gray800" lineHeight="short" variant="text4">
              Your delivery time:
            </Text>
            <Text color="gray800" lineHeight="short" variant="text4">
              {deliveryTimeRange}
            </Text>
            {basketStore?.basket?.c_windowStartTime && (
              <Text color="gray800" lineHeight="short" variant="text4">
                {new Date(basketStore.basket.c_windowStartTime).toLocaleDateString('en-GB', {
                  weekday: 'long',
                  month: 'short',
                  day: 'numeric',
                })}
              </Text>
            )}
          </Stack>
          <BaseButton
            variant="secondary"
            size="sm"
            alignSelf="flex-end"
            flexBasis={['half', 'auto']}
            as={Link}
            to={bookingDeliveryUrl}
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              whiteSpace: 'initial',
              height: 'auto',
              minHeight: 36,
            }}
          >
            <Text
              variant="unstyled"
              fontSize="inherit"
              lineHeight="inherit"
              fontWeight="inherit"
              align="center"
            >
              Change Delivery Time
            </Text>
          </BaseButton>
        </Flex>
      )}
    </Stack>
  )
}

export const BookedDelivery = observer(BookedDeliveryContainer)
