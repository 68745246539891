export const COOKIE_STORAGE_KEYS = {
  REGISTERED_CUSTOMER_SOFT_LOGOUT: 'rc_soft',
  REGISTERED_CUSTOMER_SOFT_LOGOUT_POSTCODE: 'rc_postCode',
  REGISTERED_CUSTOMER_SOFT_LOGOUT_ACCOUNT_DETAILS: 'rc_details',
  DW_POSTALCODE: 'dw_shippostalcode'
} as const

export const LOCALSTORAGE_KEYS = {
  PARKED_CUSTOMER_CART: 'parked_customer_cart'
} as const
