import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useChat} from '../../hooks/use-chat'
import {useCustomerStore, useGlobalStore} from '../../store/hooks/useStore'

export default () => {
  const {initiate} = useChat()
  const customerStore = useCustomerStore()
  const {pathname} = useLocation()
  const {isRegistered, customerInfo} = customerStore
  const {getCustomSitePreferenceById, customSitePreferences, isCustomSitePreferenceSet} = useGlobalStore()

  const [liveChatEnabled, setLiveChatEnabled] = useState(false);
  const [liveChatPages, setLiveChatPages] = useState<string[]>([]);

  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('liveChatEnabled')) {
        await getCustomSitePreferenceById('liveChatEnabled')
      }
      if (!isCustomSitePreferenceSet('liveChatPages')) {
        await getCustomSitePreferenceById('liveChatPages')
      }
    setLiveChatEnabled(customSitePreferences['liveChatEnabled'] as boolean)
    setLiveChatPages(customSitePreferences['liveChatPages'] as Array<string>)
    })()
  })

  useEffect(() => {
    const chatButton = Array.from(
      document.getElementsByClassName('helpButton') as HTMLCollectionOf<HTMLElement>
    )
    if (chatButton.length > 0 && liveChatEnabled) {
      if (liveChatPages.length > 0 && liveChatPages.includes(pathname)) {
        chatButton[0].style.visibility = 'visible'
      } else {
        chatButton[0].style.visibility = 'hidden'
      }
    }
  }, [pathname, liveChatEnabled, liveChatPages])

  useEffect(() => {
    const chatButton = Array.from(
      document.getElementsByClassName('helpButton') as HTMLCollectionOf<HTMLElement>
    )
    if (isRegistered && liveChatEnabled && liveChatPages.length > 0 && liveChatPages.includes(pathname)) {
      if (chatButton.length > 0) {
        chatButton[0].style.visibility = 'visible'
      } else {
        customerInfo && initiate(customerInfo)
      }
    } else {
      if (chatButton.length > 0) {
        chatButton[0].style.visibility = 'hidden'
      }
    }
  }, [isRegistered, liveChatEnabled, liveChatPages, pathname, customerInfo])

  return null
}
