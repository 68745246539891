import React from 'react'
import {CollapsablePanel} from '../../collapsable-panel'
import {useBasketStore} from '../../../store/hooks/useStore'
import {Box, Flex, Text} from '../../../vanilla'
import {ChevronDownIcon, DeliverySmallIcon, ExclamationTriangleIcon} from '../../icons'
import {ReservationDetails} from './mobile-navigation-bookig/reservation-details'
import {ReservationExpiredSlot} from './mobile-navigation-bookig/reservation-expired-slot'
import {observer} from 'mobx-react'
import DeliveryInitial from './mobile-navigation-bookig/delivery-initial'
import { useSlotExpiration } from '../../../store/hooks/useSlotExpiration'
import { MobileNavigationLoggedLinksProps } from './mobile-navigation-logged-links'

export const DeliverySlot = observer(({onClose}: MobileNavigationLoggedLinksProps) => {
  const {basket, currentDeliverySlotId, deliveryDate, slotTime} =
    useBasketStore()
  const {isReservationExpired} = useSlotExpiration()

  const deliveryWindow = `${slotTime(basket?.c_windowStartTime)} - ${slotTime(
    basket?.c_windowEndTime
  )}`

  return (
    <>
      <CollapsablePanel
        width="full"
        defaultOpen={true}
        mHeight='fit'
        panelContent={
          <Box
            paddingX="12px"
            paddingY="8px"
            bg="white"
            style={{borderBottom: '1px solid #d8d8d8'}}
          >
            {currentDeliverySlotId ? (
              !isReservationExpired() ? (
                <ReservationDetails />
              ) : (
                <ReservationExpiredSlot />
              )
            ) : (
              <DeliveryInitial onClose={onClose}/>
            )}
          </Box>
        }
      >
        {({isOpen, onToggle}) => (
          <Flex
            onClick={() => onToggle()}
            justify="space-between"
            align="center"
            borderTop="1px"
            borderColor="gray200"
            borderBottom="1px"
            bg="white"
            position="relative"
            paddingRight="12px"
          >
            {isReservationExpired() && (
              <ExclamationTriangleIcon
                color="accent0"
                position="absolute"
                height="16px"
                width="16px"
                style={{top: '12px', left: '12px'}}
                zIndex="docked"
              />
            )}
            <Flex paddingY="16px" gap="8px" paddingLeft="28px" paddingRight="20px">
              <DeliverySmallIcon color="accent3" height="24px" width="auto" />
              {currentDeliverySlotId ? (
                <Box>
                  <Text style={{fontSize: '12px', fontWeight: 'bold', lineHeight: '14px'}}>
                    Your Delivery
                  </Text>
                  <Text style={{fontSize: '12px', lineHeight: '14px'}}>
                    {deliveryDate} {deliveryWindow}
                  </Text>
                </Box>
              ) : (
                <Text variant="text4" textDecoration="none">
                  Book a Delivery
                </Text>
              )}
            </Flex>
            <ChevronDownIcon
              style={{transform: isOpen ? 'rotate(180deg)' : 'unset'}}
              width="16px"
              height="auto"
            />
          </Flex>
        )}
      </CollapsablePanel>
    </>
  )
})
