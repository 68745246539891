import React, {useEffect, useRef} from 'react';
import {SubmitHandler, useForm} from 'react-hook-form'
import {useCheckPostcodeFields, formatPostcode} from '../../hooks/forms/useCheckPostcodeFields'
import {Box, BoxProps, Button, Stack, Text} from '../../vanilla'
import {Field} from '../field'
import LoadingOverlay from '../loading-overlay'
import useDeliveryBooking from '../../hooks/use-delivery-booking'
import {checkPostcodeFieldWrapper} from './styles.css'
import {CheckPostalCodeFormFields} from '../../types/forms'
import {getMobileDeviceOS} from '../../utils/utils'

interface CheckPostCodeProps extends BoxProps<'div'> {
  buttonVariant?: 'primary' | 'secondary'
  shortButton?: boolean
  fullWidthForm?: boolean
  isMobileMenu?: boolean
  hideText?: boolean
  formDirection?: 'row' | 'column'
  autoFocus?: boolean
}

export const CheckPostcode = ({
  hideText,
  shortButton = false,
  isMobileMenu = false,
  fullWidthForm = false,
  buttonVariant = 'primary',
  formDirection = 'row',
  autoFocus = false,
  ...rest
}: CheckPostCodeProps) => {
  const form = useForm<CheckPostalCodeFormFields>({mode: 'onBlur'})
  const fields = useCheckPostcodeFields({form})
  const {setPostalCode, loading} = useDeliveryBooking()

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const handleFocus = () => {
      inputRef.current?.focus()
    }

    if (autoFocus && getMobileDeviceOS() === 'iOS') {
      document.addEventListener('click', handleFocus, { once: true })
      document.addEventListener('touchstart', handleFocus, { once: true })

      return () => {
        document.removeEventListener('click', handleFocus)
        document.removeEventListener('touchstart', handleFocus)
      }
    } else if (autoFocus) {
      inputRef.current?.focus()
    }
  }, [autoFocus])

  const onSubmit: SubmitHandler<CheckPostalCodeFormFields> = async ({postcode}) => {
    const formattedPostcode = formatPostcode(postcode)
    await setPostalCode(formattedPostcode)
  }

  return (
    <Stack
      flexDirection={['column', 'column', 'row']}
      spacing={['8px', '8px', '16px']}
      position="relative"
      {...rest}
    >
      <LoadingOverlay isLoading={loading} />
      {!hideText && (
        <Text
          variant="text4"
          textAlign="right"
          lineHeight="shorter"
          alignSelf={['flex-start', 'flex-end', 'flex-start']}
          marginTop={['0px', '0px', '8px']}
          style={{whiteSpace: 'nowrap'}}
        >
          Check if we can deliver to you
        </Text>
      )}
      <Box
        as="form"
        data-test-selector="postcode-check"
        onSubmit={form.handleSubmit(onSubmit)}
        width={fullWidthForm ? 'full' : 'auto'}
      >
        <Stack flexDirection={formDirection} spacing={isMobileMenu ? '8px' : '16px'}>
          <Box className={checkPostcodeFieldWrapper}>
          <Field {...fields.postcode} control={form.control} name="postcode" inputProps={{ ref: inputRef }} />
          </Box>
          <Button
            variant={buttonVariant}
            maxHeight="36px"
            data-test-selector="postcode-check-button"
          >
            Check
            <Text
              as="span"
              variant="unstyled"
              fontSize="inherit"
              lineHeight="inherit"
              fontWeight="inherit"
              display={isMobileMenu ? 'inline' : shortButton ? 'none' : ['none', 'none', 'inline']}
            >
              {' '}
              Postcode
            </Text>
          </Button>
        </Stack>
      </Box>
    </Stack>
  )
}
