import React from 'react'
import {format} from 'date-fns'
import {observer} from 'mobx-react-lite'
import {DateCalendar} from '../date-calendar'
import {Box, BoxProps, Flex, Text} from '../../vanilla'
import {useBasketStore} from '../../store/hooks/useStore'
import {ExclamationCircleIcon} from '../icons'
import {Link} from 'react-router-dom'
import {useDeliveryReservationTime} from '../../hooks/use-delivery-reservation-time'
import { useSlotExpiration } from '../../store/hooks/useSlotExpiration'

interface DeliveryDateTimeProps {
  popover?: boolean
  isBasket?: boolean
  hideDetails?: boolean
  calendarBoxProps?: BoxProps<React.ElementType>
  isCheckout?: boolean
}

const DeliveryDateTimeContainer = ({
  popover,
  isBasket,
  hideDetails = false,
  calendarBoxProps,
  isCheckout,
}: DeliveryDateTimeProps) => {
  const {
    basket,
    shippingAddress,
    slotTime,
    currentDeliveryDay,
    deliveryPostalCode,
  } = useBasketStore()

  const {isReservationExpired} = useSlotExpiration()
  const {isAvailable} = currentDeliveryDay

  const deliveryReservationDuration = useDeliveryReservationTime()

  const formatDeliveryDateTime = (withOutDate = false) => {
    let text = ''

    const deliveryWindow = `${slotTime(basket?.c_windowStartTime)} - ${slotTime(
      basket?.c_windowEndTime,
    )}`

    if (basket?.c_windowStartTime) {
      const textWithDate =
        deliveryWindow + ', ' + format(new Date(basket.c_windowStartTime), 'do MMMM')
      text = withOutDate ? deliveryWindow : textWithDate
    }

    return text
  }

  return (
    <Flex
      gap="16px"
      paddingX="20px"
      align={popover || isBasket ? 'flex-start' : 'center'}
      paddingBottom="16px"
    >
      <DateCalendar
        date={new Date(basket?.c_windowStartTime)}
        size={popover ? 'md' : 'sm'}
        variant="accent"
        style={{opacity: !isReservationExpired() ? '1' : '0.2'}}
        {...calendarBoxProps}
      />
      {isBasket && (
        <Box>
          {isReservationExpired() ? (
            <>
              <Flex align="center" gap="4px">
                <ExclamationCircleIcon color="accent0" marginBottom="2px" flexShrink="0" />
                <Text color="accent0" variant="text4">
                  Your delivery reservation has expired!
                </Text>
              </Flex>

              <Text variant="text4" paddingLeft="20px">
                {deliveryReservationDuration}
              </Text>
            </>
          ) : (
            <Text color="accent0" variant="text4">
              Your delivery time
            </Text>
          )}
          <Text variant="text4">{formatDeliveryDateTime()}</Text>
        </Box>
      )}

      {!isBasket && !hideDetails && (
        <Box>
          {isAvailable ? (
            isCheckout ? (
              <>
                <Flex gap="4px">
                  <Text color="accent0" variant="text4">
                    When:
                  </Text>
                  <Text variant="text4">{formatDeliveryDateTime(true)}</Text>
                </Flex>

                <Flex gap="4px" flexWrap="wrap">
                  <Text color="accent0" variant="text4">
                    Where:
                  </Text>
                  <Text variant="text4" data-cs-mask="">
                    {deliveryPostalCode} ({shippingAddress?.c_customerAddressID})
                  </Text>
                </Flex>

                <Text
                  as={Link}
                  to="/book-delivery"
                  variant="text4"
                  textDecoration="underline"
                  color={{default: 'accent3', hover: 'accent3Light'}}
                >
                  Change Delivery Time
                </Text>
              </>
            ) : (
              <>
                <Text color="accent0" variant="text4">
                  {isAvailable ? 'Your delivery time' : 'Your chosen time is no longer available:'}
                </Text>
                <Text variant="text4">{formatDeliveryDateTime()}</Text>
              </>
            )
          ) : (
            <>
              <Text color="accent0" variant="text4">
                Your chosen time is no longer available:
              </Text>
              <Text variant="text4">{formatDeliveryDateTime()}</Text>
            </>
          )}
        </Box>
      )}
    </Flex>
  )
}

export const DeliveryDateTime = observer(DeliveryDateTimeContainer)
