import useNavigation from '../../hooks/use-navigation'
import { DateUtils } from '../../utils/date-utils'
import {useBasketStore, useOrderStore} from './useStore'
import {isBefore} from 'date-fns'

export const useSlotExpiration = () => {
    const {editMode, editingOrder} = useOrderStore()
    const {basket} = useBasketStore()
    const navigate = useNavigation()

    const isReservationExpired = () => {
      // If we are in edit mode and the slot has not changed return false
      if (!orderEditDeliverySlotChanged() && editMode) {
          return false
      }
  
      const slotExpirationDate = new Date(basket?.c_slotExpiryTime)
  
      if (!DateUtils.validateDate(slotExpirationDate)) {
        return false
      }
    
      const slotExpirationDateZoned = DateUtils.utcToZonedDate(basket?.c_slotExpiryTime)
      
      const isExpired = isBefore(slotExpirationDateZoned, DateUtils.current())
  
      return isExpired
    }

    const handleEditReservationExpired = async () => {
        if (editMode && isReservationExpired()) {
            navigate('/account/order-history', 'push', {
                error: true
            })
          }
    }

    const orderEditDeliverySlotChanged = () => {
        if (!editingOrder || !editMode || !basket?.c_deliverySlotId) return false

        return basket?.c_deliverySlotId !== editingOrder.c_deliverySlotId
    }

    return {isReservationExpired, handleEditReservationExpired, orderEditDeliverySlotChanged}
 
}