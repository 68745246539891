import {ProductModel} from '../../../store/ProductStore'
import {useCustomerStore, useGlobalStore} from '../../../store/hooks/useStore'
import {Nullable} from '../../../types/utils'

export function useItemPrice(hit: Nullable<ProductModel>) {
  const {selectedPriceDayOffset, selectedRegion} = useGlobalStore()
  const {getApplicablePromotions} = useCustomerStore()
  const {onlineExclusivePromo} = getApplicablePromotions(hit?.productPromotions)
  let displayPrice:Nullable<number> = null
  let promotionPrice:Nullable<number> = null

  if(hit) {
    // Gets an array of prices for that day indexed by region, take day one if no selectedPriceDay
    displayPrice = selectedPriceDayOffset != null
    ? hit?.matrixPrices?.[selectedPriceDayOffset]?.[selectedRegion]
    : null

    if (displayPrice === null) {
      displayPrice = hit?.matrixPrices?.[0]?.[0]
    }
   
    if (onlineExclusivePromo && onlineExclusivePromo.promotionPrice !== undefined) {
      promotionPrice = onlineExclusivePromo.promotionPrice
    }
  }
  return {displayPrice, promotionPrice}
}
