import React from 'react'
import PropTypes from 'prop-types'
import {Box, Text, Stack, BaseButton} from '../../vanilla'
import {BrandLogo} from '../icons'
import PageNotFound from '../../pages/page-not-found'
import * as Sentry from '@sentry/react';

// <Error> is rendered when:
//
// 1. A user requests a page that is not routable from `app/routes.jsx`.
// 2. A routed component throws an error in `getProps()`.
// 3. A routed component throws an error in `render()`.
//
// It must not throw an error. Keep it as simple as possible.

export interface ErrorProps {
  stack: string
  status: number
}

const Error = (props: ErrorProps) => {
  const {stack, status} = props
  
  Sentry.addBreadcrumb({
    category: 'navigation',
    level: "error",
  });
  Sentry.captureException(stack)

  const isNotFoundError = status === 404
  if (isNotFoundError) return <PageNotFound></PageNotFound>
  else
    return (
      <>
        <Box
          as="a"
          href="/"
          display="block"
          height={['16px', '24px']}
          marginX={['24px', '48px']}
          marginBottom={['20px', '40px']}
          marginTop={['20px', '40px']}
          aria-label="Iceland Foods UK"
        >
          <BrandLogo width="auto" height="full" />
        </Box>
        <Box
          marginBottom={['20px', '40px']}
          paddingTop={['20px', '60px']}
          paddingBottom={['20px', '68px']}
          marginX={['0px', '48px']}
          minHeight={['auto', 'half']}
          overflow={'hidden'}
          color={'gray800'}
          bg={'gray50'}
        >
          <Stack gap={['24px', '44px']} justifyContent={'center'} alignItems={'center'}>
            <Text
              variant={'unstyled'}
              fontSize={'xxl'}
              lineHeight={'taller'}
              fontWeight={'semibold'}
              textAlign={'center'}
              marginTop={['24px', '48px']}
              marginBottom={['0px', '4px']}
            >
              Oops, looks like this aisle is empty
            </Text>
            <Stack
              gap={'16px'}
              direction={['column', 'row']}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <BaseButton
                as="a"
                href="/"
                marginBottom={'16px'}
                paddingY={'8px'}
                paddingX={'20px'}
                style={{width: '280px', height: '44px'}}
              >
                <Text variant="unstyled" fontSize="inherit">
                  Go to homepage
                </Text>
              </BaseButton>
              <BaseButton
                as="a"
                href="/offers"
                marginBottom={'16px'}
                paddingY={'8px'}
                paddingX={'20px'}
                style={{width: '280px', height: '44px'}}
              >
                <Text variant="unstyled" fontSize="inherit">
                  {' '}
                  Shop all offers
                </Text>
              </BaseButton>
            </Stack>
            <Stack
              gap={['0px', '4px']}
              direction={['column', 'row']}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={['20px', '0px']}
            >
              <Text variant={'unstyled'} textAlign={'center'}>
                Get in touch any time by visiting our
              </Text>
              <Stack gap={'4px'} direction={'row'}>
                <Text
                  as="a"
                  href="/customer-support/contact-us"
                  variant={'unstyled'}
                  fontWeight={'bold'}
                  style={{textDecoration: 'underline'}}
                >
                  Contact Us
                </Text>
                <Text>page.</Text>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Box marginX={['24px', '48px']} bg={'white'}>
          <Stack
            paddingY="20px"
            paddingX={'8px'}
            alignItems={['flex-start', 'center']}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            gap={'36px'}
            flexDirection={['column-reverse', 'row']}
          >
            <Text variant="text3" color={'gray700'}>
              © Copyright {new Date().getFullYear()} Iceland Foods Ltd.
            </Text>
            <Stack gap={'16px'} direction={['column', 'row']} alignItems={['flex-start', 'center']}>
              <Text variant="text3" as="a" to="/sitemap" color={'gray800'}>
                Sitemap
              </Text>
              <Text variant="text3" as="a" href="/terms.html" color={'gray800'}>
                Terms & Conditions
              </Text>
              <Text variant="text3" as="a" href="/privacy-policy" color={'gray800'}>
                Privacy
              </Text>
              <Text variant="text3" as="a" href="/privacy-policy/cookie-policy" color={'gray800'}>
                Cookies
              </Text>
              <Text id="ot-sdk-btn" className="ot-sdk-show-settings" variant="text3" as="a" href="#" color={'gray800'}>
                Manage Cookies
              </Text>
            </Stack>
          </Stack>
        </Box>
      </>
    )
}

Error.propTypes = {
  // JavaScript error stack trace: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
  stack: PropTypes.string,
  // HTTP status code: https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
  status: PropTypes.number,
}

export default Error
