import React from 'react'
import {getSecondsLeft, prependZero} from '../utils/utils'

export const useTimer = (date: string, shouldPrependZeros?: boolean) => {
  const [secondsLeft, setSecondsLeft] = React.useState(0)
  const [isFinished, setIsFinished] = React.useState(false)

  const hours = shouldPrependZeros
    ? prependZero(Math.floor(secondsLeft / 3600))
    : Math.floor(secondsLeft / 3600)
  const minutes = shouldPrependZeros
    ? prependZero(Math.floor((secondsLeft % 3600) / 60))
    : Math.floor((secondsLeft % 3600) / 60)
  const seconds = shouldPrependZeros ? prependZero(secondsLeft % 60) : secondsLeft % 60

  React.useEffect(() => {
    setSecondsLeft(getSecondsLeft(date))
  }, [date])

  React.useEffect(() => {
    let intervalId = -1
    // need to use window.setInterval to avoid TS error for setting type Timer to type number
    // https://stackoverflow.com/questions/53189729/typescript-error-setinterval-type-timer-is-not-assignable-to-type-number
    intervalId = window.setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev === 0) {
          !isFinished && setIsFinished(true)
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return {hours, minutes, seconds, isFinished}
}
