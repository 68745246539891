import React from 'react'
import {Box, Text, Stack, Container, Flex} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {CTA, Image, ColorPicker} from '../../types/cms'
import {CmsImage} from './cms-image'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {Carousel} from '../carousel'

export interface InfoCardListCmsContent {
  dividerLines: boolean
  backgroundColor?: ColorPicker
  infoCardsList: Array<InfoCardCmsContent>
}

export interface InfoCardCmsContent {
  image?: {
    image: {
      images?: Image[]
    }
    imageLink: string
  }
  imageType?: boolean
  text: ICLTexts
  cta?: CTA
  addImageCircle?: boolean
}

export interface ICLTexts {
  headlineTextColor: ColorPicker
  headline: string
  body: string
}

interface InfoCardProps extends InfoCardCmsContent {
  dividerLines: boolean
  index: number
  imagesListLength: number
  isStaticImage?: boolean
  addImageCircle?: boolean
}

const InfoCard = ({
  dividerLines,
  index,
  imagesListLength,
  image,
  isStaticImage,
  text,
  cta,
  addImageCircle,
}: InfoCardProps) => {
  return (
    <Stack
      gap="16px"
      width="full"
      flex="1"
      borderRight={dividerLines && index < imagesListLength - 1 ? ['0px', '1px'] : '0px'}
      style={{borderRightColor: '#e4e4e4'}}
      alignItems="center"
      paddingX={['0px', '12px']}
      paddingBottom={['20px', '0px']}
      key={`${image?.imageLink}--${index}`}
    >
      <Stack alignItems="center" gap="16px">
        {image?.image?.images && (
          <Box
            borderRadius={addImageCircle ? 'full' : undefined}
            bg={addImageCircle ? 'white' : 'transparent'}
            alignSelf="center"
            height="100px"
            width="100px"
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
          >
            <CmsImage
              images={image?.image?.images}
              imageLink={image?.imageLink}
              isStaticImage={isStaticImage}
              height="auto"
              width="full"
              maxHeight={addImageCircle ? '64px' : '100px'}
              maxWidth={addImageCircle ? '84px' : undefined}
              display="inline-block"
              overflow="hidden"
              verticalAlign="middle"
              alignSelf="center"
            />
          </Box>
        )}
        <Stack gap={['4px', '2px']} paddingTop={['4px', '0px']} paddingBottom={['12px', '0px']}>
          <Text
            variant="heading1"
            style={{color: text?.headlineTextColor?.color}}
            textAlign="center"
            textDecoration="none"
          >
            {text?.headline}
          </Text>
          <Text
            variant="text3"
            textAlign="center"
            alignSelf="center"
            color="gray800"
            textDecoration="none"
          >
            {text?.body}
          </Text>
        </Stack>
      </Stack>
      {cta?.ctaText && (
        <Flex textAlign="center" marginTop="auto">
          <CTAButton cta={cta} />
        </Flex>
      )}
    </Stack>
  )
}

export const InfoCardList: SlotComponent<InfoCardListCmsContent> = ({content}) => {
  const imagesList = content?.infoCardsList

  return (
    <Container
      width="full"
      position="relative"
      overflow="hidden"
      style={{backgroundColor: content?.backgroundColor?.color}}
      paddingX={['12px', '0px']}
    >
      <Flex
        align="center"
        display={['none', 'flex']}
        justifyContent="space-around"
        paddingY="12px"
        alignItems="stretch"
        height="full"
        gap="0px"
      >
        {imagesList?.map(({image, imageType: isStaticImage, text, cta, addImageCircle}, index) => {
          return (
            <InfoCard
              key={`info-card-${index}`}
              image={image}
              isStaticImage={isStaticImage}
              text={text}
              cta={cta}
              index={index}
              dividerLines={content?.dividerLines}
              imagesListLength={imagesList?.length}
              addImageCircle={addImageCircle}
            />
          )
        })}
      </Flex>
      <Box display={['block', 'none']} paddingY="12px">
        {imagesList ? (
            <Carousel
            loop={false}
            itemsPerSide={{desktop: 1, mobile: 1, tablet: 1}}
            controlsPosition="bottom"
            controlConfig={{
              activeDotColor: 'accent0',
              arrowsBgColor: 'gray600',
              arrowsColor: 'white',
              showArrows: {mobile: false, desktop: true, tablet: true},
            }}
          >
              {imagesList.map(({image, imageType, text, cta}, index) => {
                const isStaticImage = imageType
                return (
                  <InfoCard
                    key={`carousel-info-card-${index}`}
                    image={image}
                    isStaticImage={isStaticImage}
                    text={text}
                    cta={cta}
                    index={index}
                    dividerLines={content?.dividerLines}
                    imagesListLength={imagesList?.length}
                  />
                )
              })}
          </Carousel>
        ) : null}
      </Box>
    </Container>
  )
}
