import React, {CSSProperties} from 'react'
import {Box, Text, Stack, Container} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {Image} from '../../types/cms'
import {CmsImage} from './cms-image'
import {useSelectPromotionTracking} from '../../analytics/promotion-tracking'

export interface NavFlyoutBannerCmsContent {
  listItems: Array<{
    imageLink: string
    image: {
      images?: Image[]
    }
  }>
  title: string
}

export const NavFlyoutBanner: SlotComponent<
  NavFlyoutBannerCmsContent,
  {titleStyles?: CSSProperties; containerStyles?: CSSProperties}
> = ({content, titleStyles, containerStyles}) => {
  const imagesList = content?.listItems

  const promotions = imagesList.map(({image, imageLink}, index: number) => {
    return {
      creative_name: image?.images?.[0]?.data?.altText ?? 'Banner missing alt text',
      creative_slot: `${content?._meta.name} ${index + 1}` ?? 'Navigation flyout banner',
      promotion_id: imageLink ?? 'Banner without link',
      promotion_name: 'No CTA'
    }
  });

  const sendSelectPromotion = useSelectPromotionTracking();
  
  return (
    <Container width="full" position="relative" overflow="hidden" style={{padding: '0px'}}>
      <Stack align="center" gap="24px" style={containerStyles}>
        {content.title && (
          <Text variant="heading2" color="gray800" style={titleStyles}>
            {content.title}
          </Text>
        )}

        <Box gap={['16px', '4px']} width="full">
          {imagesList.map(({image, imageLink}, index) => {
            if (!image?.images) {
              return null
            }
            return (
              <CmsImage
                queryParams={[
                  {w: 550, fmt: 'auto', qlt: 'default'}, 
                  {w: 1100, fmt: 'auto', qlt: 'default'}
                ]}
                key={`${imageLink}--${index}`}
                images={image?.images}
                imageLink={imageLink}
                height="auto"
                width="full"
                onClick={() => {
                  sendSelectPromotion(promotions[index], index + 1);
                }}
              />
            )
          })}
        </Box>
      </Stack>
    </Container>
  )
}
