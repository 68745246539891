import {action, makeObservable, observable} from 'mobx'
import {RootStore} from './RootStore'

export class PreviewDebugStore {
  showSlots: boolean
  showContent: boolean

  constructor(rootStore: RootStore, initialState = {showSlots: false, showContent: false}) {
    this.showSlots = initialState.showSlots
    this.showContent = initialState.showContent

    makeObservable(this, {
      showContent: observable,
      showSlots: observable,
      setShowContent: action.bound,
      setShowSlots: action.bound,
    })
  }

  get asJson() {
    return {
      showSlots: this.showSlots,
      showContent: this.showContent,
    }
  }

  setShowSlots(flag: boolean) {
    this.showSlots = flag
  }

  setShowContent(flag: boolean) {
    this.showContent = flag
  }
}
