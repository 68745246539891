import { Hit, TransformedHit } from "../../../store/ProductStore"

interface State {
  context: {
    selectedPriceDay?: string
    selectedStoreId?: string
  }
}

export function transformHit(hit: Hit, state?: State): TransformedHit {
  const {selectedPriceDay, selectedStoreId} = state?.context || {}
  const defaultPrice = hit.global_price as string
  const dailyPrice = selectedPriceDay ? hit.default_price_per_day?.[selectedPriceDay as keyof typeof hit.default_price_per_day] as unknown as string : null
  const notAvailableInStoresArray = hit[`notAvailableInStores_${selectedPriceDay}`] as string[] | undefined
  const availableForDayAtStore =
  selectedPriceDay
    ? !notAvailableInStoresArray || (!!selectedStoreId && !notAvailableInStoresArray.includes(selectedStoreId))
    : true;

  const displayPrice = dailyPrice || defaultPrice

 return {...hit, defaultPrice, dailyPrice, displayPrice, availableForDayAtStore}
}
