import {useRef, useState, useEffect} from 'react'

interface IArgs {
  isOpen: boolean
  duration?: number
}

/**
 * Handles dynamically ading/removing an overlay (dialog or modal). The values
 * `isReady` and `isVisible` are delayed during opening and closing to allow for
 * mounting/unmounting of the overlay with in/out transitions.
 *
 * This hook also handles locking the body scroll behind the overlay. The `scrollRef`
 * should be placed on the element you want to still be scrollable, like the body
 * content of a long modal.
 */
export const useOverlayTransition = ({isOpen, duration = 250}: IArgs) => {
  const scrollRef = useRef()
  const [isReady, setIsReady] = useState(isOpen)
  const [bypassFocusLock, setByPassFocusLock] = useState(true)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isOpen && !isReady) {
      setIsReady(true)
      setTimeout(() => setIsVisible(true))
      setTimeout(() => setByPassFocusLock(false), duration + 1)
    }
    if (!isOpen && isReady) {
      setIsVisible(false)
      setByPassFocusLock(true)
      setTimeout(() => setIsReady(false), duration)
    }
  }, [isOpen])

  return {scrollRef, isVisible, isReady, bypassFocusLock}
}
