import React from 'react'
import {Modal, ModalProps} from '../modal'
import {Box, Button, Flex, Stack, Text} from '../../vanilla'
import {ProductTileCompact} from '../product-tile-compact'
import {AddAllToCartProducts} from './use-add-all-to-basket-params'

interface AddAllToCartMainProps extends ModalProps {
  loading?: boolean
  onAdd: () => void
  onCancel: () => void
  products: AddAllToCartProducts
}

export const AddAllToCartMain: React.FC<AddAllToCartMainProps> = ({
  loading,
  onAdd,
  onCancel,
  products,
  ...modal
}) => {
  return (
    <Modal {...modal} title="Adding multiple products to basket..." size="xl" onClose={onCancel}>
      <Box backgroundColor="gray50">
        <Stack paddingX={['0px', '28px']} paddingY="28px" gap="16px">
          {products.available?.length ? (
            <>
              <Text variant="heading0">
                Youʼre about to add all of the following items to your basket:
              </Text>
              <Box
                display="grid"
                gridTemplateColumns="2x"
                paddingBottom="16px"
                style={{
                  gap: '32px 16px',
                }}
              >
                {products.available?.map?.(({id, quantity}) => {
                  return (
                    <ProductTileCompact
                      key={id}
                      productId={id}
                      quantity={quantity}
                      showAddToBasket={false}
                    />
                  )
                })}
              </Box>
            </>
          ) : null}
          {products.unavailable?.length ? (
            <>
              <Text variant="heading0" color="accent0">
                The following items are currently unavailable:
              </Text>
              <Box
                display="grid"
                gridTemplateColumns="2x"
                paddingBottom="16px"
                style={{
                  gap: '32px 16px',
                }}
              >
                {products.unavailable?.map?.(({id}) => {
                  return (
                    <div style={{filter: 'grayscale(100%)', pointerEvents: 'none'}}>
                      <ProductTileCompact key={id} productId={id} showAddToBasket={false} />
                    </div>
                  )
                })}
              </Box>
            </>
          ) : null}
        </Stack>
        <Box padding="20px" borderTop="1px" borderColor="gray100">
          <Flex align="center">
            <Button variant="outlineDark" onClick={onCancel}>
              Cancel
            </Button>
            <Button isLoading={loading} onClick={onAdd} marginLeft="auto">
              Add all to my basket
            </Button>
          </Flex>
        </Box>
      </Box>
    </Modal>
  )
}
