import {useCallback, useState} from 'react'

type UseToggleReturn = [boolean, () => void, (state: boolean) => void]

export function useToggle(defaultToggle: boolean = false): UseToggleReturn {
  const [toggleState, setToggle] = useState<boolean>(defaultToggle)

  const toggle = useCallback((): void => setToggle((prevState) => !prevState), [])

  return [toggleState, toggle, setToggle]
}
