import React, {ComponentProps} from 'react'
import {CheckCircleIcon, ExclamationCircleIcon} from '../../components/icons'
import {Flex, Box} from '../../vanilla'
import {alertRecipe} from './styles.css'

export interface Alert extends ComponentProps<typeof Flex> {
  status: 'error' | 'warning' | 'info'
  children?: React.ReactNode
}

export const Alert = ({status, children, ...props}: Alert) => {
  const className = alertRecipe({status})

  return (
    <Flex className={className} gap="8px" align="center" {...props}>
      {['error', 'warning', 'info'].includes(status) && <ExclamationCircleIcon color="inherit" />}
      {['success'].includes(status) && <CheckCircleIcon color="inherit" />}
      {children && <Box color="black">{children}</Box>}
    </Flex>
  )
}
