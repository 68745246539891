import React from 'react'
import {ContentBody, DefaultContentBody} from 'dc-delivery-sdk-js'
import {observer} from 'mobx-react-lite'
import {Box, BoxProps, Flex, Stack, Text} from '../../../vanilla'
import {useContentStore} from '../../../store/hooks/useStore'
import {
  FrozenIcon,
  FreshOrFreeIcon,
  AirFryerFriendlyIcon,
  ExclusiveIcon,
  VegetarianBadgeIcon,
  VeganBadgeIcon,
  NewAndExclusiveIcon,
  FreezableIcon,
  NewIcon,
  Serves2Icon,
  Serves2To4Icon,
  Serves4Icon,
  Serves4To6Icon,
  Serves6Icon,
  Serves6To8Icon,
  Serves8Icon,
  Serves8To10Icon,
  Serves10Icon,
  Serves10PlusIcon,
  SpiceMildIcon,
  SpiceWarmIcon,
  SpiceHotIcon,
  SpiceExtraHotIcon,
  FrozenBadgeIcon,
  FourForThirtyIcon,
  ThreeForThreeIcon,
  ThreeForFiveIcon,
  ThreeForTenIcon
} from '../../icons'
import {ColorPicker, Image, ImageLink} from '../../../types/cms'
import {Link} from '../../link'
import {CmsImage} from '../cms-image'
import {getImageUrl} from '../utils'

interface TopRightProductBadgeCms extends ContentBody {
  badgeIcon: keyof typeof badgeTypeIconMap
  iconAltText?: string
}

type BadgeTypeIconMap = {
  [key: string]: React.ComponentType<any>
}

const badgeTypeIconMap: BadgeTypeIconMap = {
  'air-fryer-friendly': AirFryerFriendlyIcon,
  exclusive: ExclusiveIcon,
  'four-for-thirty': FourForThirtyIcon,
  freezable: FreezableIcon,
  'fresh-or-free': FreshOrFreeIcon,
  frozen: FrozenIcon,
  'frozen-badge': FrozenBadgeIcon,
  new: NewIcon,
  'new-and-exclusive': NewAndExclusiveIcon,
  'serves-2': Serves2Icon,
  'serves-2-to-4': Serves2To4Icon,
  'serves-4': Serves4Icon,
  'serves-4-to-6': Serves4To6Icon,
  'serves-6': Serves6Icon,
  'serves-6-to-8': Serves6To8Icon,
  'serves-8': Serves8Icon,
  'serves-8-to-10': Serves8To10Icon,
  'serves-10': Serves10Icon,
  'serves-10-plus': Serves10PlusIcon,
  'spice-extra-hot': SpiceExtraHotIcon,
  'spice-hot': SpiceHotIcon,
  'spice-mild': SpiceMildIcon,
  'spice-warm': SpiceWarmIcon,
  'three-for-three': ThreeForThreeIcon,
  'three-for-five': ThreeForFiveIcon,
  'three-for-ten': ThreeForTenIcon,
  'vegan-badge': VeganBadgeIcon,
  'vegetarian-badge': VegetarianBadgeIcon
}

export type TopRightProductBadgeKey = TopRightProductBadgeCms['badgeIcon']

interface TopRightProductBadge extends Omit<BoxProps<'i'>, 'right' | 'top'> {
  badgeKey: TopRightProductBadgeKey
  top?: number
  right?: number
}

export const TopRightProductBadge = observer(
  ({badgeKey, top = 0, right = 0, ...rest}: TopRightProductBadge) => {
    const cms = useContentStore()

    if (!cms.contentById[badgeKey]) {
      return null
    }

    const {badgeIcon} = cms.contentById[badgeKey] as TopRightProductBadgeCms
    const BadgeIcon = badgeTypeIconMap[badgeIcon]

    return (
      <Box as="i" position="absolute" style={{top, right}} {...rest}>
        <BadgeIcon boxSize="36px" />
      </Box>
    )
  }
)

export const TopRightProductBadgeVizualization = observer(
  ({content}: {content: TopRightProductBadgeCms}) => {
    if (!content) {
      return null
    }

    const BadgeIcon = badgeTypeIconMap[content.badgeIcon]

    return (
      <Box as="i">
        <BadgeIcon boxSize="36px" aria-label={content.iconAltText} />
      </Box>
    )
  }
)

interface TextProductBadgeCms extends ContentBody {
  badgeDefinition: {
    badgeText: string
    badgeTextLine2?: string
    clarificationText?: string
    badgeLink?: string
    includeNewStarIcon?: boolean
  }
  colors: {
    badgeBackgroundColor: ColorPicker
    badgeTextColor: ColorPicker
  }
  clarificationColors: {
    clarificationTextBackgroundColor: ColorPicker
    clarificationTextColor: ColorPicker
  }
}

interface TextProductBadge extends BoxProps<'div'> {
  badge: TextProductBadgeCms
}

const TextProductBadge = (props: TextProductBadge) => {
  if (!props.badge) {
    return null
  }

  const {badge, ...rest} = props

  const {badgeDefinition, colors, clarificationColors} = badge

  return (
    <Stack 
      align="flex-start"
      gap="4px"
      width="150px"
      {...rest}
      {...(badgeDefinition?.badgeLink ? {as: Link, to: badgeDefinition.badgeLink} : {})}
      >
      <Flex
        justify="center"
        align="center"
        borderRadius="base"
        backgroundColor="gray200"
        gap="2px"
        color="white"
        direction="column"
        style={{
          backgroundColor: colors?.badgeBackgroundColor?.color,
          color: colors?.badgeTextColor?.color,
          marginTop: '2px',
          padding: '3px 6px',
          minWidth: 150
        }}
      >
        <Flex
          justify="center"
          align="center"
          gap="2px"
        >
          {badgeDefinition?.includeNewStarIcon && (
            <img src="//assets.iceland.co.uk/i/iceland/NEW_STAR?h=8" alt="New" />
          )}
          <Text variant="text2" align="center" lineHeight="normal">
            {badgeDefinition?.badgeText}
          </Text>
          {badgeDefinition?.includeNewStarIcon && (
            <img src="//assets.iceland.co.uk/i/iceland/NEW_STAR?h=8" alt="New" />
          )}
        </Flex>
        {badgeDefinition?.badgeTextLine2 && (
          <Text variant="text2" lineHeight="normal" align="center">
            {badgeDefinition.badgeTextLine2}
          </Text>
        )}
      </Flex>
      {badgeDefinition?.clarificationText && (
        <Box
          borderRadius="base"
          backgroundColor="gray200"
          color="gray800"
          style={{
            backgroundColor: clarificationColors?.clarificationTextBackgroundColor?.color,
            color: clarificationColors?.clarificationTextColor?.color,
            padding: "3px 6px",
            wordWrap: "normal",
            minWidth: 150
          }}
        >
          <Text variant="text0" align="center" lineHeight="normal">
            {badgeDefinition.clarificationText}
          </Text>
        </Box>
      )}
    </Stack>
  )
}

interface ImageProductBadgeCms extends ContentBody {
  image: {
    images: Image[]
  }
  imageLink: ImageLink
}

interface ImageProductBadge extends BoxProps<'div'> {
  badge: ImageProductBadgeCms
}

const ImageProductBadge = (props: ImageProductBadge) => {
  if (!props.badge) {
    return null
  }

  const {badge, ...rest} = props

  return (
    <Box borderRadius="base" {...rest}>
      <CmsImage
        images={badge?.image?.images}
        imageLink={badge?.imageLink}
        style={{maxWidth: 120, marginTop: '2px'}}
      />
    </Box>
  )
}

interface TextProductBadgeVizProps {
  content: {
    colors: {
      badgeTextColor: {
        color: string
      }
      badgeBackgroundColor: {
        color: string
      }
    }
    badgeDefinition: {
      badgeLink: string
      badgeTextLine2: string
      includeNewStarIcon: boolean
      badgeText: string
      clarificationText: string
    }
    clarificationColors: {
      clarificationTextBackgroundColor: {color: string}
      clarificationTextColor: {color: string}
    }
  }
}

const TextProductBadgeVizualisation = ({content}: TextProductBadgeVizProps) => {
  if (!content) {
    return null
  }

  const {badgeDefinition, colors, clarificationColors} = content

  return (
    <Stack 
      align="flex-start" 
      gap="4px"
      width="150px"
      {...(badgeDefinition?.badgeLink ? {as: Link, to: badgeDefinition.badgeLink} : {})}
      >
      <Flex
        justify="center"
        align="center"
        borderRadius="base"
        backgroundColor="gray200"
        gap="2px"
        color="white"
        direction="column"
        style={{
          backgroundColor: colors?.badgeBackgroundColor?.color,
          color: colors?.badgeTextColor?.color,
          padding: '3px 6px',
          minWidth: 150
        }}
      >
        <Flex
          justify="center"
          align="center"
          gap="2px"
        >
          {badgeDefinition?.includeNewStarIcon && (
            <img src="//assets.iceland.co.uk/i/iceland/NEW_STAR?h=8" alt="New" />
          )}
          <Text variant="text2" align="center" lineHeight="normal">
            {badgeDefinition?.badgeText}
          </Text>
          {badgeDefinition?.includeNewStarIcon && (
            <img src="//assets.iceland.co.uk/i/iceland/NEW_STAR?h=8" alt="New" />
          )}
        </Flex>
        {badgeDefinition?.badgeTextLine2 && (
          <Text variant="text2" lineHeight="normal" align="center">
            {badgeDefinition.badgeTextLine2}
          </Text>
        )}
      </Flex>
      {badgeDefinition?.clarificationText && (
        <Box
          borderRadius="base"
          backgroundColor="gray200"
          color="gray800"
          style={{
            backgroundColor: clarificationColors?.clarificationTextBackgroundColor?.color,
            color: clarificationColors?.clarificationTextColor?.color,
            padding: "3px 6px",
            wordWrap: "normal",
            minWidth: 150
          }}
        >
          <Text variant="text0" align="center" lineHeight="normal">
            {badgeDefinition.clarificationText}
          </Text>
        </Box>
      )}
    </Stack>
  )
}

interface ImageProductBadgeCmsViz extends ContentBody {
  content: {
    image: {
      images: Image[]
    }
    imageLink: ImageLink
  }
}

const ImageProductBadgeVizualisation = ({content}: ImageProductBadgeCmsViz) => {
  if (!content) {
    return null
  }

  const imageLink = content.imageLink || getImageUrl(content?.image?.images[0].image)

  return (
    <Box borderRadius="base" style={{padding: '3px 6px'}}>
      <CmsImage images={content?.image?.images} imageLink={imageLink} style={{maxWidth: 120}} />
    </Box>
  )
}

const getBadgeForSchema = (schema: string, isVizualisation = false) => {
  const badgeSchemaComponentMap: Record<string, any> = {
    'https://www.iceland.co.uk/content/image-product-badge.json': isVizualisation
      ? ImageProductBadgeVizualisation
      : ImageProductBadge,
    'https://www.iceland.co.uk/content/text-product-badge.json': isVizualisation
      ? TextProductBadgeVizualisation
      : TextProductBadge,
    'https://www.iceland.co.uk/content/top-right-product-badge.json': isVizualisation
      ? TopRightProductBadgeVizualization
      : TopRightProductBadge,
  } as const
  return badgeSchemaComponentMap[schema] || null
}

interface PDPBadgeAsset extends BoxProps<'div'> {
  badgeKey: string
}

export const PDPBadgeAsset = observer(({badgeKey, ...rest}: PDPBadgeAsset) => {
  const cms = useContentStore()

  if (!cms.contentById[badgeKey]) {
    return null
  }

  const badge = cms.contentById[badgeKey] as DefaultContentBody

  const Component = getBadgeForSchema(badge?._meta?.schema)

  return <Component badge={badge} {...rest} />
})

interface VizWrapper {
  content: {
    _meta: {
      schema: string
    }
  }
}

export const PDPBadgeAssetVizualisationWrapper = observer(({content}: VizWrapper) => {
  const Component = getBadgeForSchema(content?._meta?.schema, true)
  return <Component content={content} />
})
