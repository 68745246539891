import React from 'react'
import {observer} from 'mobx-react-lite'
import {BoxProps, Stack} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {Markdown, MarkdownProps} from './Markdown'
import {TextAlignment} from '../../types/cms'

export interface ContentTextCmsContent {
  text: string
  alignment: TextAlignment
  width?: string
}

interface ContentTextComponentProps {
  markdownComponentProps?: MarkdownProps['componentProps']
  containerProps?: BoxProps<'div'>
}

const ContentTextContainer: SlotComponent<ContentTextCmsContent, ContentTextComponentProps> = ({
  content,
  markdownComponentProps,
  containerProps,
}) => {
  const textAlign = (content?.alignment || 'left')?.toLowerCase() as 'left' | 'right' | 'center'
  const maxWidth = content?.width?.includes('Inset') ? '600px' : '100%'

  return (
    <Stack
      spacing="12px"
      style={{maxWidth, margin: maxWidth === '600px' ? '0 auto' : '0px'}}
      {...(containerProps || {})}
    >
      <Markdown
        markdown={content?.text}
        componentProps={{
          h1: {variant: 'heading3', textAlign, ...(markdownComponentProps?.h1 || {})},
          h2: {variant: 'text5', textAlign, ...(markdownComponentProps?.h2 || {})},
          h3: {
            color: 'accent0',
            textTransform: 'uppercase',
            variant: 'text7',
            textAlign,
            ...(markdownComponentProps?.h3 || {}),
          },
          p: {
            color: 'gray800',
            variant: 'text3',
            textAlign,
            ...(markdownComponentProps?.p || {}),
          },
          a: {variant: 'text3', ...(markdownComponentProps?.a || {})},
          li: {variant: 'text3', ...(markdownComponentProps?.li || {})},
        }}
      />
    </Stack>
  )
}

export const ContentText = observer(ContentTextContainer)
