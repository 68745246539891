import React from 'react'

import {FieldError, UseFormReturn} from 'react-hook-form'
import {Box, Flex, Stack, Text} from '../../../vanilla'
import {useTwoFactorFields} from '../../../hooks/forms/useTwoFactorCodeFields'
import {Field} from '../../field'
import {ExclamationCircleIcon} from '../../icons'
import {TwoFactorCodeFields} from '../../../types/forms'

interface TwoFactorCodeFormProps {
  form: UseFormReturn<TwoFactorCodeFields>
  global?: string
}

export const TwoFactorCodesFields = ({form}: TwoFactorCodeFormProps) => {
  const {
    setValue,
    formState: { errors },
  } = form;
  const fields = useTwoFactorFields({form});

  const focusCodeByNumber = (number: number) => {
    const elemList = document.getElementsByName(`code_${number.toString()}`) as NodeListOf<HTMLInputElement>
    if(elemList && elemList.length === 1){
      const elem = elemList[0];
      elem.select();
      elem.focus();
    }
  }
  
  const updateCodeState = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const event = e.nativeEvent as InputEvent;
    const currentIndex = parseInt(e.currentTarget.name.split('_')[1]);
    if(typeof event.data === 'string'){
      if(event.data.length <= 1){
        // If its a single key-press check there isn't something already in the field before updating the state
        const box = document.getElementsByName(e.currentTarget.name)[0] as HTMLInputElement
        if (box.value.length <= 1){
          setValue(e.currentTarget.name as keyof TwoFactorCodeFields, event.data, {shouldValidate: true})
        }else{
          // If more than one keypress don't update the state and revert the keypress
          box.value = box.value.substring(0, 1);
        }
      }else if(event.data.length >= 6){
        //Handle iOS auto-fill in BC app.
        event.data?.split('').filter(function (code) {
          return !isNaN(Number(code));
        }).forEach((d, i) => {
          setValue(`code_${i + 1}` as keyof TwoFactorCodeFields, d, {shouldValidate: true});
        });
      }
    }
    if(e.currentTarget.value.length >= 1 && event.inputType !== 'deleteContentBackward'){
      if (currentIndex < 6) {
        focusCodeByNumber(currentIndex + 1);
      }
    // Only move the cursor back if the event was a true delete event
    }else if(e.currentTarget.value.length == 0 && event.inputType == 'deleteContentBackward'){
        if (currentIndex > 1) {
          focusCodeByNumber(currentIndex - 1);
        }
    }
  }

  const guardCodeInputs = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const currentIndex = parseInt(e.currentTarget.name.split('_')[1]);
    const allowedControlKeys = ['Backspace', 'Delete']
    if (e.key === 'Backspace') {
      // When the backspace is pressed on an empty box, move back.
      if (e.currentTarget.value.length == 0) {
        if (currentIndex > 1) {
          focusCodeByNumber(currentIndex - 1);
          return;
        }
      }
    }
    if (e.key === 'ArrowLeft') {
      if (currentIndex > 1) {
        e.stopPropagation();
        e.preventDefault();
        focusCodeByNumber(currentIndex - 1);
        return;
      }
    }
    if (e.key === 'ArrowRight') {
      if (currentIndex < 6) {
        e.stopPropagation();
        e.preventDefault();
        focusCodeByNumber(currentIndex + 1);
        return;
      }
    }
    //prevent 'e' or any non-numeric digits in number field.
    // Exclude keyboard shortcuts which use ctrl or meta key
    if ((isNaN(Number(e.key)) && !e.metaKey && !e.ctrlKey && !allowedControlKeys.includes(e.key))) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  }

  const parseCodeFromPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const currentIndex = parseInt(e.currentTarget.name.split('_')[1]);
    const paste = e.clipboardData
    .getData('text')
    .split('')
    .filter(function (code) {
        return !isNaN(Number(code));
    });
    paste.forEach(function(code, index){
        setValue(`code_${currentIndex + index}` as keyof TwoFactorCodeFields, code, {shouldValidate: true})
    });
    focusCodeByNumber(currentIndex + paste.length);
  }

  return (
    <Stack spacing="32px">
      <Stack spacing="24px">
        <Stack spacing="8px">
          <Stack spacing="24px" data-test-selector="two-factor-codes">
            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
              {Object.values(fields).map((f, i) => {
                return (
                  <Field
                    {...f}
                    key={f.name}
                    inputProps={{
                      maxLength: 1,
                      onKeyDown: guardCodeInputs,
                      onPaste: parseCodeFromPaste,
                      onInput: updateCodeState,
                      ...f.inputProps
                    }}
                  />
                )
              })}
            </Box>
          </Stack>
          {errors.root && (
            <Flex as="span" align="flex-start" gap="4px">
              <ExclamationCircleIcon
                color="error"
                marginRight="2px"
                marginTop="4px"
                boxSize="12px"
              />
              <Text variant="text4" color="error" data-test-selector="validation-error">
                {(errors.root as FieldError).message}
              </Text>
            </Flex>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
