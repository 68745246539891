import React from 'react';
import { observer } from 'mobx-react';
import { PushAlert } from './push-alert';
import { useAlertsStore, useGlobalStore } from '../../store/hooks/useStore';
import { ErrorBoundary } from '../error-boundary';


const PushAlerts = observer(() => {
  if (typeof window !== 'undefined') {
    const globalStore = useGlobalStore()
    if (globalStore.alertsUrl) {
      const alertsStore = useAlertsStore()
      const updateLastActive = () => {
        if (alertsStore){
          alertsStore.lastActiveDate = new Date();
        }
      }
      // Listen to mouse movements and touch events so we know if the page is in the background.
      document.body.addEventListener('mousedown', updateLastActive)
      document.body.addEventListener('touchstart', updateLastActive)

      const alerts = alertsStore?.alerts || {}
      const appVersion = globalStore.appVersion

      return (
        <ErrorBoundary renderErrorMessage={false}>{Object.values(alerts).map((alert, index) => (
            <PushAlert message={alert} key={index} appVersion={appVersion} />
        ))}</ErrorBoundary>
      )
    }
    // The alerts URL presence is used like a feature flag to enable / disable this service without relying on custom preferences.
    return <></>
  }
  // If rendering on the SSR render nothing.
  return <></>
});

export default PushAlerts;