import React, {useRef} from 'react'
import {useIntl} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {useRouteMatch} from 'react-router-dom'
import {
  useBasketStore,
  useContentStore,
  useCustomerStore,
  useGlobalStore,
} from '../../store/hooks/useStore'
import {
  Box,
  Center,
  Container,
  Flex,
  Text,
  useDisclosure,
  Button,
  Stack,
  BaseButton,
} from '../../vanilla'
import {Link} from '../link'
import {BasketIcon, BrandLogo, HamburgerIcon, UserIcon, HeartIcon} from '../icons'
import MobileNavigationDrawer from './mobile-navigation-drawer'
import {Autocomplete} from '../algolia/autocomplete'
import {BookADeliveryPopover} from './book-a-delivery-popover'
import DesktopNavigation from './desktop-navigation'
import {MyAccountPopover} from './my-account-popover'
import {CartDrawer} from './cart-drawer'
import {CheckoutBasketButton} from './checkout-basket-button'
import {CredentialModalPlacementMap} from '../../types/ui'
import {vars} from '../../vanilla/vars.css'
import {noop} from '../../utils/utils'
import {linkUtilityMenu, viewBasketButton, viewBasketContent} from './styles.css'
import {useCredentialModal} from '../../contexts'
import {PreviewPanel} from '../amplience-preview/preview-panel'
import {Spinner} from '../spinner'
import PushAlerts  from '../push-alerts'

const hideNavigationMenuPages = ['/sitemap']

const Header = observer(() => {
  const mobileNav = useDisclosure(false)
  const cartDrawer = useDisclosure(false)
  const basketStore = useBasketStore()
  const customerStore = useCustomerStore()
  const credentialModal = useCredentialModal()
  const {params: {isContentPreview} = {}} = useContentStore()

  const {isExact: onBasketPage} = useRouteMatch('/basket') || {isExact: false}
  const {isExact: hideNavigationMenu} = useRouteMatch(hideNavigationMenuPages) || {
    isExact: false,
  }

  // We'll use this on the header element and pass it to our mobile nav drawer.
  // This will allow us to position the drawer at the bottom of the header
  // element and apply the correct drawer height from the top of the viewport.
  const positionRef = useRef(null)
  const {formatNumber} = useIntl()
  const {currency} = useGlobalStore()

  return (
    <>
      <Box as="header" width="full" bg="white" ref={positionRef}>
      <PushAlerts />
        <Container paddingX={['4px', '32px', '0px']}>
        
          <Flex
            align="center"
            justify="space-between"
            height={{mobile: '44px', desktop: '64px'}}
            marginY={['0px', '8px', '0px']}
          >
            <Flex align="center" gap="2px">
              <Flex display={{mobile: 'flex', tablet: 'none'}}>
                <BaseButton
                  variant="unstyled"
                  data-test-selector="open-nav-mobile"
                  color="inherit"
                  height="44px"
                  width="40px"
                  paddingX="0px"
                  paddingY="4px"
                  aria-label="Menu"
                  onClick={mobileNav.onToggle}
                >
                  <Stack gap="0px" alignItems="center">
                    <HamburgerIcon boxSize="24px" />
                    <Text
                      as="span"
                      variant="unstyled"
                      align="center"
                      textTransform="uppercase"
                      style={{
                        fontSize: 7,
                        lineHeight: '8.5px',
                        color: '#4a4b4d',
                      }}
                    >
                      Menu
                    </Text>
                  </Stack>
                </BaseButton>
              </Flex>
              <Box
                as={Link}
                to="/"
                display="block"
                height={['16px', '24px']}
                aria-label="Iceland Foods UK"
              >
                <BrandLogo width="auto" height="full" />
              </Box>
            </Flex>

            <Flex alignItems="center">
              <Box display={['none', 'block']} marginY="20px">
                <Flex>
                  <Box
                    as={Link}
                    to="/customer-support/contact-us"
                    paddingX="12px"
                    borderRight="1px"
                    borderColor="gray200"
                    color="gray800"
                    className={linkUtilityMenu}
                  >
                    <Text variant="text1" lineHeight="shorter">
                      Help
                    </Text>
                  </Box>
                  <Box
                    as={Link}
                    to="/store-finder"
                    paddingX="12px"
                    borderRight="1px"
                    borderColor="gray200"
                    color="gray800"
                    className={linkUtilityMenu}
                  >
                    <Text variant="text1" lineHeight="shorter">
                      Store Finder
                    </Text>
                  </Box>
                  {customerStore.isRegistered ? (
                    <>
                      <Box
                        as={Link}
                        to="/account/order-history"
                        paddingX="12px"
                        color="gray800"
                        borderRight="1px"
                        borderColor="gray200"
                        className={linkUtilityMenu}
                      >
                        <Text variant="text1" lineHeight="shorter">
                          My Orders
                        </Text>
                      </Box>
                      <MyAccountPopover />
                    </>
                  ) : (
                    <Box
                      as={Link}
                      to="/account/register"
                      paddingX="12px"
                      color="gray800"
                      data-test-selector="register"
                      className={linkUtilityMenu}
                    >
                      <Text variant="text1" lineHeight="shorter">
                        Register
                      </Text>
                    </Box>
                  )}
                </Flex>
              </Box>

              {(basketStore.hasItems || customerStore.isRegistered) && (
                <Flex display={['flex', 'none']} align="center" gap="16px">
                  {customerStore.isRegistered && (
                    <BaseButton
                      as={Link}
                      href="/my-favourites"
                      data-test-selector="my-favourites"
                      variant="unstyled"
                      height="40px"
                      paddingX="0px"
                      aria-label="My Favourites"
                      width="auto"
                    >
                      <HeartIcon color="accent3" height="16px" width="auto" />
                    </BaseButton>
                  )}

                  <BasketIconButton onClick={!onBasketPage ? cartDrawer.onOpen : noop} />
                </Flex>
              )}

              {customerStore.customerInfo && !('authType' in customerStore.customerInfo) && (
                <Flex justify="center">
                  <Spinner color="primary" size="sm" />
                </Flex>
              )}
              {!customerStore.isRegistered && !basketStore.hasItems && (
                <Stack
                  as={Link}
                  to="/login"
                  display={['flex', 'none']}
                  alignItems="center"
                  data-test-selector="mobile-login"
                  gap="0px"
                  paddingRight="8px"
                >
                  <UserIcon color="accent3" width="24px" height="auto" />
                  <Text
                    variant="text1"
                    style={{fontSize: '11px', fontWeight: 500, lineHeight: '11px'}}
                  >
                    Sign In
                  </Text>
                </Stack>
              )}
              {!customerStore.isRegistered && customerStore.customerInfo?.authType === 'guest' && (
                <Box display={['none', 'block']} ref={credentialModal.attachmentRef}>
                  <Box
                    data-test-selector="sign-in"
                    paddingY="12px"
                    paddingX="4px"
                    style={{
                      backgroundColor: credentialModal.isOpen ? 'white' : 'unset',
                      position: credentialModal.isOpen ? 'relative' : 'static',
                      zIndex: credentialModal.isOpen ? vars.zIndex.popover : 'unset',
                    }}
                  >
                    <Button
                      onClick={() => {
                        credentialModal.setModalPlacementType(CredentialModalPlacementMap.ATTACHED)
                        credentialModal.onToggle()
                      }}
                      style={{height: '40px'}}
                    >
                      Sign in
                    </Button>
                  </Box>
                </Box>
              )}
            </Flex>
          </Flex>
          <Flex
            paddingBottom={['8px', '16px']}
            justifyContent="space-between"
            width="full"
            id="header-search-and-account-actions"
          >
            <Flex height="44px" align="center" width="full" paddingX={['8px', '0px']}>
              <Autocomplete />
            </Flex>
            <Flex display={['none', 'flex']}>
              {customerStore.isRegistered && (
                <BaseButton
                  variant="filledDark"
                  marginRight="8px"
                  as={Link}
                  to="/account/bonus-card"
                >
                  <Flex
                    flexDirection="column"
                    alignItems="flex-start"
                    marginRight="8px"
                    marginLeft="-4px"
                  >
                    <Text variant="text1" textDecoration="none" lineHeight="none">
                      Bonus
                    </Text>
                    <Text
                      variant="text0"
                      textDecoration="none"
                      fontWeight="normal"
                      lineHeight="none"
                    >
                      Card
                    </Text>
                  </Flex>
                </BaseButton>
              )}
              {customerStore.isRegistered && (
                <BaseButton marginRight="8px" variant="outlineLight" as={Link} to="/my-favourites">
                  <Flex paddingY="8px" gap="8px" alignItems="center">
                    <HeartIcon color="accent3" height="16px" width="auto" />
                    <Text variant="text3" textDecoration="none">
                      Favourites
                    </Text>
                  </Flex>
                </BaseButton>
              )}
              <BookADeliveryPopover />
              <Button
                onClick={!onBasketPage ? cartDrawer.onOpen : noop}
                position="relative"
                aria-label="View Basket"
                variant="outlineLight"
                data-test-selector="view-basket"
                className={viewBasketButton}
              >
                <Flex alignItems="center" className={viewBasketContent}>
                  <Flex position="relative" color="accent3" marginRight="12px">
                    <Text
                      position="absolute"
                      display="inline-block"
                      variant="unstyled"
                      fontSize="xxs"
                      lineHeight="none"
                      bg="accent0"
                      color="white"
                      align="center"
                      style={{
                        top: '-5px',
                        right: 0,
                        borderRadius: '7px',
                        left: '7px',
                        padding: '2px',
                      }}
                      minWidth="16px"
                    >
                      {basketStore?.count || 0}
                    </Text>
                    <BasketIcon />
                  </Flex>
                  <Flex>
                    <Text variant="text4">
                      {formatNumber(basketStore?.basket?.productTotal|| 0, {
                        style: 'currency',
                        currency: currency,
                      })}
                    </Text>
                  </Flex>
                </Flex>
              </Button>
              {!onBasketPage && <CheckoutBasketButton />}
            </Flex>
          </Flex>
          {isContentPreview && <PreviewPanel />}
        </Container>
        {!hideNavigationMenu && (
          <Stack
            display={{mobile: 'none', tablet: 'flex'}}
            backgroundColor="gray25"
            borderTop="1px"
            borderColor="gray200"
            paddingTop="12px"
          >
            <Container paddingX={['4px', '32px', '0px']}>
              <DesktopNavigation />
            </Container>
          </Stack>
        )}
        {!hideNavigationMenu && <MobileNavigationDrawer {...mobileNav} positionRef={positionRef} />}
        <CartDrawer {...cartDrawer} positionRef={positionRef} />
      </Box>
    </>
  )
})

const BasketIconButton = observer(({onClick}: {onClick: () => void}) => {
  const basketStore = useBasketStore()
  const {currency} = useGlobalStore()
  const {formatNumber} = useIntl()

  return (
    <BaseButton
      variant="unstyled"
      color="gray800"
      width="auto"
      height="40px"
      paddingX="0px"
      aria-label="Basket"
      display="flex"
      gap="12px"
      onClick={onClick}
    >
      <Stack position="relative">
        <Center
          position="absolute"
          bg="accent0"
          borderRadius="full"
          minWidth="12px"
          height="16px"
          style={{top: '50%', right: -5, transform: 'translateY(-15px)'}}
        >
          <Text color="white" align="center" variant="text0">
            {basketStore.count}
          </Text>
        </Center>
        <BasketIcon color="accent3" />
      </Stack>

      <Text variant="text2" lineHeight="none">
        {formatNumber(basketStore?.basket?.productTotal || 0, {
          style: 'currency',
          currency: currency,
        })}
      </Text>
    </BaseButton>
  )
})

export default Header
