import React from 'react'
import { SlotComponent } from '../../types/ui'
import { Stack, Text, Box } from '../../vanilla'
import { CTAButton } from '../../vanilla/components/CTAButton'
import { CTA } from '../../types/cms'
import { DefaultContentBody } from 'dc-delivery-sdk-js'
import * as Icons from '../icons'
import { accountNotification } from './styles.css'

interface AccountNotificationContent extends DefaultContentBody {
  text: {
    title: string
    description: string
  }
  cta: CTA
  icon: keyof typeof Icons
}

export const AccountNotificationBanner: SlotComponent<DefaultContentBody> = ({ content }) => {
  const { text, cta, icon } = content as AccountNotificationContent

  if (!text || !cta) return null

  const IconComponent = Icons[icon]

  return (
    <Stack
      className={accountNotification}
      border='1px'
      borderColor='gray200'
      borderRadius='base'
    >
      <Box 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={['column', 'row']}
      >
        {IconComponent && <IconComponent boxSize="24px" marginLeft="20px" />}
        <Text
          variant="heading1Bold"
          lineHeight="base"
          as="h2"
          marginX={["10px", "20px"]}
        >
          {text.title}
        </Text>
        <Text>{text.description}</Text>
      </Box>
      <CTAButton 
        cta={cta}
        marginX="30px" 
      />
    </Stack>
  )
}