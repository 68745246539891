import React from 'react'
import {Box, Flex, Container} from '../../../vanilla'
import {CTA, Image} from '../../../types/cms'
import {CmsImage} from '../cms-image'
import {CTAButton} from '../../../vanilla/components/CTAButton'
import {SlotComponent} from '../../../types/ui'
import {thinBannerCtaPosition, thinBannerImage} from './styles.css'
import {useSelectPromotionTracking} from '../../../analytics/promotion-tracking'

export interface ThinBannerCmsContent {
  image?: {
    imageLink: string
    image: {
      images: Array<Image>
    }
  }
  cta: CTA
}

export const ThinBanner: SlotComponent<ThinBannerCmsContent> = ({content}) => {
  const images = content?.image?.image?.images

  const cta = content?.cta

  const promotion = {
    creative_name: content?.image?.image?.images[0].data?.altText ?? 'Thin banner missing alt text',
    creative_slot: `${content?.slotGroupName} + ${content?._meta?.name}` ?? 'Thin Banner',
    promotion_id: content?.image?.imageLink ?? 'Thin banner without link',
    promotion_name: content?.cta?.ctaText ?? 'Thin banner without cta',
    link_location: 1
  }

  const sendSelectPromotion = useSelectPromotionTracking()

  if (!images) {
    return null
  }

  return (
    <Box width="full" position="relative" overflow="hidden">
      <Container paddingX="0px">
        <Box position="relative">
          <Flex align="stretch" flexDirection={['column', 'row']}>
            <CmsImage
              queryParams={[
                {w: 1752, fmt: 'auto', qlt: 'default'}, 
                {w: 3504, fmt: 'auto', qlt: 'default'}
              ]}
              className={thinBannerImage}
              images={images}
              width="full"
              height="auto"
              imageLink={content?.image?.imageLink}
              onClick={() => {
                sendSelectPromotion(promotion)
              }}
            />
          </Flex>
          {cta ? (
            <Box position="absolute" className={thinBannerCtaPosition[cta.ctaPosition ?? 'Right']}>
              {cta?.ctaText && (
                <CTAButton
                  cta={cta}
                  onClickCapture={() => {
                    sendSelectPromotion(promotion)
                  }}
                />
              )}
            </Box>
          ) : null}
        </Box>
      </Container>
    </Box>
  )
}
