import {CommerceAPI} from 'commerce-api'
import {computed, flow, makeObservable, observable} from 'mobx'
import {RootStore} from './RootStore'
import {PromiseFlow, StoreModel} from './utils'
import {Status, StatusMap} from '../types/commerce'

export interface PromotionResponse {
  c_contentAsset: string
  groups?: any[]
  c_groupLabels?: string[]
  t?: string
  c_isMealDealLanding: boolean
  c_promotionOverrideMessage: string
  c_rulesetJSON: string
  calloutMsg: string
  id: string
  name: string
  startDate: string
  price?: string
  _type: 'promotion'
  details: string
}

export interface NormalizedPromotion extends Omit<PromotionResponse, '_type' | 'c_rulesetJSON'> {
  productIds: string[]
  discountValue: string[]
  discountName: string
  groups: {
    id?: string
    ids?: string[]
    req?: string[]
    t?: string
  }[]
  groupLabels?: string[]
  price?: string
  t?: string
}

export class PromotionStore implements StoreModel {
  api: CommerceAPI
  promotions: Record<string, NormalizedPromotion>
  fetchQueue: { [key: string]: boolean; }
  status: Status

  constructor(rootStore: RootStore, initialState = {promotions: {}, status: StatusMap.IDLE}) {
    this.promotions = initialState?.promotions
    this.status = initialState?.status
    this.fetchQueue = {}

    makeObservable(this, {
      status: observable,
      promotions: observable,
      isRequestProcessing: computed,
      fetchPromotionsByIds: flow.bound,
    })
    this.api = rootStore.api
  }

  get asJson() {
    return {
      promotions: this.promotions,
    }
  }

  get isRequestProcessing() {
    return this.status === StatusMap.PENDING
  }

  *fetchPromotionsByIds(ids: string[]): PromiseFlow<any> {
    try {
      this.status = StatusMap.PENDING
      // Check if we already have this Promo in the store and De-dupe the list of promos
      // Also exclude here if we're already in process of fetching any of the requested Promos
      const nonExistedPromotionIds = ids?.filter((id) => !Object.prototype.hasOwnProperty.call(this.promotions, id) && !Object.prototype.hasOwnProperty.call(this.fetchQueue, id)) ?? []
      
      // If nothing to fetch, Jog on
      if (!nonExistedPromotionIds.length) return 

      // Add any we need to fetch to the fetching queue to prevent future calls hitting it while we're in progress
      nonExistedPromotionIds.forEach((id) => this.fetchQueue[id] = true)

      // Fetch Promo data for those promotions we're missing
      const promotions = yield this.api.shopperPromotions.getPromotionsByIds({
        parameters: {promotionsIds: nonExistedPromotionIds},
      })

      // If we got nothing back, move on
      if (!promotions?.data) return

      // Store the promotion in the Store
      for (const promotion of promotions.data as PromotionResponse[]) {
        // Store in store
        this.promotions[promotion.id] = transformPromotion(promotion)
        // Remove from fetching list
        delete this.fetchQueue[promotion.id]
      }
      this.status = StatusMap.DONE
    } catch (error) {
      this.status = StatusMap.ERROR
      console.error(`Failed to fetch promotion with ids: ${ids}`, error)
    }
  }
}

const transformPromotion = (promotion: PromotionResponse): NormalizedPromotion => {

  let groups: {id: string; ids: string[]}[] = [];
  let discountName = null;
  let discountValue = null;
  let ruleSet = null;
  
  try {
    ruleSet = promotion.c_rulesetJSON && JSON.parse(promotion.c_rulesetJSON);
  } catch (error) {
    console.error(error)
  }
  
  if (ruleSet) {
    ({ groups, discountName, discountValue } = ruleSet);
  }

  return {
    id: promotion.id,
    name: promotion.name,
    productIds: groups.flatMap(({ids}) => ids),
    groups,
    discountName,
    discountValue,
    c_contentAsset: promotion.c_contentAsset,
    c_isMealDealLanding: promotion.c_isMealDealLanding,
    c_promotionOverrideMessage: promotion.c_promotionOverrideMessage,
    calloutMsg: promotion.calloutMsg,
    startDate: promotion.startDate,
    groupLabels: promotion.c_groupLabels,
    price: promotion.price,
    t: promotion.t,
    details: promotion.details,
  }
}