import React, {useEffect, useState, useRef} from 'react'
import {observer} from 'mobx-react-lite'
import {CreditCardTypeIcon} from '../../../components/credit-card-type-icon'
import {Box, Checkbox, Flex, Stack, Text} from '../../../vanilla'
import {CARD_TYPES, CARD_TYPES_CODE} from '../../../utils/constants'
import {
  CheckIcon,
  LockIcon,
  MastercardAltIcon,
  PaypalAltIcon,
  VisaAltIcon,
} from '../../../components/icons'
import {BraintreeHostedFields, BraintreeInputsProps} from '../../../components/braintree'
import {BaseButton} from '../../../vanilla'
import {Link} from '../../../components/link'
import LoadingOverlay from '../../../components/loading-overlay'
import * as styles from '../styles.css'
import {useGlobalStore, useOrderStore} from '../../../store/hooks/useStore'
import {FormattedNumber} from 'react-intl'
import {FetchPaymentMethodsPayload} from 'braintree-web'
import {HostedFieldsAccountDetails} from 'braintree-web/modules/hosted-fields'
import {PayPalAccountDetails} from 'braintree-web/modules/paypal'
import {useCheckoutState} from '../../../contexts'
import {CheckoutBonusCardStatus} from '../../../types/store/common'
import {CheckoutButton} from './checkout-button'
import {CHECKOUT_ERROR_MESSAGES} from '../../../utils/constants'
import {AddressFormFields} from '../../../types/forms'

// 2 Payment Types - Credit Card & Paypal
const CREDIT_CARD = 'Credit / Debit Card'
const PAYPAL = 'Paypal'

interface PaypalVaultedPaymentMethodProps {
  email: string
  checked: boolean
  onClick: () => void
}

export const PaypalVaultedPaymentMethod = ({email, checked, onClick}: PaypalVaultedPaymentMethodProps) => {
  return (
    <Flex
      className={styles.paymentMethodBox}
      align="center"
      justify="space-between"
      paddingX="12px"
      paddingY="4px"
      border="1px"
      borderColor={checked ? 'accent3' : 'gray200'}
      borderRadius="base"
      cursor="pointer"
      onClick={() => onClick()}
    >
      <Flex gap="12px" align="center">
        <CreditCardTypeIcon cardType={CARD_TYPES.PAYPAL} />
        <Text variant="text3" lineHeight="shorter" data-cs-mask="">
          {email}
        </Text>
      </Flex>

      <Flex
        width="20px"
        height="20px"
        border="1px"
        borderColor={checked ? 'accent3' : 'gray200'}
        borderRadius="full"
        bg={checked ? 'accent3' : 'gray25'}
        align="center"
        justify="center"
      >
        {checked && <CheckIcon width="12px" height="12px" color="white" />}
      </Flex>
    </Flex>
  )
}

interface PaymentMethodProps {
  method: string
  checked: boolean
  onClick: () => void
  type: string
  expirationDate?: string
}

export const PaymentMethod = ({method, checked, onClick, type, expirationDate}: PaymentMethodProps) => {
  return (
    <Flex
      className={styles.paymentMethodBox}
      align="center"
      justify="space-between"
      paddingX="12px"
      paddingY="4px"
      border="1px"
      borderColor={checked ? 'accent3' : 'gray200'}
      borderRadius="base"
      cursor="pointer"
      onClick={() => onClick()}
    >
      <Flex gap="12px" align="center">
        <CreditCardTypeIcon cardType={type as CARD_TYPES_CODE} />
        <Flex direction="column">
          <Text variant="text4" lineHeight="shorter" data-cs-mask="">
            {method}
          </Text>
          {expirationDate && 
            <Text variant="text1" lineHeight="shorter" data-cs-mask="" color="gray400">
              {expirationDate}
            </Text>
          }
        </Flex>
      </Flex>
      <Flex
        width="20px"
        height="20px"
        border="1px"
        borderColor={checked ? 'accent3' : 'gray200'}
        borderRadius="full"
        bg={checked ? 'accent3' : 'gray25'}
        align="center"
        justify="center"
      >
        {checked && <CheckIcon width="12px" height="12px" color="white" />}
      </Flex>
    </Flex>
  )
}

interface PaymentSectionProps {
  submitOrderProcess: () => Promise<void>
  userVaultedCardsLoaded: boolean
  hostedFieldsLoading: boolean
  bonusCardStatus: CheckoutBonusCardStatus
  errorMessage: string | null
  userVaultedCards: FetchPaymentMethodsPayload[]
  selectedPayment: string | null
  setSelectedPayment: React.Dispatch<React.SetStateAction<string | null>>
  usingSavedCard: boolean
  braintreeInputs: BraintreeInputsProps
  hostedFieldsValdationErrors: {
    cardholderName: boolean
    number: boolean
    expirationDate: boolean
    cvv: boolean
  }
  amountToPay: number | null | undefined
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>
  braintreeFormIsValid: boolean
  hostedFieldsErrors: boolean
  threeDSError: boolean
  paypalContainer: string
  hasPaypalLoaded: boolean
  billingAddress?: AddressFormFields
  savedPayPalPaymentAllowed?: boolean
}

export const PaymentSection = observer((props: PaymentSectionProps) => {
  const {
    submitOrderProcess,
    userVaultedCardsLoaded,
    hostedFieldsLoading,
    bonusCardStatus,
    errorMessage,
    userVaultedCards,
    selectedPayment,
    setSelectedPayment,
    usingSavedCard,
    braintreeInputs,
    hostedFieldsValdationErrors,
    amountToPay,
    setErrorMessage,
    braintreeFormIsValid,
    hostedFieldsErrors,
    threeDSError,
    billingAddress,
    paypalContainer,
    hasPaypalLoaded,
    savedPayPalPaymentAllowed = false
  } = props
  const {currency, customSitePreferences, isCustomSitePreferenceSet, getCustomSitePreferenceById} = useGlobalStore()
  const {orderEditOrderTotal, editMode, isPaymentRefunding, isPaymentRequired} = useOrderStore()

  const {billingAddressFormValid, setSaveCreditCard} = useCheckoutState()
  const vaultedFlow = !!customSitePreferences['BRAINTREE_PAYPAL_VAULTFLOW']
  const [savedCardEnabled, setSavedCardEnabled] = useState<boolean>(false) 
  const [allowSaveCreditCard, setAllowSaveCreditCard] = useState<boolean>(false)

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({
    cardNumber: null,
    cardName: null,
    cardExpiry: null,
    cardCvv: null,
  })

  useEffect(() => {
    const handleAutoFill = (event: AnimationEvent) => {
      const target = event.target as HTMLInputElement
      target.dispatchEvent(new Event('input', { bubbles: true }))
    }

    const inputs = Object.values(inputRefs.current)
    inputs.forEach(input => {
      input?.addEventListener('animationstart', handleAutoFill)
    })

    return () => {
      inputs.forEach(input => {
        input?.removeEventListener('animationstart', handleAutoFill)
      })
    }
  }, [])

  // Checks custom pref for allowing saved cards
  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('BRAINTREE_savedCardEnabled')) {
        await getCustomSitePreferenceById('BRAINTREE_savedCardEnabled')
      }
      setSavedCardEnabled(customSitePreferences['BRAINTREE_savedCardEnabled'] as boolean)
    }())
  })

  // Checks custom pref for Braintree Vault Mode
  useEffect(() => {
    (async function () {
      if (!isCustomSitePreferenceSet('BRAINTREE_Vault_Mode')) {
        await getCustomSitePreferenceById('BRAINTREE_Vault_Mode')
      }
      setAllowSaveCreditCard(customSitePreferences['BRAINTREE_Vault_Mode'] as boolean)
    }())
  })

  const showErrorHeading = errorMessage && errorMessage === CHECKOUT_ERROR_MESSAGES.ERROR_GENERAL

  return (
    <Stack
      borderRadius={[null, null, 'base']}
      position="relative"
      bg="white"
      boxShadow="base"
      gap="0px"
    >
      {threeDSError && (
        <Box border="2px" borderColor="accent0" padding={['16px', '16px', '16px']}>
          <Text variant="text3">{CHECKOUT_ERROR_MESSAGES.THREEDSECURE_CANCELLED}</Text>
        </Box>
      )}
      <Box borderBottom="1px" borderColor="border1" padding={['16px', '16px', '16px']}>
        <Flex justify="space-between" alignItems="center">
          <Stack alignItems="baseline" direction="row">
            <LockIcon />
            <Text variant="heading1">Payment Method</Text>
          </Stack>
          <Stack direction="row">
            <VisaAltIcon boxSize="44px" />
            <MastercardAltIcon boxSize="44px" />
            <PaypalAltIcon boxSize="44px" />
          </Stack>
        </Flex>
      </Box>
      <LoadingOverlay
        isLoading={
          (!userVaultedCardsLoaded || hostedFieldsLoading) &&
          !bonusCardStatus?.hidePayment &&
          !isPaymentRefunding &&
          isPaymentRequired
        }
      />
      {errorMessage && errorMessage !== CHECKOUT_ERROR_MESSAGES.CVV_ERROR && (
        <Stack
          paddingX={['16px', '20px']}
          paddingY={['20px', '24px']}
          style={{background: '#F9E5E9'}}
        >
          {showErrorHeading && (
            <Text variant="heading1" lineHeight="shorter" color="accent0">
              Unable to place order
            </Text>
          )}
          <Text variant="text4" lineHeight="shorter" color="accent0">
            {errorMessage}
          </Text>
        </Stack>
      )}
      {isPaymentRefunding ? (
        <Stack direction="row" padding="16px">
          <Text variant="text6">Amount to refund:</Text>
          <Text color="accent3" variant="text6">
            <FormattedNumber
              value={Math.abs(orderEditOrderTotal ?? 0)}
              style="currency"
              currency={currency}
              maximumFractionDigits={2}
              minimumFractionDigits={2}
            />
          </Text>
        </Stack>
      ) : !isPaymentRequired ? (
        <Stack direction="row" padding="16px">
          <Text variant="text6">Amount left to pay:</Text>
          <Text color="accent3" variant="text6">
            <FormattedNumber
              style="currency"
              currency={currency}
              value={orderEditOrderTotal ?? 0}
            />
          </Text>
        </Stack>
      ) : (
        <>
          {!bonusCardStatus?.hidePayment && (
            <Box
              padding="16px"
              width={['full', 'full']}
              style={{
                visibility: !userVaultedCardsLoaded || hostedFieldsLoading ? 'hidden' : 'visible',
              }}
            >
              <Stack>
                {bonusCardStatus?.bonusCardUsed && (
                  <Stack direction="row">
                    <Text variant="text6">Amount left to pay:</Text>
                    <Text color="accent3" variant="text6">
                      <FormattedNumber
                        style="currency"
                        currency={currency}
                        value={bonusCardStatus.leftToPay ?? 0}
                      />
                    </Text>
                  </Stack>
                )}

                <Stack justifyContent="space-between" direction="row" flexWrap="wrap">

                  {savedCardEnabled && userVaultedCards?.map((card) => {
                    
                    if (!card?.details || !card.nonce || card?.type === 'PayPalAccount' && !savedPayPalPaymentAllowed) return null

                    if (card?.type === 'PayPalAccount' && vaultedFlow) {
                      return (
                        <PaypalVaultedPaymentMethod
                          checked={selectedPayment === card.nonce}
                          email={(card?.details as PayPalAccountDetails).email ?? ''}
                          onClick={() => {
                            if (errorMessage) setErrorMessage(null)
                            setSelectedPayment(card.nonce)
                          }}
                        />
                      )
                    }

                    const {cardType, lastFour, expirationMonth, expirationYear} = card.details as HostedFieldsAccountDetails

                    return (
                      <PaymentMethod
                        method={`${cardType} ending in ${lastFour}`}
                        expirationDate={`Expires on ${expirationMonth}/${expirationYear}`}
                        type={cardType}
                        checked={selectedPayment === card.nonce}
                        onClick={() => {
                          if (errorMessage) setErrorMessage(null)
                          setSelectedPayment(card.nonce)
                        }}
                      />
                    )
                  })}
                  <PaymentMethod
                    method="Credit / Debit Card"
                    checked={selectedPayment === CREDIT_CARD}
                    type="Generic"
                    onClick={() => setSelectedPayment(CREDIT_CARD)}
                  />
                  <PaymentMethod
                    method="PayPal"
                    type="PayPal"
                    checked={selectedPayment === PAYPAL}
                    onClick={() => setSelectedPayment(PAYPAL)}
                  />
                </Stack>
                {errorMessage && errorMessage === CHECKOUT_ERROR_MESSAGES.CVV_ERROR && (
                  <Stack
                    paddingX={'16px'}
                    paddingY={'16px'}
                    style={{ background: '#F9E5E9' }}
                  >
                    <Text variant="text4" lineHeight="shorter" color="accent0">
                      {errorMessage}
                    </Text>
                  </Stack>
                )}
                {selectedPayment !== null && selectedPayment !== PAYPAL && (
                  <Stack gap="12px">
                    <BraintreeHostedFields
                      usingSavedCard={usingSavedCard}
                      braintreeInputs={braintreeInputs}
                      validationError={hostedFieldsValdationErrors}
                      inputRefs={inputRefs.current}
                    />
                    {savedCardEnabled && allowSaveCreditCard && selectedPayment === CREDIT_CARD ? (
                      <Box marginTop="8px">
                        <Checkbox
                          id="save-this-credit-payment-method"
                          defaultChecked={false}
                          label="Save this card"
                          hasError={false}
                          onChange={(e) => setSaveCreditCard(e.target.checked)}
                        />
                      </Box>
                    ) : null}
                  </Stack>
                )}
              </Stack>
            </Box>
          )}
        </>
      )}
      <Box
        borderTop={bonusCardStatus?.hidePayment || isPaymentRefunding ? '0px' : '1px'}
        borderColor="border1"
        padding={['16px', '16px', '24px']}
      >
        <Flex justifyContent="space-between">
          <BaseButton variant="outlineDark" as={Link} to="/basket">
            <Text variant="unstyled">Back</Text>
          </BaseButton>
          <Stack style={{width: 230}}>
            <CheckoutButton
              selectedPayment={selectedPayment}
              isEditModeEnabled={editMode}
              isTopUp={false}
              submitOrderProcess={submitOrderProcess}
              amountToPay={amountToPay}
              billingAddressFormValid={billingAddressFormValid}
              setError={setErrorMessage}
              errorMessage={errorMessage || ''}
              isPaymentRefunding={isPaymentRefunding}
              braintreeFormIsValid={braintreeFormIsValid}
              bonusCardStatus={bonusCardStatus}
              hostedFieldsErrors={hostedFieldsErrors}
              billingAddress={billingAddress}
              paypalContainer={paypalContainer}
              hasPaypalLoaded={hasPaypalLoaded}
            />
          </Stack>
        </Flex>
      </Box>
    </Stack>
  )
})
