import React, {useState} from 'react'
import {SubmitHandler, useForm, UseFormReturn} from 'react-hook-form'
import {Button, Flex, Stack, Text, useDisclosure, Box} from '../../../vanilla'
import {SlotComponent} from '../../../types/ui'
import {ColorPicker, Image} from '../../../types/cms'
import {MarketingFormFields as IMarketingFormFields} from '../../../types/forms'
import {MarketingFormFields} from '../../forms/marketing-form-fields'
import {Modal} from '../../modal'
import {CloseIcon} from '../../icons'
import {CmsImage} from '../cms-image'
import {ContentMeta} from 'dc-delivery-sdk-js'

export interface MarketingFormCmsContent {
  titleColor: ColorPicker
  formFields: {
    name: boolean
    phoneNumber: boolean
    checkbox: boolean
    checkboxRequired: boolean
    checkboxLabel: string
  }
  terms: boolean
  title: string
  description: string
  dataCapture: string
  reason: string
  successMessage: string
  successIcon: {
    images: Image[]
  }
  successMessageTitle: string
  _meta: ContentMeta
}

interface MarketingFormContentProps extends MarketingFormCmsContent {
  isModal: boolean
  state: 'initial' | 'success'
  handleClose: () => void
  onSubmit: React.FormEventHandler
  form: UseFormReturn<IMarketingFormFields>
}

const MarketingFormContent = (props: MarketingFormContentProps) => {
  const {
    state,
    form,
    onSubmit,
    handleClose,
    isModal,
    successMessage,
    successMessageTitle,
    successIcon,
    title,
    titleColor,
    description,
    formFields,
    terms,
  } = props

  return (
    <>
      {state === 'success' ? (
        <Stack
          align="center"
          spacing="16px"
          style={{maxWidth: 500}}
          marginX="auto"
          paddingBottom="24px"
          paddingTop={!isModal ? '24px' : '0px'}
        >
          {successIcon && (
            <CmsImage images={successIcon.images} width="80px" style={{objectFit: 'cover'}} />
          )}
          <Stack width="full" align="center" spacing="24px">
            {successMessageTitle && (
              <Text as="h4" align="center" variant="heading5">
                {successMessageTitle}
              </Text>
            )}
            {successMessage && (
              <Text variant="text3" marginBottom="16px" align="center">
                {successMessage}
              </Text>
            )}
          </Stack>

          {isModal ? (
            <Flex justifyContent="center">
              <Button
                onClick={() => {
                  handleClose()
                }}
                width="84px"
              >
                OK
              </Button>
            </Flex>
          ) : null}
        </Stack>
      ) : (
        <Stack
          bg="white"
          paddingX="24px"
          paddingBottom="24px"
          paddingTop={!props.isModal ? '24px' : '0px'}
        >
          <Text
            as="h4"
            variant="heading4"
            lineHeight="taller"
            marginBottom="16px"
            style={{color: titleColor?.color}}
          >
            {title}
          </Text>
          {description && (
            <Text variant="text6" marginBottom="16px">
              {description}
            </Text>
          )}
          <form onSubmit={onSubmit}>
            <MarketingFormFields form={form} {...{...formFields, terms}} />
            <Flex justifyContent="center" marginTop="16px" style={{maxWidth: 450}}>
              <Button size="md" type="submit" width="88px">
                Submit
              </Button>
            </Flex>
          </form>
        </Stack>
      )}
    </>
  )
}

interface MarketingFormProps {
  isModal?: false
}

interface MarketingFormModalProps extends ReturnType<typeof useDisclosure> {
  isModal: true
}

export const MarketingForm: SlotComponent<
  MarketingFormCmsContent,
  MarketingFormProps | MarketingFormModalProps
> = ({content, ...props}) => {
  const {reason, dataCapture} = content

  const [formViewState, setFormViewState] = useState<'initial' | 'success'>('initial')
  const form = useForm<IMarketingFormFields>({mode: 'onBlur', defaultValues: {email: ''}})

  const handleMarketingFormSubmission: SubmitHandler<IMarketingFormFields> = async (formData) => {
    try {
      if (!dataCapture) throw new Error('Data Capture URL is required in form settings')

      const submitData = []
      const {firstname, lastname, marketingPermission, email, phone} = formData

      submitData.push('email=' + encodeURIComponent(email))
      submitData.push('first_name=' + encodeURIComponent(firstname ?? 'None'))
      submitData.push('last_name=' + encodeURIComponent(lastname ?? 'None'))
      submitData.push('phone_number=' + encodeURIComponent(phone ?? 'None'))
      submitData.push(
        'marketing_permission=' + encodeURIComponent(marketingPermission ? 'yes' : 'no'),
      )
      submitData.push('reason=' + encodeURIComponent(reason))

      const res = await fetch(dataCapture, {
        method: 'POST',
        body: submitData.join('&'),
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      })

      if (!res.ok) throw new Error('Form submission failed')

      setFormViewState('success')
    } catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setFormViewState('initial')
  }

  return props.isModal ? (
    <Modal
      size="full-md"
      closeIcon={false}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onOpen={props.onOpen}
      onToggle={props.onToggle}
      setIsOpen={props.setIsOpen}
    >
      <Box position="relative">
        <Flex justify="flex-end" width="full" padding="16px">
          <Button variant="unstyled" onClick={() => props.onClose()} paddingX="0px" height="auto">
            <CloseIcon boxSize="28px" color="gray800" />
          </Button>
        </Flex>
        <Box paddingX="20px" paddingTop="4px" paddingBottom="44px">
          <MarketingFormContent
            isModal={props.isModal ?? false}
            form={form}
            state={formViewState}
            onSubmit={form.handleSubmit(handleMarketingFormSubmission)}
            handleClose={() => {
              handleClose()
              props.onClose()
            }}
            {...content}
          />
        </Box>
      </Box>
    </Modal>
  ) : (
    <MarketingFormContent
      isModal={props.isModal ?? false}
      form={form}
      state={formViewState}
      onSubmit={form.handleSubmit(handleMarketingFormSubmission)}
      handleClose={() => {
        handleClose()
      }}
      {...content}
    />
  )
}
