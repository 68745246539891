import React from 'react'
import {Box, ButtonProps} from '../../vanilla'
import {Hit} from '../../store/ProductStore'
import {ATBButton} from '../add-to-basket-button'
import {Nullable} from '../../types/utils'
import {useBookDeliveryPopover} from '../../contexts'
import { useCustomerStore } from '../../store/hooks/useStore'

interface AddToBasketButtonPopoverProps extends ButtonProps {
  hit: Hit
  displayPrice: Nullable<number>
  isPLPTile: boolean | undefined
  isPDPPage?: boolean | undefined
  onButtonClick: (e: React.MouseEvent | React.TouchEvent) => void
}

export const ATBButtonPopover = React.forwardRef(
  (props: AddToBasketButtonPopoverProps, ref: React.Ref<any>) => {
    const {hit, displayPrice, isPLPTile, isPDPPage, onButtonClick, ...rest} = props
    const {PopoverWrapperComponent, hideBookDeliveryPopover} = useBookDeliveryPopover()

    const customerStore = useCustomerStore()

    const handleAddToBasketClick = (e: React.MouseEvent | React.TouchEvent) => {
      onButtonClick(e)
      customerStore.setLastActiveToNow()
    }

    return (
      <>
        <Box>
          <PopoverWrapperComponent hitId={hit.id} onClickOutside={() => hideBookDeliveryPopover()}>
            <ATBButton
              hit={hit}
              displayPrice={displayPrice}
              isPLPTile={isPLPTile}
              isPDPPage={isPDPPage}
              onClick={handleAddToBasketClick}
              {...rest}
              ref={ref}
            />
          </PopoverWrapperComponent>
        </Box>
      </>
    )
  },
)
