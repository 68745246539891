import React from 'react'
import {Box, Flex, Stack} from '../../vanilla'
import {observer} from 'mobx-react-lite'
import {Container, Text} from '../../vanilla'
import {useContentStore, useCustomerStore} from '../../store/hooks/useStore'
import {Link} from '../link'
import {FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon} from '../../components/icons/index'
import {getAssetUrl} from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'
import {ContentBody} from 'dc-delivery-sdk-js'
import {MenuDefinition} from '../../types/cms'
import {CollapsableMenu} from '../collapsable-menu'
import {APPLE_APP_STORE_LINK, GOOGLE_PLAY_MARKET_LINK} from '../../utils/constants'
import LazyLoad from 'react-lazyload'

interface LinkNode extends ContentBody {
  menuDefinition: MenuDefinition
  externalURL?: string
  route?: {
    path?: string
  }
}

interface TitleNode extends LinkNode {
  nodes: Array<LinkNode>
}

interface FooterCmsContent extends ContentBody {
  showInMenu: boolean
  nodes: Array<TitleNode>
}

export interface FooterNavProps {
  children?: React.ReactNode
  title: string
  titleColor: string
  uniqueIdentifier: string
}

const FooterNav = ({title, titleColor, uniqueIdentifier, children}: FooterNavProps) => {
  return (
    <Stack
      width="full"
      paddingY="12px"
      paddingX="8px"
      borderBottom={['1px', '0px']}
      borderColor={'gray200'}
      style={{flexBasis: '20%'}}
      key={uniqueIdentifier}
    >
      <Box>
        <Text
          variant="text7"
          paddingY="2px"
          lineHeight="tall"
          style={{
            color: titleColor || '#000',
          }}
        >
          {title}
        </Text>
      </Box>
      <Stack>{children}</Stack>
    </Stack>
  )
}

const MediaContent = () => {
  return (
    <Flex direction={'row'} gap="16px">
      <Box as="a" href="https://www.facebook.com/icelandfoods" aria-label="Facebook">
        <FacebookIcon style={{color: ' #878787', width: '26px', height: '26px'}} />
      </Box>
      <Box as="a" href="https://twitter.com/IcelandFoods" aria-label="Twitter">
        <TwitterIcon style={{color: ' #878787', width: '26px', height: '26px'}} />
      </Box>
      <Box
        as="a"
        href="https://www.youtube.com/user/IcelandFoods?feature=watch"
        aria-label="Youtube"
      >
        <YoutubeIcon style={{color: ' #878787', width: '26px', height: '26px'}} />
      </Box>
      <Box as="a" href="https://www.instagram.com/icelandfoods/" aria-label="Instagram">
        <InstagramIcon style={{color: ' #878787', width: '26px', height: '26px'}} />
      </Box>
    </Flex>
  )
}

const CardOptions = () => {
  return (
    <Stack spacing="12px" direction={['row', 'column']} justifyContent={'center'}>
      <Box as="a" href={APPLE_APP_STORE_LINK}>
        <img
          alt="App Store"
          src={getAssetUrl('static/img/apple-app-store.svg')}
          loading="lazy"
          style={{
            display: 'block',
            width: '125px',
            height: '40px',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box as="a" href={GOOGLE_PLAY_MARKET_LINK}>
        <img
          alt="Google Play"
          src={getAssetUrl('static/img/google-play.svg')}
          loading="lazy"
          style={{
            display: 'block',
            width: '125px',
            height: '36px',
            objectFit: 'contain',
          }}
        />
      </Box>
    </Stack>
  )
}

const Footer = observer(() => {
  const cms = useContentStore()
  const {isRegistered} = useCustomerStore()
  const nav = cms.contentById['footer-links'] as FooterCmsContent

  return (
    <Box as="footer" bg="white" width="full" data-test-selector="footer">
      <Container paddingX={['4px', '8px', '16px']}>
        <Flex paddingY="20px" flexDirection={['column', 'row']}>
          {nav && nav.showInMenu && (
            <>
              {[...nav.nodes]
                ?.sort((curr, next) =>
                  curr?.menuDefinition?.ranking < next?.menuDefinition?.ranking ? -1 : 1
                )
                ?.map((titleNode: TitleNode) => {
                  const {navDisplayName, showInMenu, menuTextColor} = titleNode.menuDefinition
                  const nodes = [...titleNode.nodes]
                    ?.sort((curr, next) =>
                      curr?.menuDefinition?.ranking < next?.menuDefinition?.ranking ? -1 : 1
                    )
                    ?.map((linkNode) => {
                      const {navDisplayName, showInMenu, menuTextColor} = linkNode.menuDefinition
                      let path = linkNode?.route?.path
                      const externalUrl = linkNode.externalURL
                      // If the user is not registered, we should redirect to the login page
                      if (path === '/account') {
                        if (!isRegistered) {
                          path = '/login'
                      }
                    }
                      return showInMenu ? (
                        <Text
                          variant="text1"
                          lineHeight="short"
                          key={linkNode._meta.deliveryId}
                          as={path || externalUrl ? Link : 'span'}
                          {...(path ? {to: path} : {})}
                          {...(externalUrl ? {href: externalUrl} : {})}
                          style={{
                            color: menuTextColor?.color || '#000',
                          }}
                        >
                          {navDisplayName}
                        </Text>
                      ) : null
                    })
                  return (
                    showInMenu && (
                      <Stack
                        width="full"
                        paddingY="12px"
                        paddingX="8px"
                        borderBottom={['1px', '0px']}
                        borderColor={'gray200'}
                        style={{flexBasis: '20%'}}
                        key={titleNode._meta.deliveryId}
                      >
                        <Box display={['contents', 'none']}>
                          <CollapsableMenu title={navDisplayName} titleColor={menuTextColor?.color}>
                            {titleNode.nodes ? (
                              <Stack marginTop="12px" spacing="16px">
                                {nodes}
                              </Stack>
                            ) : null}
                          </CollapsableMenu>
                        </Box>
                        <Box display={['none', 'contents']}>
                          <FooterNav
                            title={navDisplayName}
                            titleColor={menuTextColor?.color}
                            uniqueIdentifier={titleNode._meta.deliveryId}
                          >
                            {titleNode.nodes ? <Stack spacing="16px">{nodes}</Stack> : null}
                          </FooterNav>
                        </Box>
                      </Stack>
                    )
                  )
                })}
            </>
          )}
          <Stack
            width="full"
            paddingY="12px"
            paddingX="8px"
            borderBottom={['1px', '0px']}
            borderColor={'gray200'}
            style={{flexBasis: '20%'}}
          >
            <Box display={['contents', 'none']}>
              <CollapsableMenu
                title={'Stay Updated'}
                titleColor={nav?.nodes[0]?.menuDefinition?.menuTextColor?.color}
              >
                <MediaContent />
              </CollapsableMenu>
            </Box>
            <Box display={['none', 'contents']}>
              <FooterNav
                title={'Stay Updated'}
                titleColor={nav?.nodes[0]?.menuDefinition?.menuTextColor?.color}
                uniqueIdentifier={'Stay Updated'}
              >
                <MediaContent />
              </FooterNav>
            </Box>
          </Stack>
          <Stack
            width="full"
            paddingY="12px"
            paddingX="8px"
            borderBottom={['1px', '0px']}
            borderColor={'gray200'}
            style={{flexBasis: '20%'}}
          >
            <Box display={['contents', 'none']}>
              <CollapsableMenu
                title={'Iceland Bonus Card App'}
                titleColor={nav?.nodes[0]?.menuDefinition?.menuTextColor?.color}
              >
                <CardOptions />
              </CollapsableMenu>
            </Box>
            <Box display={['none', 'contents']}>
              <FooterNav
                title={'Iceland Bonus Card App'}
                titleColor={nav?.nodes[0]?.menuDefinition?.menuTextColor?.color}
                uniqueIdentifier={'Iceland Bonus Card App'}
              >
                <CardOptions />
              </FooterNav>
            </Box>
          </Stack>
        </Flex>

        <Flex
          paddingY="20px"
          paddingX={'8px'}
          borderTop={['0px', '1px']}
          borderColor={'gray200'}
          alignItems={'center'}
          justifyContent={['center', 'flex-end']}
          flexWrap={'wrap'}
          gap={'36px'}
        >
          <Stack direction={['column', 'row']} alignItems={'center'} gap={'8px'}>
            <Text
              variant="text7"
              paddingY="2px"
              lineHeight="tall"
              style={{color: nav?.nodes[0]?.menuDefinition?.menuTextColor?.color || '#000'}}
            >
              We Securely Accept
            </Text>
              <LazyLoad>
                <Flex direction={'row'} alignItems={'center'} gap={'16px'}>
                  <img
                    alt="Visa"
                    src={getAssetUrl('static/img/visa.svg')}
                    loading="lazy"
                    style={{
                      display: 'block',
                      width: '60px',
                      height: '30px',
                      objectFit: 'contain',
                    }}
                  />
                  <img
                    alt="Mastercard"
                    src={getAssetUrl('static/img/mastercard.svg')}
                    loading="lazy"
                    style={{
                      display: 'block',
                      width: '60px',
                      height: '30px',
                      objectFit: 'contain',
                    }}
                  />
                  <img
                    alt="PayPal"
                    src={getAssetUrl('static/img/paypal.svg')}
                    loading="lazy"
                    style={{
                      display: 'block',
                      width: '90px',
                      height: '30px',
                      objectFit: 'contain',
                    }}
                  />
                </Flex>
              </LazyLoad>
            </Stack>
          </Flex>
        <Stack
          paddingY="20px"
          paddingX={'8px'}
          alignItems={['flex-start', 'center']}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          gap={'36px'}
          flexDirection={['column-reverse', 'row']}
        >
          <Text variant="text3" color={'gray700'}>
            © Copyright {new Date().getFullYear()} Iceland Foods Ltd.
          </Text>
          <Stack gap={'16px'} direction={['column', 'row']} alignItems={['flex-start', 'center']}>
            <Text variant="text3" as={Link} to="/sitemap" color={'gray800'}>
              Sitemap
            </Text>
            <Text variant="text3" as="a" href="/terms.html" color={'gray800'}>
              Terms & Conditions
            </Text>
            <Text variant="text3" as="a" href="/privacy-policy" color={'gray800'}>
              Privacy
            </Text>
            <Text variant="text3" as="a" href="/privacy-policy/cookie-policy" color={'gray800'}>
              Cookies
            </Text>
            <Text id="ot-sdk-btn" className="ot-sdk-show-settings" variant="text3" as="a" href="#" color={'gray800'}>
              Manage Cookies
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
})

export default Footer
