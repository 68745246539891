import React from 'react'
import {observer} from 'mobx-react-lite'
import {useBasketStore} from '../../store/hooks/useStore'
import {Box, Flex, Text} from '../../vanilla'
import {DeliveryMapWithPinIcon} from '../icons'

export const DeliveryAddressContainer = ({showMapIcon = false}) => {
  const {addressDisplay} = useBasketStore()

  return (
    <Flex gap="16px" paddingRight={['0px', '48px']} paddingLeft={['0px', '20px']}>
      {showMapIcon && (
        <Box>
          <DeliveryMapWithPinIcon color="accent0" boxSize="60px" />
        </Box>
      )}
      <Box>
        <Text color="accent0" variant="text4">
          We'll deliver to:
        </Text>
        <Text variant="text4" data-cs-mask="">{addressDisplay}</Text>
      </Box>
    </Flex>
  )
}

export const DeliveryAddress = observer(DeliveryAddressContainer)
