import React, {RefObject, useRef, useState} from 'react'
import classNames from 'classnames'
import {FormattedNumber} from 'react-intl'
import {observer} from 'mobx-react-lite'
import {ArrowContainer, Popover} from 'react-tiny-popover'
import useNavigation from '../../hooks/use-navigation'
import {useBasketStore, useGlobalStore} from '../../store/hooks/useStore'
import {Box, Button, Flex, Text} from '../../vanilla'
import {baseThemeContract} from '../../vanilla/vars.css'
import {checkoutButton} from './styles.css'

export const CheckoutBasketButton = observer(() => {
  const basketStore = useBasketStore()
  const globalStore = useGlobalStore()
  const navigate = useNavigation()
  const ref = useRef<HTMLElement | undefined>(undefined)
  const [isMinCheckoutPopoverOpen, setIsMinCheckoutPopoverOpen] = useState(false)

  const underMinValue =
    (basketStore?.basket?.productTotal || 0) < (basketStore.minimumBasketValue || 0)

  return (
    <Flex
      ref={ref as RefObject<HTMLDivElement>}
      onMouseLeave={() => {
        if (underMinValue) setIsMinCheckoutPopoverOpen(false)
      }}
      onMouseEnter={() => {
        if (underMinValue) setIsMinCheckoutPopoverOpen(true)
      }}
    >
      <Popover
        isOpen={isMinCheckoutPopoverOpen}
        parentElement={ref.current}
        content={({position, childRect, popoverRect}) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor="white"
            arrowSize={10}
            arrowStyle={{zIndex: '101'}}
          >
            <Box
              display="flex"
              flexDirection="column"
              bg="white"
              padding="16px"
              textAlign="center"
              justifyContent="center"
              style={{boxShadow: '0 10px 25px 0 rgb(0 0 0 / 40%)'}}
            >
              <Text as="span" variant="text3" lineHeight="short">
                Minimum Order of{' '}
                <Text as="span" variant="text5" lineHeight="short">
                  <FormattedNumber
                    value={basketStore.minimumBasketValue || 0}
                    currency={globalStore.currency}
                    style="currency"
                  />
                </Text>
              </Text>
              <Text as="span" variant="text3" lineHeight="short" align="center">
                is required to Checkout
              </Text>
            </Box>
          </ArrowContainer>
        )}
        positions={['bottom']}
        align="end"
        reposition={true}
        containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
        onClickOutside={() => setIsMinCheckoutPopoverOpen(false)}
      >
        <Button
          disabled={underMinValue}
          onClick={() => navigate('/basket')}
          className={classNames('header-checkout-button', checkoutButton)}
          display={['none', 'none', 'inline-flex']}
        >
          Checkout
        </Button>
      </Popover>
    </Flex>
  )
})
