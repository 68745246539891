import React from 'react'
import {BaseButton, Stack, Text} from '../../../../vanilla'
import {useBasketStore} from '../../../../store/hooks/useStore'
import {Countdown} from '../../../book-delivery/reservation-status'
import {Link} from '../../../link'
import {drawerBookingSlotText} from './styles.css'
import {observer} from 'mobx-react'

export const ReservationDetails = observer(() => {
  const {addressDisplay, basket} = useBasketStore()

  return (
    <Stack spacing="4px">
      <Text color="accent0" className={drawerBookingSlotText}>
        {`We'll deliver to:`}
      </Text>
      <Text className={drawerBookingSlotText} data-cs-mask="">{addressDisplay}</Text>
      <Text className={drawerBookingSlotText}>
        This delivery reservation expires in{' '}
        <Countdown color="accent0" textVariant="text2" slotExpiration={basket?.c_slotExpiryTime || ''} />
      </Text>
      <BaseButton
        variant="primary"
        size="sm"
        alignSelf="stretch"
        as={Link}
        to="/book-delivery"
        style={{
          paddingTop: 6,
          paddingBottom: 6,
          whiteSpace: 'initial',
          height: 'auto',
          minHeight: 36,
        }}
      >
        <Text
          variant="unstyled"
          fontSize="inherit"
          lineHeight="inherit"
          fontWeight="inherit"
          align="center"
        >
          Change Delivery Time
        </Text>
      </BaseButton>
    </Stack>
  )
})
