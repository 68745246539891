// These transforms need to be adapted to Iceland
import {useLocation} from 'react-router-dom'
import he from "he"

const transformCategories = (productCategories) => {
  let categoryProps = {};
  if (productCategories) {
    let categoryCount = 0;
    Object.keys(productCategories).forEach(key => {
      let category = productCategories[key];
      let categoryIndex = parseInt(key);
      if (!isNaN(categoryIndex) && categoryIndex >= 0 && categoryIndex < 3) {
        categoryCount++;
        let categoryParts = category.split(' > ');
        let categoryName = categoryParts[categoryParts.length - 1];
        categoryName = he.decode(categoryName);
        categoryProps[`itemCategory${categoryCount === 1 ? '' : categoryCount}`] = categoryName;
      }
    });
  }
  return categoryProps;
};

export const transformAlgoliaItemsForGA4 = (items, hitItemIndex, itemListName) => {
  return items.map((item, index) => {
    const productCategories = item?.__primary_category;
    const categoryProps = transformCategories(productCategories);

    let wasPrice = 0
    let currentPrice = item?.price?.GBP ?? 0

    if(item && Object.prototype.hasOwnProperty.call(item, 'priceInfo') && Object.prototype.hasOwnProperty.call(item.priceInfo, 'wasPrice')) {
      wasPrice = parseFloat(item.priceInfo.wasPrice)
    }
    
    const discount = wasPrice > 0 && currentPrice > 0 ? Number((wasPrice - currentPrice).toFixed(2)) : 0

    return {
      item_id: item?.id ?? '',
      item_name: item?.name ?? '',
      item_category: categoryProps.itemCategory ?? '',
      item_category2: categoryProps.itemCategory2 ?? '',
      item_category3: categoryProps.itemCategory3 ?? '',
      item_brand: item?.brand ?? '',
      index: hitItemIndex ? hitItemIndex : index + 1,
      quantity: 1,
      price: currentPrice,
      discount: discount,
      in_stock: item?.in_stock || item?.inStock,
      item_list_name: itemListName ?? ''
    }
  })
}

export const transformAlgoliaItemsForBasketEventsGA4 = (items, cartLocation, viewingCategoryId, searchQuery, hitItemIndex) => {
  return items.map((item, index) => {
    const productCategories = item?.__primary_category;
    const categoryProps = transformCategories(productCategories);

    let wasPrice = 0
    let currentPrice = item?.price?.GBP ?? 0

    if(item && Object.prototype.hasOwnProperty.call(item, 'priceInfo') && Object.prototype.hasOwnProperty.call(item.priceInfo, 'wasPrice')) {
      wasPrice = parseFloat(item.priceInfo.wasPrice)
    }

    const discount = wasPrice > 0 && currentPrice > 0 ? Number((wasPrice - currentPrice).toFixed(2)) : 0

    let categoryName = '';

    for (const categoryArray of item.categories) {
      for (const category of categoryArray) {
        if (category.id === viewingCategoryId) {
          categoryName = he.decode(category.name)
        }
      }
    }

    let itemListName, itemListId = '';

    if (cartLocation === CART_LOCATION.SEARCH) {
      itemListId = CART_LOCATION.SEARCH;
      itemListName = searchQuery;
    } else if (cartLocation === CART_LOCATION.PDP) {
      itemListId = '';
      itemListName = '';
    } else if (cartLocation !== CART_LOCATION.PLP) {
      itemListId = '';
      itemListName = cartLocation;
    }  else {
      itemListId = viewingCategoryId;
      itemListName = categoryName;
    }

    return {
      item_id: item?.id ?? '',
      item_name: item?.name ?? '',
      item_category: categoryProps.itemCategory ?? '',
      item_category2: categoryProps.itemCategory2 ?? '',
      item_category3: categoryProps.itemCategory3 ?? '',
      item_brand: item?.brand ?? '',
      index: hitItemIndex ? hitItemIndex : index + 1,
      quantity: 1,
      price: currentPrice,
      discount: discount,
      in_stock: item?.in_stock || item?.inStock,
      item_list_id: itemListId ?? '',
      item_list_name: itemListName ?? ''
    }
  })
}

export const transformSalesforceItemsForGA4 = (items) => {
  return items.map((item, idx) => {
    return {
      item_name: item.name,
      item_id: item.id,
      price: item?.price, // Needs to change when locales are merged
      item_brand: item.c_brandCategory,
      item_category: item.primaryCategoryId,
      item_category_2: '',
      item_category_3: '',
      item_category_4: '',
      item_variant: item?.values?.color,
      item_list_name: '',
      item_list_id: item.primary_category_id,
      index: idx,
      size: item?.values?.size,
      quantity: 1,
    }
  })
}

export const transformSalesforceBasketItemsForGA4 = (items, productsById) => {
  return items.map((productItem) => {
    const product = productItem.productId ? productsById[productItem.productId] : undefined
    const productCategories = product?.__primary_category;
    const categoryProps = transformCategories(productCategories);
    const price = productItem.basePrice

    let wasPrice = 0
    if(product && Object.prototype.hasOwnProperty.call(product, 'priceInfo') && Object.prototype.hasOwnProperty.call(product.priceInfo, 'wasPrice')) {
      wasPrice = parseFloat(product.priceInfo.wasPrice)
    }

    return {
      item_id: productItem.productId,
      item_name: productItem.productName,
      item_category: categoryProps.itemCategory ?? '',
      item_category2: categoryProps.itemCategory2 ?? '',
      item_category3: categoryProps.itemCategory3 ?? '',
      item_brand: product.brand ?? '',
      quantity: productItem.quantity ?? 0,
      price: price,
      discount: wasPrice > 0 ? Number((wasPrice - price).toFixed(2)) : 0
    }
  })
}

export const transformNavMenuPromoItemsForGA4 = (promotions, navMenuLevel, hasNavFolders) => {
  if (!promotions) return

  return promotions
    .flatMap((childItem) => {
      let creativeName = 'No category ID';
      const categoryId = childItem.categoryId || (hasNavFolders ? childItem.children?.[0]?.categoryId : 'No category ID');

      if (categoryId !== undefined && categoryId !== 'undefined') {
        creativeName = categoryId;
      }
        
      return {
        creative_name: creativeName,
        creative_slot: navMenuLevel,
        promotion_id: childItem.path ?? 'Nav folder',
        promotion_name: childItem.name,
      }
    })
    .filter(Boolean)
}

export const transformNavMenuPromoBannersForGA4 = (flyOutContent) => {
  const targetSchema = 'https://www.iceland.co.uk/content/nav-flyout-banner.json'
  const promoBanners = flyOutContent.default.find(
    (item) => item._meta && item._meta.schema === targetSchema,
  )

  return promoBanners.listItems?.map(({image, imageLink}, index) => {
    return {
      creative_name: image?.images?.[0]?.data?.altText ?? 'Banner missing alt text',
      creative_slot: `${promoBanners._meta?.name} ${index + 1}` ?? 'Navigation flyout banner',
      promotion_id: imageLink ?? 'Banner without link',
      promotion_name: 'No CTA',
    }
  })
}

export const CART_LOCATION = {
  AUTOCOMPLETE: 'Quick Search',
  CHECKOUT: 'Checkout',
  PLP: 'PLP',
  PDP: 'PDP',
  BASKET: 'Basket',
  MINIBASKET: 'Mini Basket',
  SEARCH: 'Search',
  PROMO: 'Promotional Modal',
  CAROUSEL: "Product Carousel"
}

export const useSlotLocation = () => {
  const {pathname} = useLocation();
  let slotLocation;

  if (pathname === '/') {
    slotLocation = 'homepage';
  } else {
    slotLocation = pathname.split('/').filter(part => part);
    slotLocation = slotLocation[slotLocation.length - 1].replace(/-/g, ' ');
  }
  return slotLocation;
}

export const transformPromoSlotsForGA4 = (content) => {
  const HOME_HERO_CAROUSEL_SCHEMA = 'https://www.iceland.co.uk/content/carousel.json'
  const HOME_HERO_BANNER_SCHEMA = 'https://www.iceland.co.uk/content/hero-banner.json'
  const OFFER_CARD_SCHEMA = 'https://www.iceland.co.uk/content/offer-card.json'
  const OFFER_CARDS_SCHEMA = 'https://www.iceland.co.uk/content/offer-cards.json'
  const THIN_BANNER_SCHEMA = 'https://www.iceland.co.uk/content/thin-banner.json'
  const CATEGORY_TILES_SCHEMA = 'https://www.iceland.co.uk/content/category-tiles.json'
  const IN_GRID_CARD_SCHEMA = 'https://www.iceland.co.uk/content/in-grid-card.json'

  const promotions = content?.flatMap((slotGroup) => {
    return slotGroup?.flatMap((promotion) => {
      if (promotion?._meta.schema === HOME_HERO_CAROUSEL_SCHEMA) {
        return promotion.items.map((slot) => ({
          creative_name: (slot.image)?.images?.[0]?.data?.altText ?? 'Banner missing alt text',
          creative_slot: `${promotion.slotGroupName} + ${slot._meta.name}` ?? 'Hero Banner - Carousel',
          promotion_id: slot.imageLink ?? 'Banner without link',
          promotion_name: slot?.cta?.ctaText ?? 'Banner without CTA',
        }));
      } else if (promotion?._meta.schema === HOME_HERO_BANNER_SCHEMA) {
        return [{
          creative_name: (promotion.image)?.images?.[0]?.data?.altText ?? 'Banner missing alt text',
          creative_slot: `${promotion.slotGroupName} + ${promotion._meta.name}` ?? 'Hero Banner',
          promotion_id: promotion.imageLink ?? 'Banner without link',
          promotion_name: promotion?.cta?.ctaText ?? 'Banner without CTA',
        }];
      } else if (promotion?._meta.schema === OFFER_CARDS_SCHEMA) {
        return promotion.cardList.map((card, index) => ({
          creative_name: card.image?.images?.[0]?.data?.altText ?? `Offer card #${index + 1} missing alt text`,
          creative_slot: `${promotion.slotGroupName} + ${promotion._meta.name}` ?? `Offer card #${index + 1}`,
          promotion_id: card.imageLink ?? `Offer card #${index + 1} without link`,
          promotion_name: card.cta?.ctaText ?? `Offer card #${index + 1} without cta`,  
        }));
      } else if (promotion?._meta.schema === OFFER_CARD_SCHEMA) {
        return [{
          creative_name: promotion.image?.images?.[0]?.data?.altText ?? 'Offer card #1 missing alt text',
          creative_slot: `${promotion.slotGroupName} + ${promotion._meta.name}` ?? 'Offer card #1',
          promotion_id: promotion.imageLink ?? 'Offer card #1 without link',
          promotion_name: promotion.cta?.ctaText ?? 'Offer card #1 without cta',
        }];
      } else if (promotion?._meta.schema === THIN_BANNER_SCHEMA) {
        return [{
          creative_name: promotion.image?.image?.images?.[0]?.data?.altText ?? 'Thin banner missing alt text',
          creative_slot: `${promotion.slotGroupName} + ${promotion._meta.name}` ?? 'Thin banner',
          promotion_id: promotion.image?.imageLink ?? 'Thin banner without link',
          promotion_name: promotion.cta?.ctaText ?? 'Thin banner without cta',
        }];
      } else if (promotion?._meta.schema === CATEGORY_TILES_SCHEMA) {
        return promotion.tileList.map((tile, tileIndex) => ({
          creative_name: tile.image?.images?.[0]?.data?.altText ?? `Category tile #${tileIndex + 1} missing alt text`,
          creative_slot: `${promotion.slotGroupName} + ${promotion._meta.name}` ?? `Category tile #${tileIndex + 1}`,
          promotion_id: tile.imageLink ?? `Category tile #${tileIndex + 1} without link`,
          promotion_name: tile.title ?? `Category tile #${tileIndex + 1} without cta`,  
        }));
      } else if (promotion?._meta.schema === IN_GRID_CARD_SCHEMA) {
        return [{
          creative_name: promotion.image?.images[0]?.data?.altText ?? 'InGrid Card missing alt text',
          creative_slot: `${promotion._meta.name} ${promotion.index}` ?? 'InGrid Card',
          promotion_id: promotion.imageLink ?? 'InGrid Card without link',
          promotion_name: promotion.cta?.ctaText ?? 'InGrid Card without cta',
        }];
      }
      return [];
    });
  });

  return promotions;
};