import React, {ReactNode, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {nanoid} from 'nanoid'
import classNames from 'classnames'
import MultiCarousel, {
  ResponsiveType,
  CarouselProps as MultiCarouselProps,
  CarouselInternalState,
} from 'react-multi-carousel'
import {Sprinkles} from '../../vanilla/sprinkles.css'
import {CarouselDot, Controls} from './controls'
import {baseDotList, dotListInnerBottom, dotListOuterCenter} from './controls/styles.css'
import {Pointer} from '../../utils/utils'
import {sliderContainerZIndex} from './styles.css'
import {NonUndefined} from '../../types/utils'

export type Position = 'innerBottom' | 'bottom' | 'default' | 'outerCenter'

export interface ControlConfig {
  activeDotColor?: Sprinkles['color']
  showDots?: boolean
  showArrows?: Record<'mobile' | 'tablet' | 'desktop', boolean>
  arrowsBgColor?: Sprinkles['color']
  arrowsColor?: Sprinkles['color']
  arrowsOutline?: boolean
  dotsOutline?: boolean
  disableNextControlCondition?: (state: Partial<CarouselInternalState>) => boolean
  disablePrevControlCondition?: (state: Partial<CarouselInternalState>) => boolean
}

interface CarouselProps {
  className?: string
  itemsPerSide?: {mobile: number; desktop: number; tablet: number}
  children: ReactNode
  controlsPosition?: Position
  customResponsiveConfig?: ResponsiveType
  controlConfig?: ControlConfig
  rotationSpeed?: number
  loop?: boolean
  itemClassName?: string
  autoPlay?: boolean
  minimumTouchDrag?: number
  afterChangeCallback?: (
    ...args: Parameters<NonUndefined<MultiCarouselProps['afterChange']>>
  ) => void
  prevCustomHandler?: (
    state: Partial<CarouselInternalState> & {leftReached: boolean; previous?: () => void},
  ) => void
  nextCustomHandler?: (
    state: Partial<CarouselInternalState> & {rightReached: boolean; next?: () => void},
  ) => void
}

const classnamePositionMap: Record<Position, string> = {
  innerBottom: dotListInnerBottom,
  outerCenter: dotListOuterCenter,
  bottom: '',
  default: '',
} as const

export const Carousel = (props: CarouselProps) => {
  const {
    className = '',
    itemsPerSide,
    customResponsiveConfig,
    children,
    controlsPosition = 'default',
    rotationSpeed = 5,
    controlConfig,
    loop = false,
    itemClassName,
    autoPlay = false,
    minimumTouchDrag = 80,
    afterChangeCallback,
    prevCustomHandler,
    nextCustomHandler,
  } = props
  const pointer = useRef<ReturnType<typeof Pointer> | null>(null)
  const [isMoving, setIsMoving] = useState(false)

  const [allowAutoplay, setAllowAutoplay] = useState(autoPlay)

  const id = useMemo(() => `carousel-${nanoid(4)}`, [])
  const renderControlsOutside = !controlsPosition.includes('inner')

  const handleMouseDownEvent = (e: Event) => {
    const p = pointer?.current

    if (!p) return

    p.start(e as MouseEvent)
  }

  const handleMouseUpEvent = (e: Event) => {
    const p = pointer?.current

    if (!p) return

    if (p.isDragEvent(e as MouseEvent)) {
      setIsMoving(true)
    } else {
      setIsMoving(false)
      p.reset()
    }
  }

  const handleMouseMoveEvent = (e: Event) => {
    const p = pointer?.current

    if (!p) return

    const {x, y} = p.getCoords()

    if (p.isDragEvent(e as MouseEvent) && x > 0 && y > 0) {
      setIsMoving(true)
    } else {
      setIsMoving(false)
    }
  }

  const onTouchStart = () => {
    setAllowAutoplay(false)
  }

  const onTouchEnd = () => {
    setAllowAutoplay(autoPlay)
  }

  useEffect(() => {
    pointer.current = Pointer(50)

    return () => {
      const list = document.getElementsByClassName('list-to-observe')?.[0]

      if (!list) return

      list.removeEventListener('mouseup', handleMouseUpEvent)
      list.removeEventListener('mousemove', handleMouseMoveEvent)
      list.removeEventListener('mousedown', handleMouseDownEvent)
      list.removeEventListener('touchstart', onTouchStart)
      list.removeEventListener('touchend', onTouchEnd)
    }
  }, [])

  const refCallback = useCallback((node:HTMLElement | null) => {
    if (node) {
      const list = document.getElementsByClassName('list-to-observe')?.[0]

      if (!list) return

      list.addEventListener('mousedown', handleMouseDownEvent)
      list.addEventListener('mousemove', handleMouseMoveEvent)
      list.addEventListener('mouseup', handleMouseUpEvent)
      list.addEventListener('touchstart', onTouchStart, {passive: true})
      list.addEventListener('touchend', onTouchEnd, {passive: true})
    }
  }, [])

  const responsiveConfig = useMemo(() => {
    const {mobile, desktop, tablet} = itemsPerSide || {}

    if (customResponsiveConfig) {
      return customResponsiveConfig
    }

    return {
      desktop: {
        breakpoint: {min: 1024, max: 99999},
        items: desktop ?? 1,
        slidesToSlide: desktop ?? 1,
      },
      tablet: {
        breakpoint: {max: 1024, min: 780},
        items: tablet ?? 1,
        slidesToSlide: tablet ?? 1,
      },
      mobile: {
        breakpoint: {max: 780, min: 0},
        items: mobile ?? 1,
        slidesToSlide: mobile ?? 1,
      },
    }
  }, [itemsPerSide, customResponsiveConfig])

  return (
    <MultiCarousel
      ref={(node) => refCallback(node as HTMLElement | null)}
      ssr
      swipeable
      draggable
      minimumTouchDrag={minimumTouchDrag}
      showDots
      arrows={false}
      containerClass={className}
      autoPlay={allowAutoplay}
      infinite={loop}
      renderDotsOutside={renderControlsOutside}
      renderButtonGroupOutside={renderControlsOutside}
      sliderClass={classNames('list-to-observe', {
        [sliderContainerZIndex]: controlsPosition === 'outerCenter',
      })}
      itemClass={classNames(itemClassName, {'disable-pointer-events': isMoving})}
      dotListClass={classNames(baseDotList, id, classnamePositionMap[controlsPosition])}
      customDot={<CarouselDot config={controlConfig} />}
      customButtonGroup={
        <Controls
          position={controlsPosition}
          config={controlConfig}
          dotContainerClassName={id}
          loop={loop}
          prevCustomHandler={prevCustomHandler}
          nextCustomHandler={nextCustomHandler}
        />
      }
      responsive={responsiveConfig}
      customTransition={`transform 300ms ease-in-out`}
      autoPlaySpeed={rotationSpeed * 1000}
      afterChange={(...args) => {
        requestAnimationFrame(() => {
          setIsMoving(false)
          pointer.current?.reset()
        })
        afterChangeCallback?.(...args)
      }}
    >
      {children}
    </MultiCarousel>
  )
}
