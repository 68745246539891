import React, {forwardRef} from 'react'
import {Box, BoxProps} from '../../vanilla'

import '../../assets/svg/alert.svg'
import '../../assets/svg/arrow-left.svg'
import '../../assets/svg/arrow-right.svg'
import '../../assets/svg/check-circle.svg'
import '../../assets/svg/check.svg'
import '../../assets/svg/close.svg'
import '../../assets/svg/filter.svg'
import '../../assets/svg/lock.svg'
import '../../assets/svg/minus.svg'
import '../../assets/svg/payment.svg'
import '../../assets/svg/plus.svg'
import '../../assets/svg/account.svg'
import '../../assets/svg/copy.svg'
import '../../assets/svg/basket.svg'
import '../../assets/svg/chevron-down.svg'
import '../../assets/svg/chevron-left.svg'
import '../../assets/svg/chevron-right.svg'
import '../../assets/svg/chevron-up.svg'
import '../../assets/svg/chevron-left-outlined.svg'
import '../../assets/svg/chevron-right-outlined.svg'
import '../../assets/svg/hamburger.svg'
import '../../assets/svg/exclamation-circle.svg'
import '../../assets/svg/location.svg'
import '../../assets/svg/search.svg'
import '../../assets/svg/wishlist.svg'
import '../../assets/svg/user.svg'
import '../../assets/svg/eye.svg'
import '../../assets/svg/svg-social-icons/facebook.svg'
import '../../assets/svg/svg-social-icons/twitter.svg'
import '../../assets/svg/svg-social-icons/youtube.svg'
import '../../assets/svg/svg-social-icons/instagram.svg'
import '../../assets/svg/review-star-fill.svg'
import '../../assets/svg/review-star.svg'
import '../../assets/svg/review-star-half.svg'
import '../../assets/svg/generic.svg'
import '../../assets/svg/air-fry.svg'
import '../../assets/svg/air-fryer-friendly.svg'
import '../../assets/svg/bakery.svg'
import '../../assets/svg/bbq_icon.svg'
import '../../assets/svg/bigger-packs.svg'
import '../../assets/svg/blackfriday.svg'
import '../../assets/svg/bonfire.svg'
import '../../assets/svg/brands.svg'
import '../../assets/svg/breakfast.svg'
import '../../assets/svg/burger.svg'
import '../../assets/svg/can.svg'
import '../../assets/svg/carrot.svg'
import '../../assets/svg/cheese.svg'
import '../../assets/svg/chilli.svg'
import '../../assets/svg/chinesenewyear.svg'
import '../../assets/svg/christmas.svg'
import '../../assets/svg/cupcake.svg'
import '../../assets/svg/desserts.svg'
import '../../assets/svg/drinks.svg'
import '../../assets/svg/egg.svg'
import '../../assets/svg/events.svg'
import '../../assets/svg/everyday-essentials.svg'
import '../../assets/svg/exclusive.svg'
import '../../assets/svg/exclusive-b-and-w.svg'
import '../../assets/svg/fathersday.svg'
import '../../assets/svg/festivedrinks.svg'
import '../../assets/svg/fish.svg'
import '../../assets/svg/fizzy.svg'
import '../../assets/svg/flash-sale.svg'
import '../../assets/svg/football.svg'
import '../../assets/svg/halloween.svg'
import '../../assets/svg/health-and-beauty.svg'
import '../../assets/svg/homeware.svg'
import '../../assets/svg/house.svg'
import '../../assets/svg/icelolly.svg'
import '../../assets/svg/joints.svg'
import '../../assets/svg/milk.svg'
import '../../assets/svg/mum.svg'
import '../../assets/svg/nachos.svg'
import '../../assets/svg/newyear.svg'
import '../../assets/svg/offers.svg'
import '../../assets/svg/onepound.svg'
import '../../assets/svg/pancake-day.svg'
import '../../assets/svg/partyfood.svg'
import '../../assets/svg/picnic.svg'
import '../../assets/svg/pizza.svg'
import '../../assets/svg/star_light.svg'
import '../../assets/svg/sweets.svg'
import '../../assets/svg/valentinesday.svg'
import '../../assets/svg/veg.svg'
import '../../assets/svg/vegan.svg'
import '../../assets/svg/vegan-badge.svg'
import '../../assets/svg/vegetarian.svg'
import '../../assets/svg/vegetarian-badge.svg'
import '../../assets/svg/xmaspud.svg'
import '../../assets/svg/download-app.svg'
import '../../assets/svg/heart.svg'
import '../../assets/svg/heart-filled.svg'
import '../../assets/svg/pdp-cookheat.svg'
import '../../assets/svg/pdp-cooktime.svg'
import '../../assets/svg/fresh-or-free.svg'
import '../../assets/svg/frozen.svg'
import '../../assets/svg/frozen-badge.svg'
import '../../assets/svg/freezable.svg'
import '../../assets/svg/phone_light.svg'
import '../../assets/svg/circle-star.svg'
import '../../assets/svg/multibuy-badge.svg'
import '../../assets/svg/exclamation-triangle.svg'
import '../../assets/svg/bonus-calendar.svg'
import '../../assets/svg/bonus-coins-circle.svg'
import '../../assets/svg/bonus-delivery-circle.svg'
import '../../assets/svg/bonus-money-circle.svg'
import '../../assets/svg/bonus-star-circle.svg'
import '../../assets/svg/info.svg'
import '../../assets/svg/trash.svg'
import '../../assets/svg/van-motion.svg'
import '../../assets/svg/start-motion.svg'
import '../../assets/svg/cross-bold.svg'
import '../../assets/svg/calendar.svg'
import '../../assets/svg/account-bonus-card.svg'
import '../../assets/svg/account-user.svg'
import '../../assets/svg/account-passphrase.svg'
import '../../assets/svg/account-payment-card.svg'
import '../../assets/svg/account-preferences.svg'
import '../../assets/svg/edit.svg'
import '../../assets/svg/map-marker-light-alt.svg'
import '../../assets/svg/location-plus.svg'
import '../../assets/svg/account-addresses.svg'
import '../../assets/svg/crosshairs.svg'
import '../../assets/svg/bonuscard-confirmation.svg'
import '../../assets/svg/bonus-card-outline.svg'
import '../../assets/svg/bonus-money.svg'
import '../../assets/svg/print.svg'
import '../../assets/svg/help.svg'
import '../../assets/svg/faq.svg'
import '../../assets/svg/credit-card.svg'
import '../../assets/svg/payment-loader.svg'
import '../../assets/svg/delivery-map-with-pin.svg'
import '../../assets/svg/delivery-confirmation.svg'
import '../../assets/svg/clock.svg'
import '../../assets/svg/account-order.svg'
import '../../assets/svg/delivery-icon-placed.svg'
import '../../assets/svg/delivery-icon-processed.svg'
import '../../assets/svg/delivery-icon-out-for-delivery.svg'
import '../../assets/svg/delivery-icon-completed.svg'
import '../../assets/svg/delivery-icon-failed.svg'
import '../../assets/svg/house-chimney.svg'
import '../../assets/svg/clock-alert.svg'
import '../../assets/svg/list.svg'
import '../../assets/svg/circle-star-gradient.svg'
import '../../assets/svg/serves-2.svg'
import '../../assets/svg/serves-2-to-4.svg'
import '../../assets/svg/serves-4.svg'
import '../../assets/svg/serves-4-to-6.svg'
import '../../assets/svg/serves-6.svg'
import '../../assets/svg/serves-6-to-8.svg'
import '../../assets/svg/serves-8.svg'
import '../../assets/svg/serves-8-to-10.svg'
import '../../assets/svg/serves-10.svg'
import '../../assets/svg/serves-10-plus.svg'
import '../../assets/svg/spice-mild.svg'
import '../../assets/svg/spice-warm.svg'
import '../../assets/svg/spice-hot.svg'
import '../../assets/svg/spice-extra-hot.svg'
import '../../assets/svg/new-and-exclusive.svg'
import '../../assets/svg/new.svg'
import '../../assets/svg/three-for-three.svg'
import '../../assets/svg/three-for-five.svg'
import '../../assets/svg/three-for-ten.svg'
import '../../assets/svg/four-for-thirty.svg'
import '../../assets/svg/refresh.svg'

/** Amplience Preview panel icons **/
import '../../assets/svg/amplience-preview-icons/view-eye.svg'
import '../../assets/svg/amplience-preview-icons/target.svg'
import '../../assets/svg/amplience-preview-icons/hide.svg'
import '../../assets/svg/amplience-preview-icons/page-path.svg'
import '../../assets/svg/amplience-preview-icons/calendar-alt.svg'
import '../../assets/svg/amplience-preview-icons/puzzle.svg'
import '../../assets/svg/amplience-preview-icons/line.svg'
import '../../assets/svg/amplience-preview-icons/64labs.svg'

// For non-square SVGs, we can use the symbol data from the import to set the
// proper viewBox attribute on the Icon wrapper.
// @ts-ignore
import BrandLogoSymbol from '../../assets/svg/brand-logo.svg'
// @ts-ignore
import VisaSymbol from '../../assets/svg/cc-visa.svg'
// @ts-ignore
import MastercardSymbol from '../../assets/svg/cc-mastercard.svg'
// @ts-ignore
import PaypalSymbol from '../../assets/svg/paypal-64.svg'
// @ts-ignore
import DiscoverSymbol from '../../assets/svg/cc-discover.svg'
// @ts-ignore
import AmexSymbol from '../../assets/svg/cc-amex.svg'
// @ts-ignore
import FeefoVerified from '../../assets/svg/feefo-verified.svg'
// @ts-ignore
import IcelandVerified from '../../assets/svg/iceland-verified.svg'
// @ts-ignore
import DeliverySmallSymbol from '../../assets/svg/delivery-small.svg'
// @ts-ignore
import Delivery from '../../assets/svg/delivery.svg'
// @ts-ignore
import DeliverToHome from '../../assets/svg/deliver-to-home.svg'
// @ts-ignore
import BonusCard from '../../assets/svg/bonus-card.svg'
// @ts-ignore
import VisaAlt from '../../assets/svg/visa.svg'
// @ts-ignore
import Mastercard from '../../assets/svg/mastercard.svg'
// @ts-ignore
import PaypalAlt from '../../assets/svg/paypal-alt.svg'
// @ts-ignore
import CreditCardSymbol from '../../assets/svg/credit-card.svg'

// We're hardcoding the `viewBox` for these imported SVGs temporarily as the
// SVG loader plugin is not properly providing us the symbol data on the client side.
BrandLogoSymbol.viewBox = BrandLogoSymbol.viewBox || '0 0 120 16'
VisaSymbol.viewBox = VisaSymbol.viewBox || '0 0 38 22'
MastercardSymbol.viewBox = MastercardSymbol.viewBox || '0 0 38 22'
PaypalSymbol.viewBox = PaypalSymbol.viewBox || '0 0 80 20'
DiscoverSymbol.viewBox = DiscoverSymbol.viewBox || '0 0 38 22'
AmexSymbol.viewBox = AmexSymbol.viewBox || '0 0 38 22'
FeefoVerified.viewBox = FeefoVerified.viewBox || '0 0 89 30'
IcelandVerified.viewBox = IcelandVerified.viewBox || '0 0 81 40'
DeliverySmallSymbol.viewBox = DeliverySmallSymbol.viewBox || '0 0 24 25'
Delivery.viewBox = Delivery.viewBox || '0 0 24 13'
DeliverToHome.viewBox = DeliverToHome.viewBox || '0 0 183 40'
BonusCard.viewBox = BonusCard.viewBox || '0 0 36 24'
VisaAlt.viewBox = VisaAlt.viewBox || '0 0 77 30'
Mastercard.viewBox = Mastercard.viewBox || '0 0 50 30'
PaypalAlt.viewBox = PaypalAlt.viewBox || '0 0 108 30'
CreditCardSymbol.viewBox = CreditCardSymbol.viewBox || '0 0 77 52'

/* istanbul ignore next */
const icon = (name: string, passProps?: any) => {
  const displayName = name
    .toLowerCase()
    .replace(/(?:^|[\s-/])\w/g, (match) => match.toUpperCase())
    .replace(/-/g, '')

  const component = forwardRef((props: BoxProps<'svg'>, ref) => {
    return (
      <Box
        as="svg"
        ref={ref}
        viewBox="0 0 24 24"
        display="block"
        boxSize="16px"
        {...passProps}
        {...props}
      >
        <use role="presentation" xlinkHref={`#${name}`} />
      </Box>
    )
  })

  component.displayName = `${displayName}Icon`
  return component
}

export type Icon = ReturnType<typeof icon>

// For non-square SVGs, we can use the symbol data from the import to set the
// proper viewBox attribute on the Icon wrapper.
export const BrandLogo = icon('brand-logo', {viewBox: BrandLogoSymbol.viewBox})
export const CopyIcon = icon('copy')
export const BasketIcon = icon('basket')
export const CheckIcon = icon('check')
export const CheckCircleIcon = icon('check-circle')
export const ChevronDownIcon = icon('chevron-down')
export const ChevronLeftIcon = icon('chevron-left')
export const ChevronLeftOutlinedIcon = icon('chevron-left-outlined')
export const ChevronRightIcon = icon('chevron-right')
export const ChevronRightOutlinedIcon = icon('chevron-right-outlined')
export const ChevronUpIcon = icon('chevron-up')
export const ArrowDownIcon = icon('arrow-down')
export const ArrowLeftIcon = icon('arrow-left')
export const ArrowRightIcon = icon('arrow-right')
export const ArrowUpIcon = icon('arrow-up')
export const FilterIcon = icon('filter')
export const HamburgerIcon = icon('hamburger')
export const ExclamationCircleIcon = icon('exclamation-circle')
export const PlusIcon = icon('plus')
export const MinusIcon = icon('minus')
export const UserIcon = icon('user')
export const EyeIcon = icon('eye')
export const WishlistIcon = icon('wishlist')
export const CloseIcon = icon('close')
export const GenericIcon = icon('generic')
export const AirFryIcon = icon('air-fry')
export const AirFryerFriendlyIcon = icon('air-fryer-friendly')
export const BakeryIcon = icon('bakery')
export const BbqIcon = icon('bbq_icon')
export const BiggerPackIcon = icon('bigger-packs')
export const BlackFridayIcon = icon('blackfriday')
export const BonfireIcon = icon('bonfire')
export const BrandsIcon = icon('brands')
export const BreakfastIcon = icon('breakfast')
export const BurgerIcon = icon('burger')
export const CanIcon = icon('can')
export const CarrotIcon = icon('carrot')
export const CheeseIcon = icon('cheese')
export const ChilliIcon = icon('chilli')
export const ChinesenewyearIcon = icon('chinesenewyear')
export const ChristmasIcon = icon('christmas')
export const CupcakeIcon = icon('cupcake')
export const DessertsIcon = icon('desserts')
export const DrinksIcon = icon('drinks')
export const EggIcon = icon('egg')
export const EventIcon = icon('events')
export const EverydayEssentialsIcon = icon('everyday-essentials')
export const ExclusiveIcon = icon('exclusive')
export const ExclusiveBandWIcon = icon('exclusive-b-and-w')
export const FathersdayIcon = icon('fathersday')
export const FestivedrinksIcon = icon('festivedrinks')
export const FishIcon = icon('fish')
export const FizzyIcon = icon('fizzy')
export const FlashSaleIcon = icon('flash-sale')
export const FootballIcon = icon('football')
export const HalloweenIcon = icon('halloween')
export const HealthAndBeautyIcon = icon('health-and-beauty')
export const HomewareIcon = icon('homeware')
export const HouseIcon = icon('house')
export const IcelollyIcon = icon('icelolly')
export const JointsIcon = icon('joints')
export const MilkIcon = icon('milk')
export const MumIcon = icon('mum')
export const NachosIcon = icon('nachos')
export const NewyearIcon = icon('newyear')
export const OffersIcon = icon('offers')
export const OnepoundIcon = icon('onepound')
export const PancakeDayIcon = icon('pancake-day')
export const PartyfoodIcon = icon('partyfood')
export const PicnicIcon = icon('picnic')
export const PizzaIcon = icon('pizza')
export const StarLightIcon = icon('star_light')
export const SweetsIcon = icon('sweets')
export const ValentinesdayIcon = icon('valentinesday')
export const VegIcon = icon('veg')
export const VeganIcon = icon('vegan')
export const VeganBadgeIcon = icon('vegan-badge')
export const VegetarianIcon = icon('vegetarian')
export const VegetarianBadgeIcon = icon('vegetarian-badge')
export const XmaspudIcon = icon('xmaspud')
export const DownloadAppIcon = icon('download-app')
export const AmexIcon = icon('cc-amex', {viewBox: AmexSymbol.viewBox})
// export const CVVIcon = icon('cc-cvv', {viewBox: CVVSymbol.viewBox})
export const DiscoverIcon = icon('cc-discover', {viewBox: DiscoverSymbol.viewBox})
export const PaypalIcon = icon('paypal-64', {viewBox: PaypalSymbol.viewBox})
export const MastercardIcon = icon('cc-mastercard', {viewBox: MastercardSymbol.viewBox})
export const VisaIcon = icon('cc-visa', {viewBox: VisaSymbol.viewBox})
export const PaypalAltIcon = icon('paypal-alt', {viewBox: PaypalAlt.viewBox})
export const MastercardAltIcon = icon('mastercard', {viewBox: Mastercard.viewBox})
export const VisaAltIcon = icon('visa', {viewBox: VisaAlt.viewBox})
export const FeefoVerifiedIcon = icon('feefo-verified', {viewBox: FeefoVerified.viewBox})
export const IcelandVerifiedIcon = icon('iceland-verified', {viewBox: IcelandVerified.viewBox})
export const LockIcon = icon('lock')
export const LocationIcon = icon('location')
export const PaymentIcon = icon('payment')
export const DeliverySmallIcon = icon('delivery-small', {viewBox: DeliverySmallSymbol.viewBox})
export const PhoneIcon = icon('phone_light')
export const CircleStarIcon = icon('circle-star')
export const CircleStarGradientIcon = icon('circle-star-gradient')
export const ExclamationTriangleIcon = icon('exclamation-triangle')
export const BonusCalendarIcon = icon('bonus-calendar')
export const BonusCoinsCircleIcon = icon('bonus-coins-circle')
export const BonusDeliveryCircleIcon = icon('bonus-delivery-circle')
export const BonusMoneyCircleIcon = icon('bonus-money-circle')
export const BonusStarCircleIcon = icon('bonus-star-circle')
export const InfoIcon = icon('info')
export const VanMotionIcon = icon('van-motion')
export const TrashIcon = icon('trash')
export const StarMotionIcon = icon('start-motion')
export const BonusCardIcon = icon('bonus-card', {viewBox: BonusCard.viewBox})
export const CrossBoldIcon = icon('cross-bold')
export const CalendarIcon = icon('calendar')
export const EditIcon = icon('edit')
export const MapMarkerLightIcon = icon('map-marker-light-alt')
export const LocationPlusIcon = icon('location-plus')
export const CrossHairsIcon = icon('crosshairs')
export const BonusCardConfirmationIcon = icon('bonuscard-confirmation')
export const BonusCardOutlineIcon = icon('bonus-card-outline')
export const BonusMoneyIcon = icon('bonus-money')
export const PrintIcon = icon('print')
export const HelpIcon = icon('help')
export const FaqIcon = icon('faq')
export const CreditCardIcon = icon('credit-card')
export const PaymentLoaderIcon = icon('payment-loader')
export const DeliveryMapWithPinIcon = icon('delivery-map-with-pin')
export const DeliveryConfirmationIcon = icon('delivery-confirmation')
export const ClockIcon = icon('clock')
export const DeliveryPlacedIcon = icon('delivery-icon-placed')
export const DeliveryProcessedIcon = icon('delivery-icon-processed')
export const DeliveryOutForDeliveryIcon = icon('delivery-icon-out-for-delivery')
export const DeliveryCompletedIcon = icon('delivery-icon-completed')
export const DeliveryIconFailed = icon('delivery-icon-failed')
export const HouseChimneyIcon = icon('house-chimney')
export const ClockAlertIcon = icon('clock-alert')

// My Account icons
export const AccountIcon = icon('account')
export const AccountAddressesIcon = icon('account-addresses')
export const AccountBonusCardIcon = icon('account-bonus-card')
export const AccountCommunicationPreferencesIcon = icon('account-preferences')
export const AccountOrderIcon = icon('account-order')
export const AccountPassphraseIcon = icon('account-passphrase')
export const AccountPaymentCardIcon = icon('account-payment-card')
export const AccountUserIcon = icon('account-user')

export const FacebookIcon = icon('facebook')
export const YoutubeIcon = icon('youtube')
export const TwitterIcon = icon('twitter')
export const InstagramIcon = icon('instagram')

export const ReviewStar = icon('review-star')
export const ReviewStarFill = icon('review-star-fill')
export const ReviewStarHalf = icon('review-star-half')

export const HeartIcon = icon('heart')
export const ListIcon = icon('list')
export const HeartFilledIcon = icon('heart-filled')

export const PDPCookHeatIcon = icon('pdp-cookheat')
export const PDPCookTimeIcon = icon('pdp-cooktime')
export const DeliveryIcon = icon('delivery', {viewBox: Delivery.viewBox})
export const DeliverToHomeIcon = icon('deliver-to-home', {viewBox: DeliverToHome.viewBox})

export const FreshOrFreeIcon = icon('fresh-or-free')
export const FrozenIcon = icon('frozen')
export const FrozenBadgeIcon = icon('frozen-badge')
export const FreezableIcon = icon('freezable')
export const NewAndExclusiveIcon = icon('new-and-exclusive')
export const NewIcon = icon('new')
export const Serves2Icon = icon('serves-2')
export const Serves2To4Icon = icon('serves-2-to-4')
export const Serves4Icon = icon('serves-4')
export const Serves4To6Icon = icon('serves-4-to-6')
export const Serves6Icon = icon('serves-6')
export const Serves6To8Icon = icon('serves-6-to-8')
export const Serves8Icon = icon('serves-8')
export const Serves8To10Icon = icon('serves-8-to-10')
export const Serves10Icon = icon('serves-10')
export const Serves10PlusIcon = icon('serves-10-plus')
export const SpiceMildIcon = icon('spice-mild')
export const SpiceWarmIcon = icon('spice-warm')
export const SpiceHotIcon = icon('spice-hot')
export const SpiceExtraHotIcon = icon('spice-extra-hot')
export const ThreeForThreeIcon = icon('three-for-three')
export const ThreeForFiveIcon = icon('three-for-five')
export const ThreeForTenIcon = icon('three-for-ten')
export const FourForThirtyIcon = icon('four-for-thirty')
export const RefreshIcon = icon('refresh')

export const MultibuyBadgeIcon = icon('multibuy-badge')

export const AmplienceViewIcon = icon('view-eye')
export const AmplienceHideIcon = icon('hide')
export const AmpliencePathIcon = icon('page-path')
export const AmplienceCalendarIcon = icon('calendar-alt')
export const AmpliencePuzzleIcon = icon('puzzle')
export const AmpliencePencilIcon = icon('line')
export const AmplienceTargetIcon = icon('target')
export const LabsIcon = icon('64labs')
