import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import {Box, BoxProps, Text} from '../../vanilla'
import {TextProps} from '../../vanilla/components/Text'
import {Link} from '../link'

export interface MarkdownProps {
  markdown: string
  componentProps?: {
    h1?: TextProps<React.ElementType>
    h2?: TextProps<React.ElementType>
    h3?: TextProps<React.ElementType>
    h4?: TextProps<React.ElementType>
    h5?: TextProps<React.ElementType>
    h6?: TextProps<React.ElementType>
    p?: TextProps<React.ElementType>
    a?: TextProps<React.ElementType>
    ol?: BoxProps<React.ElementType>
    ul?: BoxProps<React.ElementType>
    li?: TextProps<React.ElementType>
    table?: BoxProps<React.ElementType>
    thead?: BoxProps<React.ElementType>
    tbody?: BoxProps<React.ElementType>
    tr?: BoxProps<React.ElementType>
    th?: TextProps<React.ElementType>
    td?: TextProps<React.ElementType>
    img?: React.ImgHTMLAttributes<HTMLImageElement>
  }
}

export const Markdown = ({markdown, componentProps = {}}: MarkdownProps) => {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw as any]}
      components={{
        h1: ({...props}) => (
          <Text as="h1" variant="heading2" {...(componentProps?.h1 ? {...componentProps.h1} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        h2: ({...props}) => (
          <Text as="h2" variant="text6" {...(componentProps?.h2 ? {...componentProps.h2} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        h3: ({...props}) => (
          <Text as="h3" {...(componentProps?.h3 ? {...componentProps.h3} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        h4: ({...props}) => (
          <Text as="h3" {...(componentProps?.h4 ? {...componentProps.h4} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        h5: ({...props}) => (
          <Text as="h3" {...(componentProps?.h5 ? {...componentProps.h5} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        h6: ({...props}) => (
          <Text as="h3" {...(componentProps?.h6 ? {...componentProps.h6} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        ol: ({...props}) => (
          <Box as="ol" marginLeft="16px" {...(componentProps?.ol ? {...componentProps.ol} : {})}>
            {props?.children}
          </Box>
        ),
        ul: ({node, ...props}) => (
          <Box
            as="ul"
            marginLeft="16px"
            style={{listStyleType: 'disc'}}
            {...(componentProps?.ul ? {...componentProps.ul} : {})}
          >
            {props?.children}
          </Box>
        ),
        li: ({node, ...props}) => (
          <Text as="li" {...(componentProps?.li ? {...componentProps.li} : {})} style={{ ...props.style }}>
            {props?.children}
          </Text>
        ),
        a: ({...props}) => (
          <Text
            as={Link}
            color="accent3"
            href={props?.href}
            {...(props?.title && {'aria-label': props.title})}
            {...(componentProps?.a ? {...componentProps.a} : {})}
            style={{ ...props.style }}
          >
            {props?.children}
          </Text>
        ),
        p: ({...props}) => {
          return (
          <Text {...(componentProps?.p ? {...componentProps.p} : {})} style={{ ...props.style }}>{props?.children}</Text>
        )},
        table: ({...props}) => (
          <Box as="table" {...(componentProps?.table ? {...componentProps.table} : {})}>
            {props?.children}
          </Box>
        ),
        thead: ({...props}) => (
          <Box as="thead" {...(componentProps?.thead ? {...componentProps.thead} : {})}>
            {props?.children}
          </Box>
        ),
        tbody: ({...props}) => (
          <Box as="tbody" {...(componentProps?.tbody ? {...componentProps.tbody} : {})}>
            {props?.children}
          </Box>
        ),
        tr: ({...props}) => (
          <Box as="tr" {...(componentProps?.tr ? {...componentProps.tr} : {})}>
            {props?.children}
          </Box>
        ),
        th: ({...props}) => (
          <Text as="th" {...(componentProps?.th ? {...componentProps.th} : {})}>
            {props?.children}
          </Text>
        ),
        td: ({...props}) => (
          <Text as="td" {...(componentProps?.td ? {...componentProps.td} : {})}>
            {props?.children}
          </Text>
        ),
        img: ({ src, alt, ...props }) => (
          <img 
            src={src} 
            alt={alt} 
            {...(componentProps?.img ? { ...componentProps.img } : {})} 
            style={{ ...props.style }}
          />
        ),
      }}
    >
      {markdown || ''}
    </ReactMarkdown>
  )
}
