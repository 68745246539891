import React from 'react'
import {Stack, Text, Container} from '../../vanilla'
import {Link} from '../link'
import { getMobileAppOS } from '../../utils/utils'

export const SimplyFooter = () => {
  return (
    <Stack bg="white">
      <Container
        paddingY="20px"
        paddingX={'8px'}
        display="flex"
        alignItems={['flex-start', 'center']}
        justifyContent={'flex-start'}
        flexWrap={'wrap'}
        gap={'36px'}
        flexDirection={['column', 'row']}
      >
        {!(getMobileAppOS() && location.pathname == '/login') && (
          <Stack gap={'16px'} direction={['column', 'row']} alignItems={['flex-start', 'center']}>
            <Text variant="text3" as={Link} to="/sitemap" color={'gray800'}>
              Sitemap
            </Text>
            <Text variant="text3" as="a" href="/terms.html" color={'gray800'}>
              Terms & Conditions
            </Text>
            <Text variant="text3" as="a" href="/privacy-policy" color={'gray800'}>
              Privacy
            </Text>
            <Text variant="text3" as="a" href="/privacy-policy/cookie-policy" color={'gray800'}>
              Cookies
            </Text>
            <Text id="ot-sdk-btn" className="ot-sdk-show-settings" variant="text3" as="a" href="#" color={'gray800'}>
              Manage Cookies
            </Text>
          </Stack>
        )}
        <Text variant="text3" color={'gray700'}>
          © Copyright {new Date().getFullYear()} Iceland Foods Ltd.
        </Text>
      </Container>
    </Stack>
  )
}
