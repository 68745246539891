import React from 'react'
import {OmitFromKnownKeys} from '../../store/utils'
import {Box, BoxProps} from '../../vanilla'
import {spinnerRecipe, SpinnerVariants} from './spinner.css'

type SpinnerProps = SpinnerVariants & OmitFromKnownKeys<BoxProps<'div'>, 'color'>

export const Spinner = ({size, color, ...props}: SpinnerProps) => {
  const className = spinnerRecipe({size, color})

  return <Box className={className} {...props} />
}
