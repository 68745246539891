import {useEffect, useRef, useState} from 'react'

export const useMealDealsCompleteness = (groups: any[] | undefined) => {
  const [complete, setComplete] = useState(false)
  const [completeness, setCompleteness] = useState<boolean[] | undefined>([])
  const ref = useRef<boolean[] | undefined>([])
  const update = (index: number, isComplete: boolean) => {
    ref.current = groups?.map((item, i) => {
      if (i === index) {
        return isComplete
      }
      return ref.current?.[i] ?? false
    })

    setCompleteness(ref.current)
  }

  useEffect(() => {
    if (completeness != null && completeness.length > 0) {
      setComplete(completeness.every((bool) => !!bool))
    }
  }, [completeness])

  return {
    complete,
    update,
  }
}
