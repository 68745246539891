import React from 'react'
import {Stack, Flex} from '../../vanilla'
import {SlotComponent} from '../../types/ui'
import {flyoutGrid} from './styles.css'
import {Image} from '../../types/cms'
import {CmsImage} from './cms-image'

export interface NavFlyoutGridContent {
  listItems?: Array<{
    imageLink: string
    image: {
      images: Array<Image>
    }
  }>
}

export const NavFlyoutGrid: SlotComponent<NavFlyoutGridContent> = ({content}) => {
  const imagesList = content?.listItems

  return (
    <Stack spacing="4px">
      <Flex
        alignItems="stretch"
        flexWrap="wrap"
        gap={['12px', '28px']}
        bg="white"
        paddingY={['16px', '20px']}
      >
        {imagesList?.map(({image, imageLink}) => {
          return (
            <CmsImage
              images={image.images}
              width="full"
              height="auto"
              imageLink={imageLink}
              linkProps={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                className: flyoutGrid,
              }}
              key={imageLink}
            />
          )
        })}
      </Flex>
    </Stack>
  )
}
