import {useCallback, useEffect, useState} from 'react'
import {useBasketStore, useGlobalStore} from '../store/hooks/useStore'

const key = 'baglessStores'
export const useBaglessStores = (shouldFetch = false) => {
  const globalStore = useGlobalStore()
  const basketStore = useBasketStore()
  const [baglessStores, setBaglessStores] = useState<string[]>([])

  const isBaglessStore = useCallback(
    (id: string) => {
      return !!baglessStores?.find((storeId) => {
        return id === storeId
      })
    },
    [baglessStores],
  )

  const isCurrentStoreBagless = useCallback(() => {
    if (basketStore.currentDeliveryStoreId == null) {
      return false
    }

    return baglessStores?.includes(basketStore.currentDeliveryStoreId)
  }, [basketStore, baglessStores])

  useEffect(() => {
    if (shouldFetch) {
      ;(async function () {
        await globalStore.getCustomSitePreferenceById(key)
      })()
    }
    const ids = globalStore.customSitePreferences[key] as string[]
    setBaglessStores(ids)
  }, [globalStore, shouldFetch])

  const currentStoreMessage = isCurrentStoreBagless()
    ? 'Picking & Packing'
    : 'Bags, Picking & Packing'

  return {
    baglessStores,
    isBaglessStore,
    isCurrentStoreBagless: isCurrentStoreBagless(),
    currentStoreMessage,
  }
}
