import React from 'react'
import {BaseButton, Stack, Text} from '../../../vanilla'
import {Link} from '../../link'

export const LoginButtons = () => {
  return (
    <Stack
      bg="white"
      direction="row"
      paddingX="12px"
      paddingY="12px"
      borderBottom="1px"
      style={{borderColor: '#d8d8d8'}}
    >
      <BaseButton
        variant="outlinePrimary"
        flexBasis="half"
        size="sm"
        as={Link}
        to="/account/register"
      >
        <Text
          variant="unstyled"
          fontSize="inherit"
          fontWeight="inherit"
          lineHeight="inherit"
          color="inherit"
        >
          Register
        </Text>
      </BaseButton>
      <BaseButton flexBasis="half" size="sm" as={Link} to="/login">
        <Text
          variant="unstyled"
          fontSize="inherit"
          fontWeight="inherit"
          lineHeight="inherit"
          color="inherit"
        >
          Sign In
        </Text>
      </BaseButton>
    </Stack>
  )
}
