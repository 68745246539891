import "app/components/header/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"app/components/header/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81WTY/iMAy98ysi7YUegmj5GCiX+SejtDE0IiRRmk4ZVvz3TVNgm9IC1cxKe43t52f7OcnkI9xHfC+iaZzJT9Do9wihVHKpY/RJ9Bjjj3BVvi2TMloHG2szcDSYQio1MUyKGBWCguZMwGZ0Hk2ucGEspBnHW6Zzg9OMcRo46ERq6445bO1xncZoInJFNAjjQUQuwFlZnYpwjqLp9JBXRE6Y2czHGIVe1KyuoNB5ha0kEwZ05Z+QdL/T0tLF3fXNAg9o3g3U9Gi0bCj6wkVtpTC4BLbLTDtgJvyAZV9d3elIK/pnp/s2pPLQp7L6ViGrRuZH3VPBSzO/cMuAVKpMM0j3sjA4KYyx1btUMWU5STjQ/uaFp55svxZpOk/WXgVrh0OUAmKlnUKMhKzai1CRWw45cEjN38MyYwaw3Y/as9REbR6tRUdjXZ9ydoK7LkGwedJH7jwqAeCs26Ooa6/3WhPKirztM98F/QN+bVLXFrs0d/i0yaE7zbzpkkg730N9C10pT9Wx4WGkemSuTJez5mifbVmvUDp64uF2afAmLoSklQczX+27kPyI1GxuxYkFZ8LpYMvhOFSCilDKxM4SvPTxf9Hk/Si85+jRrfRdNTYHNfQleQK/aMN7+rmpZTpZLjpftAHPVEuqs+Gx7wegjKA81QACEUHR+MAELhk1WSWYaK6O9eehUZD7XXDify7a+62rrO0N9la8x+Mi19p8H+kfn58wu9mSf0oy7GHZKc6XLsvz6PwHYkKMKB8KAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var checkoutButton = '_1k2lkn29';
export var linkUtilityMenu = '_1k2lkn20';
export var megaMenuInternalItem = '_1k2lkn24';
export var megaMenuInternalItemActive = '_1k2lkn23';
export var megaMenuInternalItemText = '_1k2lkn25';
export var megaMenuListItem = '_1k2lkn21';
export var megaMenuSection = '_1k2lkn22';
export var mobileNavigationMenuBackLink = '_1k2lkn27';
export var mobileNavigationMenuListItem = '_1k2lkn28';
export var mobileNavigationMenuListItemRoot = '_1k2lkn26';
export var viewBasketButton = '_1k2lkn2a';
export var viewBasketContent = '_1k2lkn2b';