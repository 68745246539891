import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {ArrowContainer, Popover} from 'react-tiny-popover'
import {FormattedMessage, FormattedNumber} from 'react-intl'
import {Box, BoxProps, Button, Flex, Radio, Stack, Text, BaseButton} from '../../vanilla'
import {Link} from '../link'
import {
  useBasketStore,
  useOrderStore,
  useProductStore,
} from '../../store/hooks/useStore'
import {InfoIcon, LockIcon} from '../icons'
import {baseThemeContract} from '../../vanilla/vars.css'
import {OrderSummaryProgressBars} from './progress-bars'
import {DeliveryDateTime} from '../book-delivery/delivery-date-time'
import {useExitEditModeModal} from '../../pages/cart/use-exit-order-edit-mode-context'
import {useBaglessStores} from '../../hooks/use-bagless-stores'
import {useCarrierBag} from './use-carrier-bag'
import {RefundDetails} from './refund-details'
import {useBeginCheckoutTracking} from '../../analytics/click-event-tracking'
import { useBonusCard } from '../../store/hooks/useBonusCard'

interface OrderSummaryProps extends BoxProps<'div'> {
  tabledMode?: boolean
  showItemsCount?: boolean
  showOrderTotal?: boolean
  title?: string
  minWidthItemsAmount?: number
  rowsContainerProps?: BoxProps<'div'>
  checkoutButtonText?: string
  handleCheckoutButtonClick?: () => void
  isCheckout?: boolean
  includeBag?: boolean
  setIncludeBag?: (value: boolean) => void
}

export const OrderSummary = observer((props: OrderSummaryProps) => {
  const {
    title,
    tabledMode,
    rowsContainerProps,
    minWidthItemsAmount,
    showItemsCount,
    showOrderTotal = true,
    checkoutButtonText,
    handleCheckoutButtonClick,
    isCheckout = false,
    includeBag,
    setIncludeBag,
    ...rest
  } = props

  const {editMode, isPaymentRefunding, isPaymentRequired} = useOrderStore()
  const basketStore = useBasketStore()
  const {lastFour} = useBonusCard(true)

  const {basket: order} = basketStore

  const {isCurrentStoreBagless, currentStoreMessage} = useBaglessStores()

  const [carrierBagPopover, carrierBagPopoverSetIsOpen] = useState(false)

  const exitEditModeModal = useExitEditModeModal()

  const {isCarrierBagEditable} = useCarrierBag()

  const {productsById} = useProductStore()

  const sendBeginCheckout = useBeginCheckoutTracking(productsById)

  const handleClick = () => {
    handleCheckoutButtonClick?.()
    sendBeginCheckout(order, order?.productItems)
  }

  const promotionDiscountValue = basketStore?.basket?.orderDiscount || 0

  const [smallBasketPopover, smallBasketPopoverSetIsOpen] = useState(false)
  return (
    <Stack
      bg="white"
      spacing="0px"
      align="flex-start"
      width="full"
      maxWidth={isCheckout ? '380px' : 'auto'}
      {...(rest || {})}
    >
      <Stack spacing="16px" width="full" align="flex-start" paddingTop="16px">
        {isCheckout && <DeliveryDateTime isCheckout={isCheckout} popover={false} />}
        <Stack width="full">
          {isCheckout && (
            <Box
              borderTop="1px"
              borderBottom="1px"
              borderColor="border1"
              padding={['16px', '16px', '16px']}
            >
              <Flex justifyContent="space-between">
                <Text variant="unstyled">Number of items</Text>
                <Text variant="unstyled">{basketStore.count}</Text>
              </Flex>
            </Box>
          )}
          {/* Basket Subtotal Row */}
          <Flex justify="space-between" paddingX="16px" {...(rowsContainerProps || {})}>
            <Text variant="text3">
              <FormattedMessage defaultMessage="Subtotal" />
            </Text>
            <Flex align="center">
              {showItemsCount && (
                <Text
                  variant="text3"
                  textAlign="center"
                  style={{minWidth: minWidthItemsAmount}}
                >{`${basketStore.count} Item(s)`}</Text>
              )}
              <Text variant="text3">
                {order?.currency && (
                  <FormattedNumber
                    style="currency"
                    currency={order?.currency}
                    value={basketStore?.subtotal}
                  />
                )}
              </Text>
            </Flex>
          </Flex>
          {/* Promotions Discount  Row */}
          {promotionDiscountValue > 0 && (
            <Flex justify="space-between" paddingX="16px" {...(rowsContainerProps || {})}>
              <Text variant="text3" color="accent1">
                <FormattedMessage defaultMessage="Promotional Discount" />
              </Text>
              <Text variant="text3" color="accent1">
                {order?.currency && (
                  <FormattedNumber
                    style="currency"
                    currency={order?.currency}
                    value={promotionDiscountValue * -1}
                  />
                )}
              </Text>
            </Flex>
          )}
          {/* Basket total Row */}
          <Flex justify="space-between" paddingX="16px" {...(rowsContainerProps || {})}>
            <Text variant="text3">
              <FormattedMessage defaultMessage="Basket total" />
            </Text>
            <Text variant="text3">
              {order?.currency && (
                <FormattedNumber
                  style="currency"
                  currency={order?.currency}
                  value={order?.productTotal || 0}
                />
              )}
            </Text>
          </Flex>

          {/* Basket Delivery Row */}
          <Flex justify="space-between" paddingX="16px" {...(rowsContainerProps || {})}>
            <Text variant="text3">
              <FormattedMessage defaultMessage="Delivery" />
            </Text>

            <Text variant="text3">
              {isNaN(order?.shippingItems?.[0].c_deliveryCharge) ? (
                '-'
              ) : order?.shippingItems?.[0].c_deliveryCharge === 0 ? (
                'FREE'
              ) : (
                <FormattedNumber
                  style="currency"
                  currency={order?.currency}
                  value={order?.shippingItems?.[0].c_deliveryCharge}
                />
              )}
            </Text>
          </Flex>

          {/* - Small Basket Charge */}
          {basketStore.basketSmallBasketCharge != null && basketStore.basketSmallBasketCharge != 0 && (
            <Flex
              justify="space-between"
              paddingX="12px"
              bg="gray50"
              marginX="4px"
              {...(rowsContainerProps || {})}
            >
              <Flex align="center" gap="8px">
                <Text variant="text4">Small Basket Fee</Text>
                  <Popover
                    positions={['top']}
                    align="center"
                    reposition={true}
                    containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
                    onClickOutside={() => smallBasketPopoverSetIsOpen(false)}
                    isOpen={smallBasketPopover}
                    content={({position, childRect, popoverRect}) => (
                      <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor="white"
                        arrowSize={10}
                        arrowStyle={{zIndex: baseThemeContract.zIndex.popover}}
                      >
                        <Stack
                          spacing="16px"
                          paddingX="20px"
                          paddingTop="20px"
                          paddingBottom="32px"
                          bg="white"
                          onMouseLeave={() => smallBasketPopoverSetIsOpen(false)}
                          style={{boxShadow: '0 10px 25px 0 rgb(0 0 0 / 40%)', maxWidth: 420}}
                        >
                          <Text variant="heading0">Small Basket Fee</Text>
                          <Text variant="text3" lineHeight="short">
                            Orders below £{basketStore.basketSmallBasketChargeThreshold} will be charged a small basket fee of £{basketStore.basketSmallBasketCharge}
                          </Text>
                        </Stack>
                      </ArrowContainer>
                    )}
                  >
                    <InfoIcon
                      onMouseEnter={() => smallBasketPopoverSetIsOpen(true)}
                      color="gray800"
                      boxSize="16px"
                    />
                  </Popover>
              </Flex>

              <Text
                variant="text3"
                position="relative"
                style={{right: showItemsCount ? -4 : 'initial'}}
              >
                {isNaN(basketStore.basketSmallBasketCharge) ? (
                  '-'
                ) : (
                  <FormattedNumber
                    style="currency"
                    currency={order?.currency}
                    value={basketStore.basketSmallBasketCharge}
                  />
                )}
              </Text>
            </Flex>
          )}

          {/* - End of SBC */}

          {/* Basket Bags and Picking Price Row */}
          {isCarrierBagEditable ? (
            <Box paddingX="8px" paddingY="4px" marginX="8px">
              <Flex align="center" gap="8px">
                <Text variant="text4">Carrier bags</Text>
                <Popover
                  positions={['top']}
                  align="center"
                  reposition={true}
                  containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
                  onClickOutside={() => carrierBagPopoverSetIsOpen(false)}
                  isOpen={carrierBagPopover}
                  content={({position, childRect, popoverRect}) => (
                    <ArrowContainer
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor="white"
                      arrowSize={10}
                      arrowStyle={{zIndex: baseThemeContract.zIndex.popover}}
                    >
                      <Stack
                        spacing="16px"
                        paddingX="20px"
                        paddingTop="20px"
                        paddingBottom="32px"
                        bg="white"
                        onMouseLeave={() => carrierBagPopoverSetIsOpen(false)}
                        style={{boxShadow: '0 10px 25px 0 rgb(0 0 0 / 40%)', maxWidth: 420}}
                      >
                        <Text variant="heading0">Carrier Bags</Text>
                        <Text variant="text3" lineHeight="short">
                          The government now requires that we charge for carrier bags. This charge
                          represents the average amount that we use for online orders.
                        </Text>
                      </Stack>
                    </ArrowContainer>
                  )}
                >
                  <InfoIcon
                    onMouseEnter={() => carrierBagPopoverSetIsOpen(true)}
                    color="gray800"
                    boxSize="16px"
                  />
                </Popover>
              </Flex>
              <Flex gap="16px">
                <Radio
                  label={
                    <>
                      Use carrier bags (
                      <FormattedNumber
                        style="currency"
                        currency={order?.currency}
                        value={basketStore.carrierBagCharge}
                      />
                      )
                    </>
                  }
                  value="true"
                  checked={includeBag === true}
                  name="carrierBags"
                  onChange={() => {
                    setIncludeBag && setIncludeBag(true)
                  }}
                />
                <Radio
                  value="false"
                  label="No carrier bags"
                  checked={includeBag === false}
                  name="carrierBags"
                  onChange={() => {
                    setIncludeBag && setIncludeBag(false)
                  }}
                />
              </Flex>
            </Box>
          ) : (
            <Flex
              justify="space-between"
              paddingX="12px"
              marginX="4px"
              {...(rowsContainerProps || {})}
            >
              <Flex align="center" gap="8px">
                <Text variant="text4">{currentStoreMessage}</Text>
                {!isCurrentStoreBagless && (
                  <Popover
                    positions={['top']}
                    align="center"
                    reposition={true}
                    containerStyle={{zIndex: baseThemeContract.zIndex.popover}}
                    onClickOutside={() => carrierBagPopoverSetIsOpen(false)}
                    isOpen={carrierBagPopover}
                    content={({position, childRect, popoverRect}) => (
                      <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor="white"
                        arrowSize={10}
                        arrowStyle={{zIndex: baseThemeContract.zIndex.popover}}
                      >
                        <Stack
                          spacing="16px"
                          paddingX="20px"
                          paddingTop="20px"
                          paddingBottom="32px"
                          bg="white"
                          onMouseLeave={() => carrierBagPopoverSetIsOpen(false)}
                          style={{boxShadow: '0 10px 25px 0 rgb(0 0 0 / 40%)', maxWidth: 420}}
                        >
                          <Text variant="heading0">Carrier Bags</Text>
                          <Text variant="text3" lineHeight="short">
                            The government now requires that we charge for carrier bags. This charge
                            represents the average amount that we use for online orders.
                          </Text>
                        </Stack>
                      </ArrowContainer>
                    )}
                  >
                    <InfoIcon
                      onMouseEnter={() => carrierBagPopoverSetIsOpen(true)}
                      color="gray800"
                      boxSize="16px"
                    />
                  </Popover>
                )}
              </Flex>

              <Text
                variant="text3"
                position="relative"
                style={{right: showItemsCount ? -4 : 'initial'}}
              >
                {isNaN(basketStore.basketCarrierBagCharge) ? (
                  '-'
                ) : (
                  <FormattedNumber
                    style="currency"
                    currency={order?.currency}
                    value={basketStore.basketCarrierBagCharge}
                  />
                )}
              </Text>
            </Flex>
          )}

          {/* Basket Bonus Card Row */}
          {basketStore?.bonusCardStatus?.bonusCardUsed && isCheckout && (
            <Flex justify="space-between" paddingX="16px" {...(rowsContainerProps || {})}>
              <Text variant="text3">{`Bonus Card ****${lastFour}`}</Text>

              <Text variant="text3">
                <FormattedNumber
                  currency={order?.currency}
                  style="currency"
                  value={
                    basketStore?.bonusCardStatus?.hidePayment
                      ? basketStore?.orderTotal ?? 0
                      : (basketStore?.orderTotal ?? 0) -
                        (basketStore?.bonusCardStatus?.leftToPay ?? 0)
                  }
                />
              </Text>
            </Flex>
          )}
        </Stack>
        {/* Progress bars */}
        {!tabledMode && !editMode && (
          <Box paddingX="16px" width="full">
            <Stack width="full" spacing="8px" padding="16px" borderTop="1px" borderColor="gray200">
              <OrderSummaryProgressBars />
            </Stack>
          </Box>
        )}

        {/* Order Total */}
        {showOrderTotal && (
          <Stack
            spacing="16px"
            width="full"
            borderTop="1px"
            borderBottom="1px"
            borderColor="gray200"
            paddingX="16px"
            paddingY="16px"
            {...(rowsContainerProps || {})}
          >
            <Flex width="full" justify="space-between">
              {!editMode ? (
                <Text variant="text6">{isCheckout ? 'Total' : 'Order Total'}</Text>
              ) : (
                <Text variant="text6">Amount to pay / refund </Text>
              )}
              <Flex align="center">
                {showItemsCount && (
                  <Text
                    variant="text6"
                    textAlign="center"
                    style={{minWidth: minWidthItemsAmount}}
                  >{`${basketStore.count} Item(s)`}</Text>
                )}
                {!editMode ? (
                  <Text variant="text6">
                    {order?.currency && (
                      <FormattedNumber
                        style="currency"
                        currency={order?.currency}
                        value={(order?.orderTotal || order?.productTotal) ?? 0}
                      />
                    )}
                  </Text>
                ) : (
                  <Text variant="text6">
                    {order?.currency && (
                      <FormattedNumber
                        style="currency"
                        currency={order?.currency}
                        value={(order?.orderEditBalanceWithoutBC || 0)}
                      />
                    
                    )}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Stack>
        )}
        {isCheckout && editMode && (
          <>
            {isPaymentRefunding ? (
              <RefundDetails />
            ) : (
              <Flex
                paddingX="16px"
                justify="space-between"
                width="full"
                borderBottom="1px"
                paddingBottom="20px"
                borderColor="gray200"
              >
                <Text variant="heading1">Amount left to pay</Text>
                <Text variant="heading1">
                  {order?.currency && (
                    <FormattedNumber
                      style="currency"
                      currency={order?.currency}
                      value={(isPaymentRequired ? order?.orderEditBalance || 0 : order?.orderEditBalanceWithoutBC || 0)}
                      />
                  )}
                </Text>
              </Flex>
            )}
          </>
        )}
        {!tabledMode && (
          <Flex
            gap="16px"
            direction={{desktop: 'row', mobile: 'column'}}
            paddingX="16px"
            width="full"
            paddingBottom="16px"
          >
            {editMode ? (
              <Button
                variant="outlineDark"
                size="sm"
                width={['full', 'full', 'half']}
                onClick={() => {
                  exitEditModeModal?.onOpen()
                }}
              >
                Discard Changes and Exit
              </Button>
            ) : (
              <BaseButton
                as={Link}
                variant="outlineDark"
                to="/"
                width={['full', 'full', 'half']}
                size="sm"
              >
                <Text
                  variant="unstyled"
                  fontSize="inherit"
                  fontWeight="inherit"
                  color={{hover: 'gray700', default: 'gray800'}}
                >
                  Continue Shopping
                </Text>
              </BaseButton>
            )}
            <Button
              width={['full', 'full', 'half']}
              size="sm"
              variant="primary"
              onClick={() => handleClick()}
              gap="12px"
              alignItems="center"
              disabled={(order?.productTotal || 0) < (basketStore.minimumBasketValue || 0)}
              iconRight={<LockIcon boxSize="12px" />}
            >
              {checkoutButtonText}
            </Button>
          </Flex>
        )}
      </Stack>
    </Stack>
  )
})
