import React, {ElementType} from 'react'
import {Image, Video} from 'dc-delivery-sdk-js'
import {Box, BoxProps, Stack, Text} from '../../vanilla'
import {toJS} from 'mobx'

type VideoType = 'youtube' | 'vimeo' | 'amplience'

interface ContentVideoCommonProps {
  screenSize?: 'Desktop' | 'Tablet' | 'Mobile'
  headline?: {
    headlineTextColor: {
      name: string
      color: string
    }
    headline: string
    headingSize: 'Small' | 'Medium' | 'Large'
  }
  bodyText?: {
    textColor: {
      name: string
      color: string
    }
    bodyText: string
  }
  textControls?: {
    textAlignment: 'Left' | 'Center' | 'Right'
  }
  layout?: {
    videoSize?: string
    backgroundImage?: {
      images: {
        image: Image
      }[]
    }
    backgroundColor?: {
      color: string
      name: string
    }
  }
  videoControls?: {
    autoPlay?: boolean
    loop?: boolean
  }
  videoAmplience?: {
    screenSize?: 'Desktop' | 'Tablet' | 'Mobile'
    video: Video
  }[]
  videoYouTube?: {
    screenSize?: 'Desktop' | 'Tablet' | 'Mobile'
    videoID: string
  }[]
  videoVimeo?: {
    screenSize?: 'Desktop' | 'Tablet' | 'Mobile'
    videoID: string
  }[]
}

type TextAlign = BoxProps<any>['textAlign']
type VideoSet =
  | ContentVideoCommonProps['videoYouTube']
  | ContentVideoCommonProps['videoVimeo']
  | ContentVideoCommonProps['videoAmplience']

const createMediaUrl = (
  media: Image | Video | void,
  options?: {width?: number},
): {
  url: string
  thumbnailUrl?: string
} | null => {
  if (media == null) {
    return null
  }

  if (Image.isImage(media)) {
    const content = new Image(toJS(media), {})

    const mediaUrl = options?.width
      ? content.url().width(options.width).build()
      : content.url().build()
    const url = new URL(mediaUrl)
    url.searchParams.set('fmt', 'auto')

    return {
      url: url.href,
    }
  } else if (Video.isVideo(media)) {
    const content = new Video(toJS(media), {})

    return {
      url: content.thumbnail().build(),
      thumbnailUrl: content
        .thumbnail()
        .width(1080) // Limiting video preview thumbnail to 1080px to reduce data delivery
        .build(),
    }
  } else {
    return null
  }
}

function visibility(videoSet: VideoSet): BoxProps<'video'>['display'] {
  if (videoSet != null && videoSet?.length > 1) {
    return {
      mobile: 'none',
      tablet: 'none',
      desktop: 'none',
    }
  } else {
    return 'inline-block'
  }
}
type CommonVideoProps<T extends ElementType> = BoxProps<T> & {
  allow?: string
  screenSize?: 'Desktop' | 'Tablet' | 'Mobile'
  as: T
}

const CommonVideo = <T extends ElementType>({
  allow,
  as,
  children,
  display: displayProp,
  screenSize,
  src,
  title,
  ...props
}: CommonVideoProps<T>): React.ReactElement | null => {
  const display =
    typeof displayProp === 'string'
      ? displayProp
      : {
          ...displayProp,
          ...(screenSize && {
            [screenSize.toLowerCase()]: 'inline-block',
          }),
        }

  return (
    /*
    // @ts-ignore */
    <Box
      allow={allow}
      allowFullScreen
      as={as}
      aspectRatio="video"
      display={display}
      frameBorder="0"
      src={src}
      title={title}
      verticalAlign="top"
      width="full"
      {...props}
    >
      {children}
    </Box>
  )
}

const VideoPlayer: React.FC<ContentVideoCommonProps> = ({
  headline,
  videoControls,
  videoAmplience,
  videoYouTube,
  videoVimeo,
}) => {
  // Pick a single video type to render if multiple were chosen
  const videoType: VideoType | null = videoYouTube
    ? 'youtube'
    : videoVimeo
    ? 'vimeo'
    : videoAmplience
    ? 'amplience'
    : null
  const allowedVideoFeatures = `${[
    videoControls?.autoPlay ? 'autoplay' : '',
    'fullscreen',
    'picture-in-picture',
  ]
    .filter(Boolean)
    .join('; ')}`

  switch (videoType) {
    case 'youtube': {
      return (
        <>
          {videoYouTube?.map?.((video) => {
            const id = video.videoID

            if (id == null) {
              return null
            }

            const url = new URL(`https://www.youtube.com/embed/${id}`)
            const {searchParams} = url
            const display = visibility(videoYouTube)

            searchParams.set('autoplay', videoControls?.autoPlay ? '1' : '0')
            searchParams.set('loop', videoControls?.loop ? '1' : '0')
            searchParams.set('mute', '1')
            searchParams.set('rel', '0')

            return (
              <CommonVideo
                allow={allowedVideoFeatures}
                as="iframe"
                key={id}
                display={display}
                screenSize={video.screenSize}
                src={url.href}
                title={headline?.headline ?? 'YouTube video player'}
              />
            )
          })}
        </>
      )
    }
    case 'vimeo': {
      return (
        <>
          {videoVimeo?.map?.((item) => {
            const id = item.videoID

            if (id == null) {
              return null
            }

            const url = new URL(`https://player.vimeo.com/video/${id}`)
            const {searchParams} = url
            const display = visibility(videoVimeo)

            searchParams.set('autoplay', videoControls?.autoPlay ? '1' : '0')
            searchParams.set('badge', '0')
            searchParams.set('loop', videoControls?.loop ? '1' : '0')
            searchParams.set('muted', '1')
            searchParams.set('player_id', '0')
            searchParams.set('rel', '0')

            return (
              <CommonVideo
                allow={allowedVideoFeatures}
                as="iframe"
                display={display}
                key={id}
                screenSize={item.screenSize}
                src={url.href}
                title={headline?.headline ?? 'Vimeo video player'}
              />
            )
          })}
        </>
      )
    }
    case 'amplience': {
      return (
        <>
          {videoAmplience?.map?.(({video, screenSize}, i) => {
            const media = createMediaUrl(video)
            const display = visibility(videoAmplience)
            return (
              <CommonVideo
                key={`${video.id}-${i}`}
                as="video"
                autoPlay={videoControls?.autoPlay}
                controls
                loop={videoControls?.loop}
                muted
                display={display}
                playsInline
                poster={media?.thumbnailUrl}
                preload="auto"
                screenSize={screenSize}
              >
                <source
                  /*
                  // @ts-ignore */
                  codecs="h264, aac"
                  data-bitrate="2108"
                  data-quality-label="High"
                  src={`${media?.url}/mp4_720p`}
                  type="video/mp4"
                />
                <source
                  /*
                  // @ts-ignore */
                  codecs="h264, aac"
                  data-bitrate="698"
                  data-quality-label="Medium"
                  src={`${media?.url}/mp4_480p`}
                  type="video/mp4"
                />
                <source
                  /*
                  // @ts-ignore */
                  codecs="h264, aac"
                  data-bitrate="387"
                  data-quality-label="Low"
                  src={`${media?.url}/mp4_240p`}
                  type="video/mp4"
                />
                <source
                  /*
                  // @ts-ignore */
                  codecs="vp8, vorbis"
                  data-bitrate="2091"
                  data-quality-label="High"
                  src={`${media?.url}/webm_720p`}
                  type="video/webm"
                />
                <source
                  /*
                  // @ts-ignore */
                  codecs="vp8, vorbis"
                  data-bitrate="672"
                  data-quality-label="Medium"
                  src={`${media?.url}/webm_480p`}
                  type="video/webm"
                />
                <source
                  /*
                  // @ts-ignore */
                  codecs="vp8, vorbis"
                  data-bitrate="375"
                  data-quality-label="Low"
                  src={`${media?.url}/webm_240p`}
                  type="video/webm"
                />
              </CommonVideo>
            )
          })}
        </>
      )
    }
    default:
      return null
  }
}

export const ContentVideo: React.FC<{content: ContentVideoCommonProps}> = ({content}) => {
  if (content == null) {
    return null
  }

  const {headline, bodyText, layout, textControls} = content
  const hasText = headline?.headline || bodyText?.bodyText
  const backgroundImage = createMediaUrl(layout?.backgroundImage?.images?.[0]?.image, {
    width: 1400, // Limit background image size to reasonable value. (Smaller images will not be upscaled)
  })
  const isSizeSet = layout?.videoSize && layout?.videoSize !== 'None'
  const applyPaddingX = isSizeSet && (layout?.backgroundColor || backgroundImage?.url)

  return (
    <Box
      display={!isSizeSet ? 'block' : 'grid'}
      gap="24px"
      gridTemplateColumns="12x"
      justifyItems={isSizeSet ? 'center' : undefined}
    >
      <Box
        gridColumn={{
          mobile: 'span 12',
          tablet: 'span 12',
          desktop: '3 / -3',
        }}
        paddingLeft={applyPaddingX ? '16px' : '0px'}
        paddingRight={applyPaddingX ? '16px' : '0px'}
        paddingTop={hasText ? '20px' : '0px'}
        paddingBottom={hasText ? '48px' : '0px'}
        margin={{
          tablet: 'auto',
          mobile: 'auto',
        }}
        maxWidth="fullvw"
        width={isSizeSet ? undefined : 'full'}
        style={{
          ...(layout?.backgroundColor?.color && {
            backgroundColor: layout?.backgroundColor?.color,
          }),
          ...(backgroundImage?.url && {
            backgroundImage: `url(${backgroundImage?.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }),
          margin: isSizeSet
            ? `auto calc((100% - min(calc(${layout?.videoSize ?? '0px'} - (${
                applyPaddingX ? '40px' : '0px'
              } )), 100vw)) / 2)`
            : 'auto',
          maxWidth: isSizeSet ? '100vw' : '100%',
          width: isSizeSet ? layout?.videoSize : undefined,
        }}
      >
        {hasText ? (
          <Stack paddingTop="28px" paddingBottom="28px">
            {headline ? (
              <Text
                style={
                  headline.headlineTextColor ? {color: headline.headlineTextColor.color} : undefined
                }
                textAlign={(textControls?.textAlignment?.toLowerCase() as TextAlign) || 'left'}
                variant={
                  headline.headingSize === 'Small'
                    ? 'heading5'
                    : headline.headingSize === 'Medium'
                    ? 'heading5'
                    : headline.headingSize === 'Large'
                    ? 'heading6'
                    : 'heading5'
                }
              >
                {headline?.headline}
              </Text>
            ) : null}
            {bodyText?.bodyText ? (
              <Text
                style={bodyText.textColor ? {color: bodyText.textColor.color} : undefined}
                textAlign={(textControls?.textAlignment?.toLowerCase() as TextAlign) || 'left'}
                variant="text3"
              >
                {bodyText?.bodyText}
              </Text>
            ) : null}
          </Stack>
        ) : null}
        <VideoPlayer {...content} />
      </Box>
    </Box>
  )
}
