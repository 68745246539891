import {nanoid} from 'nanoid'
import {encode as base64encode} from 'base64-arraybuffer'

// Globals
const isServer = typeof window === 'undefined'

/**
 * Creates Code Verifier use for PKCE auth flow.
 */
export const createCodeVerifier = async () => {
  if (isServer) {
    //@ts-ignore
    const randomstring = await import('randomstring').then((module) => {
      return module.default.generate(128)
    })
    return randomstring
  }

  return nanoid(128)
}

/**
 * Creates Code Challenge based on Code Verifier
 */
export const generateCodeChallenge = async (codeVerifier: string) => {
  let base64Digest

  if (isServer) {
    await import('crypto').then((module) => {
      //@ts-ignore
      const crypto = module.default
      base64Digest = crypto.createHash('sha256').update(codeVerifier).digest('base64')
    })
  } else {
    const encoder = new TextEncoder()
    const data = encoder.encode(codeVerifier)
    const digest = await window.crypto.subtle.digest('SHA-256', data)

    base64Digest = base64encode(digest)
  }

  return base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}
