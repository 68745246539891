import React from 'react'
import {useIntl} from 'react-intl'
import {ControllerRenderProps} from 'react-hook-form'
import {POSTCODE_REGEXP} from '../../utils/constants'
import {CheckPostalCodeFormFields, CreateFields} from '../../types/forms'

export function formatPostcode(postcode: string) {
  const cleaned = postcode.replace(/[^a-z0-9]/gi, '').trim()
  const formatted = cleaned.slice(0, -3) + ' ' + cleaned.slice(-3)
  return formatted.toUpperCase()
}

export const useCheckPostcodeFields: CreateFields<
  CheckPostalCodeFormFields,
  {validatePostcode?: (postcode: string) => void}
> = ({
  form: {
    control,
    formState: {errors},
  },
  validatePostcode,
}) => {
  const {formatMessage} = useIntl()

  const fields = {
    postcode: {
      name: `postcode` as const,
      label: '',
      placeholder: 'Enter your postcode',
      type: 'text' as const,
      defaultValue: '',
      rules: {
        required: formatMessage({defaultMessage: 'This field is required.'}),
        pattern: {
          value: POSTCODE_REGEXP,
          message: 'Please specify a valid postal code',
        },
      },
      inputProps: ({onChange, onBlur}: Pick<ControllerRenderProps, 'onChange' | 'onBlur'>) => ({
        onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
          const formattedValue = formatPostcode(e.target.value)
          onChange(formattedValue)
          validatePostcode?.(e.target.value)
          onBlur()
        },
      }),
      error: errors[`postcode`],
      control,
    },
  }

  return fields
}
