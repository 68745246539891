import React, {useEffect} from 'react'
import {ColorPicker, TextAlignment} from '../../types/cms'
import {SlotComponent} from '../../types/ui'
import {Box, Container, Stack, Text} from '../../vanilla'
import {Breadcrumbs} from '../breadcrumbs'
import {Slot} from './Slot'
import {SlotContentItem} from '../../types/cms'
import {Seo} from '../seo'
import {usePageDataTracking} from '../../analytics/page-tracking'
import {ContentBody} from 'dc-delivery-sdk-js/build/main/lib/content/model/ContentBody'
interface Breadcrumb {
  crumbText: string
  crumbCtaLink: string
}

interface SlotContent extends ContentBody {
  contentSlot: SlotContentItem
}

export interface ContentPageProps {
  backgroundColor?: ColorPicker
  BreadCrumb: Array<Breadcrumb>
  pageHeading?: {
    textAlignment: TextAlignment
    heading?: string
    heading2?: string
  }
  SEO: {
    pageTitle: string
    pageDescription: string
  }
  slotContent: Array<SlotContent>
}

const HERO_CONTENT_SCHEMA = 'https://www.iceland.co.uk/slot/content-hero-slot.json'
const BODY_CONTENT_SCHEMA = 'https://www.iceland.co.uk/slot/content-slot.json'
const BODY_CONTENT_VARIATION_SCHEMA = 'https://www.iceland.co.uk/slot/landing-body.json'
const HERO_CONTENT_VARIATION_SCHEMA = 'https://www.iceland.co.uk/slot/landing-hero.json'

export const ContentPage: SlotComponent<ContentPageProps> = ({content}) => {
  if (!content || !content?.slotContent) {
    return null
  }
  const {BreadCrumb, pageHeading, slotContent, backgroundColor} = content

  const heroContent = slotContent.find(
    ({_meta}) =>
      _meta?.schema === HERO_CONTENT_SCHEMA || _meta.schema === HERO_CONTENT_VARIATION_SCHEMA,
  )

  const bodyContent = slotContent.filter(
    ({_meta}) =>
      _meta?.schema === BODY_CONTENT_SCHEMA || _meta?.schema === BODY_CONTENT_VARIATION_SCHEMA,
  )
  
  const sendPageData = usePageDataTracking()
  const pageType = 'content'
  const pageCategory = 'Landing'

  useEffect(() => {
    sendPageData(pageType, pageCategory)
  }, []);

  return (
    <Box paddingBottom="20px">
      <Seo title={content?.SEO?.pageTitle} description={content?.SEO?.pageDescription} />
      <Container
        style={{backgroundColor: backgroundColor?.color || 'transparent'}}
        padding={backgroundColor?.name === 'White' ? '24px' : '0px'}
        paddingBottom={backgroundColor?.name === 'White' ? '40px' : '0px'}
      >
        {BreadCrumb && (
          <Box paddingY="12px">
            <Breadcrumbs
              breadcrumbs={BreadCrumb.map(({crumbText, crumbCtaLink}) => ({
                id: crumbText.toUpperCase(),
                name: crumbText,
                url: crumbCtaLink,
              }))}
            />
          </Box>
        )}
        {heroContent && <Slot data={heroContent} />}
        {(pageHeading?.heading || pageHeading?.heading2) && (
          <Box marginTop={['0px', '8px']} marginBottom={['24px', '36px']}>
            {pageHeading?.heading && (
              <Text
                variant="heading5"
                lineHeight="normal"
                textAlign={pageHeading?.textAlignment.toLowerCase() as 'left' | 'right' | 'center'}
                color="accent0"
              >
                {pageHeading.heading}
              </Text>
            )}
            {pageHeading?.heading2 && (
              <Text
                variant="heading1"
                textAlign={pageHeading?.textAlignment.toLowerCase() as 'left' | 'right' | 'center'}
              >
                {pageHeading.heading2}
              </Text>
            )}
          </Box>
        )}
        {bodyContent?.length ? (
          <Stack spacing={['16px', '24px']}>
            {bodyContent?.map((content) => {
              return content && <Slot data={content} />
            })}
          </Stack>
        ) : null}
      </Container>
    </Box>
  )
}
