import React from 'react'
import {AccountNotificationBanner} from './AccountNotificationBanner'
import {AllContent} from './AllContent'
import {AlternatingTextImage} from './AlternatingTextImage'
import {BrandFilter} from './brand-filter/BrandFilter'
import {ButtonGroup} from './ButtonGroup'
import {CalloutMessage} from './CalloutMessage'
import {CategoryHeadlineBanner} from './CategoryHeadlineBanner'
import {CategoryTile, CategoryTiles} from './category-tiles/CategoryTiles'
import {ContentAccordion} from './ContentAccordion'
import {ContentBox} from './ContentBox'
import {ContentPage} from './ContentPage'
import {ContentSlot} from './ContentSlot'
import {ContentTabGroup} from './ContentTabGroup'
import {ContentText} from './ContentText'
import {ContentVideo} from './ContentVideo'
import {CountdownTimer} from './CountdownTimer'
import {GridCard} from './GridCard'
import {HeroBanner} from './HeroBanner'
import {HeroBannerCarousel} from './HeroBannerCarousel'
import {HTMLContentBlock} from './HTMLContentBlock'
import {InfoCardList} from './InfoCardList'
import {NavFlyoutBanner} from './NavFlyoutBanners'
import {NavFlyoutGrid} from './NavFlyoutGrid'
import {OfferCard} from './OfferCard'
import {OfferCards} from './OfferCards'
import {PlaceholderContent} from './PlaceholderContent'
import {ProductCarousel} from './ProductCarousel'
import {PromoBanner} from './PromoBanner'
import {ReadMore} from './ReadMore'
import {Recipe} from './Recipe'
import {StickyBanner} from './sticky-banner/StickyBanner'
import {ThinBanner} from './thin-banner'
import {PDPBadgeAssetVizualisationWrapper} from './product-badges'
import {Tab} from '../tabs'
import CategoryPage from '../../pages/category'
import Home from '../../pages/home'
import {MarketingForm} from './marketing-form'
import {ProductInformationVizualization} from './ProductInformation'
import DesktopNavigation from '../header/desktop-navigation'
import {MegaMenu} from '../header/mega-menu'
import {ContentAccordion2ColumnLayout} from './content-accordion-2-column-layout'
import {useContentStore, useCustomerStore, usePreviewDebugStore} from '../../store/hooks/useStore'
import {observer} from 'mobx-react'
import {XRayPreviewWrapper} from '../amplience-preview/x-ray-preview-wrapper'
import {SlotProps, SlotContentItem, SlotItem} from '../../types/cms'
import {getComponentsBasedOnVariation} from './utils'

/**
 * @todo - wrap CMS components with `loadable`.
 * */

export const Slot = observer(({deliveryKey, contentId, data}: SlotProps) => {
  const cms = useContentStore()
  const {showSlots} = usePreviewDebugStore()

  const key = deliveryKey && deliveryKey !== 'null' ? deliveryKey : contentId

  const content = data || cms.contentById[key!]

  const components = useContentToDisplay(content)
  
  if (!components) {
    return null
  }
  
  return showSlots ? (
    <XRayPreviewWrapper title="SLOT" isComponent={false} content={content}>
      <SlotContent content={components} />
    </XRayPreviewWrapper>
  ) : (
    <SlotContent content={components} />
  )
})

interface SlotContentProps {
  content: SlotItem[] | null | undefined
}

const SlotContent = ({content}: SlotContentProps) => {
  const {showContent} = usePreviewDebugStore()

  if (!content) return null

  return (
    <>
      {content
        ?.filter((component): component is SlotItem => !!component)
        ?.map((component, idx) => {
          if (Array.isArray(component) && component.length) {
            return <SlotContent content={component} />
          }

          if (!component?._meta?.schema) {
            return null
          }

          const Component = getComponentForSchema(component._meta.schema)

          if (!Component) return null

          return showContent ? (
            <XRayPreviewWrapper title="CONTENT" isComponent={true} content={component}>
              <Component key={component._meta.schema + idx} content={component} />
            </XRayPreviewWrapper>
          ) : (
            <Component key={component._meta.schema + idx} content={component} />
          )
        })}
    </>
  )
}

export const useContentToDisplay = (slotContent: SlotContentItem) => {
  const {params, selectedPreviewCustomerGroup} = useContentStore()
  const {currentCustomerGroups} = useCustomerStore()

  try {
    if (!slotContent) {
      return null
    }

    const contentToDisplay = getComponentsBasedOnVariation(slotContent, params, currentCustomerGroups, selectedPreviewCustomerGroup)

    if (!contentToDisplay) return null

    // Makes sure that default content can be returned as a collection.
    return Array.isArray(contentToDisplay) ? contentToDisplay : [contentToDisplay]

  } catch (error) {
    console.error(`Issue rendering slot content. Error: ${error}`)
  }
}

const schemaComponentMap: Record<string, any> = {
  'https://www.iceland.co.uk/content/nav-menu-node.json': DesktopNavigation,
  'https://www.iceland.co.uk/content/nav-menu-node-single.json': DesktopNavigation,
  'https://www.iceland.co.uk/content/nav-list.json': MegaMenu,
  'https://www.iceland.co.uk/content/offer-card.json': OfferCard,
  'https://www.iceland.co.uk/content/offer-cards.json': OfferCards,
  'https://www.iceland.co.uk/content/category-tile.json': CategoryTile,
  'https://www.iceland.co.uk/content/category-tiles.json': CategoryTiles,
  'https://www.iceland.co.uk/content/seo-read-more-text.json': ReadMore,
  'https://www.iceland.co.uk/content/thin-banner.json': ThinBanner,
  'https://www.iceland.co.uk/content/brand-filter.json': BrandFilter,
  'https://www.iceland.co.uk/content/nav-flyout-grid.json': NavFlyoutGrid,
  'https://www.iceland.co.uk/content/nav-flyout-banner.json': NavFlyoutBanner,
  'https://www.iceland.co.uk/content/sticky-banner.json': StickyBanner,
  'https://www.iceland.co.uk/content/sticky-countdown-banner.json': StickyBanner,
  'https://www.iceland.co.uk/content/sticky-prizes-won-banner.json': StickyBanner,
  'https://www.iceland.co.uk/slot/sticky-banner-slot.json': StickyBanner,
  'https://www.iceland.co.uk/slot/all-content-slot.json': AllContent,
  'https://www.iceland.co.uk/content/info-cards.json': InfoCardList,
  'https://www.iceland.co.uk/content/hero-banner.json': HeroBanner,
  'https://www.iceland.co.uk/content/content-text.json': ContentText,
  'https://www.iceland.co.uk/content/category-headline-banner.json': CategoryHeadlineBanner,
  'https://www.iceland.co.uk/content/carousel.json': HeroBannerCarousel,
  'https://www.iceland.co.uk/content/promo-banner.json': PromoBanner,
  'https://www.iceland.co.uk/content/countdown-timer.json': CountdownTimer,
  'https://www.iceland.co.uk/button-group.json': ButtonGroup,
  'https://www.iceland.co.uk/content/content-callout-message.json': CalloutMessage,
  'https://iceland.co.uk/content/content-box.json': ContentBox,
  'https://www.iceland.co.uk/content/text-image.json': AlternatingTextImage,
  'https://www.iceland.co.uk/content/content-accordion.json': ContentAccordion,
  'https://www.iceland.co.uk/content/product-carousel.json': ProductCarousel,
  'https://www.iceland.co.uk/page/content-page.json': ContentPage,
  'https://www.iceland.co.uk/content/in-grid-card.json': GridCard,
  'https://www.iceland.co.uk/content/content/content-html-block.json': HTMLContentBlock,
  'https://www.iceland.co.uk/content/content-tab-group.json': ContentTabGroup,
  'https://www.iceland.co.uk/content/placeholder-content.json': PlaceholderContent,
  'https://www.iceland.co.uk/content/recipe.json': Recipe,
  'https://www.iceland.co.uk/content/content-video.json': ContentVideo,
  'https://www.iceland.co.uk/content/content-accordion-2-column-layout.json': ContentAccordion2ColumnLayout,
  'https://www.iceland.co.uk/content/image-product-badge.json': PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/text-product-badge.json': PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/top-right-product-badge.json': PDPBadgeAssetVizualisationWrapper,
  'https://www.iceland.co.uk/content/content-tab.json': Tab,
  'https://www.iceland.co.uk/page/category-page.json': CategoryPage,
  'https://www.iceland.co.uk/page/homepage.json': Home,
  'https://www.iceland.co.uk/content/home-hero-slot-variation.json': AllContent,
  'https://www.iceland.co.uk/slot/content-slot.json': ContentSlot,
  'https://www.iceland.co.uk/slot/landing-body.json': ContentSlot,
  'https://www.iceland.co.uk/slot/content-hero-slot.json': AllContent,
  'https://www.iceland.co.uk/content/marketing-form.json': MarketingForm,
  'https://www.iceland.co.uk/content/product-information.json': ProductInformationVizualization,
  'https://www.iceland.co.uk/content/account-notification.json': AccountNotificationBanner,
  'https://www.iceland.co.uk/content/book-delivery-information-slot.json': AllContent,
  
}

function getComponentForSchema(schema: string) {
  return schemaComponentMap[schema]
}
