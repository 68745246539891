import {getErrorMessage} from '../utils/utils'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'

export class ContactUsFormService {
  private static readonly SG_FORM_PREFIX = 'dwfrm_contactus_'

  private static mapPayloadToRequestBody(payload: Record<string, string>) {
    if (!Object.keys(payload).length) return ''

    const formBody = []

    for (const payloadKey in payload) {
      const payloadValue = payload[payloadKey] ?? ''
      const formBodyKey = ContactUsFormService.SG_FORM_PREFIX + payloadKey

      const encodedKey = encodeURIComponent(formBodyKey)
      const encodedValue = encodeURIComponent(payloadValue)
      formBody.push(encodedKey + '=' + encodedValue)
    }

    formBody.push(`${ContactUsFormService.SG_FORM_PREFIX}send` + '=' + encodeURIComponent(true))

    return formBody.join('&')
  }

  public static async sendFeedback(payload: Record<string, string>) {
    try {
      const body = ContactUsFormService.mapPayloadToRequestBody(payload)

      const response: Response = await fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/CustomerService-SubmitPWA`,
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
          method: 'POST',
          body: body,
        }
      )

      if (!response.ok) {
        throw new Error(await response.text())
      }

      const json: {success: boolean} = await response.json()

      return json
    } catch (error) {
      const errMessage = getErrorMessage(error)

      console.log('Error during submitting a feedback: ', errMessage)
      return {success: false}
    }
  }
}
