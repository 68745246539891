import React, {useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Box} from '../../vanilla'
import {useGlobalStore} from '../../store/hooks/useStore'

interface GoogleRecaptchaProps {
  onSuccessCallback: (result?: string) => void
  onErrorCallback: () => void
  onExpireCallback: () => void
}

interface RecaptchaAPI extends Record<string, unknown> {
  enterprise: {
    render: (
      el: HTMLDivElement,
      params: {
        sitekey: string
        callback?: () => void
        theme: 'light' | 'dark'
        'expired-callback'?: () => void
        'error-callback'?: () => void
      }
    ) => void
  }
}

export const GoogleRecaptcha = observer(
  ({onSuccessCallback, onErrorCallback, onExpireCallback}: GoogleRecaptchaProps) => {
    const {
      recaptchaSiteKey,
      getCustomSitePreferenceById,
      isCustomSitePreferenceSet,
      customSitePreferences 
    } = useGlobalStore()

    const [ready, setReady] = useState(false)

    const grecaptchaContainer = useRef<HTMLDivElement>(null)
    const grecaptcha = useRef<RecaptchaAPI | null>(null)
    const [recaptchaEnabled, setRecaptchaEnabled] = useState(false)

    

    useEffect(() => {
        (async function () {
          if (!isCustomSitePreferenceSet('reCaptchaEnable')) {
            await getCustomSitePreferenceById('reCaptchaEnable')
          }
          setRecaptchaEnabled(customSitePreferences['reCaptchaEnable'] === true)
        }())
      })

    const initGrecaptcha = () => {
      if (!grecaptchaContainer.current || !grecaptcha.current) return

      try {
        const captchaApi = grecaptcha.current as RecaptchaAPI
        captchaApi.enterprise.render(grecaptchaContainer.current, {
          sitekey: recaptchaSiteKey,
          theme: 'light',
          callback: onSuccessCallback,
          'error-callback': onErrorCallback,
          'expired-callback': onExpireCallback,
        })
      } catch (e) {
        console.error('Error during initializing rechaptcha')
      }
    }

    useEffect(() => {
      const recaptchaScript = document.querySelector(`#google-recaptcha-script`)

      if (recaptchaScript) {
        // Removes the script so the container gets populated properly on change of route
        recaptchaScript.remove()
      }

      window.ON_CAPTCHA_SCRIPT_LOADED = () => {
        setReady(true)
        grecaptcha.current = window.grecaptcha as RecaptchaAPI
      }

      const script = document.createElement('script')

      script.setAttribute('type', 'text/javascript')

      script.setAttribute(
        'src',
        `https://www.google.com/recaptcha/enterprise.js?onload=ON_CAPTCHA_SCRIPT_LOADED&render=explicit`
      )

      script.setAttribute('id', `google-recaptcha-script`)
      script.setAttribute('defer', '')

      document.head.insertBefore(script, document.head.childNodes[1])

      return () => {
        delete window.ON_CAPTCHA_SCRIPT_LOADED
      }
    }, [])

    useEffect(() => {
      if (ready && recaptchaEnabled) initGrecaptcha()
    }, [ready])

    return <Box ref={grecaptchaContainer} />
  }
)
