import React, {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {TObservablePage} from '../../types/commerce'
import {Layout} from '../../components/layout'
import {Container, Stack} from '../../vanilla'
import {flowResult} from 'mobx'
import {useBasketStore, useContentStore, useCustomerStore, useOrderStore} from '../../store/hooks/useStore'
import {Slot, useContentToDisplay} from '../../components/cms/Slot'
import {SlotContentItem} from '../../types/cms'
import {Seo} from '../../components/seo'
import {BookDelivery} from '../../components/book-delivery'
import {EditOrderStickyBanner} from '../../components/edit-order-sticky-banner'
import {usePageDataTracking} from '../../analytics/page-tracking'
import Cookies from 'js-cookie'
import {COOKIE_STORAGE_KEYS} from '../../utils/constants'
import {usePromotionDataTracking} from '../../analytics/promotion-tracking'
import {ContentBody} from 'dc-delivery-sdk-js'

interface HomePageProps {
    content?: HomePageContentProps
    children?: React.ReactNode
  }

interface HomePageContentProps extends ContentBody {
    slots: SlotContentItem [],
    SEO: {
        metaDescription: string,
        pageDescription: string,
        seoTitle: string,
        pageTitle: string
    }
}

const Home: TObservablePage<HomePageProps> = observer(({content: visualizationContent}) => {
  const contentStore = useContentStore()
  const {editMode, recentOrder} = useOrderStore()
  const {currentDeliverySlotId} = useBasketStore()

  const content = visualizationContent ?? contentStore.contentById['homepage']

  const HOME_HERO_SCHEMA = 'https://www.iceland.co.uk/slot/home-hero.json'

  const sendPageData = usePageDataTracking()
  const pageType = 'storefront'
  let pageCategory = ''

  const {isRegistered} = useCustomerStore()
  const isSoftLoggedIn = Cookies.get(COOKIE_STORAGE_KEYS.REGISTERED_CUSTOMER_SOFT_LOGOUT)

  if (isRegistered) {
    pageCategory = 'Full-login'
  } else if (isSoftLoggedIn) {
    pageCategory = 'Soft-login'
  } else {
    pageCategory = 'Default'
  }
  const sendPromotionData = usePromotionDataTracking()

  const heroSlots = content?.slots
    .filter((slotGroup: SlotContentItem) => slotGroup._meta.schema === HOME_HERO_SCHEMA)
    .map((slotGroup: SlotContentItem) => {
      const transformedSlots = useContentToDisplay(slotGroup);
      return transformedSlots?.map((slot: any) => ({
        ...slot,
        slotGroupName: slotGroup._meta.name
      })) || []
    }) || []

  const otherSlots = content?.slots
    .filter((slotGroup: SlotContentItem) => slotGroup._meta.schema !== HOME_HERO_SCHEMA)
    .map((slotGroup: SlotContentItem) => {
      const transformedSlots = useContentToDisplay(slotGroup);
      return transformedSlots?.map((slot: any) => ({
        ...slot,
        slotGroupName: slotGroup._meta.name
      })) || []
    }) || []

  let contentToDisplay = [...heroSlots, ...otherSlots]
  contentToDisplay = contentToDisplay.filter(item => item.length > 0)

  useEffect(() => {
    sendPageData(pageType, pageCategory)
  }, [isRegistered, isSoftLoggedIn])

  useEffect(() => {
    sendPromotionData(contentToDisplay)
  }, [])

  if (!content) {
    return null
  }

  return (
    <Layout>
      {editMode && <EditOrderStickyBanner />}
      <Seo
        title={content?.SEO?.pageTitle || 'Home Page | Iceland'}
        description={content?.SEO?.pageDescription}
      />
      <Stack height={['260px', '260px', '320px']}>
        {heroSlots?.map((slotGroup: SlotContentItem, idx: number) => (
          <Slot key={`${slotGroup?._meta?.deliveryId}-${idx}`} data={slotGroup} />
        ))}
      </Stack>
      <Stack height={currentDeliverySlotId ? ['292px', '122px'] : recentOrder ? ['196px', '96px'] : ['228px', '96px'] }>
      <BookDelivery />
      </Stack>
      <Container paddingBottom={['32px', '48px']}>
        <Stack spacing={['16px', '24px']}>
          {otherSlots?.map((slotGroup: SlotContentItem, idx: number) => (
              <Slot data={slotGroup} key={`${slotGroup?._meta?.deliveryId}-${idx}`} />
            ))}
        </Stack>
      </Container>
    </Layout>
  )
})

Home.getProps = async ({store}) => {
  const {contentStore} = store
  const {fetchItemByKey} = contentStore
  await flowResult(fetchItemByKey('homepage'))
}

export default Home
