import React, {createContext, useState, useEffect, useContext, useReducer} from 'react'
import {initGTM, sendToGTM} from './utils/GoogleTagManager'
import {useGlobalStore} from '../../store/hooks/useStore'
import {initOneTrust, setDefaultConsent} from './utils/OneTrust'

/**
 * The initial state
 */
export const initialState = {
  dataLayer: undefined,
  dataLayerName: 'dataLayer',
  environment: undefined,
  nonce: undefined,
  id: '',
  injectScript: true,
}
/**
 * The context
 */
export const GTMContext = createContext(initialState)
export const GTMContextDispatch = createContext(undefined)
let queuedData = []
function dataReducer(state, data) {
  if (window[state.dataLayerName]) {
    sendToGTM({data, dataLayerName: state?.dataLayerName})
    if (queuedData.length > 0) {
      queuedData.forEach(data => {
        sendToGTM({data, dataLayerName: state?.dataLayerName})
      })
      queuedData = []
    }
  } else {
    queuedData = [...queuedData, data]
  }
  return state
}
export const OneTrustCheckCompletedContext = createContext(); 
/**
 * The Google Tag Manager Provider
 */
function GTMProvider({state, children}) {
  const [store, dispatch] = useReducer(dataReducer, {...initialState, ...state})

  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} =
  useGlobalStore()

  const [oneTrustEnabled, setoneTrustEnabled] = useState(false)
  const [oneTrustId, setOneTrustId] = useState('')
  const [oneTrustCheckCompleted, setoneTrustCheckCompleted] = useState(false)

  useEffect(() => {
    ;(async function () {
      if (!isCustomSitePreferenceSet('oneTrustEnabled')) {
        await getCustomSitePreferenceById('oneTrustEnabled')
      }
      if (!isCustomSitePreferenceSet('oneTrustId')) {
        await getCustomSitePreferenceById('oneTrustId')
      }
      setoneTrustEnabled(customSitePreferences['oneTrustEnabled'] === true)
      setOneTrustId(customSitePreferences['oneTrustId'])
      setoneTrustCheckCompleted(true)
    })()
  })

  useEffect(() => {
    if (!oneTrustCheckCompleted || !state || state.injectScript == false) return
    const mergedState = {...store, ...state}
    setDefaultConsent(oneTrustEnabled)
    initGTM({
      dataLayer: mergedState.dataLayer,
      dataLayerName: mergedState.dataLayerName,
      environment: mergedState.environment,
      nonce: mergedState.nonce,
      id: mergedState.id,
    })
    initOneTrust(oneTrustEnabled, oneTrustId)
  }, [JSON.stringify(state), oneTrustCheckCompleted])
  return (
    <GTMContext.Provider value={store}>
      <GTMContextDispatch.Provider value={dispatch}>
        <OneTrustCheckCompletedContext.Provider value={oneTrustCheckCompleted}>
          {children}
        </OneTrustCheckCompletedContext.Provider>
      </GTMContextDispatch.Provider>
    </GTMContext.Provider>
  )
}
function useGTMDispatch() {
  const context = useContext(GTMContextDispatch)
  if (context === undefined) {
    console.warn(
      "The GTMProvider's context is current uninitialised. The context maybe " +
        'midway through initialization or may be undefined',
    )
  }
  return context
}

function useOneTrustCheckCompleted() {
  return useContext(OneTrustCheckCompletedContext);
}
export {GTMProvider, useGTMDispatch, sendToGTM, useOneTrustCheckCompleted}