import React, {useEffect, useMemo, useState} from 'react'
import {Modal} from '../modal'
import {LoginContainer} from '../login-container'
import {ResetPassword} from '../reset-password-container'
import {TwoFactorCode} from '../two-factor-code-container'
import {Box, useDisclosure} from '../../vanilla'
import {
  CredentialModalPlacement,
  CredentialModalPlacementMap,
  CredentialScreens,
} from '../../types/ui'
import useLockBodyScrolling from '../../hooks/use-lock-body-scrolling'
import {CREDENTIAL_SCREENS} from '../../utils/constants'
import {useRect} from '@reach/rect'
import {LogInFormFields} from '../../types/forms/login'

interface CredentialModalProps extends ReturnType<typeof useDisclosure> {
  defaultScreen: CredentialScreens
  refToAttach: React.RefObject<Element>
  placement: CredentialModalPlacement
  afterLoginHandler?: () => void
}

export const CredentialModal = (props: CredentialModalProps) => {
  const {
    defaultScreen = CREDENTIAL_SCREENS.LOGIN,
    refToAttach,
    placement,
    afterLoginHandler,
    isOpen,
    ...rest
  } = props

  const [currentCredScreen, setCurrentCredScreen] = useState<CredentialScreens>(defaultScreen)
  const [currentCredentials, setCurrentCredentials] = useState<LogInFormFields>()

  const CredentialScreenSelector = useMemo(() => {
    switch (currentCredScreen) {
      case CREDENTIAL_SCREENS.LOGIN:
        return (
          <LoginContainer
            inModal
            maintainLocation
            onCreateAccountClickHandler={() => rest.onClose()}
            placement={placement}
            afterLoginHandler={() => {
              afterLoginHandler?.()
              setCurrentCredScreen(CREDENTIAL_SCREENS.LOGIN);
              rest.onClose()
            }}
            onForgotPasswordClickHandler={() => {
              setCurrentCredScreen(CREDENTIAL_SCREENS.RESET_PASSWORD)
            }}
            showTwoFactorScreenHandler={() => {
              setCurrentCredScreen(CREDENTIAL_SCREENS.TWO_FACTOR)
            }}
            setSuccessfulCredentials={setCurrentCredentials}
          />
        )
      case CREDENTIAL_SCREENS.RESET_PASSWORD:
        return (
          <ResetPassword
            onBack={() => {
              setCurrentCredScreen(CREDENTIAL_SCREENS.LOGIN)
            }}
            onClose={() => rest.onClose()}
          />
        )
      case CREDENTIAL_SCREENS.TWO_FACTOR:
        return <TwoFactorCode 
          currentCredentials={currentCredentials} 
          inModal
          maintainLocation
          setCredentialScreen={setCurrentCredScreen}
          />
    }
  }, [currentCredScreen, placement, afterLoginHandler])

  useEffect(() => {
    // If you close the modal on reset password, it should show login on re-open
    if (
      !isOpen &&
      currentCredScreen !== CREDENTIAL_SCREENS.LOGIN &&
      currentCredScreen !== CREDENTIAL_SCREENS.TWO_FACTOR
    ) {
      setCurrentCredScreen(CREDENTIAL_SCREENS.LOGIN)
    }
  }, [isOpen])

  useLockBodyScrolling(isOpen)

  const {right, bottom} = useRect(refToAttach) || {}

  const getPlacementCssRules = (placement: CredentialModalPlacement) => {
    switch (placement) {
      case CredentialModalPlacementMap.ATTACHED:
        return {
          top: bottom ?? 0,
          left: right ?? 0,
          transform: 'translateX(-100%)',
        }

      case CredentialModalPlacementMap.BOTTOM:
        return {
          bottom: 0,
          left: 0,
        }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeIcon={false}
      size={placement === CredentialModalPlacementMap.ATTACHED ? 'sm' : 'xl'}
      containerRef={placement === CredentialModalPlacementMap.ATTACHED ? refToAttach : undefined}
      dialogContentProps={{
        position: 'absolute',
        style: {
          margin: '0px',
          ...getPlacementCssRules(placement),
        },
      }}
      {...rest}
    >
      <Box>{CredentialScreenSelector}</Box>
    </Modal>
  )
}
