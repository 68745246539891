import React, {useContext, createContext, ReactNode} from 'react'

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export function createCtx<T extends object>(defaultVal?: T & object) {
  const ctx = createContext<T | undefined>(defaultVal)

  function useCtx(): T {
    const context = useContext(ctx)
    if (context === undefined) throw new Error('useCtx must be inside a Provider with a value')
    return context
  }

  return [
    useCtx,
    (props: {value: T; children: ReactNode}) => (
      <ctx.Provider value={props.value}>{props.children}</ctx.Provider>
    ),
  ] as const
}
