import React from 'react'
import {LockIcon} from '../../../components/icons'
import {CheckoutBonusCardStatus} from '../../../types/store/common'
import {BaseButton, Text} from '../../../vanilla'
import {useOrderStore} from '../../../store/hooks/useStore'
import {AddressFormFields} from '../../../types/forms'
import { PaypalButton } from '../../../components/paypal/buttonComponents'

interface CheckoutButtonProps {
  submitOrderProcess: () => Promise<void>
  isEditModeEnabled: boolean
  isTopUp: boolean
  selectedPayment: string | null
  amountToPay: number | null | undefined
  billingAddressFormValid: boolean
  setError: React.Dispatch<React.SetStateAction<string | null>>
  errorMessage: string
  isPaymentRefunding: boolean
  braintreeFormIsValid: boolean
  bonusCardStatus: CheckoutBonusCardStatus
  hostedFieldsErrors: boolean
  paypalContainer: string
  hasPaypalLoaded: boolean
  billingAddress?: AddressFormFields
  }

export const CheckoutButton = (props: CheckoutButtonProps) => {
  const {
    submitOrderProcess,
    isEditModeEnabled,
    isTopUp,
    selectedPayment,
    billingAddressFormValid,
    isPaymentRefunding,
    braintreeFormIsValid,
    bonusCardStatus,
    hostedFieldsErrors,
    paypalContainer,
    hasPaypalLoaded = false
  } = props

  const {isPaymentRequired} = useOrderStore()

  return selectedPayment === 'Paypal' ? (
      <PaypalButton isLoading={!hasPaypalLoaded} paypalContainer={paypalContainer} />
  ) : (
    <BaseButton
      style={{minWidth: '230px'}}
      variant="primary"
      onClick={() => submitOrderProcess()}
      disabled={
        isPaymentRequired
          ? !billingAddressFormValid ||
            (!isPaymentRefunding
              ? (!braintreeFormIsValid && !bonusCardStatus?.hidePayment) || hostedFieldsErrors
              : false)
          : false
      }
    >
      {isEditModeEnabled ? (
        <Text variant="unstyled">Update Your Order Now</Text>
      ) : (
        <>
          <Text variant="unstyled">{isTopUp ? 'Top up' : 'Pay'} Securely</Text>
          &nbsp;
          <LockIcon />
        </>
      )}
    </BaseButton>
  )
}
