import type {CommerceAPI} from 'commerce-api'

export type PromiseFlow<A> = Generator<Promise<A>, void, A>
export type PromiseFlowRet<A, B = A> = Generator<Promise<A>, B, A>

export interface StoreModel {
  api: CommerceAPI

  get asJson(): Record<string, any>
}

// This class wraps the store class to allow for properly typing fields
// that assign `this` from a constructor param.
// https://stackoverflow.com/a/68877881
export function Wrapper<T extends object>(): new (init: T) => T {
  return class {
    constructor(init: T) {
      Object.assign(this, init)
    }
  } as any
}

export type Override<T, R> = Omit<T, keyof R> & R

// Use `OmitFromKnownKeys` when the type extends Record (ie [s: string]: any).
// Most of the Shopper* types do this, so this is the workaround.
// https://github.com/microsoft/TypeScript/issues/31153#issuecomment-1074283505
export type NoIndex<T> = {
  [K in keyof T as {} extends Record<K, 1> ? never : K]: T[K]
}
export type OnlyIndex<T> = {
  [K in keyof T as {} extends Record<K, 1> ? K : never]: T[K]
}
export type OmitFromKnownKeys<T, K extends keyof NoIndex<T>> = Omit<NoIndex<T>, K> & OnlyIndex<T>

/**
 * OptionalKeys
 * @desc Get union type of keys that are optional in object type `T`
 * @see https://stackoverflow.com/questions/52984808/is-there-a-way-to-get-all-required-properties-of-a-typescript-object
 * @example
 *   type Props = { req: number; reqUndef: number | undefined; opt?: string; optUndef?: number | undefined; };
 *
 *   // Expect: "opt" | "optUndef"
 *   type Keys = OptionalKeys<Props>;
 */
export type OptionalKeys<T> = {
  [K in keyof T]-?: {} extends Pick<T, K> ? K : never
}[keyof T]
