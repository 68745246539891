import React from 'react'
import {Box, BoxProps} from '../../../vanilla'
import {useProductImage} from '../../../store/hooks/useProductImage'

type BaseProductImageProps = {
    image: object
    imageAlt: string | undefined
  } & BoxProps<'img'>

type ThumbnailTrueProps = {
    isThumbnail: true;
    inDrawer: boolean | undefined;
  };

type ThumbnailFalseProps = {
    isThumbnail: false;
    inDrawer?: boolean | undefined;
  };

export type ProductImageProps = BaseProductImageProps & (ThumbnailTrueProps | ThumbnailFalseProps);

export const ProductImage: React.FC<ProductImageProps> = ({
    image,
    imageAlt,
    isThumbnail,
    inDrawer,
    ...props
  }) => {
  const imgSrc = useProductImage(image)

  if (!imgSrc) {
    return null
  }

  const srcSetProductTile = `${imgSrc}&$producttile$ 1x, ${imgSrc}&$producttile_2x$ 2x`;
  const srcProductTile = `${imgSrc}&$producttile_2x$`;
  const srcSetThumbnail = `${imgSrc}&${inDrawer ? '$smallthumbnail$' : '$lineitemthumbnail$'} 1x, 
    ${imgSrc}&${inDrawer ? '$smallthumbnail_2x$' : '$lineitemthumbnail_2x$'} 2x`;
  const srcThumbnail = `${imgSrc}&$lineitemthumbnail_2x$`;

  return (
    <picture>
      <source srcSet={isThumbnail ? srcSetThumbnail : srcSetProductTile}/>
        <Box
          as="img"
          src={isThumbnail ? srcThumbnail : srcProductTile}
          alt={imageAlt}
          display="block"
          width="full"
          height="auto"
          {...props}
        />
    </picture>
  )
}