import React, {ElementType} from 'react'
import classNames from 'classnames'
import {Box, BoxProps, Text, Stack} from '../../vanilla'
import {getDayNumberWithOrdinalSuffix} from '../../utils/utils'
import {dateCalendarRecipe} from './styles.css'

interface DateCalendarProps extends BoxProps<ElementType> {
  date: Date
  variant?: 'dark' | 'accent'
  size?: 'sm' | 'md' | 'lg'
  timeRange?: [string, string]
}

export const DateCalendar = (props: DateCalendarProps) => {
  const {date, className, variant, size, timeRange, ...boxProps} = props

  const dateObj = date || new Date()

  const dateUI = {
    weekDay: dateObj.toLocaleString('default', {weekday: 'long'}),
    month: dateObj.toLocaleString('default', {month: 'long'}),
    day: getDayNumberWithOrdinalSuffix(dateObj.getDate()),
  }

  const getWeekDayText = () => {
    const todayWeekIndex = new Date().getDay()
    const dateObjWeekIndex = dateObj.getDay()

    if (todayWeekIndex === dateObjWeekIndex) {
      return 'Today'
    }

    if (dateObjWeekIndex - todayWeekIndex === 1) {
      return 'Tomorrow'
    }

    return dateUI.weekDay
  }

  const calendarClassName = dateCalendarRecipe({
    variant,
    size,
  })

  return (
    <Box className={classNames(calendarClassName, className)} {...(boxProps || {})}>
      <Box bg="inherit" padding="4px" textAlign="center">
        <Text
          color="white"
          variant="unstyled"
          fontSize="inherit"
          fontWeight="medium"
          lineHeight="none"
          as="span"
        >
          {getWeekDayText()}
        </Text>
      </Box>
      <Stack
        spacing="0px"
        paddingTop={size === 'sm' ? '8px' : '12px'}
        paddingBottom={size === 'sm' ? '8px' : '16px'}
        bg="white"
        textAlign="center"
        align="center"
      >
        <Text
          variant="unstyled"
          fontWeight="medium"
          lineHeight="none"
          as="span"
          color="gray800"
          style={{fontSize: '130%'}}
        >
          {dateUI.day}
        </Text>
        <Text
          variant="unstyled"
          fontSize="inherit"
          fontWeight="medium"
          lineHeight="none"
          as="span"
          color="gray800"
        >
          {' '}
          {dateUI.month}
        </Text>
      </Stack>
      {timeRange && (
        <Box width="full" bg="white" paddingBottom="4px">
          <Box
            style={{width: '75%'}}
            paddingBottom="4px"
            marginX="auto"
            borderTop="1px"
            borderColor="gray200"
          />
          <Text
            display="inline-block"
            width="full"
            variant="unstyled"
            fontSize="inherit"
            align="center"
            as="span"
            style={{fontSize: '85%'}}
            fontWeight="medium"
            lineHeight="none"
          >
            {timeRange.join(' - ')}
          </Text>
        </Box>
      )}
    </Box>
  )
}
