import {StoreModel} from './utils'
import {CommerceAPI} from 'commerce-api'
import {Status, StatusMap} from '../types/commerce'
import {RootStore} from './RootStore'
import {computed, flow, flowResult, makeObservable, observable} from 'mobx'
import {
  FindStoresResponse,
  StoreDetailResponseItem,
} from '../types/store/storeDetails'
import {GoogleMapsGeocoder} from '../services/googleMapsGeocoder'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {DateUtils} from '../utils/date-utils'
 
export class StoreDetailsStore implements StoreModel {
  api: CommerceAPI
  currentSearch: StoreDetailResponseItem[]
  status: Status
  geocoder: GoogleMapsGeocoder
  cachedLocationSearches: Map<string, {timestamp: number, result: {lat: number, lng: number} | null}>
  allStores: StoreDetailResponseItem[]

  constructor(rootStore: RootStore, initialState = {currentSearch: [], status: StatusMap.IDLE}) {
    this.currentSearch = initialState?.currentSearch
    this.status = initialState?.status
    this.allStores = []

    makeObservable(this, {
      status: observable,
      loading: computed,
      findStoresByCoords: flow.bound,
      findNearestStoreByCoords: flow.bound,
      findStore: flow.bound,
      getSeasonalHours: flow.bound,
      currentSearch: observable,
      getAddressCoordinates: flow.bound,
      getAllStores: flow.bound,
      allStores: observable,
    })

    this.api = rootStore.api
    this.geocoder = new GoogleMapsGeocoder({API_KEY: rootStore?.globalStore?.googleMapsAPIkey})
    this.cachedLocationSearches = new Map<string, {timestamp: number, result: {lat: number, lng: number} | null}>()
  }

  get loading() {
    return this.status === StatusMap.PENDING
  }

  *findStoresByCoords(coords: {lat: number; lng: number}) {
    try {
      this.status = StatusMap.PENDING
      const response: FindStoresResponse = yield this.api.shopperStores.getStores({
        parameters: {
          latitude: coords.lat,
          longitude: coords.lng,
          distanceUnit: 'mi',
          maxDistance: 50,
        },
      })
      if (!response?.data || !response.data.length) {
        this.currentSearch = []
        return
      }

      this.currentSearch = response.data
        .filter(
          (store) =>
            !Object.hasOwn(store, 'c_displayOnStoreFinder') || store.c_displayOnStoreFinder,
        )
        .slice(0, 5)

      this.status = StatusMap.DONE
    } catch (e) {
      this.status = StatusMap.ERROR
      console.error(`Failed to find stores with coors: ${coords.lng} ${coords.lat}`, e)
    }
  }

  *findStore(storeId: string) {
    try {
      this.status = StatusMap.PENDING
      const response: StoreDetailResponseItem = yield this.api.shopperStores.getStore({
        parameters: {
          storeId,
        },
      })
      this.status = StatusMap.DONE

      return response
    } catch (e) {
      this.status = StatusMap.ERROR
      console.error(`Failed to get store details by storeID: ${storeId}`, e)
    }
  }


  *getSeasonalHours(storeId: string){
    try {
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/seasonal/${storeId}.json`,
        {
          method: 'GET',

        }
      )

      if (!res.ok) {

        throw new Error('Failed to get seasonal hours data by storeID: ${storeId}')
      } else {
        const json = yield res.json()
        const mappedResult = json.map((storeDay:any)=>{
          const start = new Date(storeDay.start)
          const end = new Date(storeDay.end)
          
          const day = start.toString().split(" ")
          const formattedDay = [day[0],DateUtils.getOrdinal(parseInt(day[2])),day[1]].join(" ")

          const result = {
            day: formattedDay,
            open: DateUtils.get12HourTime(start),
            close: DateUtils.get12HourTime(end),
            isClosed: false
          }

          if(start.getTime() === end.getTime()){
            result.isClosed = true
          }

          return result
        })
        return mappedResult
      }
    } catch (e) {
      console.log(e)
    }

  }

  *getAllStores(): Generator<Promise<Response>, StoreDetailResponseItem[] | void, Response> {
    try {
      this.status = StatusMap.PENDING
    
      const res: Response = yield fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Stores-GetAllStores`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }

        }
      )

      if (!res.ok) {

        console.error('Error: Could not fetch all stores. Response status:', res.status)
        this.status = StatusMap.ERROR
        return
      }

      const json = yield res.json()
      if (json) {
        this.status = StatusMap.DONE
        this.allStores = Object.values(json) as StoreDetailResponseItem[]
        return this.allStores
      }
    } catch (error) {
      this.status = StatusMap.ERROR
      console.error('Fetch error:', error)
    }

  }

  *findNearestStoreByCoords(coords: {lat: number; lng: number}) {
    try {
      this.status = StatusMap.PENDING
      const response: FindStoresResponse = yield this.api.shopperStores.getStores({
        parameters: {
          latitude: coords.lat,
          longitude: coords.lng,
          distanceUnit: 'mi',
          maxDistance: 50,
        },
      })

      if (!response?.data || !response.data.length) {
        return null
      }

      this.status = StatusMap.DONE

      return response.data.filter(
          (store) =>
            !Object.hasOwn(store, 'c_displayOnStoreFinder') || store.c_displayOnStoreFinder,
        )[0]
    } catch (e) {
      this.status = StatusMap.ERROR
      console.error(`Failed to find stores with coors: ${coords.lng} ${coords.lat}`, e)
      return null
    }
  }

  get asJson() {
    return {
      currentSearch: this.currentSearch,
    }
  }

  /**
   * Lookup co-ordinates from a postcode
   * @param deliveryPostalCode string postcode to lookup
   */
  *getAddressCoordinates(deliveryPostalCode: string) { 

    if(!deliveryPostalCode) return null
    
    // Attempt to retrieve local cached copy of lookup
    const cachedResponse = this.cachedLocationSearches.get(deliveryPostalCode)

    if(cachedResponse) { 
        
        const TTL = 60000 // 1 minute cache value - enough to protect against React sillyness. 
        const cacheAge = Date.now() - cachedResponse.timestamp

        if(cacheAge > TTL) { 
            this.cachedLocationSearches.delete(deliveryPostalCode)
        } else { 
            console.debug(`getAddressCoordinates returned cached result - cached age ${cacheAge}ms`)
            return cachedResponse.result
        }
    }
    
    // Retrieve result from the geocode 
    const result: {lat: number, lng: number} | null = yield flowResult(this.geocoder.getAddressCoordinates(deliveryPostalCode))
    // Store cached answer
    this.cachedLocationSearches.set(deliveryPostalCode, {timestamp: Date.now(), result: result})
    return result
  }
}
