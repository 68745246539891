import React from 'react'
import {Flex, Stack, useDisclosure, Text, BaseButton} from '../../vanilla'
import {Modal} from '../modal'
import {CloseIcon} from '../icons'
import {Link} from '../link'

export const LearnMoreAlcoholRestrictionModal = (props: ReturnType<typeof useDisclosure>) => {
  return (
    <Modal size="full-sm" closeIcon={false} {...props}>
      <Flex
        borderBottom="1px"
        borderColor="gray200"
        position="relative"
        justify="center"
        align="center"
        padding="12px"
      >
        <Text as="h4" variant="unstyled" fontSize="lg" fontWeight="medium">
          Alcohol Restriction
        </Text>
        <BaseButton
          variant="unstyled"
          paddingX="0px"
          height="auto"
          aria-label="Close alcohol restriction modal"
          position="absolute"
          style={{right: 16}}
          onClick={props.onClose}
        >
          <CloseIcon boxSize="24px" />
        </BaseButton>
      </Flex>

      <Stack
        spacing={['32px', '20px']}
        paddingX={['24px', '40px']}
        paddingY="20px"
        justify="center"
      >
        <Text variant="text3" align="center">
          Due to licensing laws in Scotland,
          <br /> alcohol products are restricted to <br />
          deliveries between 10pm and 10am
        </Text>
        <BaseButton
          as={Link}
          to="/book-delivery"
          variant="secondary"
          display="block"
          marginX="auto"
          size="sm"
        >
          <Text as="span" variant="unstyled" fontSize="inherit" fontWeight="inherit">
            Change Slot
          </Text>
        </BaseButton>
      </Stack>
    </Modal>
  )
}
