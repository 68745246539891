import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {Button, Flex, Stack} from '../../../../../vanilla'
import {AmplienceCalendarIcon} from '../../../../icons'
import {PanelOption} from '../../panel-option'
import {Datepicker} from '../../../../datepicker'
import {Nullable} from '../../../../../types/utils'
import {useContentStore} from '../../../../../store/hooks/useStore'
import {flowResult} from 'mobx'
import {buildPreviewUrl} from '../../../utils'
import {DateUtils} from '../../../../../utils/date-utils'

export const PreviewInTime = observer(() => {
  const {params, setPreviewTimestamp, selectedPreviewCustomerGroup} = useContentStore()

  const [selectedPreviewDate, setSelectedPreviewDate] = useState<Nullable<Date>>(
    params?.previewTimestamp ? new Date(params?.previewTimestamp) : null,
  )

  const handleSubmitPreviewDate = async () => {
    try {
      if (selectedPreviewDate) {
        const ms = selectedPreviewDate.getTime()

        const vseDomain = await flowResult(setPreviewTimestamp(ms))

        const queryParams = {
          vseDomain,
          timeStamp: ms,
          customerGroup: selectedPreviewCustomerGroup,
        }

        window.location.href = buildPreviewUrl(window.location.host, queryParams)
      }
    } catch (error) {
      console.log('Error during update of preview date', error)
    }
  }

  const handleDatepickerChange = (date: Nullable<Date>) => {
    if (!date || typeof date !== 'object') return

    setSelectedPreviewDate(date)
  }

  return (
    <PanelOption
      title="Preview in Time"
      Icon={<AmplienceCalendarIcon boxSize="24px" />}
      selectedOption={
        selectedPreviewDate ? DateUtils.format(selectedPreviewDate, 'dd/MM/yyyy HH:mm') : undefined
      }
    >
      <Stack padding="24px">
        <Flex style={{minWidth: 200}} align="center" justify="center" gap="2px">
          <Datepicker
            showTimeInput
            error={null}
            selected={selectedPreviewDate}
            label="Preview date"
            onChange={handleDatepickerChange}
            dateFormat="dd/MM/yyyy h:mm aa"
            containerProps={{flexGrow: '1'}}
            name="previewDate"
          />

          <Button
            size="sm"
            bg="previewPrimary"
            borderTopLeftRadius="none"
            borderBottomLeftRadius="none"
            onClick={handleSubmitPreviewDate}
          >
            Go
          </Button>
        </Flex>
      </Stack>
    </PanelOption>
  )
})
