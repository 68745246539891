import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {Utils} from 'commerce-api'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {UseFormReturn} from 'react-hook-form'
import {Text} from '../../vanilla'

interface ResendAPIResponse {
  code: string
  wait?: number
}

interface ResendCodeLinkProps {
  form: UseFormReturn<any, any, undefined>
}

const resendDefaultMsg = 'Resend code'
const resendSuccessMsg = 'Code Resent'
const resendFailMsg = 'Unknown Error'
const resendWaitMsg = 'Please wait before re-sending another code'

const ResendCodeLink: React.FC<ResendCodeLinkProps> = (props) => {
  const [linkText, setLinkText] = useState<string>(resendDefaultMsg)
  const [countdown, setCountdown] = useState<number>()
  let intervalId: NodeJS.Timeout

  const countdownInterval = () => {
    intervalId = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown) {
          if (prevCountdown <= 1) {
            clearInterval(intervalId)
            setLinkText(resendDefaultMsg)
            props.form.clearErrors('root')
            return 0
          } else {
            return prevCountdown - 1
          }
        }
      })
    }, 1000)
  }

  const submitResendCode = async () => {
    try {
      const request = await fetch(
        `${getAppOrigin()}/mobify/proxy/ocapi/on/demandware.store/Sites-icelandfoodsuk-Site/default/Account-ResendCode`,
        {
          credentials: 'include',
          headers: await Utils.twoFactorHeaders(),
        },
      )
      const response = (await request.json()) as ResendAPIResponse
      switch (request.status) {
        case 200:
          setLinkText(resendSuccessMsg)
          setTimeout(() => setLinkText(resendDefaultMsg), 3000)
          break
        case 429:
          if (!response.wait) {
            throw new Error("Missing required attribute 'wait'")
          }
          setCountdown(response.wait / 1000)
          countdownInterval()
          props.form.setError('root', {
            type: 'manual',
            message: resendWaitMsg,
          })
          break
        default:
          setLinkText(resendFailMsg)
          setTimeout(() => setLinkText('resend code'), 3000)
          throw new Error(`Unexpected status code: ${request.status}`)
      }
    } catch (error) {
      props.form.setError('root', {type: 'manual', message: error.toString()})
    }
  }

  return linkText === resendDefaultMsg ? (
    <Text textAlign="center" variant="text3">
      Didn't receive code? Check your spam/junk folder. &nbsp;
      <Link
        to="#"
        onClick={submitResendCode}
        style={{
          textDecoration: 'underline',
          color: countdown ? 'red' : 'inherit',
        }}
      >
        {countdown ? `00:${Math.floor(countdown).toString().padStart(2, '0')}` : linkText}
      </Link>
    </Text>
  ) : (
    <Text
      textAlign="center"
      variant="text3"
      style={{
        color: linkText === resendFailMsg ? 'red' : 'green',
      }}
    >
      {linkText}
    </Text>
  )
}

export const ResendCode = observer(ResendCodeLink)
