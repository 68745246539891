import {useCallback} from 'react'
import {useIntl} from 'react-intl'
// @ts-ignore
import {useHistory} from 'react-router-dom'

/**
 * A convenience hook for programmatic navigation uses history's `push` or `replace`. The proper locale
 * is automatically prepended to the provided path. Additional args are passed through to `history`.
 */
const useNavigation = () => {
  const history = useHistory()
  const {locale} = useIntl()

  return useCallback(
    /**
     *
     * @param {string} path - path to navigate to
     * @param {('push'|'replace')} action - which history method to use
     * @param  {...any} args - additional args passed to `.push` or `.replace`
     */
    (path: string, action = 'push', ...args: any) => {
      // const localePath = `/${locale}`
      const localePath = `/`
      const localizedHref = path && path?.includes(localePath) ? path : `${localePath}${path}`
      const location = path === '/' ? '/' : localizedHref
      action === 'push'
        ? history.push(location, ...args)
        : history.replace(location, ...args)
      
    },
    [locale]
  )
}

export default useNavigation
