import {useEffect, useState} from 'react'
import {useGlobalStore} from './useStore'

export const useProductImage = (img: any) => {
  const {getCustomSitePreferenceById, isCustomSitePreferenceSet, customSitePreferences} =
    useGlobalStore()
  const [imgSrc, setImgSrc] = useState<string>()

  const getImgData = async () => {
    if (img?.dis_base_link || img?.disBaseLink) {
      setImgSrc(
        img?.dis_base_link
          ? `${img?.dis_base_link.replace('http:', 'https:')}?&fmt=auto`
          : `${img?.disBaseLink.replace('http:', 'https:')}?fmt=auto`,
      )
      return
    }
    if (!isCustomSitePreferenceSet('lazyLoadProductImageUrl')) {
      await getCustomSitePreferenceById('lazyLoadProductImageUrl')
    }
    const defaultImage = customSitePreferences['lazyLoadProductImageUrl']
    setImgSrc(`${defaultImage as string}?&fmt=auto`)
  }

  useEffect(() => {
    getImgData()
  }, [customSitePreferences, img])

  return imgSrc
}
