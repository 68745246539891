import React from 'react'
import {useDisclosure} from '../../../vanilla'
import {PreviewButton} from '../preview-button'
import {PreviewDrawer} from '../preview-drawer'

export const PreviewPanel = () => {
  const previewDrawer = useDisclosure()

  return (
    <>
      <PreviewButton
        position="fixed"
        onPreviewButtonClick={previewDrawer.onToggle}
        style={{top: 8, left: 8}}
        zIndex="sticky"
      />
      <PreviewDrawer {...previewDrawer} />
    </>
  )
}
