import {useRef} from 'react'

export const useRestoreInitialHref = () => {
  const initialHref = useRef(typeof window !== 'undefined' ? location.href : null)

  return () => {
    // @ts-ignore
    history.pushState({}, null, initialHref.current)
  }
}
