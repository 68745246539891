import * as React from 'react'
import {BaseButton, BaseButtonProps} from './BaseButton'
import {Text, TextProps} from '../../vanilla/components/Text'

export interface ButtonProps extends BaseButtonProps<'button'> {
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  children?: React.ReactNode
  extraClassName?: string
  additionalTextProps?: TextProps<'span'>
}

export const Button = React.forwardRef(
  (
    {iconLeft, iconRight, children, textAlign, additionalTextProps, ...rest}: ButtonProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <BaseButton {...rest} ref={ref} gap="8px">
        {iconLeft && iconLeft}
        {children && (
          <Text
            as="span"
            variant="unstyled"
            fontSize="inherit"
            lineHeight="inherit"
            fontWeight="inherit"
            textAlign={textAlign || 'left'}
            {...(additionalTextProps || {})}
          >
            {children}
          </Text>
        )}
        {iconRight && iconRight}
      </BaseButton>
    )
  }
)
