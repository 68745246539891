import {observer} from 'mobx-react'
import React, {useEffect, useState} from 'react'
import {NavContentItem, NavFlyOutContent, NavItem} from '../../store/ContentStore'
import {Box, Flex, Stack, Text, BaseButton} from '../../vanilla'
import {Slot} from '../cms/Slot'
import {SlotItem} from '../../types/cms'
import {ChevronRightIcon} from '../icons'
import {Link} from '../link'
import {
  megaMenuInternalItem,
  megaMenuInternalItemActive,
  megaMenuInternalItemText,
  megaMenuSection,
} from './styles.css'
import {
  useNavMenuPromoBannersDataTracking,
  useNavMenuPromotionDataTracking,
  useSelectNavMenuPromotionTracking,
} from '../../analytics/promotion-tracking'
import {transformNavItem} from '../cms/utils'

interface MegaMenuProps {
  content: NavItem[] | NavContentItem
}

interface MegaMenuSectionProps {
  item: NavItem
  onItemClick?: (item: NavItem) => void
  flyOut?: NavFlyOutContent
  long?: boolean
  selectedObject?: NavItem | null
  level?: number
}

interface FlyOutSectionProps {
  flyOut: NavFlyOutContent
}

const SMALL_WIDTH = 260
const BIG_WIDTH = 400

const renderButton = (
  item: NavItem,
  onClick: React.MouseEventHandler,
  isActive = false,
  navMenuLevel?: number,
  index?: number
) => {
  const sendNavMenuSelectPromotion = useSelectNavMenuPromotionTracking()
  return item.children || item.path ? (
    <Box as="li" key={item.name}>
      <BaseButton
        as={item.path ? Link : undefined}
        to={item.path || undefined}
        className={isActive ? megaMenuInternalItemActive : megaMenuInternalItem}
        variant="unstyled"
        style={{width: SMALL_WIDTH, borderRadius: 0}}
        backgroundColor="white"
        height="36px"
        paddingY="4px"
        paddingLeft="12px"
        justifyContent="flex-start"
        onClick={onClick}
        onClickCapture={() =>
          sendNavMenuSelectPromotion(
            item.name,
            item.path,
            item.categoryId,
            `Desktop Navigation Level ${navMenuLevel}`,
            item.children?.[0].categoryId,
            index
          )  
        }
      >
        <Text variant="text4" className={megaMenuInternalItemText}>
          {item.name}
        </Text>
        {!!item.children && <ChevronRightIcon boxSize="16px" marginLeft="auto" />}
      </BaseButton>
    </Box>
  ) : null
}

const FlyOutSection = observer(({flyOut}: FlyOutSectionProps) => {
  const [translate, setTranslate] = useState<boolean>(true)

  useEffect(() => {
    setTranslate(false)
  }, [])

  return (
    <Box
      zIndex="dropdown"
      backgroundColor="white"
      className={megaMenuSection}
      style={{transform: `translateX(${translate ? '-100%' : 0})`, width: BIG_WIDTH}}
      padding="20px"
      borderLeft="1px"
      borderColor="gray100"
    >
      {!!flyOut && <Slot data={flyOut as SlotItem} />}
    </Box>
  )
})

const MegaMenuSection = observer(
  ({
    item,
    onItemClick,
    flyOut,
    long = false,
    selectedObject = null,
    level,
  }: MegaMenuSectionProps) => {
    const [translate, setTranslate] = useState<boolean>(true)

    useEffect(() => {
      setTranslate(false)
    }, [])

    return (
      <Box
        zIndex="dropdown"
        backgroundColor="white"
        className={megaMenuSection}
        style={{transform: `translateX(${translate ? '-100%' : 0})`}}
        borderLeft="1px"
        borderColor="gray100"
      >
        <Box as="ul">
          <Stack style={{width: long ? BIG_WIDTH : SMALL_WIDTH}} gap="0px">
            {item.children?.map((i, index) => {
              return renderButton(
                i,
                () => !!onItemClick && onItemClick(i),
                i === selectedObject,
                level ? level : 1,
                index + 1
              )
            })}
            <Box padding="20px">
              {!!flyOut?.default?.length && <Slot data={flyOut as SlotItem} />}
            </Box>
          </Stack>
        </Box>
      </Box>
    )
  }
)

export const MegaMenu = observer(({content}: MegaMenuProps) => {
  const [selectedItem, setSelectedItem] = useState<NavItem | null>(null)
  const [selectedSecondItem, setSelectedSecondItem] = useState<NavItem | null>(null)

  const sendNavMenuPromotionData = useNavMenuPromotionDataTracking()
  const sendNavMenuPromoBannersData = useNavMenuPromoBannersDataTracking()

  useEffect(() => {
    sendNavMenuPromotionData(content, 'Desktop Navigation Level 1', true)
  }, [])

  const onItemChange = (item: NavItem) => {
    if (item.children) {
      setSelectedItem(item)
      setSelectedSecondItem(null)
      sendNavMenuPromotionData(item.children, 'Desktop Navigation Level 2', true)
    }
    sendNavMenuPromoBannersData(item.flyOutContent)
  }

  const onSecondItemChange = (item: NavItem) => {
    if (item.children) {
      setSelectedSecondItem(item)
      sendNavMenuPromotionData(item.children, 'Desktop Navigation Level 3', true)
    }
    sendNavMenuPromoBannersData(item.flyOutContent)
  }

  const nav = Array.isArray(content) ? content : [transformNavItem(content)]

  return (
    <Flex alignItems="stretch" flexDirection="row-reverse">
      {/*
        HACK: we're using an array on both of the optional sections
        so that, when `name` changes
        React renders a different component, unmounting the previous one.
        This makes the translation animation easier to implement. Alternative solutions
        are welcome!
       */}
      {!!selectedSecondItem &&
        [selectedSecondItem].map((si) => (
          <MegaMenuSection
            key={si.name}
            long
            item={si}
            flyOut={selectedSecondItem?.flyOutContent}
            level={3}
          />
        ))}
      {!selectedSecondItem &&
        !!selectedItem?.flyOutContent &&
        [selectedItem?.flyOutContent].map((flyOut) => (
          <FlyOutSection key={selectedItem.name} flyOut={flyOut} />
        ))}
      {!!selectedItem &&
        [selectedItem].map((si) => (
          <MegaMenuSection
            key={si.name}
            item={si}
            onItemClick={onSecondItemChange}
            selectedObject={selectedSecondItem}
            level={2}
          />
        ))}
      <Stack as="ul" gap="0px" zIndex="dropdown" backgroundColor="white">
        {nav.map((item, index) => {
          return renderButton(item, () => onItemChange(item), item === selectedItem, 1, index + 1)
        })}
      </Stack>
    </Flex>
  )
})
