import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Modal} from '../modal'
import {Link} from '../link'
import {BaseButton, Button, Flex, Stack, Text, useDisclosure} from '../../vanilla'
import {useOrderStore} from '../../store/hooks/useStore'

const EditOrderExpiredModalComponent = () => {
  const {editOrderDurationExpired} = useOrderStore()

  const modal = useDisclosure(false)

  useEffect(() => {
    if (editOrderDurationExpired) {
      modal.onOpen()
    } else if (modal.isOpen) {
      modal.onClose()
    }
  }, [editOrderDurationExpired])

  return (
    <Modal size="full-lg" title="Looks like you were editing an order?" closeIcon {...modal}>
      <Stack paddingX="28px" paddingY="16px">
        <Text variant="text3">
          We noticed you were recently editing an order, but didn&apos;t checkout. Any changes were
          not applied and your original order will delivered as normal.
        </Text>
        <Text variant="text3">
          If you still need to edit your order, you can do this up to [11pm] the night before your
          order is due to be delivered.
        </Text>
      </Stack>
      <Flex justifyContent="flex-end" paddingX="28px" paddingY="16px" gap="16px">
        <Button variant="primary" size="sm" onClick={() => modal.onClose()}>
          Ok, Got it
        </Button>
        <BaseButton variant="secondary" size="sm" as={Link} to="/account/order-history">
          <Text variant="unstyled" fontSize="inherit" fontWeight="inherit">
            View my orders
          </Text>
        </BaseButton>
      </Flex>
    </Modal>
  )
}

export const EditOrderExpiredModal = observer(EditOrderExpiredModalComponent)
