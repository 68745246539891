import {GetCoordsApiResponse} from '../types/googleMapsGeocoder'

export interface GoogleMapsGeocoderOptions {
  API_KEY: string
}

export class GoogleMapsGeocoder {
  options: GoogleMapsGeocoderOptions

  constructor(options: GoogleMapsGeocoderOptions) {
    this.options = options
  }

  get API_URL() {
    return `https://maps.googleapis.com/maps/api/geocode/json?&key=${this.options.API_KEY}&country=GB`
  }

  // To get the coordinates (latitude and longitude) from a city name or postcode.
  async getAddressCoordinates(addressStr: string) {
    if (!addressStr) return null

    try {
      if (google?.maps?.Geocoder) {
        const gmGeocoder = new google.maps.Geocoder()

        const {results} = await gmGeocoder.geocode({
            address: addressStr,
            componentRestrictions: {country: 'GB'},
          })

        if (!results) return null

        return {
          lat: results?.[0]?.geometry?.location.lat(),
          lng: results?.[0]?.geometry?.location.lng(),
        }
      } else {
        const response: Response = await fetch(`${this.API_URL}&address=${addressStr}`)
        let json: GetCoordsApiResponse = await response.json()
        return json?.results?.[0]?.geometry?.location ?? null
      }
    } catch (e) {
      console.error(`Error during getting location coords for ${addressStr}`, e)
      return null
    }
  }
}
