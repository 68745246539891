import {Box, Button, Flex, Text} from '../../vanilla'
import {CheckIcon, CloseIcon} from '../../components/icons'
import {Link} from 'react-router-dom'
import React from 'react'

type CompletenessMessageProps = {
  onClose?: () => void
  showCloseIcon?: boolean
}

export const CompletenessMessage: React.FC<CompletenessMessageProps> = ({
  onClose = () => {},
  showCloseIcon,
}) => {
  return (
    <Box
      bg="accent1"
      borderRadius="medium"
      paddingY="24px"
      paddingX={['24px', '48px']}
      position="relative"
    >
      <Flex gap={['16px', '32px']} alignItems="center">
        <Box alignSelf={['flex-start', 'center']}>
          <CheckIcon
            viewBox="48px"
            height={['36px', '48px']}
            width={['36px', '48px']}
            color="white"
          />
        </Box>
        <Flex
          flex={'1'}
          gap={'12px'}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          align={['flex-start', 'center']}
        >
          <Box>
            <Text color="white" variant="heading7">
              Congratulations!
            </Text>
            <Text color="white" variant="text3">
              Meal Deal added,{' '}
              <Box as={Link} to="/basket" textDecoration="underline">
                View Basket
              </Box>
            </Text>
          </Box>
          <Box>
            <Button variant="outlineLight" onClick={onClose}>
              <Text color="white" as="span">
                Continue Shopping
              </Text>
            </Button>
          </Box>
        </Flex>
      </Flex>
      {showCloseIcon && (
        <Button
          variant="unstyled"
          paddingX="0px"
          height="auto"
          position="absolute"
          right="0px"
          top="0px"
          onClick={onClose}
        >
          <CloseIcon viewBox="48px" height="48px" width="48px" color="white" />
        </Button>
      )}
    </Box>
  )
}
