import React from 'react'
import {observer} from 'mobx-react'
import {Flex, Text} from '../../../../../vanilla'
import {buildPreviewUrl, getActiveEnvName, isDev, isProduction, isRelease} from '../../../utils'
import {AmplienceHideIcon, AmplienceViewIcon} from '../../../../icons'
import {PanelOption} from '../../panel-option'
import {DEV_URL, PRODUCTION_URL, RELEASE_URL} from '../../../constants'

export const MrtEnvironment = observer(() => {
  const activeEnvName = getActiveEnvName()

  return (
    <PanelOption
      title="MRT Environment"
      Icon={<AmplienceViewIcon boxSize="24px" color="previewPrimary" />}
      selectedOption={activeEnvName}
    >
      <Flex
        direction="row"
        align="center"
        flexWrap="wrap"
        color="white"
        borderTop="1px"
        borderColor="gray200"
        height="60px"
        as="a"
        href={buildPreviewUrl(DEV_URL)}
      >
        <Text
          as="span"
          color={isDev() ? 'previewPrimary' : 'gray400'}
          marginLeft="76px"
          variant="text3"
          style={{width: 180}}
        >
          Development
        </Text>
        {isDev() ? (
          <AmplienceViewIcon color="previewPrimary" boxSize="24px" />
        ) : (
          <AmplienceHideIcon boxSize="24px" color="gray400" />
        )}
      </Flex>
      <Flex
        direction="row"
        align="center"
        flexWrap="wrap"
        color="white"
        borderTop="1px"
        borderColor="gray200"
        height="60px"
        as="a"
        href={buildPreviewUrl(RELEASE_URL)}
      >
        <Text
          as="span"
          color={isRelease() ? 'previewPrimary' : 'gray400'}
          marginLeft="76px"
          variant="text3"
          style={{width: 180}}
        >
          Release
        </Text>
        {isRelease() ? (
          <AmplienceViewIcon color="previewPrimary" boxSize="24px" />
        ) : (
          <AmplienceHideIcon boxSize="24px" color="gray400" />
        )}
      </Flex>

      <Flex
        direction="row"
        align="center"
        flexWrap="wrap"
        color="white"
        borderTop="1px"
        borderColor="gray200"
        height="60px"
        as="a"
        href={buildPreviewUrl(PRODUCTION_URL)}
      >
        <Text
          as="span"
          color={isProduction() ? 'previewPrimary' : 'gray400'}
          marginLeft="76px"
          variant="text3"
          style={{width: 180}}
        >
          Production
        </Text>
        {isProduction() ? (
          <AmplienceViewIcon color="previewPrimary" boxSize="24px" />
        ) : (
          <AmplienceHideIcon boxSize="24px" color="gray400" />
        )}
      </Flex>
    </PanelOption>
  )
})
