import React, {useState} from 'react'
import {isServer} from '../utils/utils'
import {Customer} from '../store/CustomerStore'
import {Nullable} from '../types/utils'

export const useChat = () => {
  const [isLoading, setIsLoading] = useState(false)
  const initiate = (customerInfo: Customer) => {
    setIsLoading(true)
    loadScript(customerInfo)
      .then(() => {
        setIsLoading(false)
      })
      .catch((error: string) => {
        setIsLoading(false)
        console.log('Error loading and opening chat:')
        console.error(error)
      })
  }

  return {
    initiate,
    isLoading,
  }
}

let loadPromise: Promise<void>
const loadScript = (customerInfo: Customer) => {
  if (!loadPromise && !isServer) {
    loadPromise = new Promise(() => {
      const chatScriptTag = document.createElement('script')
      chatScriptTag.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js'
      chatScriptTag.async = true
      chatScriptTag.onload = () => {
        if (!window.embedded_svc) {
          var s = document.createElement('script')
          s.setAttribute(
            'src',
            'https://icelanddigital--icestage01.my.salesforce.com/embeddedservice/5.0/esw.min.js'
          )
          s.onload = function () {
            initESW(null, customerInfo)
          }
          document.body.appendChild(s)
        } else {
          initESW('https://service.force.com', customerInfo)
        }
      }

      document.body.append(chatScriptTag)
    })
  }

  return loadPromise || Promise.resolve()
}

const initESW = (gslbBaseURL: Nullable<string>, liveChatCustomerDetails: Customer) => {
  embedded_svc.settings.displayHelpButton = true //Or false
  embedded_svc.settings.prepopulatedPrechatFields = {
    FirstName: liveChatCustomerDetails.firstName!,
    LastName: liveChatCustomerDetails.lastName!,
    Email: liveChatCustomerDetails.email!,
  }

  embedded_svc.settings.language = '' //For example, enter 'en' or 'en-US'
  embedded_svc.settings.enabledFeatures = ['LiveAgent']
  embedded_svc.settings.entryFeature = 'LiveAgent'
  embedded_svc.init(
    'https://icelanddigital--iceuat01.sandbox.my.salesforce.com',
    'https://icelanddigital--iceuat01.sandbox.my.salesforce-sites.com/liveAgentSetupFlow',
    gslbBaseURL,
    '00D1X0000009QiH',
    'Live_Chat_Agents',
    {
      baseLiveAgentContentURL: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
      deploymentId: '5723W0000008OIe',
      buttonId: '5733W000000CaRq',
      baseLiveAgentURL: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'Live_Chat_Agents',
      isOfflineSupportEnabled: false,
    }
  )
}
