import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {useProductStore} from '../../store/hooks/useStore'
import {ProductModel} from '../../store/ProductStore'

type Nullable<T> = T | null
type ProductSet = { id: string; product: ProductModel; quantity: number }[] | null

export interface AddAllToCartProducts {
  available: ProductSet
  unavailable: ProductSet
}

export const useAddAllToBasketParams = () => {
  const location = useLocation()
  const parsedQuery = queryString.parse(location.search, {arrayFormat: 'comma'})
  const [params, setParams] = React.useState(parsedQuery)
  const productStore = useProductStore()
  const [products, setProducts] = useState<Nullable<AddAllToCartProducts>>(null)
  const [confirmation, setConfirmation] = useState<Nullable<boolean>>(null)

  useEffect(() => {
    if (Array.isArray(params?.products)) {
      ;(async function () {
        const products = params.products as string[]
        const quantities = params.quantities as string[]
        let available: ProductSet = null
        let unavailable: ProductSet = null
        await productStore.fetchProducts(products, false)

        // Split product into available & unavailable groups
        products.forEach((id, index) => {
          const product = productStore.productsById[id]
          const quantity = parseInt(quantities?.[index], 10) || 1
          const obj = {
            id,
            product,
            quantity,
          }
          if (product?.isAddToCartDisabled) {
            if (unavailable == null) {
              unavailable = []
            }
            unavailable.push(obj)
          } else {
            if (available == null) {
              available = []
            }
            available.push(obj)
          }
        })
        if (available == null && unavailable == null) {
          setProducts(null)
        } else {
          setProducts({
            available,
            unavailable
          })
        }
      })()
    } else {
      setProducts(null)
    }

    setConfirmation(params?.confirmation === 'yes' ? true : false)
  }, [params])

  return {
    products,
    confirmation,
    updateParams: setParams
  }
}
