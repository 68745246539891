import React, {ChangeEvent, useRef, useState} from 'react'
import {useRect} from '@reach/rect'
import cn from 'classnames'
import {observer} from 'mobx-react-lite'
import {CheckIcon, ChevronDownIcon, ChevronUpIcon, CloseIcon} from '../../../../../icons'
import {
  ButtonProps,
  Text,
  Box,
  Input,
  Flex,
  Button,
  useDisclosure,
  BaseButton,
  Stack,
} from '../../../../../../vanilla'
import {useContentStore} from '../../../../../../store/hooks/useStore'
import {Nullable} from '../../../../../../types/utils'
import {buildPreviewUrl} from '../../../../utils'
import * as styles from './styles.css'

interface CustomerMenuProps {
  options: {id: string}[]
  openDirection: 'bottom' | 'top'
  buttonProps?: ButtonProps
}

export const CustomerMenu = observer((props: CustomerMenuProps) => {
  const {options, openDirection, buttonProps = {}} = props
  const {
    setSelectedPreviewCustomerGroup,
    selectedPreviewCustomerGroup,
    params: {isContentVisualization, vseDomain, previewTimestamp} = {},
  } = useContentStore()

  const menuList = useDisclosure()
  const [filterSearchRequest, setFilterSearchRequest] = useState<Nullable<string>>(null)

  const [selectedCustomerGroupId, setSelectedCustomerGroupId] = useState<Nullable<string>>(
    selectedPreviewCustomerGroup ?? null,
  )

  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const rect = useRect(menuButtonRef)

  const handleCustomerGroupChange = () => {
    setSelectedPreviewCustomerGroup(selectedCustomerGroupId)

    if (isContentVisualization) {
      return
    }

    const queryParams = {
      vseDomain,
      timeStamp: previewTimestamp,
      customerGroup: selectedCustomerGroupId,
    }

    window.location.href = buildPreviewUrl(window.location.host, queryParams)
  }

  return (
    <Stack position="relative" spacing="0px">
      <Flex gap="2px" align="center">
        <BaseButton
          ref={menuButtonRef}
          variant="unstyled"
          display="flex"
          alignItems="center"
          height="full"
          padding="4px"
          justifyContent="space-between"
          border="1px"
          borderColor="gray100"
          style={{width: '280px'}}
          onClick={menuList.onToggle}
          {...buttonProps}
        >
          <Text
            style={{width: '150px', fontSize: 14}}
            color={selectedCustomerGroupId ? 'previewPrimary' : 'gray500'}
            marginLeft="12px"
          >
            {selectedCustomerGroupId ?? 'None selected'}
          </Text>
          {!menuList.isOpen ? (
            <ChevronDownIcon boxSize="16px" color="gray800" marginRight="12px" />
          ) : (
            <ChevronUpIcon boxSize="16px" color="gray800" marginRight="12px" />
          )}
        </BaseButton>
        <Button
          size="sm"
          bg="previewPrimary"
          borderTopLeftRadius="none"
          borderBottomLeftRadius="none"
          onClick={handleCustomerGroupChange}
        >
          Go
        </Button>
      </Flex>

      {selectedPreviewCustomerGroup && !isContentVisualization && (
        <Button
          variant="unstyled"
          height="auto"
          paddingX="0px"
          marginTop="4px"
          onClick={() => setSelectedCustomerGroupId(null)}
        >
          Clear
        </Button>
      )}
      {menuList.isOpen && (
        <Box
          className={styles.sortMenuPopover}
          style={
            openDirection === 'bottom'
              ? {top: rect?.height ?? 0}
              : {
                  bottom: rect?.height ?? 0,
                }
          }
        >
          <Flex
            as="li"
            justify="space-between"
            align="center"
            paddingX="12px"
            paddingY="8px"
            gap="16px"
            borderBottom="1px"
            borderColor="gray200"
          >
            <Input
              value={filterSearchRequest ?? ''}
              label="Search by ID"
              validatedSuccessfully={false}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFilterSearchRequest(e.target.value)
              }}
            />
            <Button
              variant="unstyled"
              paddingX="0px"
              height="auto"
              onClick={() => {
                setFilterSearchRequest(null)
              }}
            >
              <CloseIcon boxSize="16px" color="gray800" />
            </Button>
          </Flex>
          {selectedPreviewCustomerGroup && isContentVisualization && (
            <Flex
              as="li"
              paddingY="16px"
              align="center"
              justify="center"
              aria-label="Clear selected customer group"
              cursor="pointer"
              onClick={() => {
                setSelectedCustomerGroupId(null)
                menuList.onClose()
              }}
            >
              <Text variant="text5" flex="1" marginLeft="8px" textAlign="center">
                Clear
              </Text>
            </Flex>
          )}

          <Box as="ul" className={styles.sortMenuPopoverList}>
            {options
              ?.filter((opt) => {
                if (!filterSearchRequest) return true

                return opt?.id?.toLowerCase()?.includes(filterSearchRequest.toLowerCase())
              })
              ?.map((opt) => {
                const isSelected = selectedPreviewCustomerGroup === opt.id
                return (
                  <Flex
                    as="li"
                    className={cn(styles.sortMenuItem, {
                      [styles.sortMenuItemSelected]: isSelected,
                    })}
                    key={opt.id}
                    align="center"
                    onClick={() => {
                      setSelectedCustomerGroupId(!isSelected ? opt.id : null)
                      menuList.onClose()
                    }}
                  >
                    <Text
                      flex="1"
                      marginLeft="8px"
                      textAlign="left"
                      paddingRight={isSelected ? '0px' : '16px'}
                      style={{width: '150px'}}
                    >
                      {opt.id}
                    </Text>
                    {isSelected && (
                      <CheckIcon height="16px" width="16px" color="gray800" marginRight="12px" />
                    )}
                  </Flex>
                )
              })}
          </Box>
        </Box>
      )}
    </Stack>
  )
})
