import React, {useState, useEffect} from 'react'
import LazyLoad from 'react-lazyload'
import {SlotComponent} from '../../types/ui'
import {Recommender} from '../recommendations'
import {Stack} from '../../vanilla'
import {WelcomeBack} from '../welcome-back'
import {CTA} from '../../types/cms'
import {useGlobalStore} from '../../store/hooks/useStore'
interface ProductCarouselProps {
  einstein: {
    recommenderId: string
  }
  showFavourites?: boolean
  title?: string
  subtitle?: string
  category?: {
    name: string
    id: string
  }
  cta: CTA
}

export const ProductCarousel: SlotComponent<ProductCarouselProps> = ({content}) => {
  if (!content) {
    return null
  }
  const {category, showFavourites, title, subtitle, einstein, cta} = content
  const globalStore = useGlobalStore()

   // Sets current custom site preferences for three badges in state
   const [pdpTopRightTileBadgeEnabled, setPdpTopRightTileBadgeEnabled] = useState(false)
   const [pdpMiddleRightTileBadgeEnabled, setPdpMiddleRightTileBadgeEnabled] = useState(false)
   const [pdpBottomRightTileBadgeEnabled, setPdpBottomRightTileBadgeEnabled] = useState(false)
   
   // Updates state with current custom site preferences for three badges
   useEffect(() => {
     setPdpTopRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.topRightBadgeCustomPreference as boolean)
     setPdpMiddleRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.middleRightBadgeCustomPreference as boolean)
     setPdpBottomRightTileBadgeEnabled(globalStore.badgesCustomPreferences?.bottomRightBadgeCustomPreference as boolean)
   }, [])

  return (
    <Stack>
      {showFavourites ? (
          <WelcomeBack 
          cta={cta} 
          title={title} 
          subtitle={subtitle}
          pdpTopRightTileBadgeEnabled={pdpTopRightTileBadgeEnabled}
          pdpMiddleRightTileBadgeEnabled={pdpMiddleRightTileBadgeEnabled}
          pdpBottomRightTileBadgeEnabled={pdpBottomRightTileBadgeEnabled} />
      ) : (
        <Stack>
          {einstein && (
            <LazyLoad once>
              <Recommender
                name={title}
                recommender={einstein?.recommenderId}
                categoryId={category?.id}
                pdpTopRightTileBadgeEnabled={pdpTopRightTileBadgeEnabled}
                pdpMiddleRightTileBadgeEnabled={pdpMiddleRightTileBadgeEnabled}
                pdpBottomRightTileBadgeEnabled={pdpBottomRightTileBadgeEnabled}
              />
            </LazyLoad>
          )}
        </Stack>
      )}
    </Stack>
  )
}
