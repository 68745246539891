import React, { useEffect } from 'react'
import {CTA, ColorPicker, Image} from '../../types/cms'
import {Stack, Flex} from '../../vanilla'
import {CmsImage} from './cms-image'
import {SlotComponent} from '../../types/ui'
import {CTAButton} from '../../vanilla/components/CTAButton'
import {inGridCardCtaButton} from './styles.css'
import {usePromotionDataTracking, useSelectPromotionTracking} from '../../analytics/promotion-tracking'

export interface GridCardProps {
  showOnDesktop: boolean | undefined
  image: {images: Image[]}
  imageLink: string
  onClick?: (imageLink: string) => void
  cta?: CTA
  backgroundColor?: ColorPicker
  index: number
}

export const GridCard: SlotComponent<GridCardProps> = ({content}) => {
  const {showOnDesktop, image, imageLink, onClick, cta, backgroundColor, index} = content
  const isShowDesktopDefined = showOnDesktop !== undefined
  const displayProps = !showOnDesktop
    ? (['block', 'block', 'none'] as const)
    : (['none', 'none', 'block'] as const)

  const promotion = [{
    creative_name:  content?.image?.images[0]?.data?.altText ?? 'InGrid Card missing alt text',
    creative_slot: `${content?._meta?.name} ${index}` ?? 'InGrid Card',
    promotion_id: content?.imageLink ?? 'InGrid Card without link',
    promotion_name: content?.cta?.ctaText ?? 'InGrid Card without cta',
    link_position: index ?? 1
  }]
    
  const sendPromotionData = usePromotionDataTracking();
  const sendSelectPromotion = useSelectPromotionTracking()

    useEffect(() => {
      if (content.showOnDesktop) {
        sendPromotionData([[content]]);
      }
  }, [image]);

  return (
    <Stack
      gridColumn={['1', 'span 2']}
      display={isShowDesktopDefined ? displayProps : 'block'}
      onClick={() => onClick?.(imageLink)}
      onClickCapture={() => sendSelectPromotion(promotion[0])}
    >
      <CmsImage
        images={image?.images}
        imageLink={imageLink}
        width="full"
        style={{verticalAlign: 'top'}}
      />
      {cta?.ctaText && (
        <Flex
          height={['60px', '80px', '80px']}
          alignItems="center"
          justifyContent="center"
          style={{backgroundColor: backgroundColor?.theColor}}
        >
          <CTAButton 
            cta={cta} 
            extraClassName={inGridCardCtaButton} 
            onClickCapture={() => sendSelectPromotion(promotion[0])}
          />
        </Flex>
      )}
    </Stack>
  )
}
