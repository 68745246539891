import React, {useState, useEffect} from 'react'
import {BaseButton, Stack, Text} from '../../vanilla'
import {Link} from '../link'
import LoadingOverlay from '../loading-overlay'
import useDeliveryBooking from '../../hooks/use-delivery-booking'
import {useBasketStore, useCustomerStore} from '../../store/hooks/useStore'
import { DateUtils } from '../../utils/date-utils'

export const ChangeOrBookDelivery = ({postcode = ''}) => {
  const {clearPostalCode, loading} = useDeliveryBooking()
  const {nextAvailableSlot} = useBasketStore()
  const {isRegistered} = useCustomerStore()
 
  const [nextDeliverableSlot, setNextDeliverableSlot] = useState<{time: string; date: string} | null>(
    null,
  )

  useEffect(() => {
    if (nextAvailableSlot) {
      const [time, date] =
        DateUtils.formatDeliveryDate(nextAvailableSlot.windowStartTime, nextAvailableSlot.windowEndTime, 'do MMMM') || []
        setNextDeliverableSlot({time, date})
    }
  }, [nextAvailableSlot])

  return (
    <Stack
      width={['full', 'auto']}
      flexDirection={['column', 'row']}
      spacing={['16px', '24px']}
      position="relative"
      data-test-selector="book-delivery"
    >
      <LoadingOverlay isLoading={loading} />
      <Stack spacing="0px">
        <Text
          textAlign={['left', 'right']}
          variant="text4"
          color="accent0"
          lineHeight="short"
          data-test-selector="postcode-success"
          data-cs-mask=""
        >
          {isRegistered && nextAvailableSlot
            ? `Next available delivery for ${postcode}`
            : `Great! We'll deliver to ${postcode}`}
        </Text>
        <Text textAlign={['left', 'right']} variant="text4" lineHeight="short">
          {isRegistered && nextAvailableSlot ? (
            `${nextDeliverableSlot?.time}, ${nextDeliverableSlot?.date}`
          ) : (
            <>
              Postcode not right?{' '}
              <Text
                as="span"
                variant="text4"
                padding="0px"
                textDecoration="underline"
                color="accent3"
                lineHeight="short"
                data-test-selector="change-postcode"
                cursor="pointer"
                onClick={clearPostalCode}
              >
                Change it
              </Text>
            </>
          )}
        </Text>
      </Stack>
      <BaseButton
        as={Link}
        href="/book-delivery"
        variant="primary"
        size="sm"
        width={['full', 'auto']}
      >
        <Text variant="unstyled" fontSize="inherit" lineHeight="inherit">
          Book Delivery
        </Text>
      </BaseButton>
    </Stack>
  )
}
